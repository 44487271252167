define("js-common/helpers/is-orphan-matter-field", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isOrphanMatterField = isOrphanMatterField;
  _exports.default = void 0;

  /**
   * decides if a given matterField has a parent that is disabled in a certain situation
   *
   * @param [matterField, allMatterFields, requirement]
   * requirement = one of the computed properties on the matter-field model that decides if a
   *  field is enabled or not in a certain page/table/report (example: isViewableWorkflow, isViewableSubject, etc.)
   *
   * @returns boolean
   *
   * if a parent field is given as a param to this helper, it will return true.
   */
  function isOrphanMatterField(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 3),
        matterField = _ref2[0],
        allMatterFields = _ref2[1],
        requirement = _ref2[2];

    if (!Ember.isPresent(matterField.get('mergeField'))) {
      return true;
    }

    var parentField = allMatterFields.findBy('value', matterField.get('mergeField'));
    return !parentField || !parentField.get(requirement);
  }

  var _default = Ember.Helper.helper(isOrphanMatterField);

  _exports.default = _default;
});