define('ember-controller-lifecycle/initializers/controller-lifecycle', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  Ember.Route.reopen({
    setupController: function setupController(controller) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, arguments);

      if (controller.setup) {
        controller.setup.apply(controller, _toConsumableArray(args));
      }
    },
    resetController: function resetController(controller) {
      for (var _len2 = arguments.length, args = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        args[_key2 - 1] = arguments[_key2];
      }

      this._super.apply(this, arguments);

      if (controller.reset) {
        controller.reset.apply(controller, _toConsumableArray(args));
      }
    }
  });

  function initialize() /* application */{
    // application.inject('route', 'foo', 'service:foo');
  }

  exports.default = {
    initialize: initialize
  };
});