define("js-common/templates/components/custom-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+XiKAzRI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"custom-checkbox\"],[9],[0,\"\\n  \"],[1,[29,\"x-checkbox\",null,[[\"checked\",\"id\",\"class\",\"disabled\"],[[25,[\"checked\"]],[25,[\"id\"]],\"custom-checkbox-input\",[25,[\"disabled\"]]]]],false],[0,\"\\n  \"],[7,\"label\"],[12,\"for\",[30,[\"customCheckbox\",[23,\"elementId\"]]]],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/custom-checkbox.hbs"
    }
  });

  _exports.default = _default;
});