define("@smith-carson/ui/templates/components/sc-icon-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TmHE8a4O",
    "block": "{\"symbols\":[\"@icon\",\"@iconSet\",\"@hoverIcon\",\"@fixedWidthIcon\",\"@onClick\",\"@isRunning\",\"@disabled\",\"&attrs\",\"&default\"],\"statements\":[[5,\"sc-button\",[[13,\"disabled\",[29,\"or\",[[24,7,[]],false],null]],[13,\"title\",[24,0,[\"title\"]]],[13,\"class\",\"sc-icon-button\"],[14,8]],[[\"@icon\",\"@iconSet\",\"@hoverIcon\",\"@fixedWidthIcon\",\"@onClick\",\"@isRunning\",\"@color\",\"@hoverColor\",\"@iconSize\",\"@size\",\"@type\"],[[24,1,[]],[24,2,[]],[24,3,[]],[24,4,[]],[24,5,[]],[24,6,[]],[24,0,[\"color\"]],[24,0,[\"hoverColor\"]],[24,0,[\"size\"]],[24,0,[\"buttonSize\"]],[24,0,[\"type\"]]]],{\"statements\":[[0,\"\\n  \"],[15,9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-icon-button.hbs"
    }
  });

  _exports.default = _default;
});