define("@smith-carson/ui/templates/components/sc-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KL3jTE96",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[29,\"hash\",null,[[\"item\",\"link-to\",\"link\",\"dropdown\"],[[29,\"component\",[[25,[\"itemComponent\"]]],null],[29,\"component\",[[25,[\"linkToComponent\"]]],null],[29,\"component\",[[25,[\"linkComponent\"]]],[[\"href\",\"target\",\"tagName\"],[[25,[\"href\"]],[25,[\"target\"]],\"a\"]]],[29,\"component\",[[25,[\"dropdownComponent\"]]],[[\"inNav\",\"tagName\"],[true,\"li\"]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-nav.hbs"
    }
  });

  _exports.default = _default;
});