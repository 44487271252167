define("js-common/models/permission-has-endpoint", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    endpoint: _emberData.default.attr('string'),
    mode: _emberData.default.attr('number'),
    permission: _emberData.default.belongsTo('permission', {
      async: false
    })
  });

  _exports.default = _default;
});