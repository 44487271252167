define("ember-light-table/templates/components/lt-foot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IkvcpTui",
    "block": "{\"symbols\":[\"column\",\"column\",\"&default\"],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\",\"renderInPlace\"],[[29,\"concat\",[[25,[\"tableId\"]],\"_inline_foot\"],null],[25,[\"renderInPlace\"]]]],{\"statements\":[[0,\"  \"],[7,\"table\"],[12,\"class\",[23,\"tableClassNames\"]],[9],[0,\"\\n    \"],[7,\"tfoot\"],[11,\"class\",\"lt-foot\"],[9],[0,\"\\n\"],[0,\"      \"],[7,\"tr\"],[11,\"class\",\"lt-scaffolding-row\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"columns\"]]],null,{\"statements\":[[0,\"          \"],[7,\"td\"],[12,\"style\",[29,\"html-safe\",[[29,\"if\",[[24,2,[\"width\"]],[29,\"concat\",[\"width: \",[24,2,[\"width\"]]],null]],null]],null]],[11,\"class\",\"lt-scaffolding\"],[9],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"      \"],[10],[0,\"\\n\"],[4,\"if\",[[26,3]],null,{\"statements\":[[0,\"        \"],[15,3,[[25,[\"columns\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"tr\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"columns\"]]],null,{\"statements\":[[0,\"            \"],[1,[29,\"component\",[[29,\"concat\",[[25,[\"columnComponentPath\"]],[24,1,[\"type\"]]],null],[24,1,[]]],[[\"table\",\"tableActions\",\"extra\",\"sortIcons\",\"resizeOnDrag\",\"click\",\"doubleClick\",\"onColumnResized\",\"onColumnDrag\",\"onColumnDrop\"],[[25,[\"table\"]],[25,[\"tableActions\"]],[25,[\"extra\"]],[25,[\"sortIcons\"]],[25,[\"resizeOnDrag\"]],[29,\"action\",[[24,0,[]],\"onColumnClick\",[24,1,[]]],null],[29,\"action\",[[24,0,[]],\"onColumnDoubleClick\",[24,1,[]]],null],[29,\"action\",[[24,0,[]],\"onColumnResized\"],null],[29,\"action\",[[24,0,[]],\"onColumnDrag\"],null],[29,\"action\",[[24,0,[]],\"onColumnDrop\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/lt-foot.hbs"
    }
  });

  _exports.default = _default;
});