define("js-common/components/task-button", ["exports", "js-common/templates/components/task-button"], function (_exports, _taskButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _taskButton.default,
    tagName: 'button',
    attributeBindings: ['type', 'disabled'],
    classNameBindings: ['task.isRunning:running'],
    idleText: 'Save',
    runningText: 'Saving..',
    onInit: Ember.on('init', function () {
      if (this.disabled) {
        this.set('disabled', 'disabled');
      }

      (false && !(this.task) && Ember.assert('You have to provide a task to `task-button`', this.task));
      var runningClass = this.runningClass;

      if (runningClass) {
        this.set('classNameBindings', ["task.isRunning:".concat(runningClass)]);
      }
    }),
    text: Ember.computed('task.isRunning', function () {
      var idleText = this.idleText;
      var runningText = this.runningText;

      if (runningText && this.get('task.isRunning')) {
        return runningText;
      } else {
        return idleText;
      }
    })
  });

  _exports.default = _default;
});