define("js-common/helpers/string-contains", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stringContains = stringContains;
  _exports.default = void 0;

  /**
   * checks if string1 contains string2
   *
   * @param params
   * @returns {*}
   */
  function stringContains(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        first = _ref2[0],
        second = _ref2[1];

    return first && second ? first.indexOf(second) >= 0 : false;
  }

  var _default = Ember.Helper.helper(stringContains);

  _exports.default = _default;
});