define("@smith-carson/ui/pods/components/light-table/cells/external-link/component", ["exports", "ember-light-table/components/cells/base", "@smith-carson/ui/pods/components/light-table/cells/external-link/template"], function (_exports, _base, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides affordance for generating a custom link from row data. Leverages
      the `valuePath` value for the link text and exposes a `linkHref` method on
      the `column` definition that receives the `row` object and returns the
      custom `href` value.
  
      @module LightTable::Cells::ExternalLinkComponent
      @extends EmberLightTable.CellsBaseComponent
   */
  var _default = _base.default.extend({
    classNames: ['external-link-cell'],
    layout: _template.default,
    init: function init() {
      this._super.apply(this, arguments);

      var column = this.column,
          row = this.row;

      var _column$getProperties = column.getProperties('extra', 'format', 'valuePath'),
          valuePath = _column$getProperties.valuePath;

      (false && !(valuePath && typeof column.linkHref === 'function') && Ember.assert('You must provide a `valuePath` and a `linkHref` method to the `LinkToComponent`', valuePath && typeof column.linkHref === 'function'));
      this.set('rowValue', row.get(valuePath));
    },

    /**
        Provides the value for the link's `href attribute by passing the `row`
        to the `linkHref` method on the `column` definition.
         @property linkHref
        @type {String}
     */
    linkHref: Ember.computed('column', 'row', function () {
      var column = this.column,
          row = this.row;
      var linkHref = column.linkHref;
      return linkHref(row);
    }),
    actions: {
      /**
          @method onLinkClick
          @param {MouseEvent} event
       */
      onLinkClick: function onLinkClick(event) {
        event.stopPropagation();
      }
    }
  });

  _exports.default = _default;
});