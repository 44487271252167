define("js-common/helpers/dates-are-equal", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.datesAreEqual = datesAreEqual;
  _exports.default = void 0;

  function datesAreEqual(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        date1 = _ref2[0],
        date2 = _ref2[1];

    return date1.toString() === date2.toString();
  }

  var _default = Ember.Helper.helper(datesAreEqual);

  _exports.default = _default;
});