define("ember-scrollable/templates/components/ember-scrollbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hzlL/r0T",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"drag-handle \",[29,\"if\",[[25,[\"showHandle\"]],\"visible\"],null]]]],[12,\"onmousedown\",[29,\"action\",[[24,0,[]],\"startDrag\"],null]],[12,\"style\",[23,\"handleStyles\"]],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-scrollable/templates/components/ember-scrollbar.hbs"
    }
  });

  _exports.default = _default;
});