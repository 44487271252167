define("js-common/components/display-tags", ["exports", "js-common/templates/components/display-tags"], function (_exports, _displayTags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _displayTags.default,
    tagName: 'span'
  });

  _exports.default = _default;
});