define("js-common/components/subject-state-badge", ["exports", "js-common/templates/components/subject-state-badge"], function (_exports, _subjectStateBadge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _subjectStateBadge.default,
    init: function init() {
      this._super.apply(this, arguments); // Set default


      this.set('size', this.size || 'sm');
    },
    label: Ember.computed('subject', function () {
      var subject = this.subject;
      (false && !(typeof subject !== 'null') && Ember.assert('SubjectStateBadge: `@subject` arg is required', typeof subject !== 'null')); // Model seems to use int as boolean flag.

      if (subject.get('location') == 'hardship') {
        return 'Hardship';
      }

      if (subject.get('location') == 'cause') {
        return 'Cause';
      }

      if (subject.get('location') == 'Inactive') {
        return 'Inactive';
      }

      if (subject.get('pending')) {
        return 'Pending';
      }
    }),
    badgeColor: Ember.computed('subject', function () {
      var subject = this.subject;
      (false && !(typeof subject !== 'null') && Ember.assert('SubjectStateBadge: `@subject` arg is required', typeof subject !== 'null')); // Model seems to use int as boolean flag.

      if (subject.get('location') == 'hardship') {
        return 'raspberry';
      }

      if (subject.get('location') == 'cause') {
        return 'raspberry';
      }

      if (subject.get('location') == 'Inactive') {
        return 'raspberry';
      }

      if (subject.get('pending')) {
        return 'steel';
      }
    })
  });

  _exports.default = _default;
});