define("js-common/templates/components/analytic-widget/criminal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BLaJEzCj",
    "block": "{\"symbols\":[\"@stats\",\"@canEdit\",\"@toggleViewable\"],\"statements\":[[5,\"sc-analytic-card\",[],[[\"@isEnabled\",\"@canEdit\",\"@onToggle\",\"@title\"],[[24,1,[\"criminal_cases_comparison\",\"matterHasStat\",\"isEnabled\"]],[24,2,[]],[29,\"action\",[[24,0,[]],[24,3,[]],[24,1,[\"criminal_cases_comparison\",\"matterHasStat\"]]],null],\"Criminal Cases\"]],{\"statements\":[[0,\"\\n  \"],[5,\"sc-chart\",[],[[\"@chartType\",\"@chartData\"],[\"horizontal-bar-chart\",[24,0,[\"criminalCasesData\"]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/analytic-widget/criminal.hbs"
    }
  });

  _exports.default = _default;
});