define("ember-math-helpers/helpers/cos", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cos = cos;
  _exports.default = void 0;

  /**
   * Executes `Math.cos` on the number passed to the helper.
   *
   * ```hbs
   * {{cos a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.cos`
   * @return {number} The cos of the passed number
   */
  function cos(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        number = _ref2[0];

    return Math.cos(number);
  }

  var _default = Ember.Helper.helper(cos);

  _exports.default = _default;
});