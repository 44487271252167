define("@smith-carson/ui/pods/components/sc-light-table/cells/days-since-last-report/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yXs71uv9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"case-days-since-last-report-cell\"],[11,\"data-test-component\",\"case-days-since-last-report-cell\"],[9],[0,\"\\n    \"],[7,\"span\"],[12,\"class\",[24,0,[\"alertDaysSinceLastReportStyle\"]]],[9],[0,\"\\n       \"],[1,[24,0,[\"row\",\"calcDaysSinceLastReport\"]],false],[0,\"\\n        \"],[5,\"bs-tooltip\",[],[[\"@placement\"],[\"right\"]],{\"statements\":[[1,[24,0,[\"alertDaysSinceLastReportTooltip\"]],false]],\"parameters\":[]}],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/pods/components/sc-light-table/cells/days-since-last-report/template.hbs"
    }
  });

  _exports.default = _default;
});