define("@smith-carson/ui/pods/components/sc-light-table/cells/case-tm-code/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oa8Ch8Ye",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,0,[\"renderLink\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[12,\"data-test-link\",[24,0,[\"tmCode\"]]],[12,\"href\",[24,0,[\"linkHref\"]]],[12,\"title\",[24,0,[\"linkTitle\"]]],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"onLinkClick\"],null]],[9],[0,\"\\n        \"],[7,\"span\"],[12,\"data-test-span\",[24,0,[\"tmCode\"]]],[9],[0,\"\\n            \"],[1,[24,0,[\"tmCode\"]],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\"],[12,\"data-test-span\",[24,0,[\"tmCode\"]]],[9],[0,\"\\n        \"],[1,[24,0,[\"tmCode\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/pods/components/sc-light-table/cells/case-tm-code/template.hbs"
    }
  });

  _exports.default = _default;
});