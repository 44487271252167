define("js-common/mixins/crud/error", ["exports", "ember-changeset/utils/is-changeset"], function (_exports, _isChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * a basic helper mixin that provides common logic when handling errors generated from the API
   */
  var _default = Ember.Mixin.create({
    notify: Ember.inject.service(),
    title: null,
    detail: null,
    meta: null,
    validationList: [],

    /**
     * the error handling api has shifted before
     * this function will pull error related data from a given model and EXTRA normalize it for our api
     * so the rest of the mixin can pull the data it needs for display
     * @param model
     */
    loadError: function loadError(modelOrChangeset) {
      var model = modelOrChangeset;

      if ((0, _isChangeset.default)(modelOrChangeset)) {
        model = modelOrChangeset.get('_content');
        this.syncErrors(model, modelOrChangeset);
      }

      var errors = model.get('errors'); // here is a direct way to access the title.message
      // var title = model.get('errors.title')[0].message;
      // list of validation messages if any

      var validationList = []; // Internal error variables

      try {
        this.set('title', errors.errorsFor('additional_info')[0].message.title);
      } catch (e) {
        this.set('title', 'Validation errors');
      } // ['title', 'detail', 'meta', 'code', 'status'].forEach(function(name) {
      // var message = additionalInfo[name];
      // if (Ember.isPresent(message)) {
      // self.set(name, message.get('firstObject.message'));
      // }
      // });


      model.eachAttribute(function (name) {
        var message = errors.errorsFor(name.camelize());

        if (Ember.isPresent(message)) {
          validationList.pushObject({
            attribute: name,
            message: message.get('firstObject.message')
          });
        }
      });
      model.eachRelationship(function (name, descriptor) {
        if (descriptor.kind === 'belongsTo') {
          var message = errors.errorsFor(name + 'Id');

          if (Ember.isPresent(message)) {
            validationList.pushObject({
              attribute: name,
              message: message.get('firstObject.message')
            });
          }
        }
      });
      this.set('validationList', validationList);
    },

    /**
     * a method used to sync server errors with a local changeset
     * to display the errors inline
     * @param model Ember.Data.Model
     * @param changeset Changeset
     */
    syncErrors: function syncErrors(model, changeset) {
      Ember.get(model, 'errors').forEach(function (_ref) {
        var attribute = _ref.attribute,
            message = _ref.message;

        if (Ember.typeOf(message) === 'string') {
          changeset.pushErrors(attribute, message);
        }
      });
    },

    /**
     * stripped down to only report basic error messages
     * @param response
     */
    handleXHR: function handleXHR(response) {
      if (response && 'payload' in response) {
        response = response.payload;
      }

      if (response && 'errors' in response && response.errors) {
        var errors = response.errors;
        var error = errors[0].additional_info;
        var errorHTML = 'Error #' + error.code + ' - ' + error.title;

        if (!Ember.isEmpty(error.details)) {
          errorHTML = errorHTML + '<p>' + error.details + '</p>';
        }

        this.get('notify').alert({
          html: errorHTML
        });
      }
    },
    handleModelAndXHR: function handleModelAndXHR(response) {
      if (response && 'payload' in response) {
        response = response.payload;
      }

      if (response && 'errors' in response && response.errors) {
        var errors = response.errors;
        var errorHTML = '<ul>';

        for (var key in errors[0]) {
          if (key == 'additional_info') {
            errorHTML = errorHTML.concat('<li>' + errors[0][key].title + '</li>');
          } else {
            if (Ember.typeOf(errors[0][key]) != 'object') {
              errorHTML = errorHTML.concat('<li>' + errors[0][key] + '</li>');
            }
          }
        }

        errorHTML = errorHTML.concat('</ul>');
        this.get('notify').alert({
          html: errorHTML
        });
      }
    },

    /**
     * stripped down to only report basic error messages from custom endpoints
     * @param error
     */
    handleInvalidError: function handleInvalidError(error) {
      var errorHTML = '';
      var ignore = ['/data/attributes/code', '/data/attributes/status', '/data/attributes/meta'];

      if (!Ember.isEmpty(error.errors)) {
        error.errors.forEach(function (error) {
          if (!ignore.includes(error.source.pointer)) {
            errorHTML = errorHTML + '<p>' + error.detail + '</p>';
          }
        });
      }

      this.get('notify').alert({
        html: errorHTML
      });
    },

    /**
     * only report a toaster with title and detail
     * relies of private properties so there is no expectation that this logic will age well
     */
    simpleReport: function simpleReport(model) {
      this.loadError(model);
      var errorHTML = '<h5>' + this.get('title') + '</h5>';

      if (!Ember.isEmpty(this.get('detail'))) {
        errorHTML = errorHTML + '<p>' + this.get('detail') + '</p>';
      }

      this.get('notify').alert({
        html: errorHTML
      });
    },

    /**
     * report toaster with title and validation messages
     * @param model
     */
    validationReport: function validationReport(model) {
      this.loadError(model);

      if (!model.get('isNew')) {
        if ((0, _isChangeset.default)(model)) {
          model.rollback();
        }
      }

      var errorHTML = '<h5>' + this.get('title') + '</h5>';
      var validationList = this.get('validationList');

      if (validationList.length > 0) {
        errorHTML = errorHTML.concat('<ul>');
        validationList.forEach(function (item) {
          errorHTML = errorHTML.concat('<li>' + item.message + '</li>');
        });
        errorHTML = errorHTML.concat('</ul>');
      }

      this.get('notify').alert({
        html: errorHTML
      });
    },

    /**
     * report issues with ember-validations object
     * @param model (mixed in with EmberValidations)
     */
    notifyClientSideValidations: function notifyClientSideValidations(model) {
      var nestedAttribute = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var humanReadableFieldNames = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var errorHTML = '<h5>Validations failed</h5>';
      errorHTML = errorHTML.concat('<ul>');
      nestedAttribute.split(',').forEach(function (nested) {
        var key = 'errors.' + nested;

        if (nested === '') {
          key = 'errors';
        }

        var errors = model.get(key);

        for (var field in errors) {
          if (errors.hasOwnProperty(field)) {
            var message = errors[field].join(', ');

            if (message !== '') {
              errorHTML = errorHTML.concat("<li>".concat(humanReadableFieldNames[field] ? humanReadableFieldNames[field] : field, " ").concat(message, "</li>"));
            }
          }
        }
      });
      errorHTML = errorHTML.concat('</ul>');
      this.get('notify').alert({
        html: errorHTML
      });
    }
  });

  _exports.default = _default;
});