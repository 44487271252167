define("ember-bootstrap/templates/components/bs-form/element/layout/horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8vVc6+U8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"hasLabel\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"component\",[[25,[\"labelComponent\"]]],[[\"labelClass\"],[[25,[\"horizontalLabelGridClass\"]]]]],false],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[23,\"horizontalInputGridClass\"]],[9],[0,\"\\n    \"],[15,1],[0,\"\\n    \"],[1,[29,\"component\",[[25,[\"errorsComponent\"]]],null],false],[0,\"\\n    \"],[1,[29,\"component\",[[25,[\"helpTextComponent\"]]],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[30,[[23,\"horizontalInputGridClass\"],\" \",[23,\"horizontalInputOffsetGridClass\"]]]],[9],[0,\"\\n    \"],[15,1],[0,\"\\n    \"],[1,[29,\"component\",[[25,[\"errorsComponent\"]]],null],false],[0,\"\\n    \"],[1,[29,\"component\",[[25,[\"helpTextComponent\"]]],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/layout/horizontal.hbs"
    }
  });

  _exports.default = _default;
});