define("js-common/components/subject-icons/flagged", ["exports", "js-common/templates/components/subject-icons/flagged"], function (_exports, _flagged) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _flagged.default,
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments); // Set defaults


      this.set('showPopover', this.showPopover || true);
    }
  });

  _exports.default = _default;
});