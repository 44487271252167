define("@smith-carson/ui/pods/components/sc-light-table/cells/days-since-last-report/component", ["exports", "moment", "@smith-carson/ui/pods/components/sc-light-table/cells/days-since-last-report/template"], function (_exports, _moment, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides a custom light-table cell for rendering the Case _Next Due_ column
      content.
  
      @module ScLightTable::Cells::CaseNextDueComponent
      @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    /**
        Calculates the number of days the dude date has been pushed back based
        on current next due date (`calcNextDueDate`) and initial due date
        (`calcInitialDueOn`).
         @property daysPushedBack
        @type {Number}
     */
    alertDaysSinceLastReport: Ember.computed('row.{calcNumberPublishedReports,calcDaysSinceLastReport}', function () {
      var row = this.row;

      var _row$getProperties = row.getProperties('calcDaysSinceLastReport'),
          calcDaysSinceLastReport = _row$getProperties.calcDaysSinceLastReport;

      return calcDaysSinceLastReport > 90;
    }),

    /**
        Provides the bootstrap text color utility class for the "pushed back"
        indicator icon based on the number of days the due date has been
        pushed back.
         @property pushedBackTextClass
        @type {String}
     */
    alertDaysSinceLastReportStyle: Ember.computed('alertDaysSinceLastReport', function () {
      var alertDaysSinceLastReport = this.alertDaysSinceLastReport;

      if (!alertDaysSinceLastReport) {
        return null;
      }

      return 'text-danger';
    }),

    /**
        Provides the tooltip text for the "pushed back" indicator.
         @property pushedBackTooltip
        @type {String}
     */
    alertDaysSinceLastReportTooltip: Ember.computed('alertDaysSinceLastReport', 'row.calcDaysSinceLastReport', function () {
      var alertDaysSinceLastReport = this.alertDaysSinceLastReport,
          row = this.row;

      var _row$getProperties2 = row.getProperties('calcDaysSinceLastReport'),
          calcDaysSinceLastReport = _row$getProperties2.calcDaysSinceLastReport;

      if (!alertDaysSinceLastReport) {
        return null;
      }

      return Ember.String.htmlSafe("+".concat(calcDaysSinceLastReport, " days since last report"));
    })
  });

  _exports.default = _default;
});