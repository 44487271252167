define("js-common/components/field-validation-errors", ["exports", "js-common/templates/components/field-validation-errors"], function (_exports, _fieldValidationErrors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _fieldValidationErrors.default,
    propertyName: '',
    // Field to extract validations from the changeset
    changeset: null // The ember-changeset that will execute the validations

  });

  _exports.default = _default;
});