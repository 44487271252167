define("js-common/helpers/subject-field", ["exports", "jquery", "moment"], function (_exports, _jquery, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.subjectField = subjectField;
  _exports.default = void 0;

  /**
   * take in a subject and matterField
   * return the subject value for the provided matterField "value"
   *
   * @param params
   * @returns {*}
   */
  function subjectField(params
  /*, hash */
  ) {
    if (_jquery.default.isArray(params)) {
      var subject = params[0];
      var matterField = params[1];
      var breakTag = '<br />';
      var content = subject.get(matterField.get('name'));
      var fieldType = matterField.get('fieldType'); // do not return null or undefined values, just a blank space

      if (Ember.isEmpty(content)) {
        return '';
      }

      if (fieldType === 'date') {
        var dateValue = (0, _moment.default)(content, 'YYYY-MM-DD');

        if (dateValue.isValid()) {
          return dateValue.format('MM-DD-YYYY').toString();
        }
      }

      return Ember.String.htmlSafe((content + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2'));
    } // invalid params


    Ember.debug('Invalid parameters supplied to subject-field');
    return '';
  }

  var _default = Ember.Helper.helper(subjectField);

  _exports.default = _default;
});