define("js-common/templates/components/subject-icons/flagged", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I8F21wE+",
    "block": "{\"symbols\":[\"@subject\"],\"statements\":[[4,\"if\",[[24,1,[\"showIsFlagged\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[9],[0,\"\\n    \"],[5,\"sc-icon\",[],[[\"@icon\",\"@color\",\"@size\"],[\"flag2\",\"tiger\",\"lg\"]]],[0,\"\\n\"],[4,\"if\",[[24,0,[\"showPopover\"]]],null,{\"statements\":[[0,\"      \"],[5,\"sc-popover\",[],[[\"@title\",\"@triggerEvents\",\"@renderInPlace\",\"@placement\"],[\"Flag\",\"hover\",false,\"bottom\"]],{\"statements\":[[0,\"\\n        \"],[1,[24,1,[\"flagNote\"]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/subject-icons/flagged.hbs"
    }
  });

  _exports.default = _default;
});