define("@smith-carson/ui/templates/components/sc-form/element/control/radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mH6zwipJ",
    "block": "{\"symbols\":[\"option\",\"index\",\"label\",\"@name\",\"@value\",\"&default\",\"@optionLabelPath\",\"@options\"],\"statements\":[[4,\"each\",[[24,8,[]]],null,{\"statements\":[[4,\"let\",[[29,\"or\",[[24,1,[\"label\"]],[29,\"if\",[[24,7,[]],[29,\"get\",[[24,1,[]],[24,7,[]]],null],[24,1,[]]],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[30,[\"form-check\",[29,\"if\",[[25,[\"inline\"]],\" form-check-inline\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[26,6]],null,{\"statements\":[[0,\"        \"],[5,\"sc-radio-button\",[],[[\"@name\",\"@groupValue\",\"@onChange\",\"@value\"],[[24,4,[]],[24,5,[]],[29,\"action\",[[24,0,[]],[29,\"mut\",[[24,5,[]]],null],[24,1,[]]],null],[24,1,[]]]],{\"statements\":[[0,\"\\n          \"],[15,6,[[24,1,[]]]],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[5,\"sc-radio-button\",[],[[\"@name\",\"@label\",\"@groupValue\",\"@onChange\",\"@value\"],[[24,4,[]],[24,3,[]],[24,5,[]],[29,\"action\",[[24,0,[]],[29,\"mut\",[[24,5,[]]],null],[24,1,[]]],null],[24,1,[]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-form/element/control/radio.hbs"
    }
  });

  _exports.default = _default;
});