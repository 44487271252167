define("ember-light-table/templates/components/light-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wcdDvn9t",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[29,\"hash\",null,[[\"head\",\"body\",\"foot\"],[[29,\"component\",[[25,[\"headComponent\"]]],[[\"tableId\",\"table\",\"tableActions\",\"extra\",\"tableClassNames\",\"sharedOptions\"],[[25,[\"elementId\"]],[25,[\"table\"]],[25,[\"tableActions\"]],[25,[\"extra\"]],[25,[\"tableClassNames\"]],[25,[\"sharedOptions\"]]]]],[29,\"component\",[[25,[\"bodyComponent\"]]],[[\"tableId\",\"table\",\"tableActions\",\"extra\",\"tableClassNames\",\"sharedOptions\"],[[25,[\"elementId\"]],[25,[\"table\"]],[25,[\"tableActions\"]],[25,[\"extra\"]],[25,[\"tableClassNames\"]],[25,[\"sharedOptions\"]]]]],[29,\"component\",[[25,[\"footComponent\"]]],[[\"tableId\",\"table\",\"tableActions\",\"extra\",\"tableClassNames\",\"sharedOptions\"],[[25,[\"elementId\"]],[25,[\"table\"]],[25,[\"tableActions\"]],[25,[\"extra\"]],[25,[\"tableClassNames\"]],[25,[\"sharedOptions\"]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/light-table.hbs"
    }
  });

  _exports.default = _default;
});