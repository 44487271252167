define("@smith-carson/ui/templates/components/sc-light-table/cells/date-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T411Pnh9",
    "block": "{\"symbols\":[\"@value\"],\"statements\":[[1,[29,\"moment-format\",[[24,1,[]],\"MM/DD/YY @ hh:mma\"],null],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-light-table/cells/date-time.hbs"
    }
  });

  _exports.default = _default;
});