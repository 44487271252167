define("js-common/helpers/doc-type-display-name", ["exports", "js-common/utils/doc-type-display-name"], function (_exports, _docTypeDisplayName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.docTypeDisplayName = docTypeDisplayName;
  _exports.default = void 0;

  function docTypeDisplayName(params) {
    (false && !(Array.isArray(params) || !params[0]) && Ember.assert("helper:docTypeDisplayName: no `docType` provided", Array.isArray(params) || !params[0]));
    return (0, _docTypeDisplayName.default)(params[0]);
  }

  var _default = Ember.Helper.helper(docTypeDisplayName);

  _exports.default = _default;
});