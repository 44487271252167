define("js-common/models/document-group", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    groupName: _emberData.default.attr('string'),
    documentsCount: _emberData.default.attr('number'),
    isSubject: _emberData.default.attr('boolean'),
    idOrName: Ember.computed('id', 'groupName', function () {
      return this.isSubject ? this.id : this.groupName;
    })
  });

  _exports.default = _default;
});