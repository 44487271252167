define("@smith-carson/ui/pods/components/light-table/cells/expand-collapse/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wkGzjzZ2",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,0,[\"isExpandable\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"btn btn-link btn-xs d-inline-flex text-muted text-decoration-none\"],[11,\"data-test-button\",\"light-table-cell-expand-collapse\"],[11,\"type\",\"button\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"row\",\"expanded\"]]],null,{\"statements\":[[0,\"            \"],[5,\"sc-icon\",[],[[\"@icon\",\"@iconSet\"],[\"chevron-up\",\"fa\"]]],[0,\"\\n            \"],[7,\"span\"],[11,\"class\",\"sr-only\"],[9],[0,\"Collapse row\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[5,\"sc-icon\",[],[[\"@icon\",\"@iconSet\"],[\"chevron-down\",\"fa\"]]],[0,\"\\n            \"],[7,\"span\"],[11,\"class\",\"sr-only\"],[9],[0,\"Expand row\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[3,\"action\",[[24,0,[]],\"toggleRowExpanded\",[25,[\"row\"]]],[[\"bubbles\"],[false]]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/pods/components/light-table/cells/expand-collapse/template.hbs"
    }
  });

  _exports.default = _default;
});