define("ember-bootstrap/templates/components/bs-accordion/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sjEUEXfH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[27,1]],null,{\"statements\":[[0,\"  \"],[15,1,[[29,\"hash\",null,[[\"title\",\"body\"],[[29,\"component\",[[25,[\"titleComponent\"]]],[[\"collapsed\",\"disabled\",\"onClick\"],[[25,[\"collapsed\"]],[25,[\"disabled\"]],[29,\"action\",[[24,0,[]],[25,[\"onClick\"]],[25,[\"value\"]]],null]]]],[29,\"component\",[[25,[\"bodyComponent\"]]],[[\"collapsed\"],[[25,[\"collapsed\"]]]]]]]]]],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"component\",[[25,[\"titleComponent\"]]],[[\"collapsed\",\"disabled\",\"onClick\"],[[25,[\"collapsed\"]],[25,[\"disabled\"]],[29,\"action\",[[24,0,[]],[25,[\"onClick\"]],[25,[\"value\"]]],null]]],{\"statements\":[[0,\"    \"],[1,[23,\"title\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[25,[\"bodyComponent\"]]],[[\"collapsed\"],[[25,[\"collapsed\"]]]],{\"statements\":[[0,\"    \"],[15,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-accordion/item.hbs"
    }
  });

  _exports.default = _default;
});