define("js-common/models/matter-type", ["exports", "ember-data", "ember-api-actions"], function (_exports, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    prefix: attr('string'),
    name: attr('string'),
    active: attr('number'),
    tasking: attr('string'),
    newSubjectsArePublic: attr('number'),
    // not on the backend, only for show on the UI
    numberOfTaskTemplates: attr('number'),
    caseCategory: attr('string'),
    updateFieldDefaults: (0, _emberApiActions.memberAction)({
      path: 'update-field-defaults',
      type: 'post'
    })
  });

  _exports.default = _default;
});