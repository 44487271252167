define("js-common/helpers/target-blank-links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.targetBlankLinks = targetBlankLinks;
  _exports.default = void 0;

  function targetBlankLinks(params) {
    if (!Ember.isPresent(params)) return null;

    if (!Ember.isPresent(params[0].string) || Ember.typeOf(params[0].string) !== 'string') {
      return params[0];
    }

    return Ember.String.htmlSafe(params[0].string.replace(/<a href/g, '<a target="_blank" href'));
  }

  var _default = Ember.Helper.helper(targetBlankLinks);

  _exports.default = _default;
});