define("js-common/templates/components/timeline-list-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B4ODEz26",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"col-xs-12\"],[9],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"timeline2 timeline2-split\"],[9],[0,\"\\n    \"],[7,\"li\"],[11,\"class\",\"timeline2-item\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"timeline2-info\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"timeline2-marker begin-end\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"timeline2-content timeline2-header p-l-15\"],[9],[0,\"\\n        \"],[7,\"h3\"],[9],[0,\"\\n          \"],[1,[25,[\"matter\",\"personOfInterest\"]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[15,1],[0,\"\\n    \"],[7,\"li\"],[11,\"class\",\"timeline2-item\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"timeline2-info\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"timeline2-marker begin-end\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"timeline2-content\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/timeline-list-display.hbs"
    }
  });

  _exports.default = _default;
});