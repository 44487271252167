define("js-common/templates/components/edit-field/multiple-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B4W+Ov1u",
    "block": "{\"symbols\":[\"result\",\"search\",\"@renderInPlace\",\"@dropdownClass\"],\"statements\":[[7,\"div\"],[9],[0,\"\\n  \"],[5,\"sc-power-select-multiple\",[],[[\"@options\",\"@searchField\",\"@searchEnabled\",\"@selected\",\"@onchange\",\"@renderInPlace\",\"@dropdownClass\"],[[23,\"options\"],\"display\",true,[23,\"selectedMultiOptions\"],[23,\"updateSelectedOption\"],[24,3,[]],[24,4,[]]]],{\"statements\":[[0,\"\\n    \"],[1,[29,\"highlight-result\",[[29,\"get\",[[24,1,[]],[25,[\"optionDisplayField\"]]],null]],[[\"query\"],[[24,2,[]]]]],false],[0,\"\\n  \"]],\"parameters\":[1,2]}],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/edit-field/multiple-select.hbs"
    }
  });

  _exports.default = _default;
});