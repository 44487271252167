define("@smith-carson/ui/utils/sc-table/filter-operator", ["exports", "@babel/runtime/helpers/esm/classCallCheck", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _classCallCheck2, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Operator used for table filters
   */
  var FilterOperator = // operator name & identifier
  // display label
  // ember component path that will be used to render the filter widget
  // function that needs to return the serialized value for the API query
  // function that needs to return the if the current filter is valid
  // value options used for power select
  function FilterOperator(type, label, component, serialize, hasFilter) {
    (0, _classCallCheck2.default)(this, FilterOperator);
    (0, _defineProperty2.default)(this, "name", void 0);
    (0, _defineProperty2.default)(this, "label", void 0);
    (0, _defineProperty2.default)(this, "component", void 0);
    (0, _defineProperty2.default)(this, "serialize", void 0);
    (0, _defineProperty2.default)(this, "hasFilter", void 0);
    (0, _defineProperty2.default)(this, "options", null);
    (0, _defineProperty2.default)(this, "displayPath", 'label');
    (0, _defineProperty2.default)(this, "valuePath", 'value');
    this.name = type;
    this.label = label;
    this.component = component;
    this.serialize = serialize;
    this.hasFilter = hasFilter;
  };

  _exports.default = FilterOperator;
});