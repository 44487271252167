define("@smith-carson/ui/templates/components/sc-container/row/col", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7nCPaPH3",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[8,\"div\"],[13,\"class\",[30,[\"col sc-container__row__col \",[24,0,[\"heightClass\"]],\" \",[24,0,[\"sizeClass\"]]]]],[14,1],[9],[0,\"\\n  \"],[15,2],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-container/row/col.hbs"
    }
  });

  _exports.default = _default;
});