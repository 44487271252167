define("js-common/templates/components/x-preview-document/search-result-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "By57LBG/",
    "block": "{\"symbols\":[\"@search\",\"@context\",\"@goToMatch\"],\"statements\":[[7,\"a\"],[11,\"href\",\"\"],[9],[0,\"\\n  p.\\n  \"],[1,[29,\"inc\",[[24,2,[\"pageIdx\"]]],null],false],[0,\"\\n  -\\n\"],[4,\"if\",[[29,\"is-pending\",[[24,2,[\"context\"]]],null]],null,{\"statements\":[[0,\"    ...\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[29,\"highlight-result\",[[29,\"await\",[[24,2,[\"context\"]]],null]],[[\"query\",\"multiTerms\"],[[24,1,[]],true]]],false],[0,\"\\n\"]],\"parameters\":[]}],[3,\"action\",[[24,0,[]],[29,\"action\",[[24,0,[]],[24,3,[]],[24,2,[\"matchIdx\"]],[24,2,[\"pageIdx\"]]],null]]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/x-preview-document/search-result-preview.hbs"
    }
  });

  _exports.default = _default;
});