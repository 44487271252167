define("js-common/components/quick-print", ["exports", "js-common/templates/components/quick-print"], function (_exports, _quickPrint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _quickPrint.default,
    tagName: '',
    reportBuilder: Ember.inject.service(),
    notify: Ember.inject.service(),
    isAsync: false,
    enqueuedJobId: null,
    currentParams: {},
    columnFields: [],
    columnNames: [],
    columnWidths: [],
    includeFooter: false,
    fromTabular: false,
    headerText: null,
    customExtraParams: null,
    standardExtraParams: null,
    showOnlyPDF: false,
    showOnlyExcel: false,
    landscape: false,
    align: 'left',
    buttonIcon: 'download',
    buttonText: 'Export',
    itemCount: 0,
    styleForJSI: false,
    reportName: 'export_entity_find',
    entity: '',
    buttonColor: Ember.computed('itemCount', function () {
      return this.itemCount > 0 ? 'tiger' : 'dark';
    }),
    addExtraParams: function addExtraParams(jsonObject, type) {
      var extraParams = this.get(type + 'ExtraParams');

      if (extraParams) {
        // allow passing functions as extraParams so that ehy can be evaluated on demand
        if (extraParams instanceof Function) {
          extraParams = extraParams();
        }

        for (var key in extraParams) {
          jsonObject['report_builder'][type][key] = extraParams[key];
        }
      }
    },
    actions: {
      exportList: function exportList(format) {
        var _this = this;

        var jsonObject = {
          report_builder: {
            standard: {
              type: this.reportName,
              format: format,
              style: 'misc'
            },
            custom: {
              from_tabular: this.fromTabular,
              current_params: this.currentParams,
              column_fields: this.columnFields,
              column_names: this.columnNames,
              column_widths: this.columnWidths,
              entity: this.entity,
              header_text: this.headerText,
              landscape: this.landscape,
              include_footer: this.includeFooter,
              footer_center: 'Page [page] of [topage]'
            }
          }
        };
        this.addExtraParams(jsonObject, 'custom');
        this.addExtraParams(jsonObject, 'standard');

        if (this.isAsync) {
          this.reportBuilder.enqueueReport(jsonObject, function (response) {
            if (response.ok) {
              _this.set('enqueuedJobId', response.job);
            } else {
              _this.notify.error('There was a problem enqueueing the report for generation');
            }
          });
        } else {
          this.reportBuilder.executeReport(jsonObject);
        }
      }
    }
  });

  _exports.default = _default;
});