define("@smith-carson/ui/templates/components/sc-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cjq39otK",
    "block": "{\"symbols\":[\"&attrs\",\"@isShowing\"],\"statements\":[[4,\"if\",[[24,2,[]]],null,{\"statements\":[[0,\"  \"],[8,\"div\"],[13,\"class\",\"sc-loader\"],[14,1],[9],[0,\"\\n    \"],[1,[24,0,[\"label\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-loader.hbs"
    }
  });

  _exports.default = _default;
});