define("js-common/templates/components/display-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "McU2yEoO",
    "block": "{\"symbols\":[\"rht\"],\"statements\":[[4,\"each\",[[25,[\"resource\",\"resourceHasTags\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"label label-primary\"],[9],[0,\"\\n    \"],[1,[24,1,[\"tag\",\"name\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/display-tags.hbs"
    }
  });

  _exports.default = _default;
});