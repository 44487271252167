define("@smith-carson/ui/templates/components/sc-chart/horizontal-bar-chart/bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d7u2dAKK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"horizontal-bar-title\"],[9],[0,\"\\n  \"],[1,[23,\"title\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"flex-row outer-bar\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"inner-bar\"],[12,\"style\",[29,\"html-safe\",[[25,[\"barStyle\"]]],null]],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"barText\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"inner-bar-text\"],[9],[1,[23,\"barText\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"showTooltip\"]]],null,{\"statements\":[[0,\"  \"],[5,\"sc-tooltip\",[],[[\"@placement\",\"@color\"],[\"top\",\"dark\"]],{\"statements\":[[0,\"\\n    \"],[1,[23,\"hoverText\"],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"value\"],[9],[0,\"\\n  \"],[1,[23,\"value\"],false],[0,\"%\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-chart/horizontal-bar-chart/bar.hbs"
    }
  });

  _exports.default = _default;
});