define("@smith-carson/ui/templates/components/sc-sticky", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ifVnD20x",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[4,\"if\",[[29,\"not\",[[24,0,[\"isDestination\"]]],null]],null,{\"statements\":[[0,\"\\n  \"],[5,\"ember-wormhole\",[],[[\"@to\"],[[24,0,[\"edgeClass\"]]]],{\"statements\":[[0,\"\\n    \"],[15,2],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[8,\"div\"],[13,\"id\",[24,0,[\"edgeClass\"]]],[13,\"class\",[30,[\"sc-sticky sc-sticky--destination \",[24,0,[\"edgeClass\"]]]]],[14,1],[9],[0,\"\\n    \"],[15,2],[0,\"\\n  \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-sticky.hbs"
    }
  });

  _exports.default = _default;
});