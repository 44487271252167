define("js-common/models/collection-has-matter", ["exports", "ember-data", "js-common/models/matter"], function (_exports, _emberData, _matter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = _matter.default.extend({
    custom1: attr('string'),
    custom2: attr('string'),
    custom3: attr('string'),
    custom4: attr('string'),
    custom5: attr('string'),
    custom6: attr('string'),
    custom7: attr('string'),
    custom8: attr('string'),
    custom9: attr('string'),
    custom10: attr('string'),
    custom11: attr('string'),
    custom12: attr('string'),
    custom13: attr('string'),
    custom14: attr('string'),
    custom15: attr('string'),
    custom16: attr('string'),
    custom17: attr('string'),
    // workflow related fields
    dbInitialC: _emberData.default.attr('string'),
    dbInitialA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    dbInitialS: _emberData.default.attr('string'),
    dbMainC: _emberData.default.attr('string'),
    dbMainA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    dbMainS: _emberData.default.attr('string'),
    dbSuppC: _emberData.default.attr('string'),
    dbSuppA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    dbSuppS: _emberData.default.attr('string'),
    dbWitsC: _emberData.default.attr('string'),
    dbWitsA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    dbWitsS: _emberData.default.attr('string'),
    dbMonitorC: _emberData.default.attr('string'),
    dbMonitorA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    dbMonitorS: _emberData.default.attr('string'),
    fieldMainC: _emberData.default.attr('string'),
    fieldMainA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    fieldMainS: _emberData.default.attr('string'),
    fieldSuppC: _emberData.default.attr('string'),
    fieldSuppA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    fieldSuppS: _emberData.default.attr('string'),
    rptMainC: _emberData.default.attr('string'),
    rptMainA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    rptMainS: _emberData.default.attr('string'),
    rptSuppC: _emberData.default.attr('string'),
    rptSuppA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    rptSuppS: _emberData.default.attr('string'),
    qaInitialC: _emberData.default.attr('string'),
    qaInitialA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    qaInitialS: _emberData.default.attr('string'),
    qaMainC: _emberData.default.attr('string'),
    qaMainA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    qaMainS: _emberData.default.attr('string'),
    qaSuppC: _emberData.default.attr('string'),
    qaSuppA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    qaSuppS: _emberData.default.attr('string'),
    officeMainC: _emberData.default.attr('string'),
    officeMainA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    officeMainS: _emberData.default.attr('string'),
    officeLeC: _emberData.default.attr('string'),
    officeLeA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    officeLeS: _emberData.default.attr('string'),
    officeWC: _emberData.default.attr('string'),
    officeWA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    officeWS: _emberData.default.attr('string'),
    officeDlC: _emberData.default.attr('string'),
    officeDlA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    officeDlS: _emberData.default.attr('string'),
    officeBkC: _emberData.default.attr('string'),
    officeBkA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    officeBkS: _emberData.default.attr('string'),
    officeCourtsC: _emberData.default.attr('string'),
    officeCourtsA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    officeCourtsS: _emberData.default.attr('string'),
    officeSocnetC: _emberData.default.attr('string'),
    officeSocnetA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    officeSocnetS: _emberData.default.attr('string'),
    adminMainC: _emberData.default.attr('string'),
    adminMainA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    adminMainS: _emberData.default.attr('string'),
    adminRecordsC: _emberData.default.attr('string'),
    adminRecordsA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    adminRecordsS: _emberData.default.attr('string'),
    adminReportC: _emberData.default.attr('string'),
    adminReportA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    adminReportS: _emberData.default.attr('string'),
    caseMgrC: _emberData.default.attr('string'),
    caseMgrA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    caseMgrS: _emberData.default.attr('string'),
    analyticsPhase1C: _emberData.default.attr('string'),
    analyticsPhase1A: _emberData.default.belongsTo('employee', {
      async: false
    }),
    analyticsPhase1S: _emberData.default.attr('string'),
    analyticsPhase2C: _emberData.default.attr('string'),
    analyticsPhase2A: _emberData.default.belongsTo('employee', {
      async: false
    }),
    analyticsPhase2S: _emberData.default.attr('string'),
    prePublishA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    prePublishS: _emberData.default.attr('string'),
    prePublishC: _emberData.default.attr('string'),
    dbAltCauseA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    dbAltCauseS: _emberData.default.attr('string'),
    dbAltCauseC: _emberData.default.attr('string'),
    interviewsA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    interviewsS: _emberData.default.attr('string'),
    interviewsC: _emberData.default.attr('string'),
    altCauseEmploymentA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    altCauseEmploymentS: _emberData.default.attr('string'),
    altCauseEmploymentC: _emberData.default.attr('string'),
    altCauseAheraA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    altCauseAheraS: _emberData.default.attr('string'),
    altCauseAheraC: _emberData.default.attr('string'),
    altCauseResidentialA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    altCauseResidentialS: _emberData.default.attr('string'),
    altCauseResidentialC: _emberData.default.attr('string'),
    altCauseAmbientA: _emberData.default.belongsTo('employee', {
      async: false
    }),
    altCauseAmbientS: _emberData.default.attr('string'),
    altCauseAmbientC: _emberData.default.attr('string'),
    matter: belongsTo('matter'),
    collection: belongsTo('collection')
  });

  _exports.default = _default;
});