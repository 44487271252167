define("@smith-carson/ui/templates/components/sc-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A+nrEQDD",
    "block": "{\"symbols\":[\"@rowGapSize\",\"@colGapSize\",\"@gutters\",\"@fillRemainingViewportHeight\",\"&default\",\"&attrs\"],\"statements\":[[8,\"div\"],[13,\"class\",[30,[\"sc-container \",[24,0,[\"bootstrapContainerClass\"]],\" \",[24,0,[\"widthClass\"]],\" \",[24,0,[\"heightClass\"]]]]],[14,6],[9],[0,\"\\n\"],[4,\"if\",[[27,5]],null,{\"statements\":[[0,\"    \"],[15,5,[[29,\"component\",[[24,0,[\"rowComponent\"]]],[[\"rowGapSize\",\"colGapSize\",\"gutters\",\"fillRemainingViewportHeight\"],[[24,1,[]],[24,2,[]],[24,3,[]],[24,4,[]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,[24,0,[\"rowComponent\"]],[],[[\"@rowGapSize\",\"@colGapSize\",\"@gutters\",\"@fillRemainingViewportHeight\"],[[24,1,[]],[24,2,[]],[24,3,[]],[24,4,[]]]],{\"statements\":[[0,\"\\n      \"],[15,5],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-container.hbs"
    }
  });

  _exports.default = _default;
});