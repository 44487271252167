define("js-common/models/matter-task", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    city: attr('string'),
    comment: attr('string'),
    county: attr('string'),
    dueOn: attr('string'),
    helpWanted: attr('string'),
    priority: attr('number'),
    state: attr('string'),
    status: attr('string'),
    createdBy: belongsTo('employee'),
    assignedTo: belongsTo('employee'),
    taskList: belongsTo('task-list'),
    task: belongsTo('task'),
    matter: belongsTo('matter'),
    collectionHasWorkflow: belongsTo('collection-has-workflow', {
      inverse: 'matterTask'
    })
  });

  _exports.default = _default;
});