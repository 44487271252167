define("js-common/helpers/even-odd", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.evenOdd = evenOdd;
  _exports.default = void 0;

  function evenOdd(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        num = _ref2[0];

    return num % 2;
  }

  var _default = Ember.Helper.helper(evenOdd);

  _exports.default = _default;
});