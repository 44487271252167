define("js-common/models/quick-link", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    matter: _emberData.default.belongsTo('matter', {
      async: true
    }),
    name: _emberData.default.attr(),
    url: _emberData.default.attr(),
    createdOn: _emberData.default.attr('estdate'),
    updatedOn: _emberData.default.attr('estdate')
  });

  _exports.default = _default;
});