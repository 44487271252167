define("@smith-carson/ui/templates/components/sc-app-sidebar/body/collapse-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MZSeuc7z",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[29,\"hash\",null,[[\"toggle\",\"body\"],[[29,\"component\",[[24,0,[\"toggleComponent\"]]],[[\"collapsed\",\"onClick\"],[[24,0,[\"collapsed\"]],[29,\"action\",[[24,0,[]],\"collapse\"],null]]]],[29,\"component\",[[24,0,[\"bodyComponent\"]]],[[\"collapsed\"],[[24,0,[\"collapsed\"]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-app-sidebar/body/collapse-item.hbs"
    }
  });

  _exports.default = _default;
});