define("js-common/models/invoice", ["exports", "ember-data", "ember-api-actions", "accounting/format-money", "moment"], function (_exports, _emberData, _emberApiActions, _formatMoney, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    status: attr('string'),
    tmNum: attr('number'),
    billDate: attr('string'),
    billedAmount: attr('number'),
    availableTransitions: attr(),
    // computed on the API to check what actions are available for the invoice
    // custom field
    isAdmin: attr('boolean'),
    budgets: attr('string'),
    truncatedBudgets: Ember.computed('budgets', function () {
      var budgets = this.budgets;
      return budgets.length > 25 ? budgets.substring(0, 25) + '...' : budgets;
    }),
    splitNr: attr('string'),
    // relationship
    matter: belongsTo('matter', {
      async: false
    }),
    invoiceGroup: belongsTo('invoice-group', {
      async: true
    }),
    invoiceBatch: belongsTo('invoice-batch', {
      async: true
    }),
    billables: hasMany('billable', {
      async: true
    }),
    serviceAmount: attr('number'),
    expenseAmount: attr('number'),
    taxAmount: attr('number'),
    discountSubAmount: attr('number'),
    discountAmount: attr('number'),
    discountDescription: attr('string'),
    privateNotes: attr('string'),
    publicNotes: attr('string'),
    formattedAmount: Ember.computed('billedAmount', function () {
      return (0, _formatMoney.default)(this.billedAmount);
    }),
    formattedBillDate: Ember.computed('billDate', function () {
      var bd = (0, _moment.default)(this.billDate);

      if (bd.isValid()) {
        return (0, _moment.default)(this.billDate).format('MM/DD/YYYY');
      }

      return '';
    }),
    canGoToLevel1: Ember.computed('availableTransitions.[]', function () {
      return this.availableTransitions.includes('to Level 1 Complete');
    }),
    canGoToLevel2: Ember.computed('availableTransitions.[]', function () {
      return this.availableTransitions.includes('to Level 2 Complete');
    }),
    canGoToBilled: Ember.computed('availableTransitions.[]', function () {
      return this.availableTransitions.includes('to Billed');
    }),
    canGoToUnbilled: Ember.computed('availableTransitions.[]', function () {
      return this.availableTransitions.includes('to Unbilled');
    }),
    change: (0, _emberApiActions.memberAction)({
      path: 'change',
      type: 'put'
    }),
    addNewBillables: (0, _emberApiActions.memberAction)({
      path: 'add_new_billables',
      type: 'post'
    }),
    recalcTimeRates: (0, _emberApiActions.memberAction)({
      path: 'recalc_time_rates',
      type: 'post'
    }),
    updateInvoiceGroup: (0, _emberApiActions.memberAction)({
      path: 'update_invoice_group',
      type: 'post'
    }),
    getIGMDetails: (0, _emberApiActions.memberAction)({
      path: 'igm_details',
      type: 'get'
    })
  });

  _exports.default = _default;
});