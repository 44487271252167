define("js-common/templates/components/subject-autosuggest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "38r20XLy",
    "block": "{\"symbols\":[\"result\",\"search\",\"&attrs\",\"@initialSubjects\",\"@placeholder\",\"@subject\"],\"statements\":[[8,\"div\"],[14,3],[13,\"class\",\"subject-autosuggest\"],[9],[0,\"\\n    \"],[5,\"sc-power-select\",[],[[\"@options\",\"@placeholder\",\"@renderInPlace\",\"@searchEnabled\",\"@selected\",\"@size\",\"@search\",\"@onchange\"],[[24,4,[]],[24,5,[]],true,true,[24,6,[]],\"sm\",[29,\"action\",[[24,0,[]],\"searchSubjects\"],null],[29,\"action\",[[24,0,[]],\"subjectSelected\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,1,[\"hasNumbers\"]]],null,{\"statements\":[[0,\"            \"],[1,[29,\"highlight-result\",[[24,1,[\"nameAndNumbers\"]]],[[\"query\"],[[24,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[29,\"highlight-result\",[[24,1,[\"fullName\"]]],[[\"query\"],[[24,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"]],\"parameters\":[1,2]}],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/subject-autosuggest.hbs"
    }
  });

  _exports.default = _default;
});