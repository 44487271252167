define("@smith-carson/ui/pods/components/modals/case-field/component", ["exports", "@smith-carson/ui/pods/components/modals/case-field/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides affordance for editing a Case Field in a sidebar panel (modal).
  
      @module Modals::CaseFieldComponent
      @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: ''
  });

  _exports.default = _default;
});