define("js-common/models/charge", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    qty: attr('number'),
    rate: attr('number'),
    total: attr('number'),
    cut: attr('number'),
    status: attr('string'),
    type: attr('string'),
    description: attr('string'),
    date: attr('string'),
    billableGroup: belongsTo('billable-group', {
      async: false
    }),
    billableType: belongsTo('billable-type', {
      async: false
    }),
    effectiveRate: Ember.computed.or('rate', 'billableType.rate'),
    calculatedTotal: Ember.computed('effectiveRate', 'qty', function () {
      var total = this.effectiveRate * this.qty;

      if (isNaN(total) || total === 0) {
        total = null;
      }

      return total;
    }),
    hasEffectiveValues: function hasEffectiveValues() {
      if (this.get('billableType.inputByQuantity')) {
        return this.qty > 0;
      } else {
        return this.rate > 0;
      }
    },
    finalizeTotal: function finalizeTotal() {
      if (this.get('billableType.inputByQuantity')) {
        // user has input quantity
        this.set('rate', this.effectiveRate);
        this.set('total', this.calculatedTotal);
      } else {
        // user has input rate
        this.set('qty', 1);
        this.set('total', this.rate);
      }
    }
  });

  _exports.default = _default;
});