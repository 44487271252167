define("js-common/helpers/margin-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.marginLeft = marginLeft;
  _exports.default = void 0;

  function marginLeft(params
  /*, hash */
  ) {
    var pixels = params[0];
    return Ember.String.htmlSafe('margin-left: ' + pixels + 'px;');
  }

  var _default = Ember.Helper.helper(marginLeft);

  _exports.default = _default;
});