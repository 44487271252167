define("js-common/mixins/token-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This mixin is used to allow auth with a token query param eg: url.com?newtoken=xyz
   */
  var _default = Ember.Mixin.create({
    // token param for url authentication
    queryParams: ['newtoken'],
    newtoken: null,
    unsetToken: Ember.observer('newtoken', function () {
      if (this.newtoken) {
        Ember.run.next(this, function () {
          this.set('newtoken', null);
        });
      }
    })
  });

  _exports.default = _default;
});