define("js-common/models/receipt", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    name: attr('string'),
    size: attr('number'),
    viewable: attr('string'),
    // embedded key
    resourcesId: attr('number'),
    // keep split so I can change it via select
    // billableId: attr('number'),
    // relationship
    billableGroup: belongsTo('billable-group', {
      async: true
    }),
    billable: belongsTo('billable', {
      async: true
    }),
    available: Ember.computed(function () {
      var local = this.billable.get('id'); // var local = this.get('billableId');

      if (local) {
        return false;
      } else {
        return true;
      }
    })
  });

  _exports.default = _default;
});