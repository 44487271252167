define("js-common/templates/components/subject-field-yes-no-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C7e39/cu",
    "block": "{\"symbols\":[\"@fieldValue\",\"&default\"],\"statements\":[[15,2,[[24,0,[\"showValue\"]],[24,0,[\"hideAll\"]],[24,1,[]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/subject-field-yes-no-wrapper.hbs"
    }
  });

  _exports.default = _default;
});