define("js-common/components/x-upload-modal", ["exports", "js-common/templates/components/x-upload-modal", "js-common/components/x-upload"], function (_exports, _xUploadModal, _xUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component intended to replace document-uploader
   * it will have all the logic itself, without
   * the need of external dependencies like the mixin
   * or functions in controllers.
   *
   * To communicate with the outside world, it's just
   * going to send actions to be catched by the
   * controller or router where is used.
   *
   * It will have some options depending where is
   * used, in order to ask for more information
   *
   * i.e.
   *
   * - Automatically link uploads to the subject as argument
   *
   * {{x-upload matter=matter subject=subject documentTypes=listService.fileTypes
   *   cancelUpload='cancelUpload'
   *   beforeUpload='beforeUpload'
   *   afterUpload='afterUpload'
   *   errorUpload='errorUpload'}}
   *
   * - Ask for subject before upload
   *
   * {{x-upload matter=model documentTypes=listService.fileTypes
   *   cancelUpload='cancelUpload'
   *   beforeUpload='beforeUpload'
   *   afterUpload='afterUpload'
   *   errorUpload='errorUpload'}}
   *
   * - For billable group edits:
   *
   * {{x-upload matter=model documentTypes=false subject=false endPoint='receipts'
   *   cancelUpload='cancelUpload'
   *   beforeUpload='beforeUpload'
   *   afterUpload='afterUpload'
   *   errorUpload='errorUpload'}}
   */
  var _default = _xUpload.default.extend({
    /**
     * set the right layout to be rendered with this component
     */
    layout: _xUploadModal.default,
    actions: {
      saveOrCancel: function saveOrCancel() {
        if (this.showUploadButton) {
          this.send('upload');
        } else {
          this.send('cancelUpload');
        }
      }
    }
  });

  _exports.default = _default;
});