define("ember-math-helpers/helpers/sin", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sin = sin;
  _exports.default = void 0;

  /**
   * Executes `Math.sin` on the number passed to the helper.
   *
   * ```hbs
   * {{sin a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.sin`
   * @return {number} The sin of the passed number
   */
  function sin(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        number = _ref2[0];

    return Math.sin(number);
  }

  var _default = Ember.Helper.helper(sin);

  _exports.default = _default;
});