define("js-common/models/field-phrase", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    field: attr('string'),
    phrase: attr('string'),
    breaks: attr('number'),
    weight: attr('number'),
    // relationships
    matter: belongsTo('matter', {
      async: true
    }),
    name: Ember.computed('field', function () {
      var name = this.get('field');
      var parts = name.split(' | ');

      if (parts.length === 2) {
        return parts[1];
      } else {
        return name;
      }
    })
  });

  _exports.default = _default;
});