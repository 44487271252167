define("@smith-carson/ui/pods/components/light-table/cells/date-format/component", ["exports", "ember-light-table/components/cells/base", "@smith-carson/ui/pods/components/light-table/cells/date-format/template"], function (_exports, _base, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides a custom `LightTable` cell type for rendering status indicators
  
      @module LightTable::Cells::DateFormatComponent
      @extends EmberLightTable.CellsBaseComponent
   */
  var _default = _base.default.extend({
    classNames: ['date-cell'],
    layout: _template.default,
    dateValue: Ember.computed('column.valuePath', 'row', function () {
      return this.get("row.".concat(this.column.valuePath));
    }),

    /**
        Provides the dateFormat for the moment-format helper
         @property statusClassName
        @type {String}
     */
    dateFormat: Ember.computed.reads('column.extra.dateFormat')
  });

  _exports.default = _default;
});