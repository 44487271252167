define("js-common/models/custom-inflector-rules", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inflector = _emberInflector.default.inflector; // Tell the inflector that the plural of "meta" is "metas"

  inflector.irregular('meta', 'metas'); // Modules must have an export, so we just export an empty object here

  var _default = {};
  _exports.default = _default;
});