define("js-common/models/matter", ["exports", "ember-data", "ember-api-actions", "moment"], function (_exports, _emberData, _emberApiActions, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  var not = Ember.computed.not,
      equal = Ember.computed.equal,
      alias = Ember.computed.alias;

  var _default = Model.extend({
    modelName: 'matter',
    // before we were using constructor.modelName but is no longer reliable
    // Own fields
    pwId: attr('number'),
    tmNum: attr('number'),
    createdOn: attr('estdate'),
    updatedOn: attr('estdate'),
    // Basic Info Fields
    tmCode: attr('string'),
    name: attr('string'),
    personOfInterest: attr('string'),
    litigationType: attr('string'),
    product: attr('string'),
    // Court Fields
    caseStatus: attr('string'),
    mistrialReason: attr('string'),
    courtCaseNumber: attr('string'),
    defendant: attr('string'),
    plaintiff: attr('string'),
    venue: attr('string'),
    courtType: attr('string'),
    venueState: attr('string'),
    venueCounty: attr('string'),
    judge: attr('string'),
    verdict: attr('string'),
    // Classification Fields
    industry: attr('string'),
    typeOfService: attr('csv'),
    monitorStatus: attr('string'),
    analyticStatus: attr('string'),
    matterType: belongsTo('matter-type', {
      async: true
    }),
    matterTypeId: attr('number'),
    // this is used to avoid a fetch to just know the matterTypeId
    status: attr('string', {
      defaultValue: 'Active'
    }),
    legalStatus: attr('string'),
    criminalVerdict: attr('string'),
    stopWorkDate: attr('estdate'),
    // Details Fields
    purposeOfInvestigation: attr('string'),
    specialInstructions: attr('string'),
    allegation: attr('string'),
    plaintiffFirms: attr('string'),
    // Internal Processing
    leadInvestigator: belongsTo('employee', {
      async: true
    }),
    referredBy: belongsTo('client', {
      async: true
    }),
    accountsReceivableOwner: belongsTo('user', {
      async: true
    }),
    // Analytic Fields
    compensatory: attr('number'),
    punitive: attr('number'),
    punitiveOnly: attr('number', {
      defaultValue: 0
    }),
    plaintiffFault: attr('number'),
    defendantFault: attr('number'),
    mistrial: attr('number'),
    partiesInCourt: attr('string'),
    relationshipOfCoplaintiff: attr('string'),
    userStatus: attr('string'),
    startDate: attr('string'),
    dueDate: attr('string'),
    // Billing Fields
    expTravel: attr('number'),
    // Orphan fields:
    caseType: attr('string'),
    caseTypeOther: attr('string'),
    commissionEnd: attr('estdate'),
    commissionPercent: attr('number'),
    commissionStart: attr('estdate'),
    country: attr('string'),
    deliberationLength: attr('string'),
    emailDistribution: attr('string'),
    origin: attr('string', {
      defaultValue: 'Task Matters 2'
    }),
    overwriteSocialMedia: attr('string', {
      defaultValue: 'Y'
    }),
    revenueState: attr('string'),
    taxState: attr('string'),
    taxes: attr('string'),
    // comma separated values of states
    trialLength: attr('string'),
    workStatus: attr('string'),
    fileDisplayName: attr('string'),
    displayDocType: attr('string'),
    displaySubject: attr('string'),
    showLinksOnPortal: attr('number'),
    hourlyRate: attr('number'),
    flatRate: attr('number'),
    databaseNotes: attr('string'),
    caseCompletedOn: attr('estdate'),
    progressStatus: attr('string'),
    progressStatusNotes: attr('string'),
    highPriority: attr('number'),
    useJsiAndScPortal: attr('number'),
    disableGroupingTmPortal: attr('number'),
    hideDashboard: attr('number'),
    hideSubjects: attr('number'),
    hideDocuments: attr('number'),
    disableAutoInactive: attr('number'),
    templateForPortal: attr('string'),
    //  this will be changed in the backend to use appl-configs
    typeOfReportTab: attr('string'),
    // this will be changed in the backend to use appl-configs
    useNicknameInPortal: attr('string', {
      defaultValue: 'false'
    }),
    // jsi fields for dashboard
    finalNumberJurors: attr('number'),
    finalSummary: attr('string'),
    jurorsSelectedFrom: attr('number'),
    scope: attr('string'),
    scopeNote: attr('string'),
    firms: attr('string'),
    ENV: Ember.computed(function () {
      return getOwner(this).resolveRegistration('config:environment');
    }),
    jsiUrl: Ember.computed('ENV', function () {
      return "".concat(this.get('ENV.APP.js2AdminURL'), "matters/").concat(this.get('id'), "/workflows/list");
    }),
    firmsInvolved: Ember.computed('firms', function () {
      return this.get('firms').split('|');
    }),
    selectedScope: Ember.computed('scope', {
      get: function get() {
        var scope = this.get('scope');
        if (scope && scope.length > 0) return scope.split(',');
        return [];
      },
      set: function set(key, value) {
        this.set('scope', value.join(','));
        return value;
      }
    }),
    avgTimePerJuror: attr('number'),
    costPerJuror: attr('number'),
    recentName: Ember.computed('personOfInterest', 'name', 'tmCode', function () {
      var _this = this;

      var result = [];
      ['personOfInterest', 'name', 'tmCode'].forEach(function (item) {
        var value = _this.get(item);

        if (value) {
          if (item === 'personOfInterest') {
            result.push(value.length > 12 ? "".concat(value.substring(0, 12), "...") : value);
          } else {
            result.push(value);
          }
        }
      });
      return result.join(' - ');
    }),
    caseNickname: Ember.computed('personOfInterest', 'name', function () {
      var personOfInterest = this.personOfInterest;
      var name = this.name;
      return Ember.isPresent(personOfInterest) && personOfInterest !== '' ? personOfInterest : name;
    }),
    unboundTitle: Ember.computed('name', 'personOfInterest', 'tmCode', function () {
      var _this2 = this;

      var result = [];
      ['tmCode', 'personOfInterest'].forEach(function (item) {
        var value = _this2.get(item);

        if (value) {
          result.push(value);
        }
      });
      var title = result.join(' - ');
      return title || this.name;
    }),
    title: Ember.computed('unboundTitle', function () {
      var title = this.unboundTitle;
      return title.length > 70 ? "".concat(title.substring(0, 70), "...") : title;
    }),
    truncatedName: Ember.computed('name', function () {
      var name = this.name;
      return name.length > 12 ? "".concat(name.substring(0, 12), "...") : name;
    }),

    /**
     * create a camelized version of value
     * used in conjunction with dynamic-field components
     */
    workStatusName: Ember.computed('workStatus', function () {
      switch (this.workStatus) {
        case 'D':
          return 'Active-Database Only';

        case 'A':
          return 'Active-Full Investigation';

        case 'S':
          return 'Case Settled';

        case 'X':
          return 'Dimissed';

        case 'Z':
          return 'Jury Research';

        case 'L':
          return 'Limited Investigation';

        case 'H':
          return 'On Hold';

        case '1':
          return 'Phase 1';

        case 'P':
          return 'Phase 1+';

        case '2':
          return 'Phase 2';

        case '3':
          return 'Phase 3';

        case 'R':
          return 'RA-Tier 1';

        case 'T':
          return 'RA-Tier 2';

        case 'J':
          return 'Special Project';

        default:
          return 'Unknown';
      }
    }),

    /* @computed
        to check if type is JR or JP */
    isJuryCase: Ember.computed('matterType.id', function () {
      return [8, 27, 38].includes(parseInt(this.get('matterType.id'), 10));
    }),
    isNotJuryCase: not('isJuryCase'),
    portalCaseNickname: Ember.computed('useNicknameInPortal', 'personOfInterest', 'name', function () {
      var name = this.name,
          personOfInterest = this.personOfInterest,
          useNicknameInPortal = this.useNicknameInPortal;

      if (useNicknameInPortal === 'true') {
        return Ember.isPresent(personOfInterest) ? personOfInterest : name;
      }

      return name;
    }),

    /* @computed
        to check if litigation Type is N/A */
    isLitigationTypeNA: Ember.computed.equal('litigationType', 'N/A'),

    /* @computed
        to check if service type a jury research */
    isJuryResearch: Ember.computed('typeOfService', function () {
      var serviceTypes = this.typeOfService || [];
      return serviceTypes.filter(function (st) {
        return st.match(/Jury Research/) !== null;
      }).length > 0;
    }),

    /* @computed
        used on the matters list page */
    formattedStartDate: Ember.computed('startDate', function () {
      return (0, _moment.default)(this.startDate).format('MMM D, YYYY');
    }),

    /* @computed
        to check if origin is Task Matters */
    isTM: equal('origin', 'Task Matters'),

    /* @computed
        to check if origin is Task Matters 2 */
    isTM2: equal('origin', 'Task Matters 2'),
    hasStopWork: Ember.computed('status', 'stopWorkDate', function () {
      return ['Hold', 'Postpone'].indexOf(this.status) !== -1 && this.stopWorkDate;
    }),
    // todo
    fromMatterId: attr('number'),
    parentId: attr('number'),
    createdById: attr('number'),
    updatedById: attr('number'),
    // primaryId: DS.attr('string'),  //?
    isMonitored: attr('number', {
      defaultValue: null
    }),
    isMonitoredNotes: attr('string', {
      defaultValue: null
    }),
    debriefed: attr('number', {
      defaultValue: null
    }),
    debriefedNotes: attr('string', {
      defaultValue: null
    }),
    seatedJurorAnalytics: attr('number', {
      defaultValue: null
    }),
    seatedJurorAnalyticsNotes: attr('string', {
      defaultValue: null
    }),
    poolReportSent: attr('number', {
      defaultValue: 0
    }),
    poolReportSentNotes: attr('string', {
      defaultValue: null
    }),
    boxReportSent: attr('number', {
      defaultValue: 0
    }),
    boxReportSentNotes: attr('string', {
      defaultValue: null
    }),
    projectTrackerNotes: attr('string', {
      defaultValue: null
    }),
    // relationships
    documents: hasMany('document', {
      async: true
    }),
    invoiceGroups: hasMany('invoice-group', {
      async: true
    }),
    budgets: hasMany('budget', {
      async: true,
      inverse: 'matter'
    }),
    // asasas
    billableGroups: hasMany('billable-group', {
      async: true
    }),
    matterHasTeams: hasMany('matter-has-team', {
      async: true
    }),
    subjects: hasMany('subject', {
      async: true
    }),
    matterHasTags: hasMany('matter-has-tag', {
      async: true
    }),
    matterTags: hasMany('matter-tag', {
      async: true
    }),
    matterHasPrivateTags: hasMany('matter-has-private-tag', {
      async: true
    }),
    matterPrivateTags: hasMany('matter-private-tag', {
      async: true
    }),
    matterHasRecognitions: _emberData.default.hasMany('matter-has-recognition', {
      async: true
    }),
    caseOwners: attr('string'),
    // This is a readonly field computed in the API
    // new collection has matter columns
    collection: belongsTo('collection', {
      async: true
    }),
    clientCollection: belongsTo('client-collection', {
      async: true
    }),
    // this writable computed properties are used for power-select
    referredByWritable: Ember.computed('referredBy.content', {
      get: function get() {
        return this.get('referredBy.content');
      },
      set: function set(key, value) {
        this.set('referredBy', value);
        return value;
      }
    }),
    leadInvestigatorWritable: Ember.computed('leadInvestigator.content', {
      get: function get() {
        return this.get('leadInvestigator.content');
      },
      set: function set(key, value) {
        this.set('leadInvestigator', value);
        return value;
      }
    }),
    caseOwnerWritable: Ember.computed('caseOwner.content', {
      get: function get() {
        return this.get('caseOwner.content');
      },
      set: function set(key, value) {
        this.set('caseOwner', value);
        return value;
      }
    }),
    caseSupervisorWritable: Ember.computed('caseSupervisor.content', {
      get: function get() {
        return this.get('caseSupervisor.content');
      },
      set: function set(key, value) {
        this.set('caseSupervisor', value);
        return value;
      }
    }),
    matterTypeWritable: Ember.computed('matterType.content', {
      get: function get() {
        return this.get('matterType.content');
      },
      set: function set(key, value) {
        this.set('matterType', value);
        return value;
      }
    }),
    fullCaseName: Ember.computed('tmCode', 'name', function () {
      return "".concat(this.tmCode, " - ").concat(this.name);
    }),
    truncatedNickname: Ember.computed('caseNickname', function () {
      var nickname = this.caseNickname;
      var output = '';

      if (!Ember.isEmpty(nickname)) {
        output = nickname.length > 50 ? "".concat(nickname.substring(0, 50), "...") : nickname;
      }

      return output;
    }),
    truncatedCaseName: Ember.computed('tmCode', 'truncatedNickname', function () {
      return "".concat(this.tmCode, " - ").concat(this.truncatedNickname);
    }),
    tags: Ember.computed('matterTags.length', function () {
      return this.matterTags.map(function (mt) {
        return mt.get('name');
      }).join(', ');
    }),
    // get most frequent locations (combinations of country, state, county, city) from related timeline-events
    getFrequentLocations: (0, _emberApiActions.memberAction)({
      path: 'frequent_locations',
      type: 'get',
      urlType: 'findRecord'
    }),
    // search products by name
    searchProductNames: (0, _emberApiActions.collectionAction)({
      path: 'product_names',
      type: 'get',
      urlType: 'findRecord'
    }),
    // copy matter fields from another case
    copyFieldsFromCase: (0, _emberApiActions.memberAction)({
      path: 'copy_fields_from_case',
      type: 'post'
    }),
    // track a user_viewed_matter
    trackView: (0, _emberApiActions.memberAction)({
      path: 'track_view',
      type: 'post'
    }),
    // TODO see if this should be a POST instead?
    getSubjectMatches: (0, _emberApiActions.memberAction)({
      path: 'subject_matches',
      type: 'post'
    }),
    rebuildSocialMedia: (0, _emberApiActions.memberAction)({
      path: 'rebuild_social_media',
      type: 'post'
    }),
    getLastUpdatedDate: (0, _emberApiActions.memberAction)({
      path: 'last_updated_date',
      type: 'get'
    }),
    // workflow related fields
    dbInitialC: _emberData.default.attr('string'),
    dbInitialA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    dbInitialS: _emberData.default.attr('string'),
    dbMainC: _emberData.default.attr('string'),
    dbMainA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    dbMainS: _emberData.default.attr('string'),
    dbSuppC: _emberData.default.attr('string'),
    dbSuppA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    dbSuppS: _emberData.default.attr('string'),
    dbWitsC: _emberData.default.attr('string'),
    dbWitsA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    dbWitsS: _emberData.default.attr('string'),
    dbMonitorC: _emberData.default.attr('string'),
    dbMonitorA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    dbMonitorS: _emberData.default.attr('string'),
    fieldMainC: _emberData.default.attr('string'),
    fieldMainA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    fieldMainS: _emberData.default.attr('string'),
    fieldSuppC: _emberData.default.attr('string'),
    fieldSuppA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    fieldSuppS: _emberData.default.attr('string'),
    rptMainC: _emberData.default.attr('string'),
    rptMainA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    rptMainS: _emberData.default.attr('string'),
    rptSuppC: _emberData.default.attr('string'),
    rptSuppA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    rptSuppS: _emberData.default.attr('string'),
    qaInitialC: _emberData.default.attr('string'),
    qaInitialA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    qaInitialS: _emberData.default.attr('string'),
    qaMainC: _emberData.default.attr('string'),
    qaMainA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    qaMainS: _emberData.default.attr('string'),
    qaSuppC: _emberData.default.attr('string'),
    qaSuppA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    qaSuppS: _emberData.default.attr('string'),
    officeMainC: _emberData.default.attr('string'),
    officeMainA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    officeMainS: _emberData.default.attr('string'),
    officeLeC: _emberData.default.attr('string'),
    officeLeA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    officeLeS: _emberData.default.attr('string'),
    officeWcC: _emberData.default.attr('string'),
    officeWcA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    officeWcS: _emberData.default.attr('string'),
    officeDlC: _emberData.default.attr('string'),
    officeDlA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    officeDlS: _emberData.default.attr('string'),
    officeBkC: _emberData.default.attr('string'),
    officeBkA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    officeBkS: _emberData.default.attr('string'),
    officeCourtsC: _emberData.default.attr('string'),
    officeCourtsA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    officeCourtsS: _emberData.default.attr('string'),
    officeSocnetC: _emberData.default.attr('string'),
    officeSocnetA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    officeSocnetS: _emberData.default.attr('string'),
    adminMainC: _emberData.default.attr('string'),
    adminMainA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    adminMainS: _emberData.default.attr('string'),
    adminRecordsC: _emberData.default.attr('string'),
    adminRecordsA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    adminRecordsS: _emberData.default.attr('string'),
    adminReportC: _emberData.default.attr('string'),
    adminReportA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    adminReportS: _emberData.default.attr('string'),
    caseMgrC: _emberData.default.attr('string'),
    caseMgrA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    caseMgrS: _emberData.default.attr('string'),
    analyticsPhase1C: _emberData.default.attr('string'),
    analyticsPhase1A: _emberData.default.belongsTo('employee', {
      async: true
    }),
    analyticsPhase1S: _emberData.default.attr('string'),
    analyticsPhase2C: _emberData.default.attr('string'),
    analyticsPhase2A: _emberData.default.belongsTo('employee', {
      async: true
    }),
    analyticsPhase2S: _emberData.default.attr('string'),
    prePublishA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    prePublishS: _emberData.default.attr('string'),
    prePublishC: _emberData.default.attr('string'),
    dbAltCauseA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    dbAltCauseS: _emberData.default.attr('string'),
    dbAltCauseC: _emberData.default.attr('string'),
    interviewsA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    interviewsS: _emberData.default.attr('string'),
    interviewsC: _emberData.default.attr('string'),
    altCauseEmploymentA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    altCauseEmploymentS: _emberData.default.attr('string'),
    altCauseEmploymentC: _emberData.default.attr('string'),
    altCauseAheraA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    altCauseAheraS: _emberData.default.attr('string'),
    altCauseAheraC: _emberData.default.attr('string'),
    altCauseResidentialA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    altCauseResidentialS: _emberData.default.attr('string'),
    altCauseResidentialC: _emberData.default.attr('string'),
    altCauseAmbientA: _emberData.default.belongsTo('employee', {
      async: true
    }),
    altCauseAmbientS: _emberData.default.attr('string'),
    altCauseAmbientC: _emberData.default.attr('string'),
    dbInitialVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    dbMainVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    dbSuppVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    dbWitsVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    dbMonitorVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    fieldMainVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    fieldSuppVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    rptMainVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    rptSuppVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    qaMainVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    qaSuppVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    officeMainVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    officeLeVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    officeWcVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    officeDlVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    officeBkVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    officeCourtsVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    officeSocnetVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    adminMainVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    adminRecordsVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    adminReportVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    caseMgrVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    dbAltCauseVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    interviewsVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    altCauseEmploymentVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    altCauseAheraVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    altCauseResidentialVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    altCauseAmbientVarious: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    dbInitialHours: _emberData.default.attr('number'),
    dbMainHours: _emberData.default.attr('number'),
    dbSuppHours: _emberData.default.attr('number'),
    dbWitsHours: _emberData.default.attr('number'),
    dbMonitorHours: _emberData.default.attr('number'),
    fieldMainHours: _emberData.default.attr('number'),
    fieldSuppHours: _emberData.default.attr('number'),
    rptMainHours: _emberData.default.attr('number'),
    rptSuppHours: _emberData.default.attr('number'),
    qaMainHours: _emberData.default.attr('number'),
    qaSuppHours: _emberData.default.attr('number'),
    officeMainHours: _emberData.default.attr('number'),
    officeLeHours: _emberData.default.attr('number'),
    officeWcHours: _emberData.default.attr('number'),
    officeDlHours: _emberData.default.attr('number'),
    officeBkHours: _emberData.default.attr('number'),
    officeCourtsHours: _emberData.default.attr('number'),
    officeSocnetHours: _emberData.default.attr('number'),
    adminMainHours: _emberData.default.attr('number'),
    adminRecordsHours: _emberData.default.attr('number'),
    adminReportHours: _emberData.default.attr('number'),
    caseMgrHours: _emberData.default.attr('number'),
    dbAltCauseHours: _emberData.default.attr('number'),
    interviewsHours: _emberData.default.attr('number'),
    altCauseEmploymentHours: _emberData.default.attr('number'),
    altCauseAheraHours: _emberData.default.attr('number'),
    altCauseResidentialHours: _emberData.default.attr('number'),
    altCauseAmbientHours: _emberData.default.attr('number'),
    dbInitialDeadline: _emberData.default.attr('string'),
    dbMainDeadline: _emberData.default.attr('string'),
    dbSuppDeadline: _emberData.default.attr('string'),
    dbWitsDeadline: _emberData.default.attr('string'),
    dbMonitorDeadline: _emberData.default.attr('string'),
    fieldMainDeadline: _emberData.default.attr('string'),
    fieldSuppDeadline: _emberData.default.attr('string'),
    rptMainDeadline: _emberData.default.attr('string'),
    rptSuppDeadline: _emberData.default.attr('string'),
    qaMainDeadline: _emberData.default.attr('string'),
    qaSuppDeadline: _emberData.default.attr('string'),
    officeMainDeadline: _emberData.default.attr('string'),
    officeLeDeadline: _emberData.default.attr('string'),
    officeWcDeadline: _emberData.default.attr('string'),
    officeDlDeadline: _emberData.default.attr('string'),
    officeBkDeadline: _emberData.default.attr('string'),
    officeCourtsDeadline: _emberData.default.attr('string'),
    officeSocnetDeadline: _emberData.default.attr('string'),
    adminMainDeadline: _emberData.default.attr('string'),
    adminRecordsDeadline: _emberData.default.attr('string'),
    adminReportDeadline: _emberData.default.attr('string'),
    caseMgrDeadline: _emberData.default.attr('string'),
    dbAltCauseDeadline: _emberData.default.attr('string'),
    interviewsDeadline: _emberData.default.attr('string'),
    altCauseEmploymentDeadline: _emberData.default.attr('string'),
    altCauseAheraDeadline: _emberData.default.attr('string'),
    altCauseResidentialDeadline: _emberData.default.attr('string'),
    altCauseAmbientDeadline: _emberData.default.attr('string'),
    calcReportNotes: attr('string'),
    calcBudgetNotes: attr('string'),
    calcRelatedBudgetNotes: attr('string'),
    calcBudgetSpent: attr('number'),
    calcBudgetTotal: attr('number'),
    calcBudgetUtilization: attr('number'),
    calcHoursToDate: attr('number'),
    calcInvoiced: attr('number'),
    calcLastInvoiceAmount: attr('number'),
    calcLastInvoiceDate: attr('utcdate'),
    calcLastSent: attr('utcdate'),
    calcNextDueDate: attr('utcdate'),
    calcHardDeadline: attr('number'),
    calcEstimatedHours: attr('number'),
    calcAvailableHours: attr('number'),
    calcRemainingWorkflowHours: attr('number'),
    calcAllWorkflows: attr('string'),
    calcPublicTags: attr('string'),
    calcPrivateTags: attr('string'),
    calcPrimaryContacts: attr('string'),
    dashboardLayoutConfig: attr('string'),
    calcInitialDueOn: attr('utcdate'),
    calcPushedFlag: attr('string'),
    calcLastWfCompletedOn: attr('estdate'),
    calcLastWfCompletedName: attr('string'),
    calcLastTimeEntry: attr('utcdate'),
    calcLastPublishedReport: attr('utcdate'),
    calcEstimatedWorkflowHours: attr('number'),
    calcEnteredWorkflowHours: attr('number'),
    calcAvailableWorkflowHours: attr('number'),
    calcDaysSinceCreation: attr('number'),
    calcDaysSinceLastReport: attr('number'),
    calcDaysSinceLastInvoice: attr('number'),
    calcDaysSinceLastTimeEntry: attr('number'),
    calcNumberPublishedReports: attr('number'),
    calc_initial_due_on: alias('calcInitialDueOn'),
    calc_pushed_flag: alias('calcPushedFlag'),
    getMatterConfiguration: (0, _emberApiActions.memberAction)({
      path: 'get_config',
      type: 'get'
    }),
    askAi: (0, _emberApiActions.memberAction)({
      path: 'ai_response_from_prompt',
      type: 'get'
    })
  });

  _exports.default = _default;
});