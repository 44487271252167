define("ember-bootstrap/templates/components/bs-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oZeGSue4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"unless\",[[25,[\"hidden\"]]],null,{\"statements\":[[4,\"if\",[[25,[\"dismissible\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"close\"],[11,\"aria-label\",\"Close\"],[11,\"type\",\"button\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"aria-hidden\",\"true\"],[9],[0,\"×\"],[10],[0,\"\\n    \"],[3,\"action\",[[24,0,[]],\"dismiss\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[15,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-alert.hbs"
    }
  });

  _exports.default = _default;
});