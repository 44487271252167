define("js-common/models/crm-tag", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    name: attr('string'),
    // relationships
    crmTagHasClient: hasMany('crm-tag-has-client', {
      async: false
    }),
    crmTagHasFirm: hasMany('crm-tag-has-firm', {
      async: false
    }),
    crmTagHasDeal: hasMany('crm-tag-has-deal', {
      async: false
    })
  });

  _exports.default = _default;
});