define("ember-cli-new-version/components/new-version-notifier/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qndfoshR",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"message\"]]],null,{\"statements\":[[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"    \"],[15,1,[[25,[\"version\"]],[25,[\"lastVersion\"]],[29,\"action\",[[24,0,[]],\"reload\"],null],[29,\"action\",[[24,0,[]],\"close\"],null]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"update-notification\"],[9],[0,\"\\n      \"],[1,[23,\"message\"],false],[0,\" \"],[4,\"if\",[[25,[\"showReload\"]]],null,{\"statements\":[[7,\"a\"],[11,\"href\",\"\"],[11,\"class\",\"update-notification__btn\"],[9],[1,[23,\"reloadButtonText\"],false],[3,\"action\",[[24,0,[]],\"reload\"]],[10]],\"parameters\":[]},null],[0,\"\\n      \"],[7,\"a\"],[11,\"href\",\"\"],[11,\"class\",\"update-notification__close\"],[9],[0,\"×\"],[3,\"action\",[[24,0,[]],\"close\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-new-version/components/new-version-notifier/template.hbs"
    }
  });

  _exports.default = _default;
});