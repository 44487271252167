define("js-common/helpers/mut-appl-list", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 3),
          target = _ref2[0],
          targetProperty = _ref2[1],
          defaultValue = _ref2[2];

      return function (applListItem) {
        if (applListItem) {
          if (Ember.isArray(applListItem)) {
            var valueArray = applListItem.map(function (item) {
              return item.get('value');
            });
            target.set(targetProperty, valueArray);
          } else {
            target.set(targetProperty, applListItem.get('value'));
          }
        } else {
          target.set(targetProperty, defaultValue);
        }
      };
    }
  });

  _exports.default = _default;
});