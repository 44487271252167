define("ember-math-helpers/helpers/floor", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.floor = floor;
  _exports.default = void 0;

  /**
   * Executes `Math.floor` on the number passed to the helper.
   *
   * ```hbs
   * {{floor a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.floor`
   * @return {number} The floor of the passed number
   */
  function floor(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        number = _ref2[0];

    return Math.floor(number);
  }

  var _default = Ember.Helper.helper(floor);

  _exports.default = _default;
});