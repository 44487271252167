define("js-common/components/check-file-warnings", ["exports", "js-common/templates/components/check-file-warnings"], function (_exports, _checkFileWarnings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var reads = Ember.computed.reads;
  var ONE_GB = 1024 * 1024 * 1024; // 1GB

  var _default = Ember.Component.extend({
    layout: _checkFileWarnings.default,
    pdfJs: Ember.inject.service('pdf-js'),
    pdfLib: reads('pdfJs.PDFJS'),
    isChecking: true,
    isPasswordProtected: false,
    isEncrypted: false,
    hasBookmarks: false,
    errorReading: false,
    tooLarge: false,
    file: null,
    observerWarnings: Ember.observer('isPasswordProtected', 'isEncrypted', 'hasBookmarks', 'errorReading', 'tooLarge', function () {
      if (this.get('isPasswordProtected') || this.get('isEncrypted') || this.get('hasBookmarks') || this.get('errorReading') || this.get('tooLarge')) {
        this.set('file.acceptWarning', false);
        this.set('file.cantBundle', true);
      }
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var file = this.get('file');

      if (!file || !file.file) {
        this.set('isChecking', false);
        return;
      }

      var parts = file.file.name.split('.');
      var ext = parts[parts.length - 1];

      if (ext !== 'pdf') {
        this.set('isChecking', false);
        return;
      }

      if (file.size >= ONE_GB) {
        this.set('tooLarge', true);
        this.set('isChecking', false);
        return;
      }

      this.set('file.acceptWarning', true);
      this.set('file.cantBundle', false);
      this.get('file').file.arrayBuffer().catch(function () {
        _this.set('errorReading', true);

        _this.set('isChecking', false);
      }).then(function (ab) {
        if (Ember.isEmpty(ab)) {
          // error reading to a buffer
          _this.set('tooLarge', true);

          _this.set('isChecking', false);

          return;
        }

        var loadingTask = _this.get('pdfLib').getDocument(ab);

        loadingTask.promise.catch(function (reason) {
          if (reason.name === 'PasswordException') {
            _this.set('isPasswordProtected', true);
          } else {
            _this.set('errorReading', true);
          }

          _this.set('isChecking', false);
        });
        loadingTask.then(function (pdfDocument) {
          _this.set('isEncrypted', pdfDocument.pdfInfo.encrypted);

          pdfDocument.getOutline().then(function (outline) {
            _this.set('hasBookmarks', outline !== null);

            _this.set('isChecking', false);
          });
        });
      });
    }
  });

  _exports.default = _default;
});