define("js-common/mixins/scroll-to-mixin", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    scrollDuration: 1000,
    margin: 15,
    actions: {
      /**
       * for a given selector, scroll to it using the HTML BODY as reference
       * @param selector
       */
      scrollTo: function scrollTo(selector) {
        var offset = (0, _jquery.default)(selector).offset();

        if (offset) {
          (0, _jquery.default)('html, body').animate({
            scrollTop: offset.top - this.margin
          }, this.scrollDuration);
        } else {
          Ember.debug('trying to scroll to a non existing element');
        }
      },

      /**
       * for a given selector, scroll to it using the parent element as reference
       * this is used specifically when scrolling within a div whoose overflow-y is set to auto or scroll
       *
       * @param selector
       * @param parent
       */
      scrollToOverflow: function scrollToOverflow(selector, parent) {
        // relative element
        var offset = (0, _jquery.default)(selector).offset();

        if (offset) {
          (0, _jquery.default)(parent).animate({
            scrollTop: (0, _jquery.default)(selector).offset().top - (0, _jquery.default)(parent).offset().top + (0, _jquery.default)(parent).scrollTop()
          }, this.scrollDuration);
        } else {
          Ember.debug('trying to scroll to a non existing element');
        }
      }
    }
  });

  _exports.default = _default;
});