define("js-common/components/subjects/table/social-cell", ["exports", "js-common/templates/components/subjects/table/social-cell"], function (_exports, _socialCell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _socialCell.default,
    classNames: ['social-cell'],
    store: Ember.inject.service(),
    currentMatter: Ember.inject.service(),
    hideIcon: false,
    appName: null,
    // defined when cell is used
    isExpanded: Ember.computed('tableActions.highlightedRow.rowId', 'row.rowId', function () {
      var expandedRow = this.get('tableActions.expandedRow');

      if (expandedRow) {
        return this.get('row.rowId') === expandedRow.get('rowId');
      }

      return false;
    }),
    display: Ember.computed('hyperlink.display', 'hyperlink.type', function () {
      var display = this.get('hyperlink.display');
      var type = this.get('hyperlink.type');

      switch (type) {
        case 'Note':
          return 'Note';

        case 'None':
          return 'None';

        default:
          return display;
      }
    }),
    showLink: Ember.computed('hyperlink.isLinkDeactivated', 'hyperlink.type', 'currentMatter.matter', 'appName', function () {
      var type = this.get('hyperlink.type');

      switch (type) {
        case 'Note':
        case 'None':
          return false;

        default:
          if (this.get('appName') === 'js-client') {
            if (this.get('currentMatter.matter.showLinksOnPortal')) {
              return !this.get('hyperlink.isLinkDeactivated');
            }

            return false;
          }

          return !this.get('hyperlink.isLinkDeactivated');
      }
    }),
    title: Ember.computed('column', 'row', function () {
      var hyperlink = this.getCurrentHyperlink();

      if (hyperlink) {
        return hyperlink.get('type');
      }

      return null;
    }),
    hyperlink: Ember.computed('column', 'row', function () {
      var hyperlink = this.getCurrentHyperlink();

      if (hyperlink) {
        return hyperlink;
      }

      return null;
    }),
    noteMetric: Ember.computed('column', 'row', function () {
      var hyperlink = this.getCurrentHyperlink();

      if (hyperlink) {
        return this.get('store').peekAll('hyperlink-metric').filterBy('key', 'SM_NOTES').findBy('resource.id', hyperlink.get('id'));
      }

      return null;
    }),
    nonNoteMetrics: Ember.computed('column', 'row', function () {
      var hyperlink = this.getCurrentHyperlink();

      if (hyperlink) {
        return this.get('store').peekAll('hyperlink-metric').rejectBy('key', 'SM_NOTES').filterBy('resource.id', hyperlink.get('id'));
      }

      return null;
    }),
    nonNotesMetricsOrder: ['weight'],
    sortedNonNotesMetrics: Ember.computed.sort('nonNoteMetrics', 'nonNotesMetricsOrder'),
    getCurrentHyperlink: function getCurrentHyperlink() {
      var socNetIndex = parseInt(this.get('column.label').split('#')[1], 10) - 1;
      var hyperlinks = [];
      var orderedHyperlinks = this.get('row.content.hyperlinks').sortBy('weight');
      orderedHyperlinks.forEach(function (hyperlink) {
        hyperlinks.push(hyperlink);
      });

      if (socNetIndex < hyperlinks.length) {
        return hyperlinks[socNetIndex];
      }

      return null;
    }
  });

  _exports.default = _default;
});