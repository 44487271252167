define("@smith-carson/ui/pods/components/light-table/sort-icon/component", ["exports", "@smith-carson/ui/pods/components/light-table/sort-icon/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides dynamic `ScIconComponent` based on the current sort state of the
      `LightTable` `column`.
  
      @module LightTable::SortIcon
      @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    /**
        Provides the material-icon name that is passed to the `ScIconComponent`.
         @property iconName
        @type {String}
     */
    iconName: Ember.computed('sortIconProperty', 'sortIcons', function () {
      var sortIconProperty = this.get('sortIconProperty');
      return this.get("sortIcons.".concat(sortIconProperty));
    })
  });

  _exports.default = _default;
});