define("js-common/components/timeline-list-display", ["exports", "js-common/templates/components/timeline-list-display"], function (_exports, _timelineListDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _timelineListDisplay.default,
    matter: null
  });

  _exports.default = _default;
});