define("@smith-carson/ui/pods/components/trial-data-filters/component", ["exports", "@smith-carson/ui/pods/components/trial-data-filters/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Maps available filter items to selected filter items.
  
      TODO: If the pattern holds, replace this with a dynamic statement.
   */
  var selectedFiltersMap = {
    caseName: 'caseName',
    plaintiff: 'plaintiff',
    defendant: 'defendant',
    venueState: 'venueState',
    venueCounty: 'venueCounty',
    trialFrom: 'trialFrom',
    trialTo: 'trialTo',
    compensatoryFrom: 'compensatoryFrom',
    compensatoryTo: 'compensatoryTo',
    punitiveFrom: 'punitiveFrom',
    punitiveTo: 'punitiveTo',
    plaintiffFaultFrom: 'plaintiffFaultFrom',
    plaintiffFaultTo: 'plaintiffFaultTo',
    defendantFaultFrom: 'defendantFaultFrom',
    defendantFaultTo: 'defendantFaultTo',
    tag: 'tag'
  };
  /**
      Provides affordance for defining case filters.
  
      @module TrialDataFiltersComponent
      @extends Ember.Component
   */

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['case-filters'],
    layout: _template.default,
    tagLogicalOperator: 'Any',
    tagName: '',

    /**
        Not sure why it became necessary to explicitly set these to false
        in `ember-bootstrap` v2.7.1 (falsy should suffice).
     */
    isTrialDateCollapsed: false,
    isCompensatoryFilterCollapsed: false,
    isPunitiveFilterCollapsed: false,
    isPlaintiffFaultFilterCollapsed: false,
    isDefendantFaultFilterCollapsed: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        caseName: '',
        plaintiff: '',
        defendant: '',
        venueState: '',
        venueCounty: '',
        trialFrom: '',
        trialTo: '',
        compensatoryFrom: '',
        compensatoryTo: '',
        punitiveFrom: '',
        punitiveTo: '',
        plaintiffFaultFrom: '',
        plaintiffFaultTo: '',
        defendantFaultFrom: '',
        defendantFaultTo: '',
        tag: ''
      });
      var filters = this.filters;

      if (!Ember.isEmpty(filters)) {
        this.setSelectedFilters(filters);
      }
    },

    /**
        Sets the selected filter items based on the `appliedFilter` argument.
         @method setSelectedFilters
        @param {Array} filters
     */
    setSelectedFilters: function setSelectedFilters(filters) {
      var _this = this;

      filters.forEach(function (filter) {
        var filterProperty = selectedFiltersMap[filter.key];
        var isMultiple = Array.isArray(filter.value); // Tags and locations are passed as arrays of string values (not IDs)

        if (filter.key === 'locations') {
          _this.set('selectedLocations', filter.value);
        } else if (filter.key === 'tags') {
          _this.set('selectedTags', _this.tags.filter(function (tag) {
            return filter.value.includes(tag.name);
          }));
        } else if (filter.key !== 'clients') {
          // Clients are preloaded during `init` because they are async
          _this.set(filterProperty, isMultiple ? _this.get(filter.key).filter(function (item) {
            return filter.value.includes(item.id);
          }) : filter.value);
        }
      });
    },
    actions: {
      /**
          Handles compiling case filter options and passing them up to the
          invoking context.
           @method applyFilters
          @param {Event} event
       */
      applyFilters: function applyFilters(event) {
        event.preventDefault();
        event.stopPropagation();
        var caseName = this.caseName,
            plaintiff = this.plaintiff,
            defendant = this.defendant,
            venueState = this.venueState,
            venueCounty = this.venueCounty,
            trialFrom = this.trialFrom,
            trialTo = this.trialTo,
            compensatoryFrom = this.compensatoryFrom,
            compensatoryTo = this.compensatoryTo,
            punitiveFrom = this.punitiveFrom,
            punitiveTo = this.punitiveTo,
            plaintiffFaultFrom = this.plaintiffFaultFrom,
            plaintiffFaultTo = this.plaintiffFaultTo,
            defendantFaultFrom = this.defendantFaultFrom,
            defendantFaultTo = this.defendantFaultTo,
            tag = this.tag;
        this.applyFilters({
          caseName: caseName,
          plaintiff: plaintiff,
          defendant: defendant,
          venueState: venueState,
          venueCounty: venueCounty,
          trialFrom: trialFrom,
          trialTo: trialTo,
          compensatoryFrom: compensatoryFrom,
          compensatoryTo: compensatoryTo,
          punitiveFrom: punitiveFrom,
          punitiveTo: punitiveTo,
          plaintiffFaultFrom: plaintiffFaultFrom,
          plaintiffFaultTo: plaintiffFaultTo,
          defendantFaultFrom: defendantFaultFrom,
          defendantFaultTo: defendantFaultTo,
          tag: tag
        });
      }
    }
  });

  _exports.default = _default;
});