define("js-common/templates/components/analytic-widget/social-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MoKRFtds",
    "block": "{\"symbols\":[\"@stats\",\"@canEdit\",\"@toggleViewable\"],\"statements\":[[5,\"sc-analytic-card\",[],[[\"@isEnabled\",\"@canEdit\",\"@onToggle\"],[[24,1,[\"socnet_percent\",\"matterHasStat\",\"isEnabled\"]],[24,2,[]],[29,\"action\",[[24,0,[]],[24,3,[]],[24,1,[\"socnet_percent\",\"matterHasStat\"]]],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"sc-analytic-widget\",[],[[\"@chartType\",\"@chartData\"],[\"simple-percentage\",[29,\"hash\",null,[[\"value\",\"title\"],[[29,\"stat-string-to-int\",[[24,1,[\"socnet_percent\",\"analyticStat\",\"statData\"]]],null],[24,1,[\"socnet_percent\",\"analyticStat\",\"label\"]]]]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/analytic-widget/social-media.hbs"
    }
  });

  _exports.default = _default;
});