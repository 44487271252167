define("js-common/models/resource-has-resource", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    parentId: attr('string'),
    childId: attr('string'),
    // relationships
    documents: hasMany('document', {
      async: true
    }),
    subjects: hasMany('subject', {
      async: true
    }),
    hyperlinks: hasMany('hyperlink', {
      async: true
    })
  });

  _exports.default = _default;
});