define("js-common/templates/components/subject-custom-field-read", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YTj4huaP",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"component\",[[29,\"concat\",[\"subject-custom-modules/\",[29,\"dasherize\",[[25,[\"field\",\"value\"]]],null]],null]],[[\"field\",\"data\",\"state\",\"vitalsSection\",\"isParent\",\"isChild\",\"isTable\",\"overviewSection\"],[[25,[\"field\"]],[25,[\"data\"]],[25,[\"state\"]],[25,[\"vitalsSection\"]],[25,[\"isParent\"]],[25,[\"isChild\"]],[25,[\"isTable\"]],[25,[\"overviewSection\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/subject-custom-field-read.hbs"
    }
  });

  _exports.default = _default;
});