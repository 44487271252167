define("js-common/models/timeline-event-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    name: attr('string'),
    pointOrDuration: attr('string'),
    iconFile: attr('string'),
    default: attr('number'),
    timelineEvents: hasMany('timeline-event', {
      async: true,
      inverse: 'timelineEventType'
    }),
    timelineEventParent: belongsTo('timeline-event-parent', {
      async: true
    })
  });

  _exports.default = _default;
});