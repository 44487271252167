define("ember-bootstrap/templates/components/bs-modal/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1XbSdfKb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"  \"],[15,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"hasSubmitButton\"]]],null,{\"statements\":[[0,\"    \"],[4,\"component\",[[25,[\"buttonComponent\"]]],[[\"onClick\"],[[29,\"action\",[[24,0,[]],[25,[\"onClose\"]]],null]]],{\"statements\":[[1,[23,\"closeTitle\"],false]],\"parameters\":[]},null],[0,\"\\n    \"],[4,\"component\",[[25,[\"buttonComponent\"]]],[[\"type\",\"onClick\",\"disabled\"],[[25,[\"submitButtonType\"]],[29,\"action\",[[24,0,[]],[25,[\"onSubmit\"]]],null],[25,[\"submitDisabled\"]]]],{\"statements\":[[1,[23,\"submitTitle\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[4,\"component\",[[25,[\"buttonComponent\"]]],[[\"type\",\"onClick\"],[\"primary\",[29,\"action\",[[24,0,[]],[25,[\"onClose\"]]],null]]],{\"statements\":[[1,[23,\"closeTitle\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-modal/footer.hbs"
    }
  });

  _exports.default = _default;
});