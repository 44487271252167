define("@smith-carson/ui/templates/components/sc-task-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YGegLx5t",
    "block": "{\"symbols\":[\"@label\",\"&default\",\"@task\"],\"statements\":[[4,\"if\",[[24,3,[\"isRunning\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"sc-task-progress\"],[9],[0,\"\\n    \"],[5,\"sc-alert\",[[13,\"class\",\"sc-task-progress__message\"]],[[\"@type\",\"@dismissible\"],[\"warning\",false]],{\"statements\":[[0,\"\\n      \"],[5,\"sc-icon\",[],[[\"@icon\",\"@iconSet\",\"@spin\",\"@size\",\"@fixedWidth\"],[\"spinner\",\"fa\",true,\"lg\",true]]],[0,\"\\n\"],[4,\"if\",[[26,2]],null,{\"statements\":[[0,\"        \"],[15,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[24,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-task-progress.hbs"
    }
  });

  _exports.default = _default;
});