define("@smith-carson/ui/pods/components/sc-light-table-v2/component", ["exports", "@smith-carson/ui/mixins/table-common", "@smith-carson/ui/pods/components/sc-light-table-v2/template"], function (_exports, _tableCommon, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides a base component that extends the requisite `TableCommon` mixin.
  
      @module ScLightTableComponent
      @extends Ember.Component
      @mixes TableCommon
   */
  var _default = Ember.Component.extend(_tableCommon.default, {
    layout: _template.default
  });

  _exports.default = _default;
});