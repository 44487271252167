define("@smith-carson/ui/templates/components/sc-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0fck0TQ7",
    "block": "{\"symbols\":[\"@avatarUrl\",\"@label\",\"&attrs\"],\"statements\":[[8,\"div\"],[13,\"class\",[30,[\"sc-avatar \",[24,0,[\"sizeClass\"]],\" \",[24,0,[\"shapeClass\"]]]]],[14,3],[9],[0,\"\\n\"],[4,\"if\",[[24,1,[]]],null,{\"statements\":[[0,\"    \"],[7,\"img\"],[11,\"class\",\"sc-avatar--img\"],[12,\"src\",[24,1,[]]],[12,\"alt\",[24,2,[]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"sc-icon\",[[13,\"class\",\"sc-avatar--placeholder-img\"]],[[\"@icon\",\"@size\"],[[29,\"if\",[[24,0,[\"isMale\"]],\"profile_male\",[29,\"if\",[[24,0,[\"isFemale\"]],\"profile_female\",\"profile_male\"],null]],null],[24,0,[\"iconSize\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-avatar.hbs"
    }
  });

  _exports.default = _default;
});