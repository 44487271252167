define("js-common/helpers/trunc-text-by-char", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "jquery"], function (_exports, _slicedToArray2, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.truncTextByChar = truncTextByChar;
  _exports.default = void 0;

  /**
   * truncate a string to a maximum length of characters
   *
   * @param params array of parameters where the first element is the string to be truncated and the send is the maximum length
   * @returns {string}
   */
  function truncTextByChar(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        str = _ref2[0],
        length = _ref2[1];

    if (!Ember.isPresent(str)) {
      return '';
    }

    if (Ember.typeOf(str) !== 'string') {
      Ember.debug('Invalid input supplied to trunc-text-by-char', Ember.typeOf(str), str);
      return '';
    }

    var truncatedString;

    if (str.length > length) {
      truncatedString = _jquery.default.trim(str).substring(0, length) + '...';
    } else {
      truncatedString = str;
    }

    return truncatedString;
  }

  var _default = Ember.Helper.helper(truncTextByChar);

  _exports.default = _default;
});