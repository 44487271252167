define("js-common/helpers/subject-avatar-url", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "js-common/utils/get-app-url", "ember-get-config"], function (_exports, _slicedToArray2, _getAppUrl, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Fetch the Avatar image URL for a subject
   */
  var _default = Ember.Helper.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    compute: function compute(params, hash) {
      var _this = this;

      var session = this.session;
      var store = this.store; // const ENV = config

      var subject = hash.subject;
      (false && !(typeof subject !== 'null' && subject.get('id') !== null) && Ember.assert('subject model is required', typeof subject !== 'null' && subject.get('id') !== null));
      var documents = subject.get('documents');
      var profilePhotoDocId; // check if we already have loaded the documents on the subject

      if (Ember.isPresent(documents)) {
        var _documents$sortBy$fil = documents.sortBy('updatedOn').filter(function (doc) {
          var docType = doc.get('docType');
          return docType === 'PROFILE PHOTO' && doc.get('doNotUse') === 0;
        }),
            _documents$sortBy$fil2 = (0, _slicedToArray2.default)(_documents$sortBy$fil, 1),
            photoDoc = _documents$sortBy$fil2[0];

        if (photoDoc) {
          profilePhotoDocId = photoDoc.get('id');
          return (0, _getAppUrl.default)(_emberGetConfig.default, "/documents/".concat(profilePhotoDocId, "/preview/1?token=").concat(session.data.authenticated.token, "&random=").concat(Math.random()));
        }
      } else {
        // subject-avatar-url helper should awlays use await in front to handle this case
        return store.query('document', {
          'v_resource_has_resources:parent_id': subject.get('id'),
          with: 'v_resource_has_resources',
          doc_type: 'PROFILE PHOTO',
          do_not_use: 0
        }).then(function (response) {
          if (!_this.isDestroyed && Ember.isPresent(response.get('firstObject'))) {
            profilePhotoDocId = response.get('firstObject.id');

            if (profilePhotoDocId) {
              return (0, _getAppUrl.default)(_emberGetConfig.default, "/documents/".concat(profilePhotoDocId, "/preview/1?token=").concat(session.data.authenticated.token, "&random=").concat(Math.random()));
            }
          }
        });
      }
    }
  });

  _exports.default = _default;
});