define("js-common/models/data-report-has-user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    user: belongsTo('employee'),
    dataReport: belongsTo('data-report')
  });

  _exports.default = _default;
});