define("js-common/components/if-equal", ["exports", "js-common/templates/components/if-equal"], function (_exports, _ifEqual) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * compare two values to see if they match
   * ie {{#if-equal param1="somevalue" param2="someothervalue"}}
   */
  var _default = Ember.Component.extend({
    layout: _ifEqual.default,
    classNames: ['ifEqual'],
    isEqual: Ember.computed('param1', 'param2', function () {
      return this.param1 === this.param2;
    })
  });

  _exports.default = _default;
});