define("js-common/components/string-len-greater", ["exports", "js-common/templates/components/string-len-greater"], function (_exports, _stringLenGreater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * return true if a string is greater than a supplied length, otherwise false
   * can be used to optionally display template code
   *
   * usage: {{#string-len-greater str="some string" length=12}}
   */
  var _default = Ember.Component.extend({
    layout: _stringLenGreater.default,
    isGreater: Ember.computed('str', 'length', function () {
      var str = this.str;
      var length = this.length;

      if (Ember.typeOf(str) !== 'string') {
        return false;
      }

      if (str.length > length) {
        return true;
      } else {
        return false;
      }
    })
  });

  _exports.default = _default;
});