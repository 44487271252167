define("js-common/templates/components/analytic-widget/social-media-theme", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bUpmsy4d",
    "block": "{\"symbols\":[\"@stats\",\"@canEdit\",\"@toggleViewable\"],\"statements\":[[5,\"sc-analytic-card\",[],[[\"@isEnabled\",\"@canEdit\",\"@onToggle\",\"@title\"],[[24,1,[\"social_media_theme_comparison\",\"matterHasStat\",\"isEnabled\"]],[24,2,[]],[29,\"action\",[[24,0,[]],[24,3,[]],[24,1,[\"social_media_theme_comparison\",\"matterHasStat\"]]],null],\"Social Media (Theme)\"]],{\"statements\":[[0,\"\\n  \"],[5,\"sc-chart\",[],[[\"@chartData\",\"@chartType\"],[[24,0,[\"socialMediaThemeData\"]],\"horizontal-bar-chart\"]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/analytic-widget/social-media-theme.hbs"
    }
  });

  _exports.default = _default;
});