define("ember-route-helpers/helpers/transition-to", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/toArray", "ember-route-helpers/utils/mount-point"], function (_exports, _toConsumableArray2, _toArray2, _mountPoint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(_ref) {
      var _ref2 = (0, _toArray2.default)(_ref),
          params = _ref2.slice(0);

      var _params = params.slice();

      var owner = Ember.getOwner(this);
      var router = owner.lookup('router:main');
      var queryParams = _params[_params.length - 1];
      var routeName = _params[0]; // Prefix the route name with the engine's mountPoint, if exists

      _params[0] = (0, _mountPoint.prefixMountPoint)((0, _mountPoint.getMountPoint)(owner), routeName);

      if (queryParams.isQueryParams) {
        _params[_params.length - 1] = {
          queryParams: queryParams.values
        };
      }

      return function () {
        for (var _len = arguments.length, invocationArgs = new Array(_len), _key = 0; _key < _len; _key++) {
          invocationArgs[_key] = arguments[_key];
        }

        router.transitionTo.apply(router, [].concat((0, _toConsumableArray2.default)(_params), invocationArgs));
      };
    }
  });

  _exports.default = _default;
});