define("js-common/models/file-to-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    file: null,
    name: '',
    size: '',
    fileType: '',
    documentType: '',
    subjectId: null,
    viewable: 0,

    /**
     * calculate a friendly file size for display
     */
    friendlySize: Ember.computed('size', function () {
      var bytes = this.size;
      var decimals = 0;

      if (bytes === 0) {
        return '0 Byte';
      }

      var k = 1024; // Or 1 kilo = 1000

      var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
      var i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
    })
  });

  _exports.default = _default;
});