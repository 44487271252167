define("@smith-carson/ui/templates/components/sc-list-group/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AMysvJsf",
    "block": "{\"symbols\":[\"&attrs\",\"&default\",\"@onClick\"],\"statements\":[[8,\"div\"],[13,\"class\",[30,[\"\\n    sc-list-group__item\\n    \",[24,0,[\"sizeClass\"]],\"\\n    \",[24,0,[\"hoverableClass\"]],\"\\n    \",[24,0,[\"pointerClass\"]],\"\\n    list-group-item\\n    \",[24,0,[\"colorClass\"]],\"\\n    \",[24,0,[\"activeClass\"]],\"\\n  \"]]],[14,1],[9],[0,\"\\n  \"],[15,2],[0,\"\\n\"],[3,\"on\",[\"click\",[29,\"optional\",[[24,3,[]]],null]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-list-group/item.hbs"
    }
  });

  _exports.default = _default;
});