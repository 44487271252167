define("js-common/models/case", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;

  var _default = Model.extend({
    modelName: 'case',
    // before we were using constructor.modelName but is no longer reliable
    name: attr('string'),
    tmCode: attr('string'),
    createdOn: attr('estdate'),
    updatedOn: attr('estdate'),
    calcNextDueDate: attr('utcdate'),
    calcHardDeadline: attr('number'),
    calcInitialDueOn: attr('utcdate'),
    calcPushedFlag: attr('string'),
    estimatedHours: attr('string'),
    workedHours: attr('string'),
    availableHours: attr('string'),
    caseNickname: attr('string'),
    progressStatus: attr('string'),
    progressStatusNotes: attr('string'),
    status: attr('string'),
    isHighVolume: attr('number'),
    highPriority: attr('number'),
    collectionId: attr('number'),
    product: attr('string'),
    typeOfService: attr('string'),
    calcDaysSinceCreation: attr('number'),
    owners: hasMany('owner', {
      async: false
    }),
    caseTags: hasMany('case-tag', {
      async: false
    }),
    workflowsSummary: belongsTo('workflow-summary', {
      async: false
    }),
    collection: belongsTo('collection', {
      async: false
    }),
    matterType: belongsTo('matter-type', {
      async: false
    }),
    matter: belongsTo('matter', {
      async: false
    }),
    arrayOfOwners: Ember.computed('owners', function () {
      return this.owners.toArray();
    }),
    createdOnFormatted: Ember.computed('createdOn', function () {
      return (0, _moment.default)(this.createdOn).format('MM/DD/YYYY');
    }),
    updatedOnFormatted: Ember.computed('updatedOn', function () {
      return (0, _moment.default)(this.updatedOn).format('MM/DD/YYYY');
    })
  });

  _exports.default = _default;
});