define('ember-simplemde/components/simple-mde', ['exports', 'ember-simplemde/templates/components/simple-mde'], function (exports, _simpleMde) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var assign = Ember.assign,
      get = Ember.get,
      testing = Ember.testing,
      typeOf = Ember.typeOf;
  exports.default = Ember.TextArea.extend({
    layout: _simpleMde.default,

    /**
     * @private
     * @variable
     * to hold the SimpleMDE instance
     */
    currentEditor: null,

    /**
     * action to call when the value on the editor changes
     */
    change: null,

    /**
    * instance options to pass to simpleMDE
    */
    options: {},

    /**
    * default simpleMDE options
    */
    defaultSimpleMdeOptions: Ember.computed(function () {
      return {
        showIcons: ['table']
      };
    }),

    /**
    * global options defined in consuming apps config
    */
    globalSimpleMdeOptions: Ember.computed(function () {
      if (testing) {
        return {};
      } else {
        return get(Ember.getOwner(this).resolveRegistration('config:environment'), 'simpleMDE') || {};
      }
    }),

    /**
     * @method
     * @private
     * get the list of options to pass to init the SimpleMDE instance
     */
    buildSimpleMDEOptions: Ember.computed(function () {
      var builtOptions = assign({}, this.get('defaultSimpleMdeOptions'), this.get('globalSimpleMdeOptions'), this.get('options'));

      if (builtOptions.toolbar && typeOf(builtOptions.toolbar) === 'array') {
        builtOptions.toolbar.forEach(this.unpackToolbarOption);
      }

      return builtOptions;
    }),

    /**
     * @method
     * @private
     * Because simpleMDE needs toolbar options action handler to be a function reference,
     * and if toolbar options are passed in from the consuming apps config they are passed in as strings.
     * Thus, we unpack them and restore the global reference.
     * If the toolbar action handler is a string, we attempt to reference the global function reference matching that string.
     */
    unpackToolbarOption: function unpackToolbarOption(toolbarOption) {
      if (typeOf(toolbarOption.action) === 'string') {
        toolbarOption.action = toolbarOption.action.split('.').reduce(function (accumulator, value) {
          if (!accumulator) {
            accumulator = window[value];
          } else {
            accumulator = accumulator[value];
          }
          return accumulator;
        }, null);
      }
    },

    /**
     * @event
     * @public
     * instantiate the editor with the contents of value
     */
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.set('currentEditor', new SimpleMDE(Ember.merge({
        element: document.getElementById(this.elementId)
      }, this.get('buildSimpleMDEOptions'))));
      this.get('currentEditor').value(this.get('value') || '');

      this.get('currentEditor').codemirror.on('change', function () {
        return Ember.run.once(_this, function () {
          this.sendAction('change', this.get('currentEditor').value());
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.get('currentEditor').toTextArea();
      this.set('currentEditor', null);
    },


    /**
     * @event
     * @public
     * updates the editor when the value property change from the outside
     */
    didReceiveAttrs: function didReceiveAttrs() {
      var editor = this.get('currentEditor');
      if (Ember.isEmpty(editor)) {
        return;
      }
      var cursor = editor.codemirror.getDoc().getCursor();
      editor.value(this.get('value') || '');
      editor.codemirror.getDoc().setCursor(cursor);
    }
  });
});