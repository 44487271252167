define("js-common/templates/components/analytic-widget/cases-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LvLUAKJb",
    "block": "{\"symbols\":[\"@stats\",\"@canEdit\",\"@toggleViewable\"],\"statements\":[[5,\"sc-analytic-card\",[],[[\"@isEnabled\",\"@canEdit\",\"@onToggle\"],[[24,1,[\"cases_found_count\",\"matterHasStat\",\"isEnabled\"]],[24,2,[]],[29,\"action\",[[24,0,[]],[24,3,[]],[24,1,[\"cases_found_count\",\"matterHasStat\"]]],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"sc-analytic-widget\",[],[[\"@chartType\",\"@chartData\"],[\"simple-count\",[29,\"hash\",null,[[\"value\",\"title\"],[[24,1,[\"cases_found_count\",\"analyticStat\",\"statData\"]],[24,1,[\"cases_found_count\",\"analyticStat\",\"label\"]]]]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/analytic-widget/cases-found.hbs"
    }
  });

  _exports.default = _default;
});