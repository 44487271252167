define("js-common/models/scrape", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.autoAssignToUserId = void 0;
  var autoAssignToUserId = 9355; // scrape@smithcarson.com

  _exports.autoAssignToUserId = autoAssignToUserId;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    job: attr('string'),
    jobId: attr('number'),
    jobLog: attr('string'),
    automaticScrape: attr('number'),
    status: attr('string'),
    args: attr('string'),
    totalSubJobs: attr('number'),
    currentSubJobs: attr('number'),
    subJobsComplete: attr('boolean'),
    url: attr('string'),
    isUrgent: attr('number', {
      defaultValue: 0
    }),
    isForensic: attr('number', {
      defaultValue: 0
    }),
    isUpdated: attr('number', {
      defaultValue: 0
    }),
    dueDate: attr('string'),
    createdOn: attr('estdate'),
    updatedOn: attr('estdate'),
    completedOn: attr('estdate'),
    assignedOn: attr('estdate'),
    assignedTo: belongsTo('employee', {
      async: true
    }),
    createdBy: belongsTo('user', {
      async: true
    }),
    matter: belongsTo('matter', {
      async: false
    }),
    subject: belongsTo('subject', {
      async: false
    }),
    hyperlinkResource: belongsTo('hyperlink', {
      async: false
    }),
    post: belongsTo('post', {
      async: false
    }),
    hyperlinkResourceId: attr('number'),
    documents: hasMany('document', {
      async: false
    }),
    // search_requests_id not sure what is this
    assignedAndDueLabel: Ember.computed('assignedTo.content.fullName', 'isUrgent', 'dueDate', function () {
      var assignedTo = this.get('assignedTo.content');
      var dueOn = this.dueDate;
      var title = [];

      if (Ember.isPresent(assignedTo)) {
        var name = assignedTo.get('fullName');

        if (name) {
          title.push(name);
        }
      } else {
        title.push('Unassigned');
      }

      if (Ember.isPresent(dueOn)) {
        title.push((0, _moment.default)(dueOn).format('ddd, MMM D'));
      } else if (this.isUrgent) {
        title.push('Urgent');
      }

      return title.join(' - ');
    }),
    assignedToWritable: Ember.computed('assignedTo.content', {
      get: function get() {
        return this.get('assignedTo.content');
      },
      set: function set(_key, value) {
        this.set('assignedTo', value);
        return value;
      }
    }),
    notes: Ember.computed('hyperlinkResource.scrapeNotes', 'post.scrapeNotes', {
      get: function get() {
        if (this.post) {
          return this.get('post.scrapeNotes');
        }

        return this.get('hyperlinkResource.scrapeNotes');
      },
      set: function set(_key, value) {
        if (this.post) {
          this.set('post.scrapeNotes', value);
        } else {
          this.set('hyperlinkResource.scrapeNotes', value);
        }

        return value;
      }
    }),
    displayOrTitle: Ember.computed('hyperlinkResource.display', 'post.title', {
      get: function get() {
        if (this.post) {
          return this.get('post.title');
        }

        return this.get('hyperlinkResource.display');
      }
    }),
    requestScrapeUrgentType: Ember.computed('isUrgent', {
      get: function get() {
        if (this.isUrgent === 1) {
          return '1-day';
        }

        if (this.isUrgent === 2) {
          return '2-day';
        }

        return 'no';
      },
      set: function set(key, value) {
        switch (value) {
          case '1-day':
            this.set('isUrgent', 1);
            break;

          case '2-day':
            this.set('isUrgent', 2);
            break;

          case 'no':
            this.set('isUrgent', 0);
            break;

          default:
        }

        return value;
      }
    })
  });

  _exports.default = _default;
});