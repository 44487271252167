define("js-common/components/list-sort", ["exports", "js-common/templates/components/list-sort"], function (_exports, _listSort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _listSort.default,
    initializeComponent: Ember.on('init', function () {
      this.set('sortPropertiesForList', ['order:asc', this.labelProperty]);
    }),

    /**
     * @property
     * the array of things that the list is going to show
     */
    list: [],

    /**
     * @property
     * the path of the property to show as label
     */
    labelProperty: 'name',

    /**
     * @property
     * the path of the property to set as id
     */
    idProperty: 'id',

    /**
     * @property
     * message to show on the heading of the panel
     */
    message: 'Please select the things you need:',

    /**
     * @property
     * the thing being selected
     */
    thing: 'Things',

    /**
     * @property
     * the internal properties of each item on the list array to sort by
     */
    sortPropertiesForList: ['order:asc'],

    /**
     * @computed
     * the list already sorted
     * to get selected first
     * then the order the user wants
     */
    sortedList: Ember.computed.sort('list', 'sortPropertiesForList'),

    /**
     * @computed
     * list of selected items
     */
    selectedItems: Ember.computed.filter('list', 'isSelected'),
    actions: {
      /**
       * Action called when the ember-sortable component
       * finished re-ordering a field inside the list of available fields
       * it just set a new order number based on the new order.
       *
       * @param sortedFields Array of Fields already ordered
       * @param draggedModel Field moved
       */
      reorderItems: function reorderItems(sortedItems
      /*, draggedModel */
      ) {
        var order = 1;
        sortedItems.forEach(function (item) {
          return item.set('order', order++);
        });
      },
      orderUp: function orderUp(item) {
        item.set('order', item.get('order') - 1);
      },
      orderDown: function orderDown(item) {
        item.set('order', item.get('order') + 1);
      }
    }
  });

  _exports.default = _default;
});