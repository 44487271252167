define("js-common/models/hyperlink-metric", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;
  var string = attr('string');
  var number = attr('number');

  var _default = Model.extend({
    key: string,
    value: string,
    weight: number,
    resource: belongsTo('hyperlink', {
      async: true
    })
  });

  _exports.default = _default;
});