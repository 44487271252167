define("js-common/services/document-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    reportBuilder: Ember.inject.service(),
    metrics: Ember.inject.service(),
    notify: Ember.inject.service(),
    enqueuedJobId: null,

    /**
     * Download a single document.
     */
    one: function one(document, subject) {
      var docId = document.get('id');
      document.set('hasBeenViewed', true);
      (false && !(typeof document !== 'null' && docId !== null) && Ember.assert('`document` arg is required', typeof document !== 'null' && docId !== null));

      this._handleDownload([docId], subject);

      this.metrics.trackEvent({
        category: 'Documents',
        action: 'download',
        label: docId
      });
    },

    /**
     * Download a many documents.
     */
    many: function many(documents) {
      var subject = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      (false && !(Array.isArray(documents)) && Ember.assert('`documents` arg must be array', Array.isArray(documents)));
      documents.setEach('hasBeenViewed', true);

      this._handleDownload(documents.map(function (row) {
        return row.id;
      }), subject);

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = documents[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var doc = _step.value;
          this.metrics.trackEvent({
            category: 'Documents',
            action: 'download',
            label: doc.id
          });
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    },

    /**
     * Document download handler.
     */
    _handleDownload: function _handleDownload(ids) {
      var _this = this;

      var subject = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      if (!Array.isArray(ids)) {
        ids = [ids];
      }

      if (ids.length === 1) {
        this.reportBuilder.executeReport({
          report_builder: {
            standard: {
              type: 'multiple_documents',
              format: 'pdf',
              style: 'misc'
            },
            custom: {
              document_format: 'zip',
              selected_documents: ids,
              subject_id: subject ? subject.id : null
            }
          }
        }, function () {}, false);
      } else {
        this.reportBuilder.enqueueReport({
          report_builder: {
            standard: {
              type: 'multiple_documents',
              format: 'pdf',
              style: 'misc'
            },
            custom: {
              document_format: 'zip',
              selected_documents: ids,
              subject_id: subject ? subject.id : null
            }
          }
        }, function (response) {
          if (response.ok) {
            _this.set('enqueuedJobId', response.job);
          } else {
            _this.get('notify').error('Error trying to download the documents');
          }
        });
      }
    }
  });

  _exports.default = _default;
});