define("js-common/helpers/display-linebreaks", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.displayLinebreaks = displayLinebreaks;
  _exports.default = void 0;
  var Handlebars = Ember.Handlebars;

  function displayLinebreaks(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        text = _ref2[0];

    text = Handlebars.Utils.escapeExpression(text);
    text = text.replace(/(\r\n|\n|\r)/gm, '<br>');
    return Ember.String.htmlSafe(text);
  }

  var _default = Ember.Helper.helper(displayLinebreaks);

  _exports.default = _default;
});