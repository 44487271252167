define('ember-is-visible/services/visibility', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var APIs = exports.APIs = {
    global: { isSupported: true, hiddenFlag: 'hidden', eventName: 'visibilitychange' },
    webkit: { isSupported: true, hiddenFlag: 'webkitHidden', eventName: 'webkitvisibilitychange' },
    mozilla: { isSupported: true, hiddenFlag: 'mozHidden', eventName: 'mozvisibilitychange' },
    ie: { isSupported: true, hiddenFlag: 'msHidden', eventName: 'msvisibilitychange' },
    unsupported: { isSupported: false }
  };

  /**
   * Returns visibility API support flag & flag / event names, depending on the
   * user's browser.
   *
   * See also:
   * https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
   * https://whatwebcando.today/foreground-detection.html
   */
  var detectApi = exports.detectApi = function detectApi() {
    var _document = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var api = void 0;

    if (Ember.isPresent(Ember.get(_document, 'hidden'))) {
      api = APIs.global;
    } else if (Ember.isPresent(Ember.get(_document, 'webkitHidden'))) {
      api = APIs.webkit;
    } else if (Ember.isPresent(Ember.get(_document, 'mozHidden'))) {
      api = APIs.mozilla;
    } else if (Ember.isPresent(Ember.get(_document, 'msHidden'))) {
      api = APIs.ie;
    } else {
      api = APIs.unsupported;
    }

    return api;
  };

  /**
   * This service keeps track of whether the window has ever lost visibility. This
   * happens when the user switches tabs, minimizes the window, etc.
   */
  exports.default = Ember.Service.extend({

    visible: true,

    lostVisibility: false,

    pageVisibilityAPI: detectApi(document),

    init: function init() {
      this._super.apply(this, arguments);

      var _get = this.get('pageVisibilityAPI'),
          isSupported = _get.isSupported,
          eventName = _get.eventName;

      if (isSupported) {
        this._handleDocumentVisibilityChange();

        document.addEventListener(eventName, Ember.run.bind(this, '_handleDocumentVisibilityChange'));
      }
    },


    /**
     * Service teardown
     */
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this.removeBindings();
    },


    /**
     * Unsubscribes to events.
     */
    removeBindings: function removeBindings() {
      var _get2 = this.get('pageVisibilityAPI'),
          isSupported = _get2.isSupported,
          eventName = _get2.eventName;

      if (isSupported) {
        document.removeEventListener(eventName, this.get('_handleDocumentVisibilityChange'));
      }
    },


    /**
     * Event handler.
     */
    _handleDocumentVisibilityChange: function _handleDocumentVisibilityChange() {
      var hiddenFlagName = this.get('pageVisibilityAPI.hiddenFlag');
      var isHidden = Ember.get(document, hiddenFlagName);

      if (this.isDestroyed) {
        return;
      }

      this.set('visible', !isHidden);

      if (isHidden) {
        this.set('lostVisibility', true);
      }
    }
  });
});