define("js-common/models/budget", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    amount: attr('number'),
    deadlineMet: attr('number', {
      defaultValue: 0
    }),
    dueOn: attr('string'),
    enabled: attr('number', {
      defaultValue: 1
    }),
    hardDeadline: attr('number', {
      defaultValue: 0
    }),
    hours: attr('number'),
    name: attr('string'),
    notes: attr('string'),
    prev_amount: attr('number'),
    createdBy: belongsTo('user', {
      async: true
    }),
    updatedBy: belongsTo('user', {
      async: true
    }),
    createdOn: attr('estdate'),
    updatedOn: attr('estdate'),
    // relationships
    matter: belongsTo('matter', {
      async: false
    })
  });

  _exports.default = _default;
});