define("@smith-carson/ui/pods/components/light-table/cells/progress/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IQmjXh9P",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"data-test-component\",\"light-table-progress-cell-type\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"progress\"],[9],[0,\"\\n\"],[0,\"        \"],[7,\"div\"],[11,\"aria-valuemax\",\"100\"],[11,\"aria-valuemin\",\"0\"],[12,\"aria-valuenow\",[24,0,[\"progressPercentage\"]]],[11,\"class\",\"progress-bar\"],[11,\"data-test-progress-bar\",\"\"],[11,\"role\",\"progressbar\"],[12,\"style\",[29,\"html-safe\",[[29,\"concat\",[\"width: \",[24,0,[\"progressPercentage\"]],\"%; background-color: \",[24,0,[\"progressColor\"]],\";\"],null]],null]],[9],[10],[0,\"\\n\"],[0,\"    \"],[10],[0,\"\\n\"],[4,\"if\",[[24,0,[\"progressTooltip\"]]],null,{\"statements\":[[0,\"        \"],[5,\"bs-tooltip\",[],[[\"@placement\"],[\"right\"]],{\"statements\":[[1,[24,0,[\"progressTooltip\"]],false]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/pods/components/light-table/cells/progress/template.hbs"
    }
  });

  _exports.default = _default;
});