define("js-common/templates/components/field-validation-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VKbn0geV",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[4,\"if\",[[29,\"get\",[[25,[\"changeset\",\"error\"]],[25,[\"propertyName\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"help-block error\"],[9],[0,\"\\n\"],[4,\"each\",[[29,\"get\",[[29,\"get\",[[25,[\"changeset\",\"error\"]],[25,[\"propertyName\"]]],null],\"validation\"],null]],null,{\"statements\":[[0,\"      \"],[1,[24,1,[]],false],[7,\"br\"],[9],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/field-validation-errors.hbs"
    }
  });

  _exports.default = _default;
});