define("@smith-carson/ui/templates/components/sc-file-uploader/progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5kSX4Td2",
    "block": "{\"symbols\":[\"P\",\"@progress\"],\"statements\":[[5,\"sc-progress\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[24,1,[\"bar\"]],[[13,\"class\",\"sc-file-uploader__progress-bar\"]],[[\"@value\",\"@animate\",\"@showLabel\",\"@min\",\"@max\",\"@type\"],[[24,2,[]],true,[29,\"if\",[[29,\"gt\",[[24,2,[]],0],null],true],null],\"0\",\"100\",[24,0,[\"color\"]]]]],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-file-uploader/progress-bar.hbs"
    }
  });

  _exports.default = _default;
});