define("js-common/models/matter-public-subject-tag", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr,
      Model = _emberData.default.Model;

  var _default = Model.extend({
    subjectTag: belongsTo('subject-tag', {
      async: false
    }),
    matter: belongsTo('matter', {
      async: true
    }),
    createdOn: attr('estdate'),
    // UI only
    checked: attr('boolean', {
      defaultValue: true
    })
  });

  _exports.default = _default;
});