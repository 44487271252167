define("@smith-carson/ui/templates/components/sc-table/readonly-page-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CC47Ituo",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"per-page-text\"],[9],[0,\"\\n    Showing \"],[1,[23,\"fromRecord\"],false],[0,\" - \"],[1,[23,\"toRecord\"],false],[0,\" of \"],[1,[23,\"totalRecords\"],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-table/readonly-page-nav.hbs"
    }
  });

  _exports.default = _default;
});