define("js-common/components/input-tooltip", ["exports", "js-common/templates/components/input-tooltip"], function (_exports, _inputTooltip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _inputTooltip.default,
    tagName: 'span',
    classNames: ['input-group-addon'],
    // define properties
    content: 'Help',
    position: 'top'
  });

  _exports.default = _default;
});