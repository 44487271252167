define("js-common/components/x-upload/body-list", ["exports", "js-common/templates/components/x-upload/body-list"], function (_exports, _bodyList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _bodyList.default,
    filesToUpload: [],
    actions: {
      changeType: function changeType(fileToUpload, newType) {
        fileToUpload.set('docTypeInitial', newType);

        if (newType) {
          fileToUpload.set('documentType', newType.get('value'));
        } else {
          fileToUpload.set('documentType', null);
        }
      },
      changeSubject: function changeSubject(fileToUpload, newSubject) {
        fileToUpload.set('subject', newSubject);

        if (newSubject) {
          fileToUpload.set('subjectId', newSubject.get('id'));
        } else {
          fileToUpload.set('subjectId', null);
        }
      }
    }
  });

  _exports.default = _default;
});