define("js-common/models/subject-workflow-column", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    displayName: attr(),
    fieldName: attr(),
    mergeField: attr(),
    apiName: attr(),
    order: attr('number'),
    width: attr(),
    fieldType: attr(),
    // these two are used for presentational purposes on the list page
    workflowAssignedColumn: _emberData.default.attr('boolean'),
    workflowStatusColumn: _emberData.default.attr('boolean'),
    workflowCommentColumn: _emberData.default.attr('boolean'),
    workflowTimeColumn: _emberData.default.attr('boolean'),
    workflowTimeOnlyColumn: _emberData.default.attr('boolean'),
    workflowDeadlineColumn: _emberData.default.attr('boolean'),
    workflowColumn: _emberData.default.attr('boolean'),
    // Component used to display the cell in the table
    cellComponent: null,
    // Component used to capture input from the advanced filter
    advFilterComponent: null
  });

  _exports.default = _default;
});