define("js-common/helpers/formatted-subject-field-value", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formattedSubjectFieldValue = formattedSubjectFieldValue;
  _exports.default = void 0;

  /** global SimpleMDE */

  /**
   *  Used to display regular subject field values
   * (NOT analytic fields)
   *
   * @param params
   * expects an array with the following elements, in this order: content, subject, matterField, matterFieldName (optional)
   * content = subject's field value that we want to format (we send this as a param so the helper is notified of any changes on the subject)
   * @returns {*}
   */
  function formattedSubjectFieldValue(params
  /*, hash */
  ) {
    if (Array.isArray(params)) {
      var subject = params[1];
      var matterField = params[2];
      var matterFieldName = params[3];
      (false && !(subject) && Ember.assert('Invalid parameters supplied to formatted-subject-field-value helper', subject));

      if (subject && matterField) {
        if (!matterFieldName) {
          matterFieldName = matterField.get('name');
        }

        var content = subject.get(matterFieldName);

        if (!Ember.isEmpty(content)) {
          if (Ember.isPresent(content) && ['race', 'gender', 'affiliation', 'affiliationHistory'].indexOf(matterFieldName) >= 0) {
            content = content.split(',').join(', ');
          }

          if (matterFieldName === 'affiliation') {
            content = content.split(', ').map(function (x) {
              if (x.indexOf('Unknown') < 0) {
                if (matterField.get('fieldType') === 'radio') {
                  return x + ' (likely current)';
                } else {
                  return x;
                }
              } else {
                return x;
              }
            }).join(', ');
          }

          if (matterFieldName === 'affiliationHistory') {
            content = content.split(', ').map(function (x) {
              return x + ' (historical)';
            }).join(', ');
          }

          switch (matterField.get('fieldType')) {
            case 'date':
              var dateValue = (0, _moment.default)(content, 'YYYY-MM-DD');

              if (dateValue.isValid()) {
                return dateValue.format('MM-DD-YYYY').toString();
              }

            default:
              var breakTag = '<br />';

              if (matterField.get('fieldType') === 'longtext+markdown') {
                return Ember.String.htmlSafe(SimpleMDE.prototype.markdown(content));
              }

              return Ember.String.htmlSafe((content + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2'));
          }
        }

        return '';
      } else {
        // temp fix because "monitorFrequency" cell does not provide matterField param to this helper (check both subject & matter field in assert after frequency issue is resolved)
        (false && Ember.warn('Invalid parameters supplied to formatted-subject-field-value helper, no matterField passed', true, {
          id: 'temp-fix-monitor-frequency-not-passing-matter-field'
        }));
        return '';
      }
    }
  }

  var _default = Ember.Helper.helper(formattedSubjectFieldValue);

  _exports.default = _default;
});