define("js-common/models/group-has-permission", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    relationship: attr('string'),
    group: belongsTo('group', {
      async: true
    }),
    permission: belongsTo('permission', {
      async: true
    })
  });

  _exports.default = _default;
});