define("ember-bootstrap/templates/components/bs-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L1tFj4Qn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[29,\"hash\",null,[[\"button\",\"toggle\",\"menu\",\"toggleDropdown\",\"openDropdown\",\"closeDropdown\",\"isOpen\"],[[29,\"component\",[[25,[\"buttonComponent\"]]],[[\"dropdown\",\"onClick\"],[[24,0,[]],[29,\"action\",[[24,0,[]],\"toggleDropdown\"],null]]]],[29,\"component\",[[25,[\"toggleComponent\"]]],[[\"dropdown\",\"inNav\",\"onClick\"],[[24,0,[]],[25,[\"inNav\"]],[29,\"action\",[[24,0,[]],\"toggleDropdown\"],null]]]],[29,\"component\",[[25,[\"menuComponent\"]]],[[\"isOpen\",\"direction\",\"inNav\",\"toggleElement\",\"dropdownElementId\"],[[25,[\"isOpen\"]],[25,[\"direction\"]],[25,[\"inNav\"]],[25,[\"toggleElement\"]],[25,[\"elementId\"]]]]],[29,\"action\",[[24,0,[]],\"toggleDropdown\"],null],[29,\"action\",[[24,0,[]],\"openDropdown\"],null],[29,\"action\",[[24,0,[]],\"closeDropdown\"],null],[25,[\"isOpen\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-dropdown.hbs"
    }
  });

  _exports.default = _default;
});