define("js-common/models/resource-has-tag", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    tag: _emberData.default.belongsTo('tag', {
      async: false
    }),
    resource: _emberData.default.belongsTo('resource', {
      async: true,
      polymorphic: true
    }),
    resourceId: Ember.computed.reads('resource.id')
  });

  _exports.default = _default;
});