define("js-common/utils/specific-subject-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * For each subject type, we keep a list of fields
   */
  var _default = {
    jurorSubjectFieldNames: ['location', 'cases', 'locationOrder', 'foreperson', 'courtPanel', 'courtJuror'],
    plaintiffSubjectFieldNames: ['injured', 'broughtSuit', 'deceased'],
    otherSubjectFieldNames: [],
    witnessSubjectFieldNames: []
  };
  _exports.default = _default;
});