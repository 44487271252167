define("@smith-carson/ui/templates/components/sc-card/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hEJPOsX6",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[8,\"header\"],[13,\"class\",[30,[\"card-header sc-card__head \",[24,0,[\"colorClass\"]]]]],[14,1],[9],[0,\"\\n  \"],[15,2],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-card/head.hbs"
    }
  });

  _exports.default = _default;
});