define("js-common/templates/components/edit-field/checkboxes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ns9GFf9V",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"ul\"],[11,\"class\",\"list-inline d-flex flex-wrap\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"checkboxItems\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[11,\"class\",\"list-inline-item mb-1\"],[9],[0,\"\\n            \"],[5,\"sc-checkbox\",[],[[\"@checked\",\"@disabled\",\"@label\",\"@onChange\"],[[24,1,[\"isChecked\"]],[24,1,[\"disabled\"]],[29,\"if\",[[29,\"compute\",[[29,\"action\",[[24,0,[]],\"isOther\"],null],[24,1,[]]],null],\"Other\",[24,1,[\"display\"]]],null],[29,\"action\",[[24,0,[]],\"itemSelectionChanged\",[24,1,[]]],null]]]],[0,\"\\n        \"],[10],[0,\"\\n\"],[4,\"if\",[[29,\"and\",[[29,\"compute\",[[29,\"action\",[[24,0,[]],\"isOther\"],null],[24,1,[]]],null],[24,1,[\"isChecked\"]]],null]],null,{\"statements\":[[0,\"            \"],[7,\"li\"],[11,\"class\",\"list-inline-item mb-1\"],[9],[0,\"\\n                \"],[5,\"sc-input\",[[13,\"class\",\"other-text\"]],[[\"@value\",\"@onChange\"],[[23,\"otherText\"],[29,\"action\",[[24,0,[]],\"changeOther\",[24,1,[]]],null]]]],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/edit-field/checkboxes.hbs"
    }
  });

  _exports.default = _default;
});