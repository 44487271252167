define("js-common/components/sc-common-components", ["exports", "js-common/templates/components/sc-common-components"], function (_exports, _scCommonComponents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _scCommonComponents.default,
    cities: ['Barcelona', 'London', 'New York', 'Porto'],
    destination: 'London',
    radioButtonValue: true,
    showSubjects: true,
    actions: {
      chooseDestination: function chooseDestination(city) {
        this.set('destination', city);
      },
      openconfirm: function openconfirm() {
        this.set('openconfirm', true);
      }
    }
  });

  _exports.default = _default;
});