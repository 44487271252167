define("@smith-carson/ui/templates/components/sc-chart/legend/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0hF3HIvE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"description\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"dot\"],[12,\"style\",[30,[\"background-color:\",[23,\"color\"],\";\"]]],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"legend-title\"],[9],[1,[23,\"title\"],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[2,\"<div>\"],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"value\"],[9],[1,[23,\"value\"],false],[0,\"%\"],[10],[0,\"\\n\"],[2,\"</div>\"],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-chart/legend/item.hbs"
    }
  });

  _exports.default = _default;
});