define("ember-bootstrap/templates/components/bs-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kyyVB0Sx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"icon\"]]],null,{\"statements\":[[7,\"i\"],[12,\"class\",[23,\"icon\"]],[9],[10],[0,\" \"]],\"parameters\":[]},null],[1,[23,\"text\"],false],[15,1,[[29,\"hash\",null,[[\"isFulfilled\",\"isPending\",\"isRejected\",\"isSettled\"],[[25,[\"isFulfilled\"]],[25,[\"isPending\"]],[25,[\"isRejected\"]],[25,[\"isSettled\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-button.hbs"
    }
  });

  _exports.default = _default;
});