define("js-common/helpers/duration-uncertainty-label", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.labelPrefix = labelPrefix;
  _exports.default = void 0;

  function labelPrefix(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 4),
        startOrEnd = _ref2[0],
        earliestOrLatest = _ref2[1],
        hasDuration = _ref2[2],
        hasUncertainty = _ref2[3];

    var label = '';

    if (hasDuration) {
      label += startOrEnd + ' ';
    }

    if (hasUncertainty) {
      label += earliestOrLatest + ' ';
    }

    return label;
  }

  var _default = Ember.Helper.helper(labelPrefix);

  _exports.default = _default;
});