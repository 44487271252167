define("@smith-carson/ui/pods/components/light-table/cells/custom-format/component", ["exports", "ember-light-table/components/cells/base", "@smith-carson/ui/pods/components/light-table/cells/custom-format/template"], function (_exports, _base, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides a custom `LightTable` cell type for rendering a value as a
      link-button. Supports primitives, objects and arrays of objects.
  
      The button label will be derived from either the `format` function, the
      `labelPath` that is passed via `extra` (resolves to `valuePath.labelPath`),
      or the default `valuePath` reference on the `row`.
  
      Usage:
      ```
          {
              cellType: 'custom-format',
  
              // required
              format(row) {
                  const person = row.get('owners').firstObject;
                  const { firstName, lastName } = person.getProperties('firstName', 'lastName');
  
                  return `${firstName.charAt(0)}. ${lastName}`;
              },
              label: 'Owner',
              valuePath: 'owners',
          },
      ```
  
      @module LightTable::Cells::CustomFormatComponent
      @extends EmberLightTable.CellsBaseComponent
   */
  var _default = _base.default.extend({
    classNames: ['custom-format-cell'],
    layout: _template.default,
    value: Ember.computed('row', 'column', function () {
      var row = this.row,
          column = this.column;
      var format = column.format;

      if (typeof format === 'function') {
        return format(row);
      }

      return null;
    })
  });

  _exports.default = _default;
});