define("js-common/components/subject-autosuggest", ["exports", "js-common/templates/components/subject-autosuggest"], function (_exports, _subjectAutosuggest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    layout: _subjectAutosuggest.default,
    initialSubjects: null,
    matter: null,
    placeholder: 'Please select an option',
    subject: null,
    tagName: '',
    // event fired when selecting a subject
    selectSubject: '',
    _performSearchSubjects: function _performSearchSubjects(term, resolve) {
      var params = {
        'first_name||last_name||member_id||court_panel||court_juror': "*".concat(term, "*"),
        matter_id: this.get('matter.id'),
        limit: 30,
        sortField: 'member_id,last_name'
      };

      if (!Number.isNaN(term)) {
        /**
            User typed in a number, so we need the API to prioritize
            the results.
             Ex: If user enters "1" in the search, they will see #1, then
            #10, then #100, #101, etc. but NOT 21, 301, etc.
         */
        params.use_special_priority = term;
      }

      return this.store.query('subject', params).then(function (items) {
        return resolve(items);
      });
    },
    actions: {
      // TODO: Fix this `ember/closure-actions` violation
      subjectSelected: function subjectSelected(subject) {
        // eslint-disable-next-line ember/closure-actions
        this.sendAction('selectSubject', subject);
      },
      // power-select for subject
      searchSubjects: function searchSubjects(term) {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          Ember.run.debounce(_this, _this._performSearchSubjects, term, resolve, reject, 600);
        });
      }
    }
  });

  _exports.default = _default;
});