define("js-common/templates/components/timelines/multiple-event-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x5goupaH",
    "block": "{\"symbols\":[\"state\"],\"statements\":[[7,\"div\"],[11,\"class\",\"ev-col-birth\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-xs-4 col-md-2\"],[9],[0,\"\\n    \"],[7,\"label\"],[9],[0,\"\\n      \"],[1,[25,[\"event\",\"timelineEventType\",\"name\"]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-xs-8 col-md-10 pr-0\"],[9],[0,\"\\n    \"],[1,[29,\"input\",null,[[\"placeholder\",\"value\",\"maxlength\",\"class\",\"key-up\"],[\"YYYY\",[25,[\"event\",\"year\"]],4,\"form-control no-spinners sm-width d-inline\",[29,\"action\",[[24,0,[]],\"validateNumber\",\"year\"],null]]]],false],[0,\"\\n    \"],[1,[29,\"input\",null,[[\"placeholder\",\"value\",\"maxlength\",\"class\",\"key-up\"],[\"MM\",[25,[\"event\",\"month\"]],2,\"form-control no-spinners sm-width d-inline\",[29,\"action\",[[24,0,[]],\"validateNumber\",\"month\"],null]]]],false],[0,\"\\n    \"],[1,[29,\"input\",null,[[\"placeholder\",\"value\",\"maxlength\",\"class\",\"key-up\"],[\"DD\",[25,[\"event\",\"day\"]],2,\"form-control no-spinners sm-width d-inline\",[29,\"action\",[[24,0,[]],\"validateNumber\",\"day\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"ev-col-state\"],[9],[0,\"\\n  \"],[5,\"sc-power-select\",[],[[\"@placeholder\",\"@options\",\"@onchange\",\"@selected\"],[\"State\",[23,\"states\"],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"selectedState\"]]],null]],null],[23,\"selectedState\"]]],{\"statements\":[[0,\"\\n    \"],[1,[24,1,[\"name\"]],false],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"ev-col-city\"],[9],[0,\"\\n  \"],[1,[29,\"input\",null,[[\"type\",\"value\",\"class\",\"placeholder\"],[\"text\",[25,[\"event\",\"city\"]],\"form-control\",\"City\"]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/timelines/multiple-event-row.hbs"
    }
  });

  _exports.default = _default;
});