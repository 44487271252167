define("@smith-carson/ui/pods/components/light-table/cells/icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FN3KT9ck",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,0,[\"iconName\"]]],null,{\"statements\":[[0,\"    \"],[5,\"sc-icon\",[],[[\"@icon\",\"@iconSet\"],[[24,0,[\"iconName\"]],[24,0,[\"iconSet\"]]]]],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"sr-only\"],[9],[1,[24,0,[\"altText\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/pods/components/light-table/cells/icon/template.hbs"
    }
  });

  _exports.default = _default;
});