define('ember-simplemde/helpers/simple-mde-preview', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.simpleMdePreview = simpleMdePreview;

  /*global SimpleMDE*/

  function simpleMdePreview(params /*, hash*/) {
    Ember.assert('This helper requires one string parameter', params.length === 1);
    var plainText = params[0];
    if (Ember.isEmpty(plainText)) {
      return '';
    }
    Ember.assert('Parameter should be string', Ember.typeOf(plainText) === 'string');
    var rendered = SimpleMDE.prototype.markdown(plainText);
    return Ember.String.htmlSafe(rendered);
  }

  exports.default = Ember.Helper.helper(simpleMdePreview);
});