define("js-common/helpers/subject-field-is-null", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    observingKey: false,
    compute: function compute(_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
          subject = _ref2[0],
          matterField = _ref2[1];

      var key = Ember.String.camelize(matterField.get('value'));
      var content = subject.get(key);

      if (key !== this.observingKey) {
        // if we were observing something else, cleanup
        if (this.observingKey) {
          subject.removeObserver(this.observingKey, this, 'recompute');
        }

        this.observingKey = key;
        subject.addObserver(key, this, 'recompute');
      }

      switch (matterField.get('fieldClass')) {
        case 'widget':
          if (matterField.get('mergeField') && matterField.get('mergeField') !== '') {
            return true;
          } else {
            return false;
          }

        default:
          return Ember.isEmpty(content);
      }
    }
  });

  _exports.default = _default;
});