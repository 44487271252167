define("js-common/templates/components/general-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Teu0nUK8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[1,[29,\"bs-tooltip\",null,[[\"title\"],[[25,[\"content\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/general-tooltip.hbs"
    }
  });

  _exports.default = _default;
});