define("js-common/components/timelines/edit-timeline-event", ["exports", "js-common/mixins/crud/error", "js-common/mixins/timeline-event-mixin", "js-common/mixins/timeline-date-fields-enablement-mixin", "js-common/templates/components/timelines/edit-timeline-event"], function (_exports, _error2, _timelineEventMixin, _timelineDateFieldsEnablementMixin, _editTimelineEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_error2.default, _timelineEventMixin.default, _timelineDateFieldsEnablementMixin.default, {
    layout: _editTimelineEvent.default,
    didReceiveAttrs: function didReceiveAttrs() {
      this.initializeComponent();
      this.loadData();
    },
    loadData: function loadData() {
      var _this = this;

      this.initFrequentLocations();
      var event = this.get('event');
      var eventType = event.get('timelineEventType');

      if (eventType) {
        this.set('currentEventType', eventType);
        this.set('hasDuration', eventType.get('pointOrDuration') === 'duration' || event.get('endDate')); // update parent

        this.set('currentTimelineEventParent', eventType.get('timelineEventParent'));
      } // load meta keys


      this.get('store').query('resource-meta', {
        resource_id: event.get('id')
      }).then(function (metaObjects) {
        _this.set('metaObjects', metaObjects);

        var metaFields = _this.get('metaFields');

        if (metaObjects) {
          metaObjects.forEach(function (o) {
            metaFields.set(o.get('key'), o.get('value'));
          });
        }
      }); // sentence

      this.set('timelineSentence', event.get('description')); // location

      this.set('selectedCityName', event.get('city'));
      this.set('selectedCountryName', event.get('country'));
      this.set('selectedCountry', this.get('countries').findBy('name', event.get('country')));
      var state = this.get('states').findBy('name', event.get('state'));
      this.set('selectedState', state);
      this.set('selectedStateName', event.get('state'));
      this.populateCounties(state, event.get('county')); // document source

      this.set('documentSource', this.get('documentTypes').findBy('display', event.get('docType')));
      this.set('documentSourceName', event.get('docType')); // select current subject

      if (!this.get('subject')) {
        // subject is not set, try to auto detect it
        var subjects = event.get('subjects');

        if (subjects && subjects.get('length')) {
          this.set('subject', subjects.get('firstObject')); // load resource has resource

          this.get('store').queryRecord('resource_has_resource', {
            parent_id: this.get('subject.id'),
            child_id: event.get('id')
          }).then(function (subjectHasEvent) {
            _this.set('subjectHasEvent', subjectHasEvent);
          });
        }
      } // load dates


      this.setProperties({
        startYear: event.get('startYear'),
        startMonth: event.get('startMonth'),
        startDay: event.get('startDay'),
        startPrecision: event.get('startPrecision'),
        endYear: event.get('endYear'),
        endMonth: event.get('endMonth'),
        endDay: event.get('endDay'),
        endPrecision: event.get('endPrecision')
      }); // TODO: see if this needs fixing
      // TODO: When the user reopens the form the “Before” and “After” radio buttons are locked. The user should be allowed to edit these buttons.

      this.toggleRadioEnablement('start');
      this.toggleRadioEnablement('end');
      this.toggleEndInputEnablement();
    },
    actions: {
      saveEvent: function saveEvent() {
        var _this2 = this;

        if (!this.validateEventRules()) {
          return;
        }

        var event = this.get('event');
        event.set('timelineEventType', this.get('currentEventType'));
        event.set('country', this.get('selectedCountryName'));
        event.set('state', this.get('selectedStateName'));
        event.set('county', this.get('selectedCountyName'));
        event.set('city', this.get('selectedCityName'));
        event.set('docType', this.get('documentSourceName'));
        event.set('startYear', this.getNumberValue(this.get('startYear')));
        event.set('startMonth', this.getNumberValue(this.get('startMonth')));
        event.set('startDay', this.getNumberValue(this.get('startDay')));
        event.set('startPrecision', this.getNumberValue(this.get('startPrecision')));

        if (this.get('hasDuration')) {
          event.set('endYear', this.getNumberValue(this.get('endYear')));
          event.set('endMonth', this.getNumberValue(this.get('endMonth')));
          event.set('endDay', this.getNumberValue(this.get('endDay')));
          event.set('endPrecision', this.getNumberValue(this.get('endPrecision')));
        } else {
          event.set('endYear', null);
          event.set('endMonth', null);
          event.set('endDay', null);
          event.set('endPrecision', null);
        }

        var metaFields = this.get('metaFields');
        var rules = this.get('currentEventRules');
        var metaResources = [];

        if (rules && 'fields' in rules) {
          rules.fields.forEach(function (field) {
            // only create a resource meta if a value exists
            if (metaFields.get(field.key)) {
              metaResources.push({
                key: field.key,
                value: metaFields.get(field.key)
              });
            }
          });
        }

        event.set('metaResources', JSON.stringify(metaResources)); // add subject id

        event.set('subjectId', this.get('subject.id'));
        event.save().then(function (result) {
          _this2.get('notify').success('Event has been saved successfully');

          _this2.closeThisForm();

          return _this2.attrs.refresh();
        }, function (_error) {
          _this2.validationReport(event);
        });
      },
      closeThisForm: function closeThisForm() {
        // rollback any changes
        this.get('event').rollbackAttributes();
        this.attrs.closeThisForm();
      },
      setDocType: function setDocType(docType) {
        this.set('documentSource', docType.get('value'));
      }
    }
  });

  _exports.default = _default;
});