define("js-common/models/data-report", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    name: attr('string'),
    description: attr('string'),
    category: attr('string'),
    // client defined properties
    displayName: '',
    // The name that will be shown on the table header
    perPage: 100,
    // The Ember route that the report is located at
    route: '',
    // An optional custom component for the table layout for the report
    component: 'report/default-table',
    // A boolean for whether report stands on its own (will not be on list page if true)
    subReport: false,
    // Optional - designates parent report of a sub report
    parentReport: ''
  });

  _exports.default = _default;
});