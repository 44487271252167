define("ember-bootstrap/templates/components/bs-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "krBW+Aed",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[29,\"hash\",null,[[\"item\",\"link-to\",\"dropdown\"],[[29,\"component\",[[25,[\"itemComponent\"]]],null],[29,\"component\",[[25,[\"linkToComponent\"]]],null],[29,\"component\",[[25,[\"dropdownComponent\"]]],[[\"inNav\",\"tagName\"],[true,\"li\"]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-nav.hbs"
    }
  });

  _exports.default = _default;
});