define("@smith-carson/ui/templates/components/sc-container/row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gPmIZuWa",
    "block": "{\"symbols\":[\"@fillRemainingViewportHeight\",\"&attrs\",\"&default\"],\"statements\":[[8,\"div\"],[13,\"class\",[30,[\"\\n    row\\n    sc-container__row\\n    \",[24,0,[\"rowGapSizeClass\"]],\"\\n    \",[24,0,[\"colGapSizeClass\"]],\"\\n    \",[24,0,[\"heightClass\"]],\"\\n    \",[24,0,[\"noGuttersClass\"]],\"\\n  \"]]],[14,2],[9],[0,\"\\n\"],[4,\"if\",[[27,3]],null,{\"statements\":[[0,\"    \"],[15,3,[[29,\"component\",[[24,0,[\"columnComponent\"]]],[[\"fillRemainingViewportHeight\"],[[24,1,[]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,[24,0,[\"columnComponent\"]],[[14,2]],[[\"@fillRemainingViewportHeight\"],[[24,1,[]]]],{\"statements\":[[0,\"\\n      \"],[15,3],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-container/row.hbs"
    }
  });

  _exports.default = _default;
});