define("js-common/templates/components/edit-subject-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E436PY2s",
    "block": "{\"symbols\":[\"result\",\"@isInModal\",\"@model\"],\"statements\":[[5,\"sc-power-select\",[],[[\"@renderInPlace\",\"@dropdownClass\",\"@options\",\"@selected\",\"@onchange\"],[[29,\"if\",[[24,2,[]],false,true],null],[29,\"if\",[[24,2,[]],\"ember-power-select-dropdown--in-modal \"],null],[24,0,[\"subjectTypeList\"]],[29,\"get-appl-list\",[[24,0,[\"subjectTypeList\"]],[24,3,[\"subjectType\"]]],null],[29,\"action\",[[24,0,[]],[29,\"mut-appl-list\",[[24,3,[]],\"subjectType\"],null]],null]]],{\"statements\":[[0,\"\\n  \"],[1,[24,1,[\"display\"]],false],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/edit-subject-type.hbs"
    }
  });

  _exports.default = _default;
});