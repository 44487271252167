define("js-common/utils/sort-timeline-events-by-date-and-accuracy", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = sortTimelineEventsByDateAndAccuracy;

  /**
   * @function
   *
   * This is a standalone function used to sort a list of timeline events
   * by sortDate and it takes into account the weight and the amount of date information that is provided
   * (has just a year value / year & month values / year, month and day are provided)
   *
   * Returns the sorted list of timeline events
   */
  function getEventYear(event) {
    return event.isEnd ? parseInt(event.endYear) : parseInt(event.startYear);
  }

  function getEventMonth(event) {
    return event.isEnd ? event.endMonth ? parseInt(event.endMonth) - 1 : 0 : event.startMonth ? parseInt(event.startMonth) - 1 : 0;
  }

  function getEventDay(event) {
    return event.isEnd ? event.endDay ? parseInt(event.endDay) : 1 : event.startDay ? parseInt(event.startDay) : 1;
  }

  function eventHasDay(event) {
    return event.isEnd ? event.endDay : event.startDay;
  }

  function eventHasMonth(event) {
    return event.isEnd ? event.endMonth : event.startMonth;
  }

  function sortTimelineEventsByDateAndAccuracy(timelineEvents) {
    return timelineEvents.sort(function (aObject, bObject) {
      var a = aObject.event;
      var b = bObject.event;
      var aYear = getEventYear(a);
      var aMonth = getEventMonth(a);
      var aDay = getEventDay(a);
      var bYear = getEventYear(b);
      var bMonth = getEventMonth(b);
      var bDay = getEventDay(b);
      var aSortDate = (0, _moment.default)([aYear, aMonth, aDay]);
      var bSortDate = (0, _moment.default)([bYear, bMonth, bDay]);

      if (aSortDate.isAfter(bSortDate)) {
        return 1;
      }

      if (aSortDate.isBefore(bSortDate)) {
        return -1;
      } // dates are equal
      // both have all date info set


      if (eventHasDay(a) && eventHasDay(b)) {
        return Math.sign(a.weight - b.weight);
      } // one of the events has all date info set


      if (eventHasDay(a) && !eventHasDay(b)) {
        return 1;
      }

      if (!eventHasDay(a) && eventHasDay(b)) {
        return -1;
      } // both events have month set


      if (eventHasMonth(a) && eventHasMonth(b)) {
        return Math.sign(a.weight - b.weight);
      } // one of the events has month set (the other just the year)


      if (eventHasMonth(a) && !eventHasMonth(b)) {
        return 1;
      }

      if (!eventHasMonth(a) && eventHasMonth(b)) {
        return -1;
      } // both events have just year


      return Math.sign(a.weight - b.weight);
    });
  }
});