define("js-common/models/shared-file", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    code: _emberData.default.attr(),
    deleteFileAfterExpire: _emberData.default.attr(),
    downloadedOn: _emberData.default.attr(),
    expiresAfterDownloadMin: _emberData.default.attr(),
    expiresMin: _emberData.default.attr(),
    note: _emberData.default.attr(),
    reportName: _emberData.default.attr(),
    public: _emberData.default.attr('number'),
    shareMethod: _emberData.default.attr(),
    recipientUserEmail: _emberData.default.attr(),
    updatedOn: _emberData.default.attr(),
    fileType: _emberData.default.attr(),
    downloadUrl: _emberData.default.attr(),
    senderUser: _emberData.default.belongsTo('user'),
    recipientUser: _emberData.default.belongsTo('user'),
    resource: _emberData.default.belongsTo('resource')
  });

  _exports.default = _default;
});