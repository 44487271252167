define("js-common/templates/components/sc-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hrD7NVI7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"sc-sidebar\"],[12,\"class\",[30,[[29,\"unless\",[[25,[\"sidebarExpanded\"]],\"sidebar-collapsed\"],null]]]],[9],[0,\"\\n  \"],[15,1],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"sc-sidebar-handle\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"sidebarExpanded\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"fa-icon\",[\"chevron-left\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[29,\"fa-icon\",[\"chevron-right\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[3,\"action\",[[24,0,[]],\"toggleSidebar\"]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/sc-sidebar.hbs"
    }
  });

  _exports.default = _default;
});