define("js-common/components/trimmable-subject-field-read", ["exports", "js-common/templates/components/trimmable-subject-field-read"], function (_exports, _trimmableSubjectFieldRead) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Used as a wrapper component around subject-field-read
   * in order to be able to display a trimmed version of its text output
   *
   */
  var _default = Ember.Component.extend({
    layout: _trimmableSubjectFieldRead.default,
    trimSize: 200,
    showAll: true,
    textContent: null
  });

  _exports.default = _default;
});