define("js-common/mixins/authorize-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    ENV: Ember.computed(function () {
      return Ember.getOwner(this).resolveRegistration('config:environment');
    }),
    authorize: function authorize(xhr) {
      var ENV = this.ENV;
      var accessToken = this.get('session.data.authenticated.token');

      if (Ember.isPresent(accessToken)) {
        xhr.setRequestHeader('X-Authorization', "Token: ".concat(accessToken));

        if (ENV.environment === 'test') {
          xhr.setRequestHeader('X-CI-KEY', ENV.cikey);
        }
      }
    }
  });

  _exports.default = _default;
});