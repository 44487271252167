define("ember-math-helpers/helpers/acos", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.acos = acos;
  _exports.default = void 0;

  /**
   * Executes `Math.acos` on the number passed to the helper.
   *
   * ```hbs
   * {{acos a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.acos`
   * @return {number} The acos of the passed number
   */
  function acos(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        number = _ref2[0];

    return Math.acos(number);
  }

  var _default = Ember.Helper.helper(acos);

  _exports.default = _default;
});