define("@smith-carson/ui/templates/components/sc-radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g3bQovyh",
    "block": "{\"symbols\":[\"checked\",\"@value\",\"@label\",\"@hideLabel\",\"&default\",\"@name\",\"@disabled\",\"&attrs\",\"@groupValue\"],\"statements\":[[7,\"div\"],[11,\"class\",\"sc-radio-button\"],[9],[0,\"\\n\"],[4,\"let\",[[29,\"eq\",[[24,9,[]],[24,2,[]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"input\"],[12,\"id\",[24,0,[\"radioInputId\"]]],[11,\"class\",\"sc-radio-button__input\"],[12,\"checked\",[24,1,[]]],[12,\"aria-checked\",[24,1,[]]],[12,\"name\",[24,6,[]]],[12,\"value\",[24,2,[]]],[12,\"disabled\",[24,7,[]]],[11,\"type\",\"radio\"],[9],[10],[0,\"\\n\\n    \"],[8,\"label\"],[13,\"class\",\"sc-radio-button__label\"],[13,\"for\",[24,0,[\"radioInputId\"]]],[14,8],[9],[0,\"\\n\"],[4,\"if\",[[26,5]],null,{\"statements\":[[0,\"        \"],[15,5],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"unless\",[[24,4,[]]],null,{\"statements\":[[0,\"          \"],[1,[29,\"or\",[[24,3,[]],[24,2,[]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"    \"],[3,\"action\",[[24,0,[]],[24,0,[\"clicked\"]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-radio-button.hbs"
    }
  });

  _exports.default = _default;
});