define("@smith-carson/ui/templates/components/sc-analytic-widget/gender", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7ctENNBp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"d-flex justify-content-center female\"],[9],[0,\"\\n  \"],[5,\"sc-circle\",[[13,\"class\",\"circle\"]],[[\"@color\",\"@value\"],[\"#213e47\",[25,[\"chartData\",\"femaleValue\"]]]]],[0,\"\\n  \"],[5,\"sc-icon\",[],[[\"@size\",\"@color\",\"@icon\"],[\"xxxl\",\"steel\",\"female\"]]],[0,\"\\n\"],[4,\"if\",[[25,[\"showTooltip\"]]],null,{\"statements\":[[0,\"    \"],[5,\"sc-tooltip\",[],[[\"@placement\",\"@color\"],[\"top\",\"dark\"]],{\"statements\":[[0,\"\\n      Female: \"],[1,[25,[\"chartData\",\"femaleValue\"]],false],[0,\"%\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"d-flex justify-content-center\"],[9],[0,\"\\n  \"],[5,\"sc-circle\",[[13,\"class\",\"circle\"]],[[\"@color\",\"@value\"],[\"#ea601c\",[25,[\"chartData\",\"maleValue\"]]]]],[0,\"\\n  \"],[5,\"sc-icon\",[],[[\"@size\",\"@color\",\"@icon\"],[\"xxxl\",\"steel\",\"male\"]]],[0,\"\\n\"],[4,\"if\",[[25,[\"showTooltip\"]]],null,{\"statements\":[[0,\"    \"],[5,\"sc-tooltip\",[],[[\"@placement\",\"@color\"],[\"top\",\"dark\"]],{\"statements\":[[0,\"\\n      Male: \"],[1,[25,[\"chartData\",\"maleValue\"]],false],[0,\"%\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-analytic-widget/gender.hbs"
    }
  });

  _exports.default = _default;
});