define("js-common/helpers/humanize", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.humanize = humanize;
  _exports.default = void 0;
  var regex = /_+|-+/g;
  var replacement = " "; // The substituted value will be contained in the result variable

  function humanize(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        string = _ref2[0];

    if (string === undefined || string === null) {
      return '';
    }

    var result = string.toLowerCase().replace(regex, replacement);
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  var _default = Ember.Helper.helper(humanize);

  _exports.default = _default;
});