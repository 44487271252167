define("@smith-carson/ui/templates/components/sc-file-uploader/file-chooser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bJ/vhEmy",
    "block": "{\"symbols\":[\"&attrs\",\"@multi\"],\"statements\":[[8,\"div\"],[13,\"class\",\"box-documents sc-file-uploader__file-chooser\"],[14,1],[9],[0,\"\\n\"],[0,\"  \"],[7,\"div\"],[11,\"class\",\"col-sm-12\"],[11,\"style\",\"background-color: transparent; padding-top: 20px;\"],[9],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"\\n      Drop here or Click here and press\\n      \"],[7,\"code\"],[9],[0,\"\\n        Ctrl+V\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[5,\"file-field\",[[13,\"class\",\"sc-file-uploader__file-chooser__input\"]],[[\"@multiple\"],[[24,2,[]]]]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-file-uploader/file-chooser.hbs"
    }
  });

  _exports.default = _default;
});