define("@smith-carson/ui/pods/components/light-table/cells/expand-collapse/component", ["exports", "ember-light-table/components/cells/base", "@smith-carson/ui/pods/components/light-table/cells/expand-collapse/template"], function (_exports, _base, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides a custom `LightTable` cell type for rendering an expand/collapse
      affordance to be used in conjunction with a `LightTable::SpannedRowComponent`.
  
      Usage:
      ```
          {
              cellType: 'expand-collapse',
  
              // (optional) path to truthy/falsy value that conditionally renders affordance
              extra: { isExpandablePath: 'workflowsSummary.workflowsCount' },
              width: '2.5em',
          }
      ```
  
      @module LightTable::Cells::ExpandCollapseComponent
      @extends EmberLightTable.CellsBaseComponent
   */
  var _default = _base.default.extend({
    classNames: ['expand-collapse-cell', 'align-center'],
    layout: _template.default,

    /**
        Provides boolean flag indicating presence of associated data (to be
        rendered in `SpannedRowComponent`).
         Defaults to `true`, but is conditional based on a truthy/falsy value
        provided on the `row`.
         @property isExpandable
        @type {Boolean}
     */
    isExpandable: Ember.computed('column', 'row.workflowsSummary.workflowsCount', function () {
      var _this$getProperties = this.getProperties('column', 'row'),
          column = _this$getProperties.column,
          row = _this$getProperties.row;

      var isExpandablePath = column.get('extra.isExpandablePath'); // Default to `true`

      if (isExpandablePath === undefined) {
        return true;
      }

      return !!row.get(isExpandablePath);
    }),

    /**
     * Stop propagation of `click` event on entire cell.
     *
     * @method click
     * @param {jQuery.Event} event
     */
    click: function click(event) {
      event.stopPropagation();
    },
    actions: {
      /**
          Toggles the `row.expanded` property based on user interaction and
          optionally invokes an action passed in from the invoking context.
           @method toggleRowExpanded
          @param {Object} row light-table row
       */
      toggleRowExpanded: function toggleRowExpanded(row) {
        row.set('expanded', !row.get('expanded'));
        var contextualAction = this.get('tableActions.toggleRowExpanded');

        if (typeof contextualAction === 'function') {
          contextualAction(row);
        }
      }
    }
  });

  _exports.default = _default;
});