define("@smith-carson/ui/templates/components/sc-table/filter-operators/multiselect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iBK7x9O6",
    "block": "{\"symbols\":[\"result\",\"@options\",\"@displayPath\",\"@renderInPlace\",\"@matchTriggerWidth\"],\"statements\":[[5,\"sc-power-select-multiple\",[],[[\"@allowClear\",\"@options\",\"@searchField\",\"@searchEnabled\",\"@renderInPlace\",\"@matchTriggerWidth\",\"@selected\",\"@onchange\",\"@dropdownClass\"],[true,[24,2,[]],[24,3,[]],[29,\"if\",[[29,\"gt\",[[24,2,[\"length\"]],5],null],true,false],null],[24,4,[]],[24,5,[]],[24,0,[\"currentSelection\"]],[29,\"action\",[[24,0,[]],\"changeValue\"],null],\"ember-power-select-dropdown--in-modal \"]],{\"statements\":[[0,\"\\n  \"],[1,[29,\"get\",[[24,1,[]],[24,3,[]]],null],false],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-table/filter-operators/multiselect.hbs"
    }
  });

  _exports.default = _default;
});