define("js-common/templates/components/trimmable-subject-field-read", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BHrUNK2+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"style\",\"visibility: hidden; max-height: 1px;\"],[9],[0,\"\\n  \"],[1,[29,\"subject-field-read\",null,[[\"textContent\",\"currentMatterFieldValue\",\"subject\",\"data\",\"matterFieldFamilies\",\"sortedMatterFields\"],[[25,[\"textContent\"]],[25,[\"currentMatterFieldValue\"]],[25,[\"subject\"]],[25,[\"data\"]],[25,[\"matterFieldFamilies\"]],[25,[\"sortedMatterFields\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"showAll\"]]],null,{\"statements\":[[0,\"  \"],[1,[23,\"textContent\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[29,\"truncate\",[[25,[\"textContent\"]],[25,[\"trimSize\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/trimmable-subject-field-read.hbs"
    }
  });

  _exports.default = _default;
});