define("js-common/models/document-template", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    name: attr('string'),
    isMain: attr('number'),
    // relationships
    user: belongsTo('user', {
      async: true
    }),
    // relationships
    documentTemplateHasFields: hasMany('document-template-has-field', {
      async: true
    })
  });

  _exports.default = _default;
});