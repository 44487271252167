define("js-common/helpers/array-by-index", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayByIndex = arrayByIndex;
  _exports.default = void 0;

  /**
   * simple add on to make it easy to display a dynamic array value by key
   * @param params
   * @returns {*}
   */
  function arrayByIndex(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        arr = _ref2[0],
        key = _ref2[1];

    return arr[key];
  }

  var _default = Ember.Helper.helper(arrayByIndex);

  _exports.default = _default;
});