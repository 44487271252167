define("@smith-carson/ui/pods/components/modals/case-group-workflow/component", ["exports", "@smith-carson/ui/pods/components/modals/case-group-workflow/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides affordance for editing a Case Group Workflow configuration in a
      sidebar panel (modal).
  
      @module Modals::CaseGroupWorkflowComponent
      @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    actions: {
      saveCaseGroupWorkflow: function saveCaseGroupWorkflow() {// debugger;
      }
    }
  });

  _exports.default = _default;
});