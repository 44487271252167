define("js-common/models/user-has-team", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    bill: attr('boolean', {
      defaultValue: false
    }),
    billCopy: attr('boolean', {
      defaultValue: false
    }),
    report: attr('boolean', {
      defaultValue: false
    }),
    referredBy: attr('boolean', {
      defaultValue: false
    }),
    whoHired: attr('boolean', {
      defaultValue: false
    }),
    decisionMaker: attr('boolean', {
      defaultValue: false
    }),
    team: belongsTo('team', {
      async: false
    }),
    user: belongsTo('user', {
      async: false
    })
  });

  _exports.default = _default;
});