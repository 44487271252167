define("js-common/components/subject-field-notes-value", ["exports", "js-common/templates/components/subject-field-notes-value"], function (_exports, _subjectFieldNotesValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * For a given subject and field, display the data from it's child note fields
   */
  var _default = Ember.Component.extend({
    layout: _subjectFieldNotesValue.default,
    tagName: '',
    prefix: true
  });

  _exports.default = _default;
});