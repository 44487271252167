define("js-common/models/billable-group", ["exports", "ember-data", "ember-api-actions", "accounting/format-money", "moment"], function (_exports, _emberData, _emberApiActions, _formatMoney, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    start: attr('string'),
    end: attr('string'),
    total: attr('number'),
    createdOn: attr('estdate'),
    updatedOn: attr('estdate'),
    approvalStatus: attr('string'),
    type: attr('string'),
    includeForTravel: attr('boolean', {
      defaultValue: true
    }),
    reimbursedOn: attr('estdate'),
    includeForBilling: attr('boolean', {
      defaultValue: true
    }),
    // not really needed
    updatedById: attr('number'),
    canSeeAmounts: attr('boolean', {
      defaultValue: true
    }),
    // relationships
    billables: hasMany('billable', {
      async: false
    }),
    receipts: hasMany('receipt', {
      async: false
    }),
    charges: hasMany('charge', {
      async: false
    }),
    travelExpenseFiles: hasMany('travel-expense-has-files', {
      async: false
    }),
    deductions: hasMany('deduction', {
      async: false
    }),
    updatedBy: belongsTo('employee', {
      async: true,
      inverse: 'updatedBys'
    }),
    createdBy: belongsTo('employee', {
      async: true,
      inverse: 'createdBys'
    }),
    approvedBy: belongsTo('employee', {
      async: true,
      inverse: 'approvedBys'
    }),
    // serialized from the api if manual details are solicitated:
    billablesCount: attr('number'),
    billType: attr('string'),
    // computed properties
    // custom actions
    updateBillables: (0, _emberApiActions.memberAction)({
      path: 'update-billables',
      type: 'put'
    }),
    reimburse: (0, _emberApiActions.memberAction)({
      path: 'reimburse',
      type: 'put'
    }),
    undoReimbursement: (0, _emberApiActions.memberAction)({
      path: 'undo-reimbursement',
      type: 'put'
    }),
    expenses: Ember.computed('billables.@each.billType', function () {
      return this.billables.filter(function (billable) {
        switch (billable.get('billType')) {
          case 'Time:Expense':
          case 'Piece:Expense':
          case 'Expense':
            return true;
        }

        return false;
      });
    }),
    timeBillables: Ember.computed.filterBy('billables', 'billType', 'Time'),
    pieceBillables: Ember.computed.filterBy('billables', 'billType', 'Piece'),
    mainBillables: Ember.computed('billables.[]', function () {
      return this.billables.filter(function (b) {
        return b.get('billType') === 'Time' || b.get('billType') === 'Piece';
      });
    }),
    formattedTotal: Ember.computed('total', function () {
      return (0, _formatMoney.default)(this.total);
    }),
    totalService: Ember.computed('timeBillables.[]', function () {
      return this.timeBillables.reduce(function (acc, b) {
        return acc + b.get('total');
      }, 0);
    }),
    formattedTotalService: Ember.computed('totalService', function () {
      return (0, _formatMoney.default)(this.totalService);
    }),
    totalExpense: Ember.computed('expenses.[]', function () {
      return this.expenses.reduce(function (acc, b) {
        return acc + b.get('total');
      }, 0);
    }),
    formattedTotalExpense: Ember.computed('totalExpense', function () {
      return (0, _formatMoney.default)(this.totalExpense);
    }),
    formattedStart: Ember.computed('start', function () {
      return (0, _moment.default)(this.start).format('MM-DD-YYYY');
    }),
    rangeDate: Ember.computed('start', 'end', function () {
      var start = this.start,
          end = this.end;
      var startFormatted = (0, _moment.default)(start).format('MM/DD/YY');
      var endFormatted = (0, _moment.default)(end).format('MM/DD/YY');
      return "".concat(startFormatted, " to ").concat(endFormatted);
    }),
    normalizeDates: function normalizeDates() {
      var startDate = this.start;
      var endDate = this.end;
      this.set('start', (0, _moment.default)(startDate).format('YYYY-MM-DD'));

      if (endDate) {
        this.set('end', (0, _moment.default)(endDate).format('YYYY-MM-DD'));
      } else {
        this.set('end', (0, _moment.default)(startDate).format('YYYY-MM-DD'));
      }
    },
    reimburseValue: Ember.computed('total', 'deductions.[]', function () {
      var total = this.total;

      if (total === null) {
        total = 0;
      }

      var deduct = 0;
      this.deductions.forEach(function (deduction) {
        deduct += deduction.get('amount');
      });
      return parseFloat(total) - parseFloat(deduct);
    })
  });

  _exports.default = _default;
});