define("js-common/models/report-component-template", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    name: attr(),
    text: attr(),
    reportComponentType: belongsTo('report-component-type', {
      async: true
    })
  });

  _exports.default = _default;
});