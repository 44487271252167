define("@smith-carson/ui/pods/components/light-table/cells/badge/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b0eGesrl",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,0,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[30,[\"badge badge-\",[24,0,[\"badgeType\"]]]]],[11,\"data-test-component\",\"light-table-badge-status\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"d-flex align-items-center\"],[9],[0,\"\\n            \"],[1,[29,\"html-safe\",[[24,0,[\"label\"]]],null],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/pods/components/light-table/cells/badge/template.hbs"
    }
  });

  _exports.default = _default;
});