define("js-common/helpers/date-format", ["exports", "moment", "ember-moment/utils/helper-compute", "ember-moment/helpers/-base"], function (_exports, _moment, _helperCompute, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    defaultFormatDidChange: Ember.observer('moment.defaultFormat', function () {
      this.recompute();
    }),
    compute: (0, _helperCompute.default)(function (params, _ref) {
      var _this$morphMoment;

      var locale = _ref.locale,
          timeZone = _ref.timeZone;

      this._super.apply(this, arguments);

      var moment = this.moment;
      var length = params.length;

      if (length > 3) {
        throw new TypeError('ember-moment: Invalid number of arguments, expected at most 3');
      }

      var args = [];
      var formatArgs = [];
      var defaultFormat = Ember.get(this, 'moment.defaultFormat');
      var inputDate = params[0];

      if (!inputDate) {
        return null;
      } // convert inputDate to string and create a new moment with the right tz


      var dateString = inputDate.toString();

      var newDate = _moment.default.tz(dateString, 'America/New_York');

      args.push(newDate);

      if (length === 1 && !Ember.isEmpty(defaultFormat)) {
        formatArgs.push(defaultFormat);
      } else if (length === 2) {
        formatArgs.push(params[1]);
      } else if (length > 2) {
        args.push(params[2]);
        formatArgs.push(params[1]);
      }

      return (_this$morphMoment = this.morphMoment(moment.moment.apply(moment, args), {
        locale: locale,
        timeZone: timeZone
      })).format.apply(_this$morphMoment, formatArgs);
    })
  });

  _exports.default = _default;
});