define("js-common/mixins/timeline-date-fields-enablement-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    startIsDisabled: true,
    endIsDisabled: true,
    endInputIsDisabled: true,
    startObserver: Ember.observer('startYear', 'startPrecision', function () {
      this.toggleRadioEnablement('start');
      this.toggleEndInputEnablement();
    }),
    endObserver: Ember.observer('endYear', function () {
      this.toggleRadioEnablement('end');
    }),

    /**
     * @param prefix = (string) start OR end
     * decides if before & after radio boxes should be enabled/disabled
     * precision is null when disabling radios
     */
    toggleRadioEnablement: function toggleRadioEnablement(prefix) {
      if (this.get(prefix + 'Year')) {
        this.set(prefix + 'IsDisabled', false);
      } else {
        this.set(prefix + 'IsDisabled', true);
        this.send('clearPrecision', prefix + 'Precision');
      }
    },
    toggleEndInputEnablement: function toggleEndInputEnablement() {
      var endInputIsDisabled = !this.startYear || this.startYear && this.startPrecision === '-1';
      this.set('endInputIsDisabled', endInputIsDisabled);

      if (endInputIsDisabled) {
        this.set('endYear', null);
        this.set('endMonth', null);
        this.set('endDay', null);
        this.set('endPrecision', null);
      }
    }
  });

  _exports.default = _default;
});