define("js-common/utils/parse-post-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = parsePostData;

  /**
   * @function
   *
   * This is a standalone function used in acceptance
   * tests, in order to get information about the POST
   * request in the `pretender` library so it can check
   * how to respond
   */
  function parsePostData(query) {
    var result = {};
    query.split('&').forEach(function (part) {
      var item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }
});