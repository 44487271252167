define("ember-bootstrap/templates/components/bs-tooltip/element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5QHPSweP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"ember-popper\",null,[[\"id\",\"class\",\"ariaRole\",\"placement\",\"renderInPlace\",\"popperTarget\",\"modifiers\",\"popperContainer\",\"onCreate\",\"onUpdate\"],[[25,[\"id\"]],[25,[\"popperClass\"]],[25,[\"ariaRole\"]],[25,[\"placement\"]],[25,[\"renderInPlace\"]],[25,[\"popperTarget\"]],[25,[\"popperModifiers\"]],\"#ember-bootstrap-wormhole\",[29,\"action\",[[24,0,[]],\"updatePlacement\"],null],[29,\"action\",[[24,0,[]],\"updatePlacement\"],null]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[23,\"arrowClass\"]],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"tooltip-inner\"],[9],[0,\"\\n    \"],[15,1],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-tooltip/element.hbs"
    }
  });

  _exports.default = _default;
});