define("js-common/templates/components/subject-state-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pNY7Ua3B",
    "block": "{\"symbols\":[],\"statements\":[[5,\"sc-badge\",[],[[\"@color\",\"@size\",\"@label\",\"@shape\"],[[24,0,[\"badgeColor\"]],[24,0,[\"size\"]],[24,0,[\"label\"]],\"pill\"]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/subject-state-badge.hbs"
    }
  });

  _exports.default = _default;
});