define("@smith-carson/ui/templates/components/sc-light-table/cells/boolean-yes-no", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QB+LStzd",
    "block": "{\"symbols\":[\"@value\"],\"statements\":[[4,\"if\",[[24,1,[]]],null,{\"statements\":[[0,\"  Yes\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  No\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-light-table/cells/boolean-yes-no.hbs"
    }
  });

  _exports.default = _default;
});