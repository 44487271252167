define("js-common/templates/components/analytics-modules/questionnaire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o7sOadbK",
    "block": "{\"symbols\":[\"Row\",\"Col\",\"Row\",\"Col\",\"Row\"],\"statements\":[[4,\"if\",[[29,\"eq\",[[25,[\"state\"]],\"isEditing\"],null]],null,{\"statements\":[[0,\"  \"],[5,\"sc-container\",[],[[\"@width\",\"@gutters\",\"@rowGapSize\"],[\"fluid\",false,\"none\"]],{\"statements\":[[0,\"\\n    \"],[6,[24,5,[]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"sc-checkbox\",[],[[\"@label\",\"@checked\",\"@onChange\"],[\"Significant discrepancies between questionnaire and research\",[23,\"checked\"],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"checked\"]]],null]],null]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[5]}],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[29,\"eq\",[[25,[\"state\"]],\"analyticsTabReadOnly\"],null]],null,{\"statements\":[[0,\"  \"],[5,\"sc-container\",[[13,\"class\",\"profile-data-row-component\"]],[[\"@width\"],[\"fluid\"]],{\"statements\":[[0,\"\\n    \"],[6,[24,3,[]],[],[[\"@gutters\"],[false]],{\"statements\":[[0,\"\\n      \"],[6,[24,4,[]],[[13,\"class\",\"pr-0 pl-0 field-title\"]],[[\"@size\"],[\"md-12\"]],{\"statements\":[[0,\"\\n        Questionnaire\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[4]}],[0,\"\\n  \"]],\"parameters\":[3]}],[0,\"\\n\"],[4,\"if\",[[25,[\"data\",\"subject\",\"questionnaire\"]]],null,{\"statements\":[[0,\"    \"],[5,\"sc-container\",[[13,\"class\",\"profile-data-row-component-with-contents\"]],[[\"@width\"],[\"fluid\"]],{\"statements\":[[0,\"\\n      \"],[6,[24,1,[]],[],[[\"@gutters\"],[false]],{\"statements\":[[0,\"\\n        \"],[6,[24,2,[]],[[13,\"class\",\"field-contents\"]],[[\"@size\"],[\"md-12\"]],{\"statements\":[[0,\"\\n          Significant discrepancies between questionnaire and research\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"]],\"parameters\":[2]}],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/analytics-modules/questionnaire.hbs"
    }
  });

  _exports.default = _default;
});