define("js-common/models/prorate-subscription-cost-process", ["exports", "ember-data", "moment", "ember-api-actions"], function (_exports, _emberData, _moment, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    executionDate: attr('date'),
    createdOn: attr('estdate'),
    canBeReversed: attr('boolean'),
    formattedExecutionDate: Ember.computed('executionDate', function () {
      var bd = (0, _moment.default)(this.executionDate);

      if (bd.isValid()) {
        return (0, _moment.default)(this.executionDate).format('MM/DD/YYYY');
      }

      return '';
    }),
    createdBy: belongsTo('employee', {
      async: false
    }),
    billableGroup: belongsTo('billableGroup', {
      async: false
    }),
    reverse: (0, _emberApiActions.memberAction)({
      path: 'reverse',
      type: 'PUT'
    })
  });

  _exports.default = _default;
});