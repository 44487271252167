define("js-common/helpers/array-contains-by-id", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayContainsById = arrayContainsById;
  _exports.default = void 0;

  function arrayContainsById(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        array = _ref2[0],
        idToFind = _ref2[1];

    return Boolean(array.find(function (item) {
      return item.id === idToFind;
    }));
  }

  var _default = Ember.Helper.helper(arrayContainsById);

  _exports.default = _default;
});