define("@smith-carson/ui/pods/components/light-table/cells/external-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+Sd9AkmW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[12,\"data-test-link\",[24,0,[\"tmCode\"]]],[12,\"href\",[24,0,[\"linkHref\"]]],[11,\"rel\",\"noreferrer noopener\"],[11,\"target\",\"_blank\"],[12,\"title\",[24,0,[\"linkTitle\"]]],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"onLinkClick\"],null]],[9],[0,\"\\n    \"],[1,[24,0,[\"rowValue\"]],false],[0,\"\\n    \"],[5,\"sc-icon\",[],[[\"@icon\",\"@iconSet\",\"@size\"],[\"external-link\",\"fa\",\"xs\"]]],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"sr-only\"],[9],[0,\"\\n        (Opens in a new window)\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/pods/components/light-table/cells/external-link/template.hbs"
    }
  });

  _exports.default = _default;
});