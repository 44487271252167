define("js-common/metrics-adapters/google-analytics-extra", ["exports", "ember-metrics/metrics-adapters/google-analytics", "ember-metrics/utils/can-use-dom", "ember-metrics/utils/object-transforms"], function (_exports, _googleAnalytics, _canUseDom, _objectTransforms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var compact = _objectTransforms.default.compact;

  var _default = _googleAnalytics.default.extend({
    identify: function identify() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var compactedOptions = compact(options);
      var distinctId = compactedOptions.distinctId;

      if (_canUseDom.default) {
        window.ga('set', 'userId', distinctId); // set the userID as a custom dimension so we can export it

        window.ga('set', 'dimension1', distinctId);
      }
    }
  });

  _exports.default = _default;
});