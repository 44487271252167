define("js-common/components/dashboard-case-note", ["exports", "js-common/templates/components/dashboard-case-note"], function (_exports, _dashboardCaseNote) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _dashboardCaseNote.default,
    showFullText: false,
    actions: {
      toggleShowFullText: function toggleShowFullText() {
        this.set('showFullText', !this.showFullText);
      }
    }
  });

  _exports.default = _default;
});