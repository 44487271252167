define("js-common/helpers/user-has-reviewed-subject", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.userHasReviewedSubject = userHasReviewedSubject;
  _exports.default = void 0;

  function userHasReviewedSubject(params, hash) {
    var userReviewedResources = hash.userReviewedResources,
        subject = hash.subject;
    (false && !(userReviewedResources) && Ember.assert("helper:userHasReviewedSubject: `userReviewedResources' arg is required", userReviewedResources));
    (false && !(subject) && Ember.assert("helper:userHasReviewedSubject: `subject' arg is required", subject));
    return userReviewedResources.findBy('resource.id', subject.get('id'));
  }

  var _default = Ember.Helper.helper(userHasReviewedSubject);

  _exports.default = _default;
});