define("js-common/models/db-has-file", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    // these values are stored in the relainship
    // dealId: attr('number'),
    // fileId: attr('number'),
    // relationships
    file: belongsTo('file', {
      async: false
    }),
    db: belongsTo('db', {
      async: false
    })
  });

  _exports.default = _default;
});