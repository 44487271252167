define("js-common/components/x-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * inspired by this SO article
   * http://stackoverflow.com/questions/24154974/trigger-an-action-on-the-change-event-with-ember-js-checkbox-input-helper
   */
  var _default = Ember.Checkbox.extend({
    change: function change()
    /* event */
    {
      this._super.apply(this, arguments);

      if (typeof this.attrs['on-change'] === 'function') {
        this.attrs['on-change'](this.value, this.checked);
      }
    }
  });

  _exports.default = _default;
});