define("js-common/templates/components/dashboard-case-note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cTeb7x5G",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"style\",\"margin-top:10px;margin-bottom:10px;\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"style\",\"font-weight:bold;\"],[9],[0,\"\\n    \"],[1,[25,[\"v_note\",\"note\",\"createdBy\",\"fullName\"]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"style\",\"color:#aaa;\"],[9],[0,\"\\n    on\\n    \"],[1,[29,\"moment-format\",[[25,[\"v_note\",\"note\",\"createdOn\"]],\"MMM D, YYYY\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"br\"],[9],[10],[0,\"\\n  \"],[7,\"span\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"showFullText\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"nl-2br\",[[25,[\"v_note\",\"note\",\"body\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[29,\"nl-2br\",[[29,\"trunc-text-by-lines\",[[29,\"trunc-text-by-char\",[[25,[\"v_note\",\"note\",\"body\"]],150],null],1],null]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[4,\"string-is-truncated\",null,[[\"text\",\"lines\",\"characters\"],[[25,[\"v_note\",\"note\",\"body\"]],1,150]],{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"show-hide-note\"],[9],[0,\"\\n\"],[4,\"unless\",[[25,[\"showFullText\"]]],null,{\"statements\":[[0,\"        read more\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        hide\\n\"]],\"parameters\":[]}],[0,\"    \"],[3,\"action\",[[24,0,[]],\"toggleShowFullText\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/dashboard-case-note.hbs"
    }
  });

  _exports.default = _default;
});