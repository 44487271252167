define("ember-light-table/templates/components/lt-spanned-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VX2sc79v",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"visible\"]]],null,{\"statements\":[[0,\"  \"],[7,\"tr\"],[12,\"class\",[30,[\"lt-row \",[23,\"classes\"]]]],[9],[0,\"\\n    \"],[7,\"td\"],[12,\"colspan\",[23,\"colspan\"]],[9],[0,\"\\n      \"],[15,1,[[25,[\"yield\"]]]],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/lt-spanned-row.hbs"
    }
  });

  _exports.default = _default;
});