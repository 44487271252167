define("js-common/helpers/auto-refresh-pause", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    autoRefresh: Ember.inject.service(),
    compute: function compute() {
      var _this = this;

      return function () {
        return _this.autoRefresh.stop();
      };
    }
  });

  _exports.default = _default;
});