define("js-common/models/client", ["exports", "jquery", "ember-data", "ember-api-actions"], function (_exports, _jquery, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    modelName: 'client',
    // before we were using constructor.modelname but is no longer reliable
    pwId: attr('number'),
    title: attr('string'),
    clientType: attr('string'),
    history: attr('string'),
    // user fields
    firstName: attr('string'),
    middleName: attr(),
    lastName: attr('string'),
    nameSuffix: attr(),
    email: attr('string'),
    userName: attr('string'),
    password: attr('string'),
    salt: attr('string'),
    passwordExpired: attr('string'),
    status: attr('string'),
    lastLogin: attr('string'),
    lastLogout: attr('string'),
    tmNum: attr('number'),
    tmType: attr('string'),
    token: attr('string'),
    tokenExpires: attr('string'),
    avatarId: attr('number'),
    currentEmployerName: attr('string'),
    currentEmployerId: attr('string'),
    // relationships
    // user: belongsTo('user', {inverse: 'client'}),
    userAddrs: hasMany('user-addr', {
      async: false
    }),
    userNumbers: hasMany('user-number', {
      async: false
    }),
    userUrls: hasMany('user-url', {
      async: false
    }),
    firmHasClients: hasMany('firm-has-client', {
      async: true
    }),
    todos: hasMany('todo', {
      async: true
    }),
    updatedBys: hasMany('billable-group', {
      async: true,
      inverse: 'updatedBy'
    }),
    createdBys: hasMany('billable-group', {
      async: true,
      inverse: 'createdBy'
    }),
    approvedBys: hasMany('billable-group', {
      async: true,
      inverse: 'approvedBy'
    }),
    clientInvoiceHistory: belongsTo('client-invoice-history', {
      async: false
    }),

    /**
     * support a particular non REST action
     *
     * @returns {*}
     */
    reminder: (0, _emberApiActions.memberAction)({
      path: 'reminder',
      type: 'get'
    }),

    /**
     * support a particular non REST action
     *
     * @returns {*}
     */
    sendWelcomeEmail: (0, _emberApiActions.memberAction)({
      path: 'send-welcome-email',
      type: 'get'
    }),
    // calculated
    fullName: Ember.computed('firstName', 'lastName', function () {
      var fullName = this.lastName + ', ' + this.firstName;
      return (0, _jquery.default)('<div/>').html(fullName).text();
    }),
    // this seems to only work when the firm_has_client record was already side loaded
    currentEmployer: Ember.computed('firmHasClients', function () {
      var fhc = this.firmHasClients.findBy('relationship', 'Current Employer');

      if (typeof fhc !== 'undefined') {
        return fhc.get('firm');
      } else {
        return '';
      }
    })
  });

  _exports.default = _default;
});