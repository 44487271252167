define("@smith-carson/ui/pods/components/light-table/cells/link-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3Ect1idE",
    "block": "{\"symbols\":[\"linkButton\"],\"statements\":[[4,\"each\",[[24,0,[\"linkButtons\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"btn-link text-truncate\"],[11,\"data-test-button\",\"light-table-cell-link-button\"],[12,\"title\",[25,[\"column\",\"extra\",\"buttonTitle\"]]],[11,\"type\",\"button\"],[9],[1,[24,1,[\"label\"]],false],[3,\"action\",[[24,0,[]],\"onClick\",[24,1,[\"item\"]]],[[\"bubbles\"],[false]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/pods/components/light-table/cells/link-button/template.hbs"
    }
  });

  _exports.default = _default;
});