define("@smith-carson/ui/pods/components/light-table/cells/link-to/component", ["exports", "@babel/runtime/helpers/esm/typeof", "@babel/runtime/helpers/esm/toConsumableArray", "ember-light-table/components/cells/base", "@smith-carson/ui/pods/components/light-table/cells/link-to/template"], function (_exports, _typeof2, _toConsumableArray2, _base, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides a custom `cellType` for `ember-light-table` implementations that
      convert a column's data value into a link (via `link-to` helper). Supports
      dynamic segments and query string parameters, as well as objects and
      primitive cell values.
  
      Usage:
      ```
          // When setting the `columns` property on an EmberLightTable `Table`:
  
          this.set('columns': [
              /* ... other columns .../
              {
                  cellType: 'link-to',
                  label: 'Column header label',
                  valuePath: 'row.datum', // used for link text
  
                  // Contains info used to generate an Ember `link-to` params array
                  extra: {
                      // (optional) array of paths for route dynamic segments
                      dynamicSegmentPaths: ['id'],
  
                      // (optional) `title` attribute value for link
                      linkTitle: 'Go to some route',
  
                      // (optional) array of name/valuePath pairs (from `row` data) to be passed as querystring
                      // (omit `valuePath` for primitive values)
                      queryParams: [{ name: 'employee', valuePath: 'assignee' }],
  
                      // required route name
                      routeName: 'desired.route.name',
  
                      // optional `format()` method that calculates the link text
                      format() {
                          const { firstName, lastName, middleName } = this.get('row');
                          const middleInitial = middleName.substring(0, 1);
  
                          return `${lastName}, ${firstName} ${middleInitial}.`;
                      },
                  },
              },
              /* ... other columns .../
          ]);
      ```
  
      @module LightTable::Cells::LinkToComponent
      @extends EmberLightTable.CellsBaseComponent
   */
  var _default = _base.default.extend({
    classNames: ['link-to-cell'],
    layout: _template.default,
    init: function init() {
      this._super.apply(this, arguments);

      var column = this.column,
          row = this.row;

      var _column$getProperties = column.getProperties('extra', 'format', 'valuePath'),
          valuePath = _column$getProperties.valuePath;

      (false && !(valuePath) && Ember.assert('You must provide a `valuePath` to the `LinkToComponent`', valuePath));
      var rowValue = row.get(valuePath);

      if (rowValue === null) {
        this.set('linkData', []);
      } else {
        // Supports arrays so ensure we are working with an array
        this.set('linkData', Array.isArray(rowValue) ? rowValue : [rowValue]);
      }
    },

    /**
        Provides a collection of `link` objects mapped from the link data items.
         @property links
        @type {Array}
     */
    links: Ember.computed('linkData', function () {
      var _this = this;

      var linkData = this.linkData;
      return linkData.map(function (linkDatum) {
        return Ember.Object.create({
          anchorText: _this.getAnchorText(linkDatum),
          routeParams: _this.getRouteParams(linkDatum)
        });
      });
    }),

    /**
     * Stop propagation of `click` event on entire cell.
     *
     * @method click
     * @param {jQuery.Event} event
     */
    click: function click(event) {
      event.stopPropagation();
    },

    /**
        Provides anchor text for the the `link-to` based on `extra.labelPath`,
        `format` function (if defined), or raw `linkItem` value.
         @method getAnchorText
        @param {*} linkItem
        @returns {String}
     */
    getAnchorText: function getAnchorText(linkItem) {
      var column = this.column,
          row = this.row;

      var _column$getProperties2 = column.getProperties('extra', 'format'),
          extra = _column$getProperties2.extra,
          format = _column$getProperties2.format;

      var labelPath = extra.labelPath; // If label comes for `format` function...

      if (typeof format === 'function') {
        return format(linkItem, column, row);
      } // ...or is label comes from property on an object...


      if (labelPath) {
        return linkItem.get(labelPath);
      } // ...or default to raw value for the cell.


      return linkItem;
    },

    /**
        Composes a dynamic array containing all of the necessary route
        parameters for the a `link-to` helper's `params` member.
         @method getRouteParams
        @param {*} linkItem
        @returns {Array}
     */
    getRouteParams: function getRouteParams(linkItem) {
      var column = this.column,
          row = this.row;
      var extra = column.extra;
      var dynamicSegmentPaths = extra.dynamicSegmentPaths,
          queryParams = extra.queryParams,
          routeName = extra.routeName;
      var params = [routeName]; // Handle route dynamic segments

      if (dynamicSegmentPaths && dynamicSegmentPaths.length > 0) {
        // eslint-disable-next-line arrow-body-style
        var dynamicSegments = dynamicSegmentPaths.map(function (segmentPath) {
          var segmentFromLinkItemObject = Ember.get(linkItem, segmentPath);
          var segmentFromRowObject = Ember.get(row, segmentPath);
          /*
              Look up value on `linkItem` object first, then try the
              `row`, otherwise return the `linkItem`.
           */

          return segmentFromLinkItemObject || segmentFromRowObject || linkItem;
        });
        params.splice.apply(params, [1, null].concat((0, _toConsumableArray2.default)(dynamicSegments)));
      } // Handle querystring parameters


      if (queryParams && queryParams.length > 0) {
        var linkParams = {};

        var reducer = function reducer(map, param) {
          // Either lookup the querystring parameter value on the `linkItem` object...
          if ((0, _typeof2.default)(linkItem) === 'object') {
            linkParams[param.name] = Ember.get(linkItem, param.valuePath);
            return linkParams;
          } // ...or use the `linkItem` primitive value.


          linkParams[param.name] = linkItem;
          return linkParams;
        };

        queryParams.reduce(reducer, linkParams);
        params.push({
          isQueryParams: true,
          values: linkParams
        });
      }

      return params;
    },

    /**
        Provides access to the column's `routeName` (Ember route name) value for
        use in constructing the link's parameters.
         @property routeName
        @type {String}
     */
    routeName: Ember.computed.reads('column.extra.routeName')
  });

  _exports.default = _default;
});