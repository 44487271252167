define("ember-bootstrap/templates/components/bs-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+t1hV81b",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[29,\"unbound\",[[25,[\"_parentFinder\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[25,[\"inDom\"]]],null,{\"statements\":[[4,\"component\",[[25,[\"elementComponent\"]]],[[\"id\",\"placement\",\"fade\",\"showHelp\",\"class\",\"renderInPlace\",\"popperTarget\",\"autoPlacement\",\"viewportElement\",\"viewportPadding\"],[[25,[\"overlayId\"]],[25,[\"placement\"]],[25,[\"fade\"]],[25,[\"showHelp\"]],[25,[\"class\"]],[25,[\"_renderInPlace\"]],[25,[\"triggerTargetElement\"]],[25,[\"autoPlacement\"]],[25,[\"viewportElement\"]],[25,[\"viewportPadding\"]]]],{\"statements\":[[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"      \"],[15,1,[[29,\"hash\",null,[[\"close\"],[[29,\"action\",[[24,0,[]],\"close\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[23,\"title\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-tooltip.hbs"
    }
  });

  _exports.default = _default;
});