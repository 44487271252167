define("ember-collapsible-panel/components/cp-panel-body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mXL+XF8g",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"shouldAnimate\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"liquid-if\",[[25,[\"isOpen\"]]],[[\"use\"],[\"crossFade\"]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"cp-Panel-body-inner\"],[9],[0,\"\\n      \"],[15,1],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,[\"isOpen\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"cp-Panel-body-inner\"],[9],[0,\"\\n      \"],[15,1],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-collapsible-panel/components/cp-panel-body/template.hbs"
    }
  });

  _exports.default = _default;
});