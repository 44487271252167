define("@smith-carson/ui/templates/components/sc-compact-analytic-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s673JoIb",
    "block": "{\"symbols\":[\"@title\",\"&default\"],\"statements\":[[4,\"if\",[[24,0,[\"isEnabled\"]]],null,{\"statements\":[[4,\"if\",[[24,1,[]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"title\"],[9],[0,\"\\n      \"],[1,[24,1,[]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"analytic-content\"],[12,\"style\",[29,\"html-safe\",[[24,0,[\"contentStyle\"]]],null]],[9],[0,\"\\n    \"],[15,2],[0,\"\\n  \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"no-data\"],[9],[0,\"\\n\"],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-compact-analytic-card.hbs"
    }
  });

  _exports.default = _default;
});