define("js-common/models/matter-field-default", ["exports", "js-common/mixins/matter-field-bitmasks", "ember-data"], function (_exports, _matterFieldBitmasks, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend(_matterFieldBitmasks.default, {
    matterTypeId: attr('number'),
    fieldName: attr('string'),
    fieldDisplay: attr('string'),
    enabled: attr('number'),
    viewable: attr('number'),
    weight: attr('number'),
    locked: attr('number'),
    iconFile: attr('string'),
    // pulled from appl_list: icon_file_names
    section: attr('number'),
    mergeField: attr('string'),
    fieldType: attr('string'),
    // From the appl_list: matter_field_input_types
    listName: attr('string'),
    // One group_name from the appl_list
    columnWidth: attr('number'),
    fieldClass: attr('string'),
    parsable: attr('number'),
    // Tells whether the field is enabled for Data Entry Parsing
    isPortalFilter: attr('number'),
    showAboveOverview: attr('number'),
    matterType: belongsTo('matter-type', {
      async: true
    }),
    isVital: Ember.computed('section', function () {
      return this.get('section') === 1;
    }),
    isOverview: Ember.computed('section', function () {
      return this.get('section') === 2;
    }),
    isLabelEditable: Ember.computed('fieldName', 'isEnabledCase', function () {
      return this.fieldName.indexOf('custom') >= 0 && this.isEnabledCase;
    }),

    /**
     * Mare sure some fields cannot be customized.
     * Examples:
     * first & last name must always be text input field
     * dob & dod must always be calendar dates
     * foreperson & monitor must always be booleans
     */
    isUncustomizable: Ember.computed('value', function () {
      return ['first_name', 'last_name', 'dob', 'dod', 'monitor', 'foreperson'].indexOf(this.fieldName) > -1;
    }),
    // used to prevent users from changing some fields
    disabledChangeLock: Ember.computed('fieldName', 'isDisabledCase', function () {
      return this.fieldName === 'first_name' || this.fieldName === 'last_name' || this.fieldName === 'dob' || this.fieldName === 'dod' || this.fieldName === 'monitor' || this.fieldName === 'foreperson' || this.isDisabledCase;
    })
  });

  _exports.default = _default;
});