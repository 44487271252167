define("@smith-carson/ui/pods/components/sc-light-table/cells/case-tm-code/component", ["exports", "@smith-carson/ui/pods/components/sc-light-table/cells/case-tm-code/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides affordance for conditionally rendering a link out to the
      JurorSmarts application the case is high-volume.
  
      @module ScLightTable::Cells::CaseTmCodeComponent
      @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    classNames: ['case-tm-code-cell'],
    layout: _template.default,

    /**
        Provide an alias for `id` on the `row`.
         @property caseId
        @type {String}
     */
    caseId: Ember.computed.alias('row.id'),

    /**
        Provide an alias for `caseNickname` on the `row`.
         @property caseStyle
        @type {String}
     */
    caseStyle: Ember.computed.alias('row.caseNickname'),
    linkHref: Ember.computed('caseId', function () {
      var caseId = this.caseId;
      return "/matters/".concat(caseId, "/workflows/list");
    }),

    /**
        Provide the value for the link (`a` tag) `title` attribute.
         @property linkTitle
        @type {String}
     */
    linkTitle: Ember.computed('caseStyle', function () {
      var caseStyle = this.caseStyle;
      return "Open ".concat(caseStyle, " in JSI.");
    }),

    /**
        Provide an alias for the `isHighVolume` flag on the `row`.
         @property renderLink
        @type {Boolean}
     */
    renderLink: Ember.computed.equal('row.isHighVolume', 'high_volume'),

    /**
        Provide an alias for the `tmCode` on the `row`.
         @property tmCode
        @type {String}
     */
    tmCode: Ember.computed.alias('row.tmCode'),
    actions: {
      /**
          Stop propagation of the `PointerEvent` to prevent the default row
          action (opening of case sidebar.)
           @param {PointerEvent} event
       */
      onLinkClick: function onLinkClick(event) {
        event.stopPropagation();
      }
    }
  });

  _exports.default = _default;
});