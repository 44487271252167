define("js-common/helpers/show-filesize", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatFilesize = formatFilesize;
  _exports.default = void 0;

  function formatFilesize(value) {
    var round = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
    var mb = value / 1024 / 1024;

    if (round) {
      mb = mb.toFixed(round);
    }

    return mb + ' MB';
  }

  var _default = Ember.Helper.helper(function (_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        value = _ref2[0],
        round = _ref2[1];

    return formatFilesize(value, round);
  });

  _exports.default = _default;
});