define("js-common/templates/components/analytic-widget/type-of-cancer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OXxsB6jr",
    "block": "{\"symbols\":[\"@stats\",\"@canEdit\",\"@toggleViewable\",\"@editConfig\"],\"statements\":[[5,\"sc-analytic-card\",[],[[\"@isEnabled\",\"@canEdit\",\"@onToggle\",\"@onEditConfig\",\"@title\"],[[24,1,[\"type_of_cancer_comparison\",\"matterHasStat\",\"isEnabled\"]],[24,2,[]],[29,\"action\",[[24,0,[]],[24,3,[]],[24,1,[\"type_of_cancer_comparison\",\"matterHasStat\"]]],null],[29,\"action\",[[24,0,[]],[24,4,[]],[24,1,[\"type_of_cancer_comparison\",\"matterHasStat\"]]],null],\"Type of Cancer\"]],{\"statements\":[[0,\"\\n  \"],[5,\"sc-chart\",[],[[\"@chartData\",\"@chartType\"],[[24,0,[\"cancerData\"]],\"horizontal-bar-chart\"]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/analytic-widget/type-of-cancer.hbs"
    }
  });

  _exports.default = _default;
});