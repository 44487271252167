define("@smith-carson/ui/templates/components/sc-table/filter-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZHKJlvjE",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"column\",\"scColumn\",\"usesPowerSelect\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\"],[11,\"class\",\"link\"],[9],[0,\"\\n    \"],[1,[29,\"if\",[[29,\"get\",[[25,[\"column\",\"scColumn\"]],[25,[\"column\",\"scColumn\",\"valuePath\"]]],null],[29,\"get\",[[25,[\"column\",\"scColumn\"]],[25,[\"column\",\"scColumn\",\"displayPath\"]]],null],\"Select\"],null],false],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],\"openModal\"]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[29,\"sc-input\",null,[[\"type\",\"class\",\"value\",\"onChange\",\"name\"],[\"text\",\"form-control inline-column-filter\",[25,[\"column\",\"scColumn\",\"filterValue\"]],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"column\",\"scColumn\",\"filterValue\"]]],null]],null],[25,[\"column\",\"scColumn\",\"fieldName\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-table/filter-input.hbs"
    }
  });

  _exports.default = _default;
});