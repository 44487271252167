define("@smith-carson/ui/templates/components/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uAFcSJf6",
    "block": "{\"symbols\":[\"&attrs\",\"@label\",\"@route\",\"&default\"],\"statements\":[[4,\"if\",[[26,4]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,3,[]]],null,{\"statements\":[[4,\"link-to\",[[24,3,[]]],[[\"class\"],[\"link\"]],{\"statements\":[[0,\"      \"],[15,4],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[8,\"a\"],[13,\"class\",\"link\"],[14,1],[9],[0,\"\\n      \"],[15,4],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,3,[]]],null,{\"statements\":[[4,\"link-to\",[[24,3,[]]],[[\"class\"],[\"link\"]],{\"statements\":[[0,\"      \"],[1,[24,2,[]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[8,\"a\"],[13,\"class\",\"link\"],[14,1],[9],[0,\"\\n      \"],[1,[24,2,[]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/link.hbs"
    }
  });

  _exports.default = _default;
});