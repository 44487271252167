define("js-common/templates/components/subject-icons/reviewed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Evw19EwA",
    "block": "{\"symbols\":[\"isReviewed\",\"@toggleReviewedTask\",\"@subject\",\"@toggleReviewedAs\",\"@userReviewedResources\"],\"statements\":[[4,\"let\",[[29,\"user-has-reviewed-subject\",null,[[\"userReviewedResources\",\"subject\"],[[24,5,[]],[24,3,[]]]]]],null,{\"statements\":[[4,\"if\",[[29,\"or\",[[24,1,[]],[29,\"eq\",[[24,4,[]],\"icon-button\"],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"mt-n2\"],[9],[0,\"\\n      \"],[5,\"sc-icon-button\",[],[[\"@icon\",\"@color\",\"@onClick\"],[\"check\",\"jade\",[29,\"perform\",[[24,2,[]],[24,3,[]]],null]]]],[0,\"\\n      \"],[5,\"sc-popover\",[],[[\"@triggerEvents\",\"@renderInPlace\",\"@placement\"],[\"hover\",false,\"bottom\"]],{\"statements\":[[0,\"\\n        Reviewed\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"eq\",[[24,4,[]],\"button\"],null]],null,{\"statements\":[[0,\"    \"],[5,\"sc-button\",[[13,\"onclick\",[29,\"perform\",[[24,2,[]],[24,3,[]]],null]]],[[\"@label\",\"@icon\",\"@size\",\"@isRunning\"],[\"Mark as reviewed\",\"check-circle-filled\",\"xs\",[24,2,[\"isRunning\"]]]]],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/subject-icons/reviewed.hbs"
    }
  });

  _exports.default = _default;
});