define("js-common/models/task", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  var string = attr('string');
  var estdate = attr('estdate');
  var boolean = attr('boolean');
  var number = attr('number');

  var _default = Model.extend({
    order: number,
    timeLimit: number,
    priority: string,
    status: attr('string', {
      defaultValue: 'I'
    }),
    comment: string,
    dueOn: string,
    createdOn: estdate,
    updatedOn: estdate,
    helpWanted: boolean,
    county: string,
    state: string,
    // make me a relationship
    createdBy: number,
    updatedBy: number,
    taskList: belongsTo('task-list'),
    assignedTo: belongsTo('employee', {
      async: true,
      inverse: 'tasks'
    }),
    // property only for UI not persisted
    isSelected: boolean,
    editAssignAndDueDate: boolean,
    notes: hasMany('task-has-note', {
      async: false
    }),
    // computed properties
    isDone: Ember.computed('status', {
      get: function get(key) {
        return this.status === 'C';
      },
      set: function set(key, value) {
        if (value) {
          this.set('status', 'C');
        } else {
          this.set('status', 'I');
        }

        return value;
      }
    }),
    assignedAndDueLabel: Ember.computed('assignedTo', 'dueOn', function () {
      var assignedTo = this.get('assignedTo.content');
      var dueOn = this.dueOn;
      var title = [];

      if (Ember.isPresent(assignedTo)) {
        title.push(assignedTo.get('fullName'));
      } else {
        title.push('Unassigned');
      }

      if (Ember.isPresent(dueOn)) {
        title.push((0, _moment.default)(dueOn).format('ddd, MMM D'));
      }

      return title.join(' - ');
    }),
    urgent: Ember.computed('priority', {
      get: function get(key) {
        return this.priority === '1';
      },
      set: function set(key, value) {
        this.set('priority', value ? 1 : 0);
        return value;
      }
    }),
    assignedToWritable: Ember.computed({
      get: function get(key) {
        return this.get('assignedTo.content');
      },
      set: function set(key, value) {
        this.set('assignedTo', value);
        return value;
      }
    })
  });

  _exports.default = _default;
});