define("@smith-carson/ui/pods/components/light-table/cells/progress/component", ["exports", "ember-light-table/components/cells/base", "@smith-carson/ui/pods/components/light-table/cells/progress/template"], function (_exports, _base, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides a custom `cellType` for `ember-light-table` implementations that
      renders a Bootstrap `progress` element based the `percentage` method
      defined on the light-table `column` and supports an optional `tooltip`
      method for providing more details.
  
      Note: `row` will be passed into the `column#percentage` and `column#tooltip`
      methods.
  
      Usage:
      ```
          {
              cellType: 'progress',
              label: 'Progress',
              sortable: false,
              width: '12em',
              percentage(row) {
                  const { estHours, remainingHrs } = row.getProperties('estHours', 'remainingHrs');
                  const hoursComplete = estHours - remainingHrs;
                  const percentageComplete = parseInt((hoursComplete / estHours) * 100, 10);
  
                  return percentageComplete;
              },
              tooltip(row) {
                  return `${row.get('remainingHrs')} of ${row.get('estHours')} hours remaining`;
              },
          },
      ```
  
      @module LightTable::Cells::ProgressComponent
      @extends EmberLightTable.CellsBaseComponent
   */
  var _default = _base.default.extend({
    layout: _template.default,

    /**
        Provides access to calculated `percentage` complete defined in `column`.
         @property progressPercentage
        @type {Number}
     */
    progressPercentage: Ember.computed(function () {
      var _this$getProperties = this.getProperties('column', 'row'),
          column = _this$getProperties.column,
          row = _this$getProperties.row;

      var percentage = column.percentage;

      if (typeof percentage === 'function') {
        // Contain between range from 0–100
        return Math.min(Math.max(percentage(row), 0), 100);
      }

      return 0;
    }),

    /**
        Provides access to calculated `tooltip` defined in `column`.
         @property progressTooltip
        @type {String}
     */
    progressTooltip: Ember.computed(function () {
      var _this$getProperties2 = this.getProperties('column', 'row'),
          column = _this$getProperties2.column,
          row = _this$getProperties2.row;

      var tooltip = column.tooltip;

      if (typeof tooltip === 'function') {
        return tooltip(row);
      }

      return null;
    }),
    progressColor: Ember.computed(function () {
      var _this$getProperties3 = this.getProperties('column', 'row'),
          column = _this$getProperties3.column,
          row = _this$getProperties3.row;

      var color = column.color;

      if (typeof color === 'function') {
        // Contain between range from 0–100
        return color(row);
      }

      return '#007bff';
    })
  });

  _exports.default = _default;
});