define('ember-cli-google-recaptcha/-private/g-recaptcha-base', ['exports', 'ember-cli-google-recaptcha/templates/components/g-recaptcha'], function (exports, _gRecaptcha) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function noop() {}

  exports.default = Ember.Component.extend({
    layout: _gRecaptcha.default,

    mergedProperties: ['_extendedOptions'],

    /// The Google reCAPTCHA service.
    grecaptcha: Ember.inject.service('g-recaptcha'),

    /// Set the required class names for the reCAPTCHA element.
    classNames: ['g-recaptcha'],

    /// The attribute bindings for the component.
    attributeBindings: ['tabIndex:data-tabindex'],

    theme: 'light',

    tabIndex: 0,

    _response: null,

    reset: false,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var _getProperties = this.getProperties(['size', 'type', 'theme', 'tabIndex', 'siteKey', 'grecaptcha', '_callback', '_expiredCallback', '_extendedOptions']),
          size = _getProperties.size,
          type = _getProperties.type,
          theme = _getProperties.theme,
          tabIndex = _getProperties.tabIndex,
          grecaptcha = _getProperties.grecaptcha,
          siteKey = _getProperties.siteKey,
          _callback = _getProperties._callback,
          _expiredCallback = _getProperties._expiredCallback,
          _extendedOptions = _getProperties._extendedOptions;

      var options = Ember.merge({
        size: size,
        type: type,
        theme: theme,
        tabindex: tabIndex,
        callback: _callback.bind(this),
        'expired-callback': _expiredCallback.bind(this)
      }, _extendedOptions);

      if (Ember.isPresent(siteKey)) {
        options.sitekey = siteKey;
      }

      grecaptcha.render(this.elementId, options).then(function (widgetId) {
        _this.set('widgetId', widgetId);
        _this.didRenderCaptcha();
      });
    },
    didRenderCaptcha: function didRenderCaptcha() {},


    /**
     * The name of your callback function to be executed when the user submits
     * a successful CAPTCHA response. The user's response, g-recaptcha-response,
     * will be the input for your callback function.
     *
     * @private
     */
    _callback: function _callback() {
      var _this2 = this;

      var _getProperties2 = this.getProperties(['grecaptcha', 'widgetId']),
          grecaptcha = _getProperties2.grecaptcha,
          widgetId = _getProperties2.widgetId;

      grecaptcha.getResponse(widgetId).then(function (response) {
        _this2.set('_response', response);

        // Let the client know we have verified the user.
        _this2.getWithDefault('verified', noop)(response);

        // We also need to let the client know the component has left the verifying
        // state. This is different from the verified event.
        _this2.getWithDefault('verifying', noop)(false);
      });
    },


    /**
     * Callback function to be executed when the recaptcha response expires and the
     * user needs to solve a new CAPTCHA.
     *
     * @private
     */
    _expiredCallback: function _expiredCallback() {
      this.getWithDefault('expired', noop)();
    },


    /**
     * Reset the recaptcha component.
     */
    _reset: function _reset() {
      var _this3 = this;

      var _getProperties3 = this.getProperties(['grecaptcha', 'widgetId']),
          grecaptcha = _getProperties3.grecaptcha,
          widgetId = _getProperties3.widgetId;

      return grecaptcha.reset(widgetId).then(function () {
        _this3.didReset();
        _this3.setProperties({ reset: false, _response: null });
      });
    },
    _execute: function _execute() {
      var _this4 = this;

      var _getProperties4 = this.getProperties(['grecaptcha', 'widgetId']),
          grecaptcha = _getProperties4.grecaptcha,
          widgetId = _getProperties4.widgetId;

      // Notify the client we started the verification process.


      this.getWithDefault('verifying', noop)(true);

      return grecaptcha.execute(widgetId).then(function () {
        _this4.didExecute();
        _this4.set('execute', false);
      });
    },
    didExecute: function didExecute() {},
    didReset: function didReset() {}
  });
});