define("ember-pdf-js/templates/components/pdf-js", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ct0VW2xq",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[1,[29,\"component\",[[25,[\"toolbarComponent\"]]],[[\"changePage\",\"changeSearchResult\",\"goToMatch\",\"search\",\"zoom\",\"page\",\"pageTotal\",\"class\",\"currentMatch\",\"currentMatchIdx\",\"matchTotal\",\"searchResultContexts\"],[[29,\"action\",[[24,0,[]],\"changePage\"],null],[29,\"action\",[[24,0,[]],\"changeSearchResult\"],null],[29,\"action\",[[24,0,[]],\"goToMatch\"],null],[29,\"action\",[[24,0,[]],\"search\"],null],[29,\"action\",[[24,0,[]],\"zoom\"],null],[25,[\"pdfPage\"]],[25,[\"pdfTotalPages\"]],\"ember-pdf-js toolbar\",[25,[\"currentMatch\"]],[25,[\"currentMatchIdx\"]],[25,[\"matchTotal\"]],[25,[\"searchResultContexts\"]]]]],false],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"pdfViewerContainer\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pdfViewer\"],[9],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-pdf-js/templates/components/pdf-js.hbs"
    }
  });

  _exports.default = _default;
});