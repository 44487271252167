define("@smith-carson/ui/templates/components/sc-light-table/columns/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wC8WfBeW",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[29,\"or\",[[25,[\"tableActions\",\"selectAllAction\"]],[25,[\"tableActions\",\"customSelectAll\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[29,\"sc-checkbox\",null,[[\"checked\",\"onChange\",\"title\"],[[24,0,[\"allChecked\"]],[29,\"action\",[[24,0,[]],[29,\"optional\",[[29,\"if\",[[25,[\"tableActions\",\"customSelectAll\"]],[25,[\"tableActions\",\"customSelectAll\"]],[25,[\"tableActions\",\"selectAllAction\"]]],null]],null],[29,\"not\",[[24,0,[\"allChecked\"]]],null]],null],\"Select all on this page\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-light-table/columns/select.hbs"
    }
  });

  _exports.default = _default;
});