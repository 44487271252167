define("@smith-carson/ui/pods/components/light-table/cells/money-format/component", ["exports", "ember-light-table/components/cells/base", "@smith-carson/ui/pods/components/light-table/cells/money-format/template"], function (_exports, _base, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides a custom `LightTable` cell type for rendering status indicators
  
      @module LightTable::Cells::MoneyFormatComponent
      @extends EmberLightTable.CellsBaseComponent
   */
  var _default = _base.default.extend({
    classNames: ['money-cell'],
    layout: _template.default,
    amount: Ember.computed('column.valuePath', 'row', function () {
      return this.get("row.".concat(this.column.valuePath));
    })
  });

  _exports.default = _default;
});