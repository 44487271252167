define("@smith-carson/ui/pods/components/record-pagination/component", ["exports", "@smith-carson/ui/pods/components/record-pagination/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides affordance for paging through partially-rendered record sets.
  
      @module RecordPaginationComponent
      @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    classNames: ['record-pagination'],
    layout: _template.default,
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(typeof this.get('onPagingChange') === 'function') && Ember.assert('You must provide an `onPagingChange` handler to the `RecordPaginationComponent`', typeof this.get('onPagingChange') === 'function'));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      /**
          Creating proxy properties to support in-component value updates and
          `readonly` value bindings.
       */
      this.setProperties({
        page: this.currentPage,
        pageSize: this.currentPageSize
      });
    },

    /**
        Provides boolean flag for disabling the go to first page and go to
        previous page buttons when those actions make no sense.
         @property canGoBackward
        @type {Boolean}
     */
    canGoBackward: Ember.computed('page', function () {
      var _this$getProperties = this.getProperties('page'),
          page = _this$getProperties.page;

      return page > 1;
    }),

    /**
        Provides boolean flag for disabling the go to last page and go to next
        page buttons when those actions make no sense.
         @property canGoForward
        @type {Boolean}
     */
    canGoForward: Ember.computed('page', 'totalPages', function () {
      var _this$getProperties2 = this.getProperties('page', 'totalPages'),
          page = _this$getProperties2.page,
          totalPages = _this$getProperties2.totalPages;

      return page < totalPages;
    }),

    /**
        Provides the total number of pages based on `pageSize` and `totalRecords`.
         @property totalPages
        @type {Number}
     */
    currentRange: Ember.computed('page', 'pageSize', 'totalRecords', function () {
      var _this$getProperties3 = this.getProperties('page', 'pageSize', 'totalRecords'),
          page = _this$getProperties3.page,
          pageSize = _this$getProperties3.pageSize,
          totalRecords = _this$getProperties3.totalRecords;

      var firstPageRecord = page * pageSize - pageSize + 1;
      var lastPageRecord = Math.min(firstPageRecord + pageSize - 1, totalRecords);
      return Ember.String.htmlSafe("".concat(firstPageRecord, "&ndash;").concat(lastPageRecord));
    }),

    /**
        Provides boolean flag for hiding the paging controls when the component
        is invoked without the requisite values.
         @property showPaginationControls
        @type {Boolean}
     */
    showPaginationControls: Ember.computed('page', 'pageSize', 'totalRecords', function () {
      var _this$getProperties4 = this.getProperties('page', 'pageSize', 'totalRecords'),
          page = _this$getProperties4.page,
          pageSize = _this$getProperties4.pageSize,
          totalRecords = _this$getProperties4.totalRecords;
      /* eslint-disable no-restricted-globals */


      if (isNaN(page) || isNaN(pageSize) || isNaN(totalRecords)) {
        return false;
      }
      /* eslint-enable no-restricted-globals */


      return true;
    }),

    /**
        Provides the total number of pages based on `pageSize` and `totalRecords`.
         @property totalPages
        @type {Number}
     */
    totalPages: Ember.computed('pageSize', 'totalRecords', function () {
      var _this$getProperties5 = this.getProperties('pageSize', 'totalRecords'),
          pageSize = _this$getProperties5.pageSize,
          totalRecords = _this$getProperties5.totalRecords;

      var totalPages = Math.ceil(totalRecords / pageSize);
      return Number.isNaN(totalPages) ? 0 : totalPages;
    }),
    actions: {
      /**
          Handles navigating pages via buttons by calculating the number of
          the `relativePage` and passing it to the contextual `onPagingChange`
          handler.
           @method goToRelativePage
       */
      goToRelativePage: function goToRelativePage(relativePage) {
        var _this$getProperties6 = this.getProperties('onPagingChange', 'page', 'pageSize', 'totalPages'),
            onPagingChange = _this$getProperties6.onPagingChange,
            page = _this$getProperties6.page,
            pageSize = _this$getProperties6.pageSize,
            totalPages = _this$getProperties6.totalPages;

        var newPage;

        switch (relativePage) {
          case 'first':
            newPage = 1;
            break;

          case 'previous':
            newPage = page - 1;
            break;

          case 'next':
            newPage = page + 1;
            break;

          case 'last':
            newPage = totalPages;
            break;

          default:
        }

        onPagingChange(newPage, pageSize);
      },

      /**
          Updates `pageSize` value based on user interaction with unbound
          form control (`BsDropdown`) and calls `updatePaging` action.
           @method updatePageSize
          @param {Number} size
       */
      updatePageSize: function updatePageSize(size) {
        this.set('pageSize', size);
        this.send('updatePaging');
      },

      /**
          Passes `page` and `pageSize` values back to the contextual
          `onPagingChange` handler to update the displayed records.
           Note: Getting `page` directly from the `input` value to support
          `readonly` value that prevents premature updating of `currentRange`
          computed property.
           @method updatePaging
       */
      updatePaging: function updatePaging() {
        var _this$getProperties7 = this.getProperties('onPagingChange', 'pageSize'),
            onPagingChange = _this$getProperties7.onPagingChange,
            pageSize = _this$getProperties7.pageSize;

        var pageInput = this.element.querySelector('#case-records-page');

        if (pageInput && typeof onPagingChange === 'function') {
          onPagingChange(pageInput.value, parseInt(pageSize, 10));
        }
      }
    }
  });

  _exports.default = _default;
});