define("js-common/models/team", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    mode: _emberData.default.attr('string'),
    userHasTeam: _emberData.default.hasMany('user-has-team', {
      async: false
    })
  });

  _exports.default = _default;
});