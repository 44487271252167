define("js-common/components/x-filefield", ["exports", "ember-uploader/components/file-field", "js-common/models/file-to-upload"], function (_exports, _fileField, _fileToUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var testing = Ember.testing;

  var _default = _fileField.default.extend({
    classNames: ['x-filefield'],

    /**
     * allow select multiple files when the file open
     * dialog is shown
     *
     * @attribute
     */
    multiple: true,

    /**
     * list of files selected
     *
     * @property
     */
    selectedFiles: [],

    /**
     * Action to perform when files are selected
     * Default implementation is to add them to selectedFiles
     * or reset selectedFiles to empty array if nothing was selected
     *
     * @property
     */
    onSelect: function onSelect() {
      var files = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      this.selectedFiles.pushObjects(files);
    },

    /**
     * override change method from FileField to
     * support set empty list when input.files is empty
     */
    change: function change(e) {
      var files = testing ? e.testingFiles : e.target.files;
      var selectedFiles = null;

      if (!Ember.isEmpty(files)) {
        var totalFiles = files.length;
        selectedFiles = [];

        for (var i = 0; i < totalFiles; i++) {
          var file = files[i];
          selectedFiles.push(_fileToUpload.default.create({
            file: file,
            name: file.name,
            size: file.size,
            type: file.type,
            docType: null,
            subject: null,
            viewable: 0
          }));
        }
      }

      this.onSelect(selectedFiles);
    }
  });

  _exports.default = _default;
});