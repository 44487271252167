define("js-common/templates/components/subject-custom-field-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W53UolQT",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"component\",[[29,\"concat\",[\"subject-custom-modules/\",[29,\"dasherize\",[[25,[\"field\",\"value\"]]],null]],null]],[[\"field\",\"data\",\"state\",\"value\",\"phrases\",\"changeValue\",\"usePhrase\"],[[25,[\"field\"]],[25,[\"data\"]],[25,[\"state\"]],[25,[\"value\"]],[25,[\"phrases\"]],[25,[\"changeValue\"]],[25,[\"usePhrase\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/subject-custom-field-edit.hbs"
    }
  });

  _exports.default = _default;
});