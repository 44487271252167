define("ember-bootstrap/templates/components/bs-form/element/control/radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4X5JEIpK",
    "block": "{\"symbols\":[\"option\",\"index\",\"id\",\"&default\"],\"statements\":[[4,\"each\",[[25,[\"options\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[30,[\"form-check\",[29,\"if\",[[25,[\"inline\"]],\" form-check-inline\"],null]]]],[9],[0,\"\\n\"],[4,\"with\",[[29,\"concat\",[[24,0,[\"elementId\"]],\"-\",[24,2,[]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"input\"],[11,\"class\",\"form-check-input\"],[12,\"id\",[24,3,[]]],[12,\"checked\",[29,\"bs-eq\",[[24,1,[]],[25,[\"value\"]]],null]],[12,\"onclick\",[29,\"action\",[[24,0,[]],[25,[\"onChange\"]],[24,1,[]]],null]],[12,\"name\",[23,\"name\"]],[12,\"required\",[23,\"required\"]],[12,\"disabled\",[23,\"disabled\"]],[12,\"autofocus\",[23,\"autofocus\"]],[12,\"tabindex\",[23,\"tabindex\"]],[12,\"form\",[23,\"form\"]],[12,\"title\",[23,\"title\"]],[11,\"type\",\"radio\"],[9],[10],[0,\"\\n      \"],[7,\"label\"],[12,\"for\",[24,3,[]]],[11,\"class\",\"form-check-label\"],[9],[0,\"\\n\"],[4,\"if\",[[26,4]],null,{\"statements\":[[0,\"          \"],[15,4,[[24,1,[]],[24,2,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"optionLabelPath\"]]],null,{\"statements\":[[0,\"            \"],[1,[29,\"get\",[[24,1,[]],[25,[\"optionLabelPath\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[24,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/control/radio.hbs"
    }
  });

  _exports.default = _default;
});