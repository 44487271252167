define("js-common/models/document-field", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    fieldName: attr('string'),
    weight: attr('number'),
    verticalPosition: attr('string'),
    horizontalPosition: attr('string'),
    // relationships
    documentTemplateHasFields: hasMany('document-template-has-field', {
      async: true
    })
  });

  _exports.default = _default;
});