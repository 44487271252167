define("js-common/components/document-download-link", ["exports", "js-common/templates/components/document-download-link"], function (_exports, _documentDownloadLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Examples extracted from js2-admin, tm2-admin and tm2-client all the document-url in those apps should be replaced with this one:
   *
   * <h1>subjects/info/documents</h1>
   * <dl>
   *   <dt>old</dt>
   *   <dd>{{document-url id=100 text="Full text file name.pdf" icon="download" showIcon=false}}</dd>
   *   <dt>new</dt>
   *   <dd><DocumentDownloadLink @id={{100}} @label="Full text file name.pdf" /></dd>
   * </dl>
   *
   * <h1>search-documents</h1>
   * <dl>
   *   <dt>old</dt>
   *   <dd>{{document-url id=100 text="Download"}}</dd>
   *   <dt>new</dt>
   *   <dd><DocumentDownloadLink @id={{100}} @label="Download" /></dd>
   * </dl>
   *
   * <h1>documents/table-row</h1>
   * <dl>
   *   <dt>old</dt>
   *   <dd>{{document-url id=100 text="Download" icon="download"}}</dd>
   *   <dt>new</dt>
   *   <dd><DocumentDownloadLink @id={{100}} @label="Download" @icon="download" /></dd>
   * </dl>
   *
   * <h1>subjects/info</h1>
   * <dl>
   *   <dt>old</dt>
   *   <dd>{{document-url id=100 icon="paperclip" showIcon=true extraClasses="btn btn-link p-r-0"}}</dd>
   *   <dt>new</dt>
   *   <dd><DocumentDownloadLink @id={{100}} @icon="paperclip" class="btn btn-link p-r-0"/></dd>
   * </dl>
   *
   * <h1>subjects/monitoring-activity-cell/</h1>
   * <dl>
   *   <dt>old</dt>
   *   <dd>{{document-url id=100 icon="paperclip" showIcon=true extraClasses="btn btn-link"}}</dd>
   *   <dt>new</dt>
   *   <dd><DocumentDownloadLink @id={{100}} @icon="paperclip" class="btn btn-link"/></dd>
   * </dl>
   *
   * <h1>subjects/info/documents</h1>
   * <dl>
   *   <dt>old</dt>
   *   <dd>{{document-url id=100}}</dd>
   *   <dt>new</dt>
   *   <dd><DocumentDownloadLink @id={{document.id}} @icon="download"/></dd>
   * </dl>
   *
   * <h1>TM2 subjects/info/documents with metrics</h1>
   * <dl>
   *   <dt>old</dt>
   *   <dd>{{document-url id=100 text="Download" icon="download" markDocumentAsViewed=(action "markDocumentAsViewed" document)}}</dd>
   *   <dt>new</dt>
   *   <dd><DocumentDownloadLink @id={{100}} @label="Download" @icon="download"
   *     @markDocumentAsViewed={{action 'markDocumentAsViewed' document}}
   *     @trackDownload={{true}} /></dd>
   * </dl>
   *
   * <h1>TM2-portal report-browser</h1>
   * <dl>
   *   <dt>old</dt>
   *   <dd>{{document-url id=100 fileNameFormat='tab'}}</dd>
   *   <dt>new</dt>
   *   <dd><DocumentDownloadLink @id={{100}} @label="Download" @tooltip="Download document"
   *     @trackDownload={{true}} @fileNameFormat='tab'
   *     target="_blank" class="small" style="padding: 1px" /></dd>
   * </dl>
   */
  var _default = Ember.Component.extend({
    layout: _documentDownloadLink.default,
    tagName: '',

    /**
     * @argument
     * @public
     * id of the document to link to
     */
    id: null,

    /**
     * @argument
     * @public
     * icon name that you want to show before the label
     */
    icon: null,

    /**
     * @argument
     * @public
     * label of the link after the icon
     */
    label: null,

    /**
     * @argument
     * @public
     * text on the tooltip if we need one
     */
    tooltip: null,

    /**
     * @argument
     * @public
     * name of the file suggested on the save as dialog
     */
    suggestedFileName: 'downloaded_document',

    /**
     * @argument
     * @public
     * action to call to set document as viewed
     */
    markDocumentAsViewed: null,

    /**
     * @argument
     * @public
     * if we should call the metrics service to track the download of the document
     */
    trackDownload: false,

    /**
     * @argument
     * @public
     * options tab or name
     */
    fileNameFormat: null,

    /**
     * @argument
     * @public
     * if should download the searchable pdf instead
     */
    searchable: false,
    session: Ember.inject.service(),
    metrics: Ember.inject.service(),
    ENV: Ember.computed(function () {
      return Ember.getOwner(this).resolveRegistration('config:environment');
    }),
    downloadURI: Ember.computed('id', 'subjectId', 'searchable', function () {
      var baseURI = this.ENV.APP.documentDownloadURL;
      var url = "".concat(baseURI).concat(this.id, "/download?token=").concat(this.session.data.authenticated.token, "&subject_id=").concat(this.subjectId);

      if (!Ember.isEmpty(this.fileNameFormat)) {
        url += "&file_name_format=".concat(this.fileNameFormat);
      }

      if (this.searchable) {
        url += '&searchable=true';
      }

      return url;
    }),
    actions: {
      click: function click() {
        if (this.trackDownload) {
          this.metrics.trackEvent({
            category: 'Documents',
            action: 'download',
            label: this.id
          });
        }

        if (this.markDocumentAsViewed) {
          this.markDocumentAsViewed();
        }
      }
    }
  });

  _exports.default = _default;
});