define("js-common/templates/components/analytic-widget/criminal-bankruptcy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eVZfyafJ",
    "block": "{\"symbols\":[\"@canEdit\",\"@stats\",\"@toggleViewable\"],\"statements\":[[5,\"sc-analytic-card\",[],[[\"@canEdit\",\"@isEnabled\",\"@note\",\"@title\",\"@onToggle\"],[[24,1,[]],[24,2,[\"case_type_comparison\",\"matterHasStat\",\"isEnabled\"]],\"* Per background databases\",\"Case History\",[29,\"action\",[[24,0,[]],[24,3,[]],[24,2,[\"case_type_comparison\",\"matterHasStat\"]]],null]]],{\"statements\":[[0,\"\\n    \"],[5,\"sc-chart\",[],[[\"@chartData\",\"@chartType\"],[[24,0,[\"caseTypesData\"]],\"horizontal-bar-chart\"]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/analytic-widget/criminal-bankruptcy.hbs"
    }
  });

  _exports.default = _default;
});