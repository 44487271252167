define("js-common/models/timeline-event-parent", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var hasMany = _emberData.default.hasMany;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    icon: _emberData.default.attr('string'),
    timelineEventTypes: hasMany('timeline-event-type', {
      async: true
    })
  });

  _exports.default = _default;
});