define("js-common/models/matter-column", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;

  var _default = Model.extend({
    display: attr('string'),
    value: attr('string')
  });

  _exports.default = _default;
});