define("js-common/models/note", ["exports", "jquery", "ember-data"], function (_exports, _jquery, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    body: _emberData.default.attr('string'),
    sendEmail: _emberData.default.attr('boolean'),
    recipients: _emberData.default.attr('string'),
    noteType: _emberData.default.attr('string'),
    // type is a reserved word in the api
    monitorActivity: _emberData.default.attr('string'),
    hasDocuments: _emberData.default.attr('boolean'),
    documentCount: _emberData.default.attr('number'),
    documentId: _emberData.default.attr('number'),
    // the id of the only document that is attached to the note
    // replies on case notes
    children: _emberData.default.hasMany('note', {
      async: false,
      inverse: 'parent'
    }),
    parent: _emberData.default.belongsTo('note', {
      async: false,
      inverse: 'children'
    }),
    // resource fields
    childTable: _emberData.default.attr('string'),
    syncId: _emberData.default.attr('number'),
    parentId: _emberData.default.attr('number'),
    subjectId: _emberData.default.attr('number'),
    createdOn: _emberData.default.attr('estdate'),
    updatedOn: _emberData.default.attr('estdate'),
    viewable: _emberData.default.attr('number'),
    doNotUse: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    // relationships
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    matter: _emberData.default.belongsTo('matter', {
      async: true
    }),
    vNoteParents: _emberData.default.hasMany('v-note-parent', {
      async: true,
      inverse: 'note'
    }),
    // computed
    isViewable: Ember.computed('viewable', function () {
      return parseInt(this.viewable, 10) === 1;
    }),
    recipientList: Ember.computed('recipients', function () {
      var self = this;
      var employeeList = [];
      var rec = this.recipients;

      if (Ember.isEmpty(rec)) {
        return [];
      }

      rec.split('/ ').forEach(function (recipient) {
        var employeeParts = recipient.split(':');
        var id = employeeParts[0];
        var employee = self.store.findRecord('employee', id);
        employeeList.pushObject(employee);
      });
      return employeeList;
    }),
    hasRecipients: Ember.computed.notEmpty('recipients'),
    truncatedBody: Ember.computed('body', function () {
      var body = this.body;
      return _jquery.default.trim(body).substring(0, 100);
    })
  });

  _exports.default = _default;
});