define("js-common/utils/get-sc-portal-report-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getScPortalReportTab;

  function getScPortalReportTab(config) {
    var tab = 'matter.new-report';

    if (config.length === 2) {
      tab = config.rejectBy('name', 'matters.default.sc_portal_report_tab').get('firstObject.value');
    } else if (config.length === 1) {
      tab = config.get('firstObject.value');
    }

    return tab;
  }
});