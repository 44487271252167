define("js-common/utils/get-subject-sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getSubjectSort;

  function transformConfigToField(config, modelName) {
    if (modelName) {
      switch (config) {
        case 'panel_then_order':
          return ['courtPanel', 'memberId'];

        case 'order_only':
        default:
          return ['memberId'];
      }
    } else {
      switch (config) {
        case 'panel_then_order':
          return 'court_panel,member_id';

        case 'order_only':
        default:
          return 'member_id';
      }
    }
  }

  function getSubjectSort(jsSubjectSort) {
    var modelName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var sortField = 'member_id';

    if (jsSubjectSort.length === 2) {
      sortField = transformConfigToField(jsSubjectSort.rejectBy('name', 'matters.default.js_subject_sort').get('firstObject.value'), modelName);
    } else if (jsSubjectSort.length === 1) {
      sortField = transformConfigToField(jsSubjectSort.get('firstObject.value'), modelName);
    }

    return sortField;
  }
});