define("js-common/models/case-workflow", ["exports", "ember-data", "moment", "ember-api-actions"], function (_exports, _emberData, _moment, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    matterId: attr('number'),
    matterWorkflowId: attr('number'),
    matterWorkflowAssigneeId: attr('number'),
    matterCode: attr('string'),
    matterName: attr('string'),
    matterProgressStatus: attr('string'),
    matterTypeId: attr('number'),
    matterCollectionId: attr('number'),
    workflowId: attr('number'),
    enabled: attr('number'),
    name: attr('string'),
    assignedToId: attr('number'),
    assignedTo: attr('string'),
    assignedToFullName: attr('string'),
    status: attr('string'),
    statusName: attr('string'),
    comment: attr('string'),
    hours: attr('number'),
    percentage: attr('number'),
    deadline: attr('string'),
    calcDeadline: attr('string'),
    hardDeadline: attr('number'),
    calcNextDue: attr('string'),
    various: attr('boolean'),
    updatedOn: attr('estdate'),
    createdOn: attr('estdate'),
    caseOwnerIds: attr(),
    weight: attr('number'),
    lastEntry: attr('string'),
    totalHours: attr('number'),
    onScheduler: attr('boolean'),
    onSchedulerSince: attr('string'),
    lastScheduled: attr('string'),
    numberOfAssignees: attr('number'),
    buffer: attr('number'),
    workflowField: Ember.computed('name', function () {
      return Ember.String.underscore(this.name);
    }),
    isCustom: Ember.computed.equal('workflowId', 55),
    events: hasMany('case-workflow-event', {
      async: false
    }),
    dotColor: Ember.computed('deadline', function () {
      if (Ember.isEmpty(this.deadline)) {
        return 'white';
      }

      var today = (0, _moment.default)();
      var diff = (0, _moment.default)(this.deadline).diff(today, 'days');

      if (diff < 0) {
        return 'gray';
      }

      if (diff <= 1) {
        return 'red';
      }

      if (diff <= 7) {
        return 'orange';
      }

      return 'green';
    }),
    multipleHours: Ember.computed('various', 'hours', 'numberOfAssignees', function () {
      if (this.various) {
        return this.hours * this.numberOfAssignees;
      }

      return this.hours;
    }),
    createMatterWorkflow: (0, _emberApiActions.memberAction)({
      path: 'create',
      type: 'post'
    }),
    updateAllMatterWorkflow: (0, _emberApiActions.memberAction)({
      path: 'mass',
      type: 'put'
    }),
    updateWeights: (0, _emberApiActions.collectionAction)({
      path: 'update-weights',
      type: 'put'
    })
  });

  _exports.default = _default;
});