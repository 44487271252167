define("js-common/models/user-event-log", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    code: attr('string'),
    description: attr('string'),
    createdOn: attr('estdate'),
    createdBy: belongsTo('user')
  });

  _exports.default = _default;
});