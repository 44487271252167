define("js-common/components/analytics-modules/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showFieldLabel: Ember.computed('vitalsSection', 'overviewSection', 'isTable', 'isParent', 'isChild', function () {
      return this.get('isChild') || this.get('overviewSection');
    }),
    isParentVital: Ember.computed('vitalsSection', 'isParent', function () {
      return this.get('vitalsSection') && this.get('isParent');
    })
  });

  _exports.default = _default;
});