define("js-common/helpers/highlight-result", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.highlightResult = highlightResult;
  _exports.default = void 0;

  /**
   * @public
   * this helper function replace the hash.query parameter
   * with a sanitized <i class='highlight'>hash.query</i>
   * tag so it can be styled to show as highlighted
   */
  function highlightResult(params, hash) {
    var _params = (0, _slicedToArray2.default)(params, 1),
        phrase = _params[0];

    var q = Ember.get(hash, 'query.lastSearchedText');
    var multiTerms = Ember.get(hash, 'multiTerms') || false;

    if (!Ember.isPresent(q)) {
      var query = Ember.get(hash, 'query'); // second try at getting the query

      if (typeof query === 'string') {
        q = query;
      }
    }

    if (!Ember.isPresent(q)) {
      return phrase;
    }

    if (Ember.isEmpty(phrase)) {
      return phrase;
    }

    var highlighted;
    var escapeRegexp = /[-/\\^$*+?.()|[\]{}]/g;

    if (multiTerms) {
      highlighted = q.split(' ').reduce(function (phrase, term) {
        return phrase.replace(new RegExp(term.toLowerCase().replace(escapeRegexp, '\\$&'), 'gi'), function (match) {
          return "<i class=\"highlight\">".concat(match, "</i>");
        });
      }, phrase);
    } else {
      phrase = phrase.toString(); // in case phrase is not a string. eg: number

      highlighted = phrase.replace(new RegExp(q.toLowerCase().replace(escapeRegexp, '\\$&'), 'gi'), function (match) {
        return "<i class=\"highlight\">".concat(match, "</i>");
      });
    }

    return Ember.String.htmlSafe(highlighted.trim());
  }

  var _default = Ember.Helper.helper(highlightResult);

  _exports.default = _default;
});