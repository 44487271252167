define("js-common/routes/shared-file", ["exports", "js-common/mixins/open-route-mixin"], function (_exports, _openRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_openRouteMixin.default, {
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    ENV: Ember.computed(function () {
      return Ember.getOwner(this).resolveRegistration('config:environment');
    }),
    model: function model(params) {
      return {
        code: params.code
      };
    },
    setupController: function setupController(controller, resolved) {
      this._super(controller, {});

      controller.set('code', resolved.code);
      controller.get('retrieveReport').perform();
    },
    actions: {
      error: function error(_error) {
        this.notify.error(_error.message);
      }
    }
  });

  _exports.default = _default;
});