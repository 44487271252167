define("js-common/models/workflow-assignment-summary", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    total: attr('number'),
    name: attr('string'),
    records: attr(),
    c: attr('number'),
    p: attr('number'),
    i: attr('number'),
    s: attr('number'),
    u: attr('number'),
    n: attr('number')
  });

  _exports.default = _default;
});