define("@smith-carson/ui/templates/components/sc-date-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3NzQ03Mp",
    "block": "{\"symbols\":[\"@disabled\",\"@format\",\"@maxDate\",\"@minDate\",\"@onClose\",\"@onDraw\",\"@onOpen\",\"@onSelection\",\"@options\",\"@placeholder\",\"@readonly\",\"@useUTC\",\"@value\",\"@yearRange\",\"&attrs\"],\"statements\":[[5,\"pikaday-input\",[[13,\"class\",\"sc-date-picker\"],[14,15]],[[\"@disabled\",\"@format\",\"@maxDate\",\"@minDate\",\"@onClose\",\"@onDraw\",\"@onOpen\",\"@onSelection\",\"@options\",\"@placeholder\",\"@readonly\",\"@useUTC\",\"@value\",\"@yearRange\"],[[24,1,[]],[24,2,[]],[24,3,[]],[24,4,[]],[29,\"optional\",[[24,5,[]]],null],[29,\"optional\",[[24,6,[]]],null],[29,\"optional\",[[24,7,[]]],null],[29,\"optional\",[[24,8,[]]],null],[24,9,[]],[24,10,[]],[24,11,[]],[24,12,[]],[24,13,[]],[24,14,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-date-picker.hbs"
    }
  });

  _exports.default = _default;
});