define("@smith-carson/ui/pods/components/light-table/select-all/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tZ5leKkt",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"input\",null,[[\"checked\",\"data-test-input\",\"id\",\"type\",\"click\"],[[29,\"readonly\",[[24,0,[\"allSelected\"]]],null],\"light-table-select-all\",[24,0,[\"ckbId\"]],\"checkbox\",[29,\"action\",[[24,0,[]],\"toggleAllSelected\"],null]]]],false],[0,\"\\n\"],[7,\"label\"],[11,\"class\",\"sr-only\"],[11,\"data-test-label\",\"light-table-select-all\"],[12,\"for\",[24,0,[\"ckbId\"]]],[9],[0,\"\\n    Select all\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/pods/components/light-table/select-all/template.hbs"
    }
  });

  _exports.default = _default;
});