define("@smith-carson/ui/templates/components/sc-list-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k3H+PwuS",
    "block": "{\"symbols\":[\"&default\",\"@hoverable\",\"@size\",\"&attrs\"],\"statements\":[[0,\"  \"],[8,\"div\"],[13,\"class\",[30,[\"sc-list-group list-group \",[24,0,[\"typeClass\"]]]]],[14,4],[9],[0,\"\\n\"],[4,\"if\",[[27,1]],null,{\"statements\":[[0,\"      \"],[15,1,[[29,\"hash\",null,[[\"item\"],[[29,\"component\",[\"sc-list-group/item\"],[[\"size\",\"hoverable\"],[[24,3,[]],[24,2,[]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"sc-list-group/item\",null,[[\"size\",\"hoverable\"],[[24,3,[]],[24,2,[]]]],{\"statements\":[[0,\"        \"],[15,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-list-group.hbs"
    }
  });

  _exports.default = _default;
});