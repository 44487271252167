define("@smith-carson/ui/templates/components/sc-app-sidebar/toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tLdns1rs",
    "block": "{\"symbols\":[\"@collapsed\",\"&default\"],\"statements\":[[4,\"if\",[[26,2]],null,{\"statements\":[[0,\"  \"],[15,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[29,\"fa-icon\",null,[[\"icon\"],[[29,\"if\",[[24,1,[]],\"caret-right\",\"caret-left\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-app-sidebar/toggle.hbs"
    }
  });

  _exports.default = _default;
});