define("js-common/components/timeline-display", ["exports", "js-common/mixins/crud/error", "js-common/mixins/timeline-display-mixin", "js-common/templates/components/timeline-display", "moment", "ember-cli-string-helpers/utils/titleize"], function (_exports, _error, _timelineDisplayMixin, _timelineDisplay, _moment, _titleize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_error.default, _timelineDisplayMixin.default, {
    layout: _timelineDisplay.default,
    tagName: 'div',
    classNames: ['timeline-item'],
    classNameBindings: ['event.viewable::timeline-item--hidden', 'isActive:timeline-item--active', 'componentId'],
    isEditable: false,
    componentId: Ember.computed('event.id', function () {
      return 'timeline-item-id-' + this.get('event.id');
    }),
    isActive: Ember.computed('event.id', 'selectedEvent.id', function () {
      var eid = this.get('event.id');
      var sid = this.get('selectedEvent.id');
      return eid === sid;
    }),
    monthNameStart: Ember.computed('event.startMonth', function () {
      var month = this.get('event.startMonth');

      if (month) {
        return (0, _moment.default)('2000-' + month + '-1').format('MMM');
      }

      return null;
    }),
    monthNameEnd: Ember.computed('event.endMonth', function () {
      var month = this.get('event.endMonth');

      if (month) {
        return (0, _moment.default)('2000-' + month + '-1').format('MMM');
      }

      return null;
    }),
    showButtons: Ember.computed('isEditable', 'admin', function () {
      return this.isEditable && this.admin;
    }),
    iconName: Ember.computed('event', 'event.timelineEventType', 'event.timelineEventType.name', function () {
      return this.get('event.timelineEventType.iconFile');
    }),
    title: Ember.computed('event.timelineEventType.timelineEventParent', function () {
      var cat = this.get('event.timelineEventType.timelineEventParent.name');
      var eventType = this.get('event.timelineEventType.name');

      switch (cat) {
        case 'Education':
          switch (eventType) {
            case 'Attended':
              return 'Education';

            case 'Degree (unspecified)':
              return 'Education';

            case 'PhD degree':
              return 'PhD Degree';

            default:
              return (0, _titleize.default)(eventType);
          }

        case 'Employment':
          switch (eventType) {
            case 'Employed':
              return 'Employment';

            case 'Professional license (disciplinary action)':
              return 'Professional License (Disciplinary Action)';

            default:
              return (0, _titleize.default)(eventType);
          }

        case 'Bankruptcy':
          return 'Bankruptcy';

        case 'Criminal':
          switch (eventType) {
            case 'Criminal (traffic-related)':
              return 'Criminal (Traffic-Related)';

            case 'Unknown':
              return 'Criminal';

            case 'Drug-related':
              return 'Criminal (Drug-Related)';

            case 'DUI':
              return 'Criminal (DUI)';

            default:
              return 'Criminal (' + (0, _titleize.default)(eventType) + ')';
          }

        case 'Property':
          return eventType + ' Property';

        default:
          return (0, _titleize.default)(eventType);
      }
    }),
    visibilityIcon: Ember.computed('event', 'event.viewable', function () {
      return this.get('event.viewable') === 0 ? 'lock' : 'unlock';
    }),
    visibilityIconColor: Ember.computed('event', 'event.viewable', function () {
      switch (this.get('event.viewable')) {
        case 0:
          return 'steel';

        case 1:
          return 'success';

        case 2:
          return 'plum';
      }
    }),
    visibilityIconTitle: Ember.computed('event', 'event.viewable', function () {
      switch (this.get('event.viewable')) {
        case 0:
          return 'Private';

        case 1:
          return 'Public';

        case 2:
          return 'Pending';
      }
    }),
    mouseLeave: function mouseLeave() {
      this.sendAction('select', this.event);
    },
    mouseEnter: function mouseEnter() {
      this.sendAction('select', this.event);
    }
  });

  _exports.default = _default;
});