define("ember-pdf-js/templates/components/pdf-js-toolbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "19HIvx7l",
    "block": "{\"symbols\":[\"@changePage\"],\"statements\":[[7,\"button\"],[12,\"disabled\",[29,\"gte\",[[25,[\"page\"]],[25,[\"pageTotal\"]]],null]],[11,\"type\",\"button\"],[9],[0,\"\\n  Next page !\\n\"],[3,\"action\",[[24,0,[]],[29,\"action\",[[24,0,[]],[24,1,[]]],null],\"next\"]],[10],[0,\"\\n\"],[7,\"button\"],[12,\"disabled\",[29,\"lte\",[[25,[\"page\"]],1],null]],[11,\"type\",\"button\"],[9],[0,\"\\n  Prev page !\\n\"],[3,\"action\",[[24,0,[]],[29,\"action\",[[24,0,[]],[24,1,[]]],null],\"prev\"]],[10],[0,\"\\n\"],[1,[29,\"one-way-input\",[[25,[\"searchTerms\"]]],[[\"update\"],[[29,\"action\",[[24,0,[]],\"updateSearchTerm\"],null]]]],false],[0,\"\\n\"],[7,\"br\"],[9],[10],[0,\"\\n\"],[7,\"label\"],[9],[0,\"\\n  \"],[1,[29,\"one-way-checkbox\",[[25,[\"caseSensitive\"]]],[[\"update\"],[[29,\"action\",[[24,0,[]],\"updateCaseSensitive\"],null]]]],false],[0,\"\\n  case sensitive\\n\"],[10],[0,\"\\n\"],[7,\"label\"],[9],[0,\"\\n  \"],[1,[29,\"one-way-checkbox\",[[25,[\"phraseSearch\"]]],[[\"update\"],[[29,\"action\",[[24,0,[]],\"updatePhraseSearch\"],null]]]],false],[0,\"\\n  match phrase\\n\"],[10],[0,\"\\n\"],[7,\"label\"],[9],[0,\"\\n  \"],[1,[29,\"one-way-checkbox\",[[25,[\"highlightAll\"]]],[[\"update\"],[[29,\"action\",[[24,0,[]],\"updateHighlightAll\"],null]]]],false],[0,\"\\n  highlightAll\\n\"],[10],[0,\"\\n\"],[7,\"label\"],[9],[0,\"page \"],[1,[23,\"page\"],false],[0,\" of \"],[1,[23,\"pageTotal\"],false],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-pdf-js/templates/components/pdf-js-toolbar.hbs"
    }
  });

  _exports.default = _default;
});