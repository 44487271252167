define("js-common/components/user-avatar", ["exports", "js-common/templates/components/user-avatar", "js-common/utils/get-app-url"], function (_exports, _userAvatar, _getAppUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    compAdditionClass: '',
    classNameBindings: ['compAdditionClass'],
    ENV: Ember.computed(function () {
      var applicationConfig = Ember.getOwner(this).resolveRegistration('config:environment');
      return applicationConfig;
    }),
    layout: _userAvatar.default,

    /**
     * @property
     *
     * set the files.id number to show the avatar
     */
    fileId: null,

    /**
     * @computed
     *
     * check if fileId exists
     */
    hasAvatar: Ember.computed.notEmpty('fileId'),

    /**
     * size of the avatar
     */
    width: 100,
    height: 100,

    /**
     * if we want the avatar rounded
     */
    circle: false,

    /**
     * @computed
     *
     * return the url needed to preview the avatar
     */
    avatarUrl: Ember.computed('fileId', function () {
      var ENV = this.ENV;
      return (0, _getAppUrl.default)(ENV, '/files/' + this.fileId + '/preview/1?token=' + this.get('session.data.authenticated.token'));
    }),
    classStr: Ember.computed('circle', 'pullLeft', 'pullRight', function () {
      var circle = this.circle;
      var pullLeft = this.pullLeft;
      var pullRight = this.pullRight;
      var classNameArr = [];

      if (circle) {
        classNameArr.push('img-circle');
      }

      if (pullLeft) {
        classNameArr.push('pull-left');
      }

      if (pullRight) {
        classNameArr.push('pull-right');
      }

      classNameArr.push(this.additionalClass);
      return classNameArr.join(' ');
    })
  });

  _exports.default = _default;
});