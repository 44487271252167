define("ember-popper/templates/components/ember-popper-targeting-parent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VKFrbfI2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[29,\"unbound\",[[25,[\"_parentFinder\"]]],null],false],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"renderInPlace\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"id\",[23,\"id\"]],[12,\"class\",[23,\"class\"]],[12,\"hidden\",[23,\"hidden\"]],[12,\"role\",[23,\"ariaRole\"]],[9],[0,\"\\n    \"],[15,1,[[29,\"hash\",null,[[\"disableEventListeners\",\"enableEventListeners\",\"scheduleUpdate\",\"update\"],[[29,\"action\",[[24,0,[]],\"disableEventListeners\"],null],[29,\"action\",[[24,0,[]],\"enableEventListeners\"],null],[29,\"action\",[[24,0,[]],\"scheduleUpdate\"],null],[29,\"action\",[[24,0,[]],\"update\"],null]]]]]],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"in-element\",[[25,[\"_popperContainer\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"id\",[23,\"id\"]],[12,\"class\",[23,\"class\"]],[12,\"hidden\",[23,\"hidden\"]],[12,\"role\",[23,\"ariaRole\"]],[9],[0,\"\\n    \"],[15,1,[[29,\"hash\",null,[[\"disableEventListeners\",\"enableEventListeners\",\"scheduleUpdate\",\"update\"],[[29,\"action\",[[24,0,[]],\"disableEventListeners\"],null],[29,\"action\",[[24,0,[]],\"enableEventListeners\"],null],[29,\"action\",[[24,0,[]],\"scheduleUpdate\"],null],[29,\"action\",[[24,0,[]],\"update\"],null]]]]]],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-popper/templates/components/ember-popper-targeting-parent.hbs"
    }
  });

  _exports.default = _default;
});