define("@smith-carson/ui/pods/components/case-fields/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/yUH2d3G",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"data-test-component\",\"case-fields\"],[9],[0,\"\\n    \"],[1,[29,\"sc-light-table-2\",null,[[\"canExpand\",\"canReorder\",\"canSelect\",\"columns\",\"multiSelect\",\"onRowClick\",\"resizeOnDrag\",\"rows\",\"tableActions\",\"tableClassNames\"],[false,true,true,[24,0,[\"columns\"]],true,[29,\"action\",[[24,0,[]],\"editField\"],null],true,[24,0,[\"fields\"]],[29,\"hash\",null,[[\"onRowMove\"],[[29,\"action\",[[24,0,[]],\"moveRow\"],null]]]],\"table table-truncated\"]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,0,[\"showEditFieldModal\"]]],null,{\"statements\":[[0,\"        \"],[1,[29,\"modals/case-field\",null,[[\"caseField\",\"showEditFieldModal\"],[[24,0,[\"selectedCaseField\"]],[24,0,[\"showEditFieldModal\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/pods/components/case-fields/template.hbs"
    }
  });

  _exports.default = _default;
});