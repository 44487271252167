define("@smith-carson/ui/pods/components/light-table/cells/icon/component", ["exports", "@babel/runtime/helpers/esm/typeof", "ember-light-table/components/cells/base", "@smith-carson/ui/pods/components/light-table/cells/icon/template"], function (_exports, _typeof2, _base, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides a custom `LightTable` cell type for rendering an icon that
      represents the column's data point.
  
      Usage:
      ```
          {
              cellType: 'icon',
              valuePath: 'type',
  
              // provide a map of potential values to icon names
              extra: {
                  iconsMap: {
                      value1: 'icon_one_name`,
                      value2: 'icon_two_name`,
                      value3: 'icon_three_name`,
                      value4: 'icon_four_name`,
                  },
              },
          }
      ```
  
      @module LightTable::Cells::IconComponent
      @extends EmberLightTable.CellsBaseComponent
   */
  var _default = _base.default.extend({
    classNames: ['icon-cell'],
    layout: _template.default,
    init: function init() {
      this._super.apply(this, arguments);

      var iconsMap = this.get('column.extra.iconsMap');
      (false && !((0, _typeof2.default)(iconsMap) === 'object') && Ember.assert('You must provide a `iconsMap` (`Object`) to the `LightTableCellsIconComponent`', (0, _typeof2.default)(iconsMap) === 'object'));
    },

    /**
        Provides text for accessibility purposes as icons are hidden from screen
        readers. Currently just returns the row's column value, but could be
        extended.
         @property altText
        @type {String}
     */
    altText: Ember.computed(function () {
      var _this$getProperties = this.getProperties('column', 'row'),
          column = _this$getProperties.column,
          row = _this$getProperties.row;

      var valuePath = column.get('valuePath');
      return row.get("".concat(valuePath));
    }),

    /**
        Provides the desired icon name for the `ScIconComponent`'s `icon`
        argument.
         @property iconName
        @type {String}
     */
    iconName: Ember.computed(function () {
      var _this$getProperties2 = this.getProperties('column', 'row'),
          column = _this$getProperties2.column,
          row = _this$getProperties2.row;

      var _column$getProperties = column.getProperties('extra', 'valuePath'),
          extra = _column$getProperties.extra,
          valuePath = _column$getProperties.valuePath;

      var iconsMap = extra.iconsMap;
      var rowValue = row.get("".concat(valuePath));
      return iconsMap[rowValue];
    })
  });

  _exports.default = _default;
});