define("js-common/mixins/timeline-display-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    actions: {
      addCurrentDocumentToEvent: function addCurrentDocumentToEvent(event) {
        this.store.createRecord('resource_has_resource', {
          parentId: this.documentId,
          childId: event.get('id')
        }).save();
        this.attrs.refresh();
      },
      removeEvent: function removeEvent(event) {
        this.removeEvent(event);
      },
      editEvent: function editEvent(event) {
        this.editEvent(event);
      },
      previewDocument: function previewDocument(docId, subjectId) {
        window.open(this.apiUrl + '/preview/' + docId + '/by-subject/' + subjectId);
      }
    }
  });

  _exports.default = _default;
});