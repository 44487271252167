define("js-common/models/deal", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    modelName: 'deal',
    // before we were using constructor.modelName but is no longer reliable
    createdOn: attr('estdate'),
    updatedOn: attr('estdate'),
    stage: attr('string'),
    lowValue: attr('number'),
    highValue: attr('number'),
    notes: attr('string'),
    source: attr('string'),
    name: attr('string'),
    probability: attr('number'),
    priority: attr('number'),
    fallBack: attr('number'),
    // soon to be relationships
    // employeeId: attr('number'),
    // relationships
    dealHasClients: hasMany('deal-has-client', {
      async: false
    }),
    employee: belongsTo('employee', {
      async: true
    }),
    createdBy: belongsTo('user', {
      async: true
    }),
    updatedBy: belongsTo('user', {
      async: true
    }),
    employeeWritable: Ember.computed({
      get: function get(key) {
        return this.get('employee.content');
      },
      set: function set(key, value) {
        this.set('employee', value);
        return value;
      }
    })
  });

  _exports.default = _default;
});