define("@smith-carson/ui/utils/sc-table/column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * simple helper to store the columns that will be used to for display & search on a paginate list
   */
  var _default = Ember.Object.extend({
    // the friendly name
    displayName: null,
    // the model property
    fieldName: null,
    // list this field in the search component?
    enableSearch: true,
    // list this field in the main listing?
    enableDisplay: true,
    // order in which to display the fields
    order: 0,
    // show the quick filter or not
    showingFilter: false,
    // To make a column not searchable/sortable
    disableServerInteractions: false,
    // developer supplied value that maps to API field
    // this value is used in preference to fieldName when the client needs to interact with this api field
    apiName: null,
    // calculate to pull either the apiName or fieldName
    apiInteractionName: Ember.computed('fieldName', 'apiName', function () {
      if (Ember.isPresent(this.get('apiName'))) {
        return this.get('apiName');
      } else if (Ember.isPresent(this.get('fieldName'))) {
        return Ember.String.underscore(this.get('fieldName'));
      }
    }),
    width: undefined,
    align: 'left',
    // usesPowerSelect: false,
    displayPath: null,
    valuePath: null,
    // value on the filter for this column
    filterValue: undefined,
    filterValue2: undefined,
    // filter-operator name
    filterOperator: 'contains',
    // supported filter operators for this column
    enabledFilterOperators: ['contains', 'not_contains', 'equal', 'not_equal', 'blank', 'not_blank', 'between', 'between_dates', 'csv']
  });

  _exports.default = _default;
});