define("js-common/templates/components/document-download-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W0GOZ1XZ",
    "block": "{\"symbols\":[\"@tooltip\",\"@label\",\"@icon\",\"@suggestedFilename\",\"&attrs\"],\"statements\":[[8,\"a\"],[13,\"href\",[24,0,[\"downloadURI\"]]],[13,\"class\",\"btn btn-link text-left\"],[13,\"target\",\"_blank\"],[13,\"title\",[29,\"unless\",[[24,1,[]],\"Download document\"],null]],[13,\"download\",[24,4,[]]],[14,5],[9],[0,\"\\n\"],[4,\"if\",[[24,3,[]]],null,{\"statements\":[[0,\"    \"],[5,\"sc-icon\",[[13,\"class\",\"mr-2\"]],[[\"@icon\",\"@iconSet\"],[[24,3,[]],\"fa\"]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,2,[]]],null,{\"statements\":[[0,\"    \"],[1,[24,2,[]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,1,[]]],null,{\"statements\":[[0,\"    \"],[5,\"sc-tooltip\",[],[[\"@placement\"],[\"top\"]],{\"statements\":[[0,\"\\n      \"],[1,[24,1,[]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[3,\"action\",[[24,0,[]],\"click\"],[[\"preventDefault\"],[false]]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/document-download-link.hbs"
    }
  });

  _exports.default = _default;
});