define("ember-light-table/templates/components/lt-scrollable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MibBhRXN",
    "block": "{\"symbols\":[\"scrollbar\",\"&default\"],\"statements\":[[4,\"if\",[[25,[\"virtualScrollbar\"]]],null,{\"statements\":[[4,\"ember-scrollable\",null,[[\"classNames\",\"autoHide\",\"horizontal\",\"vertical\",\"scrollToY\",\"onScrollY\"],[\"lt-scrollable\",[25,[\"autoHide\"]],[25,[\"horizontal\"]],[25,[\"vertical\"]],[25,[\"scrollTo\"]],[25,[\"onScrollY\"]]]],{\"statements\":[[0,\"    \"],[15,2],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[15,2],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/lt-scrollable.hbs"
    }
  });

  _exports.default = _default;
});