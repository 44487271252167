define("js-common/models/group", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    name: attr('string'),
    description: attr('string'),
    groupType: attr('string'),
    apiName: attr('string'),
    permissions: hasMany('permission', {
      async: true
    }),
    groupHasPermissions: hasMany('group-has-permission', {
      async: true
    }),

    /**
     * @computed
     *
     * used to differentiate the groups with a class on the badge
     */
    labelColor: Ember.computed('apiName', function () {
      switch (this.apiName) {
        case 'Portal - Basic Access':
          return 'label-default';

        case 'Portal - Contact':
          return 'label-success';

        case 'Portal - Dormant':
          return 'label-info';

        default:
          return 'label-warning';
      }
    }),
    isByTeam: Ember.computed('id', function () {
      return this.id === 'by-team';
    })
  });

  _exports.default = _default;
});