define("ember-bootstrap/templates/components/bs-progress/bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0v4DaKeE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"showLabel\"]]],null,{\"statements\":[[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"    \"],[15,1,[[25,[\"percentRounded\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[23,\"percentRounded\"],false],[0,\"%\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"sr-only\"],[9],[15,1,[[25,[\"percentRounded\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"sr-only\"],[9],[1,[23,\"percentRounded\"],false],[0,\"%\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-progress/bar.hbs"
    }
  });

  _exports.default = _default;
});