define("js-common/models/matter-field", ["exports", "js-common/mixins/matter-field-bitmasks", "ember-data"], function (_exports, _matterFieldBitmasks, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend(_matterFieldBitmasks.default, {
    lists: Ember.inject.service(),
    display: attr('string'),
    value: attr('string'),
    weight: attr('number'),
    locked: attr('number'),
    viewable: attr('number'),
    iconFile: attr('string'),
    section: attr('number'),
    mergeField: attr('string'),
    fieldClass: attr('string'),
    fieldType: attr('string'),
    // From the appl_list: matter_field_input_types
    listName: attr('string'),
    // One group_name from the appl_list
    columnWidth: attr('number'),
    parsable: attr('number'),
    isPortalFilter: attr('number'),
    showAboveOverview: attr('number'),
    // not part of the db
    enabled: attr('number'),
    // this is used in cases like social_media field that is readonly depending on a flag on the matter
    readonly: attr('boolean', {
      defaultValue: false
    }),
    matter: belongsTo('matter', {
      async: true
    }),
    isVital: Ember.computed('section', function () {
      return this.get('section') === 1;
    }),
    isOverview: Ember.computed('section', function () {
      return this.get('section') === 2;
    }),

    /**
     * create a camelized version of value
     * used in conjunction with dynamic-field components
     */
    name: Ember.computed('value', function () {
      return this.value.camelize();
    }),

    /** this property just reads the fieldType from the aPI */
    type: Ember.computed.reads('fieldType'),

    /** this property gets the list from the lists service with the right listName */
    options: Ember.computed('fieldType', function () {
      if (this.fieldType === 'enum' || this.fieldType === 'radio' || this.fieldType === 'checkboxes') {
        return this.lists.getListForGroup(this.listName);
      }

      return [];
    }),
    isLabelEditable: Ember.computed('value', 'isEnabledCase', function () {
      return this.value.indexOf('custom') >= 0 && this.isEnabledCase;
    }),
    isEditable: Ember.computed.not('readonly'),
    isNoteField: Ember.computed('fieldClass', function () {
      return this.fieldClass === 'notes';
    }),

    /**
     * Mare sure some fields cannot be customized.
     * Examples:
     * first & last name must always be text input field
     * dob & dod must always be calendar dates
     * foreperson & monitor must always be booleans
     */
    isUncustomizable: Ember.computed('value', function () {
      return ['first_name', 'last_name', 'dob', 'dod', 'monitor', 'foreperson'].indexOf(this.value) > -1;
    }),
    showLabel: attr('boolean', {
      defaultValue: false
    })
  });

  _exports.default = _default;
});