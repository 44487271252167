define("js-common/models/subject-workflow-v2", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    matterId: attr('number'),
    subjectId: attr('number'),
    workflowId: attr('number'),
    matterWorkflowId: attr('number'),
    matterCode: attr('string'),
    matterName: attr('string'),
    subjectName: attr('string'),
    name: attr('string'),
    assignedToId: attr('number'),
    assignedTo: attr('string'),
    assignedToFullName: attr('string'),
    assignedOn: attr('estdate'),
    completedOn: attr('estdate'),
    status: attr('string'),
    comment: attr('string'),
    hours: attr('number'),
    deadline: attr('string'),
    enabled: attr('number'),
    updatedOn: attr('estdate'),
    createdOn: attr('estdate'),
    createdBy: belongsTo('user', {
      async: true
    }),
    showStatus: Ember.computed('enabled', function () {
      // eslint-disable-next-line no-bitwise
      return !!(this.enabled & 1);
    }),
    showAssigned: Ember.computed('enabled', function () {
      // eslint-disable-next-line no-bitwise
      return !!(this.enabled & 2);
    }),
    showComment: Ember.computed('enabled', function () {
      // eslint-disable-next-line no-bitwise
      return !!(this.enabled & 4);
    }),
    showHours: Ember.computed('enabled', function () {
      // eslint-disable-next-line no-bitwise
      return !!(this.enabled & 8);
    }),
    showDeadline: Ember.computed('enabled', function () {
      // eslint-disable-next-line no-bitwise
      return !!(this.enabled & 16);
    })
  });

  _exports.default = _default;
});