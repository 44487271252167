define("js-common/templates/components/subject-icons/report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zc4rOc00",
    "block": "{\"symbols\":[\"@subject\"],\"statements\":[[4,\"if\",[[24,1,[\"hasBeenViewedOn\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[9],[0,\"\\n    \"],[5,\"sc-icon\",[],[[\"@iconSet\",\"@icon\",\"@color\"],[\"fa\",\"file-text\",\"honey\"]]],[0,\"\\n    \"],[5,\"sc-popover\",[],[[\"@title\",\"@triggerEvents\",\"@renderInPlace\",\"@placement\"],[\"Report Downloaded\",\"hover\",false,\"bottom\"]],{\"statements\":[[0,\"\\n      \"],[1,[29,\"moment-format\",[[24,1,[\"hasBeenViewedOn\"]],\"MM/DD/YYYY\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/subject-icons/report.hbs"
    }
  });

  _exports.default = _default;
});