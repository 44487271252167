define("@smith-carson/ui/templates/components/sc-table/filter-operators/date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iutew8b4",
    "block": "{\"symbols\":[\"@filterValue\",\"@changeFilterValue\"],\"statements\":[[5,\"sc-date-picker\",[[13,\"class\",\"form-control\"]],[[\"@value\",\"@onSelection\",\"@format\"],[[29,\"get-date\",[[24,1,[]]],null],[29,\"action\",[[24,0,[]],[29,\"pipe\",[[29,\"action\",[[24,0,[]],[29,\"set-date\",[[24,0,[]],\"filterValue\"],null]],null],[29,\"action\",[[24,0,[]],[29,\"optional\",[[24,2,[]]],null]],null]],null]],null],\"MM/DD/YYYY\"]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-table/filter-operators/date.hbs"
    }
  });

  _exports.default = _default;
});