define("@smith-carson/ui/pods/components/light-table/cells/custom-format/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pWREnyeJ",
    "block": "{\"symbols\":[],\"statements\":[[1,[24,0,[\"value\"]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/pods/components/light-table/cells/custom-format/template.hbs"
    }
  });

  _exports.default = _default;
});