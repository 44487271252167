define("js-common/components/read-more", ["exports", "js-common/templates/components/read-more", "js-common/helpers/nl-2br"], function (_exports, _readMore, _nl2br) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _readMore.default,
    classNames: 'read-more',
    classNameBindings: ['isOpen:is-open:is-closed'],
    isOpen: false,
    maxLines: 2,
    // max number of lines to show
    textToShow: Ember.computed('text', 'isOpen', 'maxLines', function () {
      if (this.isOpen) {
        return (0, _nl2br.nl2br)([this.text]);
      } else {
        return (0, _nl2br.nl2br)([this.text.split('\n').slice(0, this.maxLines).join('\n')]);
      }
    }),
    hasMore: Ember.computed('text', 'maxLines', function () {
      return this.text.split('\n').length > this.maxLines;
    }),
    openText: 'Read more',
    closeText: 'Close',
    toggleText: Ember.computed('openText', 'closeText', 'isOpen', function () {
      return this.isOpen ? this.closeText : this.openText;
    }),
    actions: {
      toggle: function toggle() {
        this.toggleProperty('isOpen');

        if (this.isOpen) {
          this.sendAction('onOpen');
        } else {
          this.sendAction('onClose');
        }
      }
    }
  });

  _exports.default = _default;
});