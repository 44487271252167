define("js-common/templates/components/read-more", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L0xUZE3t",
    "block": "{\"symbols\":[],\"statements\":[[1,[23,\"textToShow\"],false],[0,\"\\n\"],[7,\"br\"],[12,\"class\",[30,[[29,\"unless\",[[25,[\"hasMore\"]],\"hidden\"],null]]]],[9],[10],[0,\"\\n\"],[7,\"a\"],[11,\"href\",\"#\"],[12,\"class\",[30,[[29,\"unless\",[[25,[\"hasMore\"]],\"hidden\"],null]]]],[9],[0,\"\\n  \"],[1,[23,\"toggleText\"],false],[0,\"\\n\"],[3,\"action\",[[24,0,[]],\"toggle\"]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/read-more.hbs"
    }
  });

  _exports.default = _default;
});