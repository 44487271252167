define("js-common/helpers/nl-2br", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.nl2br = nl2br;
  _exports.default = void 0;

  /**
   * convert line breaks to BR tags
   *
   * @param params
   * expects an array with a single element as string
   * @returns {*}
   */
  function nl2br(params
  /*, hash */
  ) {
    if (_jquery.default.isArray(params)) {
      var breakTag = '<br />';
      var str = params[0];

      if (str) {
        return Ember.String.htmlSafe((str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2'));
      }

      return '';
    }
  }

  var _default = Ember.Helper.helper(nl2br);

  _exports.default = _default;
});