define("@smith-carson/ui/templates/components/sc-analytic-widget/simple-count", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zwu6XlG2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"value\"],[9],[0,\"\\n  \"],[1,[25,[\"chartData\",\"value\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"label\"],[11,\"class\",\"title\"],[9],[0,\"\\n  \"],[1,[25,[\"chartData\",\"title\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-analytic-widget/simple-count.hbs"
    }
  });

  _exports.default = _default;
});