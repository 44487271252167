define("ember-light-table/templates/components/columns/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uSuu6V9j",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"column\",\"component\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"component\",[[25,[\"column\",\"component\"]]],[[\"column\",\"table\",\"tableActions\",\"extra\",\"sortIcons\"],[[25,[\"column\"]],[25,[\"table\"]],[25,[\"tableActions\"]],[25,[\"extra\"]],[25,[\"sortIcons\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"and\",[[25,[\"sortIcons\",\"iconComponent\"]],[25,[\"sortIconProperty\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[29,\"component\",[[25,[\"sortIcons\",\"iconComponent\"]]],[[\"sortIcons\",\"sortIconProperty\"],[[25,[\"sortIcons\"]],[25,[\"sortIconProperty\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"sortIconProperty\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\"],[12,\"class\",[30,[\"lt-sort-icon \",[29,\"get\",[[25,[\"sortIcons\"]],[25,[\"sortIconProperty\"]]],null]]]],[9],[10],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[1,[23,\"label\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[25,[\"isResizable\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"lt-column-resizer\",null,[[\"column\",\"table\",\"resizeOnDrag\",\"isResizing\",\"onColumnResized\"],[[25,[\"column\"]],[25,[\"table\"]],[25,[\"resizeOnDrag\"]],[29,\"mut\",[[25,[\"isResizing\"]]],null],[29,\"action\",[[24,0,[]],[25,[\"onColumnResized\"]],[25,[\"column\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/columns/base.hbs"
    }
  });

  _exports.default = _default;
});