define("js-common/templates/components/edit-field/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wq+KXmM+",
    "block": "{\"symbols\":[\"result\",\"@renderInPlace\",\"@expandOptions\",\"@dropdownClass\"],\"statements\":[[5,\"sc-power-select\",[],[[\"@renderInPlace\",\"@dropdownClass\",\"@options\",\"@searchField\",\"@searchEnabled\",\"@selected\",\"@onchange\",\"@allowClear\"],[[24,2,[]],[29,\"concat\",[[24,4,[]],[29,\"if\",[[24,3,[]],\"options-expand-max\"],null]],null],[23,\"options\"],[23,\"searchField\"],[23,\"searchEnabled\"],[23,\"selectedOption\"],[23,\"updateSelectedOption\"],true]],{\"statements\":[[0,\"\\n  \"],[1,[29,\"get\",[[24,1,[]],[25,[\"optionDisplayField\"]]],null],false],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/edit-field/select.hbs"
    }
  });

  _exports.default = _default;
});