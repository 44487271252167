define("@smith-carson/ui/pods/components/light-table/cells/status/component", ["exports", "ember-light-table/components/cells/base", "@smith-carson/ui/pods/components/light-table/cells/status/template"], function (_exports, _base, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides a custom `LightTable` cell type for rendering status indicators
      (along with optional info tooltips) to an `ember-light-table` row.
  
      @module LightTable::Cells::StatusComponent
      @extends EmberLightTable.CellsBaseComponent
   */
  var _default = _base.default.extend({
    classNames: ['status-cell'],
    layout: _template.default,
    statusLabel: Ember.computed('column.valuePath', 'row', function () {
      return this.get("row.".concat(this.column.valuePath));
    }),

    /**
        Provides the status specific HTML class name for visual treatment.
         @property statusClassName
        @type {String}
     */
    statusClassName: Ember.computed('column.valuePath', 'row', function () {
      return Ember.String.dasherize((this.get("row.".concat(this.column.valuePath)) || '').toLowerCase());
    }),

    /**
        Provides the (optional) info text to be rendered as a tooltip in the
        status cell.
         @property infoContent
        @type {String}
     */
    infoContent: Ember.computed('column.extra.infoPath', function () {
      var infoMember = this.get('column.extra.infoPath');
      return this.get("row.".concat(infoMember));
    })
  });

  _exports.default = _default;
});