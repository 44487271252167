define("js-common/mixins/analytics-util", ["exports", "js-common/mixins/crud/error"], function (_exports, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_error.default, {
    ENV: Ember.computed(function () {
      return Ember.getOwner(this).resolveRegistration('config:environment');
    }),
    isPortal: Ember.computed('ENV', function () {
      var ENV = this.ENV;
      return ENV.modulePrefix === 'sc-portal' || ENV.modulePrefix === 'js2-client';
    }),
    store: Ember.inject.service(),
    analyticsFormsData: Ember.inject.service(),
    normalizeAndSetAnalytics: function normalizeAndSetAnalytics(subject, data) {
      if (Ember.typeOf(subject) !== 'instance') {
        return;
      }

      subject.set('subjectAnalyticsStates', data.statesOptions);
      subject.set('subjectAnalyticsCivil', data.civilData);
      subject.set('subjectAnalyticsCriminal', data.criminalOptions);
      subject.set('subjectAnalyticsCriminalCharges', data.criminalOptions);
      subject.set('subjectAnalyticsCriminalRights', data.criminalOptions);
      subject.set('subjectAnalyticsCriminalCases', data.criminalData);
      subject.set('subjectAnalyticsTraffic', data.trafficOptions);
      subject.set('subjectAnalyticsEmploymentStatus', data.employmentOptions);
      subject.set('subjectAnalyticsEmploymentAnalysisCategory', data.employmentTwoOptions);
      this.updateSubjectAnalyticsSocnet(subject, data.socnetOptions);
      subject.set('subjectAnalyticsPossibleConnectionsCategories', data.possibleConnectionsOptions);
      subject.set('subjectAnalyticsYearsMarried', data.maritalStatusOptions);
      subject.set('subjectAnalyticsHasChildren', data.childrenOptions);
      this.updateSubjectEmploymentPhaseTwo(subject, data.employmentTwoOptions);
      subject.set('subjectAnalyticsProductUse', data.productUseOptions);
      subject.set('subjectAnalyticsMediaOutlet', data.otherOptions);
      subject.set('subjectAnalyticsJurorMedicalIssues', data.productUseOptions);
      subject.set('subjectAnalyticsHigherEducation', data.higherEducationOptions);
      subject.set('subjectAnalyticsReligiousAffiliation', data.religionOptions);
      subject.set('subjectAnalyticsReligiousLevelOfInterest', data.religionOptions);
    },

    /**
     * @param subject
     * @param data
     * When selecting industry in Phase 1, the industry is also selected in Phase 2
     */
    updateSubjectEmploymentPhaseTwo: function updateSubjectEmploymentPhaseTwo(subject, data) {
      if (Ember.isPresent(subject.militaryVeteranCurrent) && subject.militaryVeteranCurrent === '1' && Ember.isEmpty(subject.militaryVeteran)) {
        subject.set('militaryVeteran', subject.militaryVeteranCurrent);
      }

      if (Ember.isPresent(subject.governmentSectorCurrent) && subject.governmentSectorCurrent === '1' && Ember.isEmpty(subject.governmentSector)) {
        subject.set('governmentSector', subject.governmentSectorCurrent);
      }

      subject.set('subjectAnalyticsEmploymentType', data);
      subject.set('subjectAnalyticsEmploymentRole', data);
      subject.set('subjectAnalyticsEmploymentOther', data);
    },
    updateSubjectAnalyticsSocnet: function updateSubjectAnalyticsSocnet(subject, data) {
      var profileCount = this.calculateProfileCount(subject.get('hyperlinks'));

      if (!profileCount) {
        // reset options
        data.socnetOtherOptions.forEach(function (st) {
          st.set('checked', null);
        });
        data.socnetThemesOptions.forEach(function (st) {
          st.set('checked', null);
        });
        data.socnetThemesHiddenOptions.forEach(function (st) {
          st.set('checked', null);
        });
      }

      subject.set('subjectAnalyticsSocnetOther', data);
      subject.set('subjectAnalyticsSocnetThemes', data);
      subject.set('subjectAnalyticsSocnetThemesHidden', data);
    },
    calculateProfileCount: function calculateProfileCount(hyperlinks) {
      var _this = this;

      if (hyperlinks && hyperlinks.get('length') > 0) {
        var filteredHyperlinks = hyperlinks.filter(function (h) {
          if (_this.isPortal) {
            return h.get('viewable') === 1;
          } else {
            return h.get('viewable') !== 0;
          }
        });
        var EXCLUDED_HYPERLINK_TYPES = ['Note', 'None', 'Media', 'Non-Subject'];
        filteredHyperlinks = filteredHyperlinks.reject(function (h) {
          return EXCLUDED_HYPERLINK_TYPES.includes(h.get('type'));
        });

        if (filteredHyperlinks && filteredHyperlinks.get('length') > 0) {
          return filteredHyperlinks.get('length');
        }
      }

      return null;
    },
    calculateSocNetTypes: function calculateSocNetTypes(hyperlinks) {
      var _this2 = this;

      if (hyperlinks && hyperlinks.get('length') > 0) {
        var filteredHyperlinks = hyperlinks.filter(function (h) {
          if (_this2.isPortal) {
            return h.get('viewable') === 1;
          } else {
            return h.get('viewable') !== 0;
          }
        });
        var EXCLUDED_HYPERLINK_TYPES = ['Note', 'None', 'Media', 'Non-Subject'];
        filteredHyperlinks = filteredHyperlinks.reject(function (h) {
          return EXCLUDED_HYPERLINK_TYPES.includes(h.get('type'));
        });

        if (filteredHyperlinks && filteredHyperlinks.get('length') > 0) {
          return filteredHyperlinks.mapBy('type').uniq().sort().join(', ');
        }
      }

      return null;
    },
    calculateAllLinkOfTypeNone: function calculateAllLinkOfTypeNone(hyperlinks) {
      if (!hyperlinks) {
        return [];
      }

      var EXCLUDED_HYPERLINK_TYPES = ['Note', 'Media', 'Non-Subject'];
      var filteredHyperlinks = this.isPortal ? hyperlinks.filterBy('viewable', 1) : hyperlinks.rejectBy('viewable', 0);
      filteredHyperlinks = filteredHyperlinks.reject(function (h) {
        return EXCLUDED_HYPERLINK_TYPES.includes(h.get('type'));
      });
      return filteredHyperlinks.get('length') && filteredHyperlinks.isEvery('type', 'None');
    }
  });

  _exports.default = _default;
});