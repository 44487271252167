define("js-common/models/hyperlink", ["exports", "ember-data", "ember-api-actions"], function (_exports, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  var number = attr('number');
  var estdate = attr('estdate');
  var string = attr('string');

  var _default = Model.extend({
    resourceId: number,
    display: string,
    hyperlink: string,
    type: string,
    activityLevel: string,
    weight: number,
    deactivateLink: number,
    privateFriendsList: number,
    requestScrape: number,
    automaticScrape: number,
    createdOn: estdate,
    updatedOn: estdate,
    viewable: number,
    doNotUse: number,
    isUrgent: number,
    isForensic: number,
    scrapeNotes: string,
    dueDate: string,
    writeUpdatedColumns: number,
    matter: belongsTo('matter', {
      async: true
    }),
    subject: belongsTo('subject', {
      async: true
    }),
    createdBy: belongsTo('user', {
      async: true
    }),
    updatedBy: belongsTo('user', {
      async: true
    }),
    metrics: hasMany('hyperlink-metric', {
      async: true
    }),
    posts: hasMany('posts', {
      async: true
    }),
    icon: Ember.computed('type', function () {
      switch (this.type) {
        case 'Other':
          return 'miscellaneous';

        case 'Note':
        case 'None':
        case 'Non-Subject':
          return 'social_media1';

        default:
          return this.type.toLowerCase();
      }
    }),
    isPrivate: Ember.computed('viewable', {
      get: function get() {
        return this.viewable === 0;
      },
      set: function set(key, value) {
        this.set('viewable', value ? 0 : 1);
        return value;
      }
    }),
    isFriendsListPrivate: Ember.computed('privateFriendsList', {
      get: function get() {
        return this.privateFriendsList === 1;
      },
      set: function set(key, value) {
        this.set('privateFriendsList', value ? 1 : 0);
        return value;
      }
    }),
    isLinkDeactivated: Ember.computed('deactivateLink', {
      get: function get() {
        return this.deactivateLink === 1;
      },
      set: function set(key, value) {
        this.set('deactivateLink', value ? 1 : 0);
        return value;
      }
    }),
    isAutomatic: Ember.computed('automaticScrape', {
      get: function get() {
        return this.automaticScrape === 1;
      },
      set: function set(key, value) {
        this.set('automaticScrape', value ? 1 : 0);
        return value;
      }
    }),
    typeHasFollowSystem: Ember.computed('type', function () {
      return ['X-Twitter', 'Instagram', 'Pinterest'].includes(this.type);
    }),
    numOfFriends: Ember.computed('metrics', {
      get: function get() {
        var correspondingMetrics = this.metrics.filterBy('key', 'FRIENDS');
        return correspondingMetrics.length ? correspondingMetrics[0].get('value') : undefined;
      },
      set: function set(key, value) {
        var correspondingMetrics = this.metrics.filterBy('key', 'FRIENDS');

        if (correspondingMetrics.length === 0) {
          var newCorrespondingMetric = this.store.createRecord('hyperlink-metric', {
            key: 'FRIENDS',
            weight: 1,
            value: value,
            resource: this
          });
          this.metrics.pushObject(newCorrespondingMetric);
        } else {
          var correspondingMetric = correspondingMetrics.get('firstObject');
          correspondingMetric.set('value', value);
        }

        return value;
      }
    }),
    numOfConnections: Ember.computed('metrics', {
      get: function get() {
        var correspondingMetrics = this.metrics.filterBy('key', 'CONNECTIONS');
        return correspondingMetrics.length ? correspondingMetrics[0].get('value') : undefined;
      },
      set: function set(key, value) {
        var correspondingMetrics = this.metrics.filterBy('key', 'CONNECTIONS');

        if (correspondingMetrics.length === 0) {
          var newCorrespondingMetric = this.store.createRecord('hyperlink-metric', {
            key: 'CONNECTIONS',
            weight: 1,
            value: value,
            resource: this
          });
          this.metrics.pushObject(newCorrespondingMetric);
        } else {
          var correspondingMetric = correspondingMetrics.get('firstObject');
          correspondingMetric.set('value', value);
        }

        return value;
      }
    }),
    numOfPins: Ember.computed('metrics', {
      get: function get() {
        var correspondingMetrics = this.metrics.filterBy('key', 'PINS');
        return correspondingMetrics.length ? correspondingMetrics[0].get('value') : undefined;
      },
      set: function set(key, value) {
        var correspondingMetrics = this.metrics.filterBy('key', 'PINS');

        if (correspondingMetrics.length === 0) {
          var newCorrespondingMetric = this.store.createRecord('hyperlink-metric', {
            key: 'PINS',
            weight: 1,
            value: value,
            resource: this
          });
          this.metrics.pushObject(newCorrespondingMetric);
        } else {
          var correspondingMetric = correspondingMetrics.get('firstObject');
          correspondingMetric.set('value', value);
        }

        return value;
      }
    }),
    numOfPosts: Ember.computed('metrics', {
      get: function get() {
        var correspondingMetrics = this.metrics.filterBy('key', 'POSTS');
        return correspondingMetrics.length ? correspondingMetrics[0].get('value') : undefined;
      },
      set: function set(key, value) {
        var correspondingMetrics = this.metrics.filterBy('key', 'POSTS');

        if (correspondingMetrics.length === 0) {
          var newCorrespondingMetric = this.store.createRecord('hyperlink-metric', {
            key: 'POSTS',
            weight: 1,
            value: value,
            resource: this
          });
          this.metrics.pushObject(newCorrespondingMetric);
        } else {
          var correspondingMetric = correspondingMetrics.get('firstObject');
          correspondingMetric.set('value', value);
        }

        return value;
      }
    }),
    numOfFollowers: Ember.computed('metrics', {
      get: function get() {
        var correspondingMetrics = this.metrics.filterBy('key', 'FOLLOWERS');
        return correspondingMetrics.length ? correspondingMetrics[0].get('value') : undefined;
      },
      set: function set(key, value) {
        var correspondingMetrics = this.metrics.filterBy('key', 'FOLLOWERS');

        if (correspondingMetrics.length === 0) {
          var weight = ['X-Twitter', 'TikTok'].includes(this.type) ? 3 : 2;
          var newCorrespondingMetric = this.store.createRecord('hyperlink-metric', {
            key: 'FOLLOWERS',
            weight: weight,
            value: value,
            resource: this
          });
          this.metrics.pushObject(newCorrespondingMetric);
        } else {
          var correspondingMetric = correspondingMetrics.get('firstObject');
          correspondingMetric.set('value', value);
        }

        return value;
      }
    }),
    numOfFollowing: Ember.computed('metrics', {
      get: function get() {
        var correspondingMetrics = this.metrics.filterBy('key', 'FOLLOWING');
        return correspondingMetrics.length ? correspondingMetrics[0].get('value') : undefined;
      },
      set: function set(key, value) {
        var correspondingMetrics = this.metrics.filterBy('key', 'FOLLOWING');

        if (correspondingMetrics.length === 0) {
          var weight = ['X-Twitter', 'TikTok'].includes(this.type) ? 2 : 3;
          var newCorrespondingMetric = this.store.createRecord('hyperlink-metric', {
            key: 'FOLLOWING',
            weight: weight,
            value: value,
            resource: this
          });
          this.metrics.pushObject(newCorrespondingMetric);
        } else {
          var correspondingMetric = correspondingMetrics.get('firstObject');
          correspondingMetric.set('value', value);
        }

        return value;
      }
    }),
    numOfLikes: Ember.computed('metrics', {
      get: function get() {
        var correspondingMetrics = this.metrics.filterBy('key', 'LIKES');
        return correspondingMetrics.length ? correspondingMetrics[0].get('value') : undefined;
      },
      set: function set(key, value) {
        var correspondingMetrics = this.metrics.filterBy('key', 'LIKES');

        if (correspondingMetrics.length === 0) {
          var newCorrespondingMetric = this.store.createRecord('hyperlink-metric', {
            key: 'LIKES',
            weight: 4,
            value: value,
            resource: this
          });
          this.metrics.pushObject(newCorrespondingMetric);
        } else {
          var correspondingMetric = correspondingMetrics.get('firstObject');
          correspondingMetric.set('value', value);
        }

        return value;
      }
    }),
    numOfSubscribers: Ember.computed('metrics', {
      get: function get() {
        var correspondingMetrics = this.metrics.filterBy('key', 'SUBSCRIBERS');
        return correspondingMetrics.length ? correspondingMetrics[0].get('value') : undefined;
      },
      set: function set(key, value) {
        var correspondingMetrics = this.metrics.filterBy('key', 'SUBSCRIBERS');

        if (correspondingMetrics.length === 0) {
          var newCorrespondingMetric = this.store.createRecord('hyperlink-metric', {
            key: 'SUBSCRIBERS',
            weight: 1,
            value: value,
            resource: this
          });
          this.metrics.pushObject(newCorrespondingMetric);
        } else {
          var correspondingMetric = correspondingMetrics.get('firstObject');
          correspondingMetric.set('value', value);
        }

        return value;
      }
    }),
    numOfViews: Ember.computed('metrics', {
      get: function get() {
        var correspondingMetrics = this.metrics.filterBy('key', 'VIEWS');
        return correspondingMetrics.length ? correspondingMetrics[0].get('value') : undefined;
      },
      set: function set(key, value) {
        var correspondingMetrics = this.metrics.filterBy('key', 'VIEWS');

        if (correspondingMetrics.length === 0) {
          var newCorrespondingMetric = this.store.createRecord('hyperlink-metric', {
            key: 'VIEWS',
            weight: 2,
            value: value,
            resource: this
          });
          this.metrics.pushObject(newCorrespondingMetric);
        } else {
          var correspondingMetric = correspondingMetrics.get('firstObject');
          correspondingMetric.set('value', value);
        }

        return value;
      }
    }),
    notes: Ember.computed('metrics', {
      get: function get() {
        var correspondingMetrics = this.metrics.filterBy('key', 'SM_NOTES');
        return correspondingMetrics.length ? correspondingMetrics[0].get('value') : undefined;
      },
      set: function set(key, value) {
        var correspondingMetrics = this.metrics.filterBy('key', 'SM_NOTES');

        if (correspondingMetrics.length === 0) {
          var newCorrespondingMetric = this.store.createRecord('hyperlink-metric', {
            key: 'SM_NOTES',
            weight: 5,
            value: value,
            resource: this
          });
          this.metrics.pushObject(newCorrespondingMetric);
        } else {
          var correspondingMetric = correspondingMetrics.get('firstObject');
          correspondingMetric.set('value', value);
        }

        return value;
      }
    }),
    printableMetrics: Ember.computed('metrics.[]', function () {
      return this.metrics.rejectBy('key', 'SM_NOTES').sortBy('weight');
    }),
    joinedMetrics: Ember.computed('printableMetrics.[]', 'privateFriendsList', 'type', function () {
      var metrics = this.printableMetrics.map(function (m) {
        return "".concat(m.get('key'), ": ").concat(m.get('value'));
      });

      if (this.privateFriendsList && this.type === 'Facebook') {
        metrics.pushObject('FRIENDS: Private');
      }

      return metrics.join(' | ');
    }),
    hyperlinkDecoded: Ember.computed('hyperlink', function () {
      if (typeof this.hyperlink !== 'string') {
        return this.hyperlink;
      }

      return decodeURIComponent(this.hyperlink.replace(/\+/g, ' '));
    }),
    requestScrapeType: Ember.computed('isForensic', 'requestScrape', {
      get: function get() {
        if (this.isForensic && this.requestScrape) {
          return 'forensic';
        }

        if (this.requestScrape) {
          return 'standard';
        }

        if (this.requestScrape === 0) {
          return 'no';
        }

        return 'none';
      },
      set: function set(key, value) {
        switch (value) {
          case 'forensic':
            this.set('requestScrape', 1);
            this.set('isForensic', 1);
            break;

          case 'standard':
            this.set('requestScrape', 1);
            this.set('isForensic', 0);
            break;

          case 'no':
            this.set('requestScrape', 0);
            this.set('isForensic', 0);
            this.set('isUrgent', 0);
            break;

          default:
        }

        return value;
      }
    }),
    requestScrapeUrgentType: Ember.computed('isUrgent', 'requestScrape', {
      get: function get() {
        if (!this.requestScrape) {
          return 'no';
        }

        if (this.isUrgent === 1) {
          return '1-day';
        }

        if (this.isUrgent === 2) {
          return '2-day';
        }

        return 'no';
      },
      set: function set(key, value) {
        switch (value) {
          case '1-day':
            this.set('isUrgent', 1);
            break;

          case '2-day':
            this.set('isUrgent', 2);
            break;

          case 'no':
            this.set('isUrgent', 0);
            break;

          default:
        }

        return value;
      }
    }),
    updateCaptureRequest: (0, _emberApiActions.memberAction)({
      path: 'update-scrape',
      type: 'put'
    })
  });

  _exports.default = _default;
});