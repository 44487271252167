define("ember-multiselect-checkboxes/templates/components/multiselect-checkboxes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3z2TA2S5",
    "block": "{\"symbols\":[\"checkbox\",\"index\",\"&default\"],\"statements\":[[4,\"each\",[[25,[\"checkboxes\"]]],null,{\"statements\":[[4,\"if\",[[26,3]],null,{\"statements\":[[0,\"    \"],[15,3,[[24,1,[\"option\"]],[24,1,[\"isSelected\"]],[24,2,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"li\"],[9],[0,\"\\n      \"],[7,\"label\"],[9],[0,\"\\n        \"],[1,[29,\"input\",null,[[\"type\",\"checked\",\"disabled\"],[\"checkbox\",[24,1,[\"isSelected\"]],[25,[\"disabled\"]]]]],false],[0,\"\\n        \"],[1,[24,1,[\"label\"]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-multiselect-checkboxes/templates/components/multiselect-checkboxes.hbs"
    }
  });

  _exports.default = _default;
});