define("js-common/models/matter-workflow-default", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    matterTypeId: attr('number'),
    workflowId: attr('number'),
    enabled: attr('number'),
    viewable: attr('number'),
    weight: attr('number'),
    isEnabled: Ember.computed('enabled', {
      get: function get(key) {
        return this.enabled === 1;
      },
      set: function set(key, value) {
        this.set('enabled', value ? 1 : 0);
        return value;
      }
    }),
    matterType: belongsTo('matter-type', {
      async: true
    }),
    workflow: belongsTo('workflow', {
      async: true
    }),

    /**
     * BEGIN BOOLEAN CALCS
     *
     * a series of booleans to describe if a workflow bitmask is enabled
     * for a particular case
     *
     * useful for template logic
     */
    assigned: Ember.computed('viewable', {
      get: function get(key) {
        return !!(this.viewable & 1);
      },
      set: function set(key, value) {
        var viewable = this.viewable;

        if (value) {
          viewable |= 1;
        } else {
          viewable &= ~1;
        }

        this.set('viewable', viewable);
        return value;
      }
    }),
    status: Ember.computed('viewable', {
      get: function get(key) {
        return !!(this.viewable & 2);
      },
      set: function set(key, value) {
        var viewable = this.viewable;

        if (value) {
          viewable |= 2;
        } else {
          viewable &= ~2;
        }

        this.set('viewable', viewable);
        return value;
      }
    }),
    comment: Ember.computed('viewable', {
      get: function get(key) {
        return !!(this.viewable & 4);
      },
      set: function set(key, value) {
        var viewable = this.viewable;

        if (value) {
          viewable |= 4;
        } else {
          viewable &= ~4;
        }

        this.set('viewable', viewable);
        return value;
      }
    })
    /**
     * END BOOLEAN CALCS
     */

  });

  _exports.default = _default;
});