define("ember-bind-helper/helpers/bind", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/toArray"], function (_exports, _toConsumableArray2, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bind = bind;
  _exports.default = void 0;

  function bind(_ref, _ref2) {
    var _ref3 = (0, _toArray2.default)(_ref),
        f = _ref3[0],
        params = _ref3.slice(1);

    var target = _ref2.target;

    if (!f || typeof f !== "function") {
      throw "bind needs to receive at least one argument, a function";
    }

    return f.bind.apply(f, [target].concat((0, _toConsumableArray2.default)(params)));
  }

  var _default = Ember.Helper.helper(bind);

  _exports.default = _default;
});