define("@smith-carson/ui/pods/components/light-table/cells/draggable-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yD6V9Tgq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"class\",\"btn btn-xs\"],[11,\"data-test-button\",\"drag-handle\"],[12,\"onkeydown\",[29,\"action\",[[24,0,[]],\"onKeyDown\"],null]],[12,\"onmousedown\",[29,\"action\",[[24,0,[]],\"onMouseDown\"],null]],[11,\"type\",\"button\"],[9],[0,\"\\n    \"],[1,[29,\"sc-icon\",null,[[\"icon\",\"iconSet\"],[\"grip-lines\",\"fa\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/pods/components/light-table/cells/draggable-row/template.hbs"
    }
  });

  _exports.default = _default;
});