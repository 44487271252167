define("js-common/mixins/same-route-transition-loading-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      loading: function loading(transition, originRoute) {
        var routeName = this.routeName;
        var controller = this.controllerFor(routeName); // Ember.Logger.debug('routeName -> ', routeName);
        // Ember.Logger.debug('controller -> ', controller);
        // Ember.Logger.debug('originRoute.routeName -> ', originRoute.routeName);
        // Ember.Logger.debug('transition.targetName -> ', transition.targetName);
        // check if the transition is to the same route but with a different "params" value.

        if (originRoute.routeName === transition.targetName || "".concat(originRoute.routeName, ".index") === transition.targetName) {
          // if so then do not bubble and set the showSpinner property on the controller
          controller.set('currentlyLoading', true);
          transition.promise.finally(function () {
            controller.set('currentlyLoading', false);
          }); // do not bubble is triggered by not returning true

          return false;
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});