define("ember-composable-helpers/helpers/object-at", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.objectAt = objectAt;
  _exports.default = void 0;

  function objectAt(index, array) {
    if (!Ember.isArray(array)) {
      return undefined;
    }

    index = parseInt(index, 10);
    return Ember.A(array).objectAt(index);
  }

  var _default = Ember.Helper.extend({
    content: Ember.computed('index', 'array.[]', function () {
      var index = Ember.get(this, 'index');
      var array = Ember.get(this, 'array');
      return objectAt(index, array);
    }),
    compute: function compute(_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
          index = _ref2[0],
          array = _ref2[1];

      Ember.set(this, 'index', index);
      Ember.set(this, 'array', array);
      return Ember.get(this, 'content');
    },
    contentDidChange: Ember.observer('content', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});