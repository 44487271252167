define('ember-light-table/components/lt-foot', ['exports', 'ember-light-table/templates/components/lt-foot', 'ember-light-table/mixins/table-header'], function (exports, _ltFoot, _tableHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_tableHeader.default, {
    layout: _ltFoot.default,
    classNames: ['lt-foot-wrap'],
    table: null,
    sharedOptions: null,
    sharedOptionsFixedKey: 'fixedFooter',
    columnComponentPath: 'light-table/columns/'
  });
});