define("@smith-carson/ui/templates/components/sc-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uY+1G57h",
    "block": "{\"symbols\":[\"Modal\",\"@content\",\"&default\",\"@header\",\"@size\",\"@open\",\"@onHide\",\"@cancelLabel\",\"@onCancel\",\"@confirmLabel\",\"@onConfirm\"],\"statements\":[[5,\"sc-modal\",[],[[\"@size\",\"@open\",\"@onHide\",\"@class\"],[[29,\"or\",[[24,5,[]],\"sm\"],null],[24,6,[]],[29,\"pipe\",[[29,\"action\",[[24,0,[]],[29,\"mut\",[[24,6,[]]],null],false],null],[29,\"action\",[[24,0,[]],[29,\"optional\",[[24,7,[]]],null]],null]],null],\"sc-confirm\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,4,[]]],null,{\"statements\":[[0,\"    \"],[6,[24,1,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[1,[24,4,[]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[6,[24,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[26,3]],null,{\"statements\":[[0,\"      \"],[15,3],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[24,2,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[24,1,[\"footer\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"sc-button\",[],[[\"@label\",\"@color\",\"@type\",\"@onClick\"],[[29,\"or\",[[24,8,[]],\"Cancel\"],null],\"secondary\",\"contents\",[24,9,[]]]]],[0,\"\\n\\n    \"],[5,\"sc-button\",[],[[\"@color\",\"@label\",\"@onClick\"],[\"primary\",[29,\"or\",[[24,10,[]],\"Confirm\"],null],[24,11,[]]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-confirm.hbs"
    }
  });

  _exports.default = _default;
});