define("js-common/helpers/status-label", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.statusLabel = statusLabel;
  _exports.default = void 0;

  /**
   * Replace billing statuses used in db (Preliminary, Lebel 1 Complete, etc) to more user friendly text
   *
   * @param params
   * expects an array with a single element as string
   * @returns {*}
   */
  function statusLabel(params
  /*, hash */
  ) {
    if (_jquery.default.isArray(params)) {
      var status = params[0];

      switch (status) {
        case 'Preliminary':
          return 'Timekeeper Approval Required';

        case 'Level 1 Complete':
          return 'Manager Approval Required';

        case 'Level 2 Complete':
          return 'Accounting Approval Required';

        default:
          return status;
      }
    }

    return '';
  }

  var _default = Ember.Helper.helper(statusLabel);

  _exports.default = _default;
});