define("js-common/helpers/dates-begin-and-end-month", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.datesBeginAndEndMonth = datesBeginAndEndMonth;
  _exports.default = void 0;

  function datesBeginAndEndMonth(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        date1 = _ref2[0],
        date2 = _ref2[1];

    var startingStartEarliestDate = new Date(date1 + ' 00:00:00');
    var startEarliestYear = startingStartEarliestDate.getFullYear();
    var startEarliestMonth = startingStartEarliestDate.getMonth() + 1;
    var startEarliestDay = startingStartEarliestDate.getDate();
    var startingStartLatestDate = new Date(date2 + ' 00:00:00');
    var startLatestYear = startingStartLatestDate.getFullYear();
    var startLatestMonth = startingStartLatestDate.getMonth() + 1;
    var startLatestDay = startingStartLatestDate.getDate();
    var lastDayOfStartLatestDateMonth = new Date(startLatestYear, startLatestMonth, 0).getDate();
    return startEarliestYear === startLatestYear && startEarliestMonth === startLatestMonth && startEarliestDay === 1 && startLatestDay === lastDayOfStartLatestDateMonth;
  }

  var _default = Ember.Helper.helper(datesBeginAndEndMonth);

  _exports.default = _default;
});