define("ember-math-helpers/helpers/abs", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.abs = abs;
  _exports.default = void 0;

  /**
   * Uses `Math.abs` to take the absolute value of the number passed to the helper.
   *
   * ```hbs
   * {{abs a}}
   * ```
   *
   * @param {number} number The number to take the absolute value of
   * @return {number} The absolute value of the passed number
   */
  function abs(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        number = _ref2[0];

    return Math.abs(number);
  }

  var _default = Ember.Helper.helper(abs);

  _exports.default = _default;
});