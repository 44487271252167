define("ember-bootstrap/templates/components/bs-modal/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mKQZpQcn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[27,1]],null,{\"statements\":[[0,\"  \"],[15,1,[[29,\"hash\",null,[[\"title\",\"close\"],[[29,\"component\",[[25,[\"titleComponent\"]]],null],[29,\"component\",[[25,[\"closeComponent\"]]],[[\"onClick\"],[[29,\"action\",[[24,0,[]],[25,[\"onClose\"]]],null]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"    \"],[15,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[4,\"component\",[[25,[\"titleComponent\"]]],null,{\"statements\":[[1,[23,\"title\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[25,[\"closeButton\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"component\",[[25,[\"closeComponent\"]]],[[\"onClick\"],[[29,\"action\",[[24,0,[]],[25,[\"onClose\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-modal/header.hbs"
    }
  });

  _exports.default = _default;
});