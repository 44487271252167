define("@smith-carson/ui/templates/components/sc-link-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kbqbiSEJ",
    "block": "{\"symbols\":[\"&attrs\",\"@label\",\"@icon\",\"@iconSet\",\"@size\",\"@buttonGroupType\",\"@groupValue\",\"@value\",\"@onClick\",\"@buttonType\",\"@buttonClass\",\"&default\"],\"statements\":[[4,\"if\",[[26,12]],null,{\"statements\":[[0,\"\\n    \"],[8,\"a\"],[14,1],[9],[0,\"\\n      \"],[5,\"sc-button\",[[13,\"class\",[24,11,[]]]],[[\"@label\",\"@icon\",\"@iconSet\",\"@size\",\"@buttonGroupType\",\"@groupValue\",\"@value\",\"@onClick\",\"@buttonType\"],[[24,2,[]],[24,3,[]],[24,4,[]],[24,5,[]],[24,6,[]],[24,7,[]],[24,8,[]],[29,\"optional\",[[24,9,[]]],null],[24,10,[]]]],{\"statements\":[[0,\"\\n        \"],[15,12],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n  \"],[8,\"a\"],[14,1],[9],[0,\"\\n    \"],[5,\"sc-button\",[[13,\"class\",[24,11,[]]]],[[\"@label\",\"@icon\",\"@iconSet\",\"@size\",\"@buttonGroupType\",\"@groupValue\",\"@value\",\"@onClick\",\"@buttonType\"],[[24,2,[]],[24,3,[]],[24,4,[]],[24,5,[]],[24,6,[]],[24,7,[]],[24,8,[]],[29,\"optional\",[[24,9,[]]],null],[24,10,[]]]]],[0,\"\\n  \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-link-button.hbs"
    }
  });

  _exports.default = _default;
});