define("@smith-carson/ui/utils/validate-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateColor;
  _exports.colors = void 0;

  /**
   * Validate that a specific color ("string") corresponds to our css-defined color maps
   */
  function validateColor(color) {
    return colors.includes(color);
  }

  var colors = ['ash', 'azure', 'background', 'black', 'brand', 'charcoal', 'danger', 'dark', 'default', 'fade', 'ghost', 'grass', 'honey', 'info', 'jade', 'jade-dark', 'jungle', 'light', 'magenta', 'pearl', 'plum', 'primary', 'raspberry', 'sapphire', 'secondary', 'smoke', 'smoke2', 'snow', 'steel', 'success', 'tiger', 'tiger-dark', 'transparent', 'warning', 'wheat', 'white'];
  _exports.colors = colors;
});