define("@smith-carson/ui/templates/components/sc-chart/doughnut-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c1uALeQt",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"ember-chart\",null,[[\"type\",\"data\",\"options\",\"width\",\"height\"],[\"doughnut\",[25,[\"doughnutData\"]],[25,[\"options\"]],[25,[\"width\"]],[25,[\"height\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-chart/doughnut-chart.hbs"
    }
  });

  _exports.default = _default;
});