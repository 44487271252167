define("js-common/helpers/permissions-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    permissions: Ember.inject.service(),
    compute: function compute(params) {
      var argc = params.length;
      (false && !(argc === 1) && Ember.assert('permissions-check helper needs 1 parameter', argc === 1));
      var requiredPermissions = params[0];
      (false && !(Ember.isArray(requiredPermissions) || typeof requiredPermissions === 'string') && Ember.assert('Parameter of permissions-check helper should be an array of required permission names', Ember.isArray(requiredPermissions) || typeof requiredPermissions === 'string'));

      if (typeof requiredPermissions === 'string') {
        requiredPermissions = [requiredPermissions];
      }

      return this.permissions.check(requiredPermissions);
    }
  });

  _exports.default = _default;
});