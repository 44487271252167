define("js-common/models/list-template", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    name: attr('string'),
    listTemplateGroup: belongsTo('list-template-group', {
      async: true
    }),
    taskTemplates: hasMany('task-template', {
      async: true
    }),
    listTemplateGroupWritable: Ember.computed('listTemplateGroup', {
      get: function get(key) {
        return this.get('listTemplateGroup.content');
      },
      set: function set(key, value) {
        this.set('listTemplateGroup', value);
        return value;
      }
    })
  });

  _exports.default = _default;
});