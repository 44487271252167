define("ember-bootstrap/templates/components/bs-button-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N0Yob1rZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[29,\"hash\",null,[[\"button\"],[[29,\"component\",[[25,[\"buttonComponent\"]]],[[\"buttonGroupType\",\"groupValue\",\"onClick\"],[[25,[\"type\"]],[25,[\"value\"]],[29,\"action\",[[24,0,[]],\"buttonPressed\"],null]]]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-button-group.hbs"
    }
  });

  _exports.default = _default;
});