define("js-common/helpers/remove-links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.removeLinks = removeLinks;
  _exports.default = void 0;

  function removeLinks(params) {
    if (!Ember.isPresent(params)) return null;

    if (!Ember.isPresent(params[0].string) || Ember.typeOf(params[0].string) !== 'string') {
      return params[0];
    }

    return Ember.String.htmlSafe(params[0].string.replace(/<\/?a[^>]*>/g, '').replace('<table>', '<table class="table table-bordered">'));
  }

  var _default = Ember.Helper.helper(removeLinks);

  _exports.default = _default;
});