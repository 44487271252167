define("js-common/components/sc-sidebar", ["exports", "js-common/templates/components/sc-sidebar"], function (_exports, _scSidebar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _scSidebar.default,
    tagName: '',
    sidebarExpanded: true,
    actions: {
      toggleSidebar: function toggleSidebar() {
        this.toggleProperty('sidebarExpanded');
      }
    }
  });

  _exports.default = _default;
});