define("js-common/models/employee-summary", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    assignedTo: belongsTo('user', {
      async: false
    }),
    billable: attr('number'),
    completed: attr('number'),
    avgTimePerWf: attr('number')
  });

  _exports.default = _default;
});