define("js-common/services/counts", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This service can be used to query an API endpoint with the count
   * parameter set, so we can get number of records without getting the full
   * response
   */
  var _default = Ember.Service.extend({
    /**
     * @property
     * @private
     *
     * ajax service to communicate with the servre
     */
    ajax: Ember.inject.service(),
    query: function query(modelName, options) {
      options['count'] = '';
      return this.ajax.request(Ember.String.underscore((0, _emberInflector.pluralize)(modelName)), {
        data: options
      }).then(function (response) {
        return response.meta.total_record_count;
      });
    }
  });

  _exports.default = _default;
});