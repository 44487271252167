define("@smith-carson/ui/pods/components/case-fields/component", ["exports", "@smith-carson/ui/pods/components/case-fields/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides affordance for listing and editing Case Fields.
  
      @module CaseFieldsComponent
      @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('columns', [{
        cellType: 'select-row',
        sortable: false,
        width: '2.5rem'
      }, {
        label: 'Label',
        resizable: true,
        sortable: false,
        valuePath: 'display'
      }, {
        align: 'center',
        cellType: 'icon',
        extra: {
          iconsMap: {
            0: 'check'
          }
        },
        label: 'Dashboard',
        resizable: true,
        sortable: false,
        valuePath: 'dashboardViewable',
        width: '9rem'
      }]);
    },
    actions: {
      /**
          Sets the current `selectedCaseField` and the `showEditFieldModal`
          boolean flag to support editing of the current field in the sidebar.
           @method editField
          @param {Row} row ember-light-table Row object
       */
      editField: function editField(row) {
        this.setProperties({
          selectedCaseField: row.content,
          showEditFieldModal: true
        });
      },

      /**
          Reorders the fields data collection based on user interaction in
          the `ember-light-table`.
           @method moveRow
          @param {Number} from Index of item to move
          @param {Number} to Index to move it to
          @param {Object} row Item that was moved
       */
      moveRow: function moveRow(from, to
      /* , row */
      ) {
        this.get('fields').move(from, to);
      }
    }
  });

  _exports.default = _default;
});