define("@smith-carson/ui/models/table-setting", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    state: attr(),
    appName: attr(),
    route: attr(),
    createdOn: attr(),
    isExpired: function isExpired() {
      return (0, _moment.default)(this.createdOn).isBefore((0, _moment.default)().subtract(8, 'hours'));
    }
  });

  _exports.default = _default;
});