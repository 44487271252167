define("ember-math-helpers/helpers/hypot", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hypot = hypot;
  _exports.default = void 0;

  /**
   * Takes two or more numbers, and returns the square root of the sum of squares of them using `Math.hypot`
   *
   * ```hbs
   * {{max a b}}
   * ```
   *
   * @param {number[]} numbers A list of numbers to pass to `Math.hypot`
   * @return {number} The hypot of the set of numbers
   */
  function hypot(numbers) {
    return Math.hypot.apply(Math, (0, _toConsumableArray2.default)(numbers));
  }

  var _default = Ember.Helper.helper(hypot);

  _exports.default = _default;
});