define("js-common/components/basic-modal", ["exports", "js-common/templates/components/basic-modal"], function (_exports, _basicModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // basic model expect a model to be supplied
  // handy for delete confirms and other unspecified uses
  var _default = Ember.Component.extend({
    layout: _basicModal.default,
    // set a default style for the modal, can be customized during implementation
    modalStyle: 'display: block;',
    autoCloseOnOk: false,
    useFixedHeight: true,
    showActionButtonsAtTop: false,
    closeText: 'Close',
    okText: 'Ok',
    hideOk: false,
    hideCancel: false,
    hasForm: false,
    actions: {
      ok: function ok() {
        if (this.hasForm) {
          var form = this.$('.modal-body form');

          if (form.length > 0) {
            // trigger submit event on body form
            form.trigger('submit');
          }
        } else {
          if (this.autoCloseOnOk) {
            this.$('.modal').modal('hide');
          }

          this.sendAction('ok', this.model);
        }
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.$('.modal').modal().on('hidden.bs.modal', function () {
        _this.sendAction('close');
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.$('.modal').modal('hide');
    },
    // place holder for supplied mode
    model: false
  });

  _exports.default = _default;
});