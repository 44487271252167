define("js-common/templates/components/analytic-widget/property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bzUjScAF",
    "block": "{\"symbols\":[\"@stats\",\"@canEdit\",\"@toggleViewable\"],\"statements\":[[5,\"sc-analytic-card\",[],[[\"@isEnabled\",\"@canEdit\",\"@onToggle\",\"@title\"],[[24,1,[\"property_comparison\",\"matterHasStat\",\"isEnabled\"]],[24,2,[]],[29,\"action\",[[24,0,[]],[24,3,[]],[24,1,[\"property_comparison\",\"matterHasStat\"]]],null],\"Property Ownership\"]],{\"statements\":[[0,\"\\n  \"],[5,\"sc-analytic-widget\",[],[[\"@chartType\",\"@chartData\"],[\"property\",[24,0,[\"propertyData\"]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/analytic-widget/property.hbs"
    }
  });

  _exports.default = _default;
});