define("@smith-carson/ui/templates/components/sc-app-sidebar/body/collapse-item/body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oh9aXGD8",
    "block": "{\"symbols\":[\"&default\",\"@collapsed\"],\"statements\":[[4,\"sc-collapse\",null,[[\"collapsed\"],[[24,2,[]]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"sc-app-sidebar__body__collapse-item__content-container\"],[9],[0,\"\\n    \"],[15,1],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-app-sidebar/body/collapse-item/body.hbs"
    }
  });

  _exports.default = _default;
});