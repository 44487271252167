define("@smith-carson/ui/pods/components/case-group-workflows/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CXVO5+fY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"case-group-workflows\"],[11,\"data-test-component\",\"case-group-workflows\"],[9],[0,\"\\n    \"],[1,[29,\"sc-light-table\",null,[[\"canExpand\",\"canReorder\",\"canSelect\",\"columns\",\"onRowClick\",\"resizeOnDrag\",\"rows\",\"tableClassNames\",\"tableActions\"],[false,true,true,[24,0,[\"columns\"]],[29,\"action\",[[24,0,[]],\"editCaseGroupWorkflow\"],null],true,[24,0,[\"caseGroupWorkflows\"]],\"table table-truncated\",[29,\"hash\",null,[[\"onRowMove\"],[[29,\"action\",[[24,0,[]],\"moveRow\"],null]]]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,0,[\"showCaseGroupWorkflowsModal\"]]],null,{\"statements\":[[0,\"        \"],[1,[29,\"modals/case-group-workflow\",null,[[\"caseGroupWorkflow\",\"showCaseGroupWorkflowsModal\"],[[24,0,[\"selectedCaseGroupWorkflow\"]],[24,0,[\"showCaseGroupWorkflowsModal\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/pods/components/case-group-workflows/template.hbs"
    }
  });

  _exports.default = _default;
});