define("js-common/models/email-log", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    result: attr('string'),
    senderName: attr('string'),
    recipients: attr('string'),
    subject: attr('string'),
    content: attr('string'),
    createdOn: attr('estdate'),
    type: attr('string'),
    code: attr('string', {
      defaultValue: 'Other'
    }),
    format: attr('string', {
      defaultValue: 'Plain Text'
    }),
    // relationships
    createdBy: belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});