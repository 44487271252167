define("js-common/templates/components/edit-field/textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lXm30cn/",
    "block": "{\"symbols\":[],\"statements\":[[5,\"sc-textarea\",[],[[\"@value\",\"@class\",\"@rows\",\"@disabled\",\"@onChange\"],[[29,\"readonly\",[[25,[\"value\"]]],null],\"form-control\",\"2\",[23,\"disabled\"],[23,\"triggerValueChanged\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/edit-field/textarea.hbs"
    }
  });

  _exports.default = _default;
});