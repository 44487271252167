define("js-common/helpers/should-show-end-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.shouldShowEndDate = shouldShowEndDate;
  _exports.default = void 0;

  function shouldShowEndDate(params) {
    return false;
  }

  var _default = Ember.Helper.helper(shouldShowEndDate);

  _exports.default = _default;
});