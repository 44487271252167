define("js-common/utils/valid-upload-file-extensions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.receiptExtensions = _exports.allExtensions = void 0;
  var allExtensions = ['pdf', 'png', 'jpeg', 'tiff', 'doc', 'docx', 'jpg', 'gif', 'bmp', 'jp2', 'tif', 'webp', 'ppt', 'pptx', 'pps', 'ppsx', 'key', 'psd', 'mp3', 'mp4', 'm4a', 'ogg', 'mov', 'wmv', 'avi', 'mpg', 'ogv', 'xls', 'xlsx', 'xlsm', 'swf', 'txt', 'wmf', 'wav', 'wav', 'xml', 'xpm', 'csv', 'odt', 'odc', 'odp', 'odg', 'mpp', 'zip', 'rar', '7z', 'gz', 'ptx', 'mhtml', 'webm', 'asf', 'wma'];
  _exports.allExtensions = allExtensions;
  var receiptExtensions = ['pdf', 'png', 'jpeg', 'gif', 'jpg'];
  _exports.receiptExtensions = receiptExtensions;
});