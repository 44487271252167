define("js-common/initializers/auth-token", ["exports", "js-common/authenticators/token", "js-common/authenticators/custom"], function (_exports, _token, _custom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   Ember Simple Auth Token's Initializer.
   By default load both the Token and Custom.
   */
  var _default = {
    name: 'auth-token',
    before: 'ember-simple-auth',
    initialize: function initialize(container) {
      container.register('authenticator:token', _token.default);
      container.register('authenticator:custom', _custom.default);
    }
  };
  _exports.default = _default;
});