define("@smith-carson/ui/components/sc-file-uploader/-lib/file-payload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: '',
    file: null,
    size: '',
    fileType: '',
    documentType: '',
    subject: undefined,
    cantBundle: false,
    viewable: 0
  };
  _exports.default = _default;
});