define("js-common/helpers/user-avatar-url", ["exports", "js-common/utils/get-app-url"], function (_exports, _getAppUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Fetch the Avatar image URL for a user
   */
  var _default = Ember.Helper.extend({
    session: Ember.inject.service(),
    ENV: Ember.computed(function () {
      return Ember.getOwner(this).resolveRegistration('config:environment');
    }),
    compute: function compute(params, hash) {
      var ENV = this.ENV;
      var user = hash.user.content;

      if (typeof user === 'undefined' || user === null || user.get('id') === null) {
        return null;
      }

      var url = null;

      if (user.get('avatarId')) {
        url = (0, _getAppUrl.default)(ENV, "/files/".concat(user.get('avatarId'), "/preview/1?token=").concat(this.session.data.authenticated.token));
      }

      return url;
    }
  });

  _exports.default = _default;
});