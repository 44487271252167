define("ember-composable-helpers/helpers/invoke", ["exports", "@babel/runtime/helpers/esm/toArray"], function (_exports, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.invoke = invoke;
  _exports.default = void 0;
  var all = Ember.RSVP.all;

  function invoke(_ref) {
    var _ref2 = (0, _toArray2.default)(_ref),
        methodName = _ref2[0],
        args = _ref2.slice(1);

    var obj = args.pop();

    if (Ember.isArray(obj)) {
      return function () {
        var promises = obj.map(function (item) {
          return Ember.tryInvoke(item, methodName, args);
        });
        return all(promises);
      };
    }

    return function () {
      return Ember.tryInvoke(obj, methodName, args);
    };
  }

  var _default = Ember.Helper.helper(invoke);

  _exports.default = _default;
});