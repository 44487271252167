define("ember-light-table/templates/components/cells/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BrIHjNv0",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"column\",\"cellComponent\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"component\",[[25,[\"column\",\"cellComponent\"]]],[[\"tableActions\",\"extra\",\"table\",\"column\",\"row\",\"value\",\"rawValue\"],[[25,[\"tableActions\"]],[25,[\"extra\"]],[25,[\"table\"]],[25,[\"column\"]],[25,[\"row\"]],[25,[\"value\"]],[25,[\"rawValue\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[23,\"value\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/cells/base.hbs"
    }
  });

  _exports.default = _default;
});