define("@smith-carson/ui/pods/components/modals/case-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "86PqqX2n",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[5,\"bs-modal\",[[13,\"data-test-modal\",\"case-field\"]],[[\"@class\",\"@fade\",\"@onHidden\",\"@scrollable\"],[\"side-panel side-panel-right\",false,[29,\"action\",[[24,0,[]],[29,\"mut\",[[24,0,[\"showEditFieldModal\"]]],null],false],null],true]],{\"statements\":[[0,\"\\n    \"],[6,[24,1,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[7,\"h5\"],[11,\"class\",\"modal-title align-items-center d-inline-flex\"],[9],[0,\"\\n            \"],[1,[25,[\"field\",\"value\"]],false],[0,\"\\n        \"],[10],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[24,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n            [FORM HERE]\\n        \"],[10],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/pods/components/modals/case-field/template.hbs"
    }
  });

  _exports.default = _default;
});