define("js-common/models/timeline-event", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    startDate: attr('string'),
    startPrecision: attr('string'),
    startYear: attr('string'),
    startMonth: attr('string'),
    startDay: attr('string'),
    endDate: attr('string'),
    endPrecision: attr('string'),
    endYear: attr('string'),
    endMonth: attr('string'),
    endDay: attr('string'),
    city: attr('string'),
    county: attr('string'),
    state: attr('string'),
    country: attr('string'),
    weight: attr('number'),
    location: Ember.computed('city', 'county', 'state', 'country', function () {
      var loc = '';

      if (this.city) {
        loc += this.city + ', ';
      }

      if (this.county) {
        loc += this.county + ', ';
      }

      if (this.state) {
        loc += this.state + ', ';
      }

      if (this.country) {
        loc += this.country;
      } else {
        loc = loc.substring(0, loc.length - 2);
      }

      if (!loc) {
        loc = 'Unknown';
      }

      return loc;
    }),
    description: attr('string'),
    note: attr('string'),
    metaResources: attr('string'),
    subjectId: attr('string'),
    // resource fields
    childTable: attr('string', {
      defaultValue: 'timeline_events'
    }),
    syncId: attr('number'),
    createdOn: attr('estdate'),
    updatedOn: attr('estdate'),
    viewable: attr('number'),
    // relationship
    subjects: hasMany('subject', {
      async: false
    }),
    documents: hasMany('document', {
      async: false
    }),
    matter: belongsTo('matter', {
      async: false
    }),
    createdBy: belongsTo('user', {
      async: true
    }),
    updatedBy: belongsTo('user', {
      async: true
    }),
    timelineEventType: belongsTo('timeline-event-type', {
      async: true,
      inverse: 'timelineEvents'
    }),
    docType: attr('string')
  });

  _exports.default = _default;
});