define("js-common/models/v-matter-task", ["exports", "jquery", "ember-data"], function (_exports, _jquery, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    status: attr('string'),
    comment: attr('string'),
    priority: attr('string'),
    timeLimit: attr('string'),
    firstName: attr('string'),
    lastName: attr('string'),
    // relationships
    assignedTo: belongsTo('employee', {
      async: true
    }),
    workflow: belongsTo('workflow', {
      async: true
    }),
    matterHasWorkflow: belongsTo('matter-has-workflow', {
      async: true
    }),
    matter: belongsTo('matter'),
    task: belongsTo('task', {
      async: true
    }),
    fullName: Ember.computed('firstName', 'lastName', function () {
      var fullName = '';

      if (Ember.isPresent(this.lastName)) {
        fullName += this.lastName;
      }

      if (Ember.isPresent(this.firstName)) {
        fullName += ', ' + this.firstName;
      }

      return (0, _jquery.default)('<div/>').html(fullName).text();
    })
  });

  _exports.default = _default;
});