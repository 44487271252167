define("js-common/templates/components/edit-field/radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Myj5QFnK",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"ul\"],[11,\"class\",\"list-inline\"],[11,\"style\",\"display: flex;align-items: center;\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"options\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[11,\"class\",\"list-inline-item\"],[9],[0,\"\\n      \"],[5,\"sc-radio-button\",[],[[\"@label\",\"@groupValue\",\"@value\",\"@onChange\"],[[29,\"get\",[[24,1,[]],[25,[\"optionDisplayField\"]]],null],[23,\"radioValue\"],[24,1,[\"value\"]],[29,\"action\",[[24,0,[]],\"changeOption\"],null]]]],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"li\"],[11,\"class\",\"list-inline-item\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"and\",[[29,\"eq\",[[24,1,[\"value\"]],\"Other\"],null],[29,\"eq\",[[25,[\"radioValue\"]],\"Other\"],null]],null]],null,{\"statements\":[[0,\"        \"],[5,\"sc-input\",[],[[\"@value\",\"@onChange\"],[[23,\"otherText\"],[29,\"action\",[[24,0,[]],\"changeOther\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/edit-field/radio.hbs"
    }
  });

  _exports.default = _default;
});