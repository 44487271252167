define("js-common/models/user-addr", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    type: _emberData.default.attr('string'),
    primary: _emberData.default.attr('number'),
    addr_1: _emberData.default.attr('string'),
    addr_2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    // relationship
    user: _emberData.default.belongsTo('user', {
      async: false
    }),
    // because the FK is user_id
    // linked address to firm
    firm: _emberData.default.belongsTo('firm', {
      async: true
    })
  });

  _exports.default = _default;
});