define("js-common/helpers/stat-string-to-int", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.statStringToInt = statStringToInt;
  _exports.default = void 0;

  /**
   * convert a stat (received as array with single string element) into int
   *
   * @param params
   * expects an array with a single element as string
   * @returns {*}
   */
  function statStringToInt(params
  /*, hash */
  ) {
    if (Array.isArray(params)) {
      var str = params[0];

      if (str) {
        return parseFloat((parseFloat(str) * 100).toFixed(0));
      }

      return 0;
    }
  }

  var _default = Ember.Helper.helper(statStringToInt);

  _exports.default = _default;
});