define("@smith-carson/ui/templates/components/sc-table/filter-operators/csv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1w1VAg9n",
    "block": "{\"symbols\":[\"@filterValue\",\"@changeFilterValue\"],\"statements\":[[5,\"sc-input\",[[13,\"class\",\"form-control\"],[13,\"placeholder\",\"multiple values are accepted separated by commas\"]],[[\"@value\",\"@onChange\"],[[24,1,[]],[29,\"action\",[[24,0,[]],[29,\"pipe\",[[29,\"action\",[[24,0,[]],[29,\"mut\",[[24,1,[]]],null]],null],[29,\"action\",[[24,0,[]],[29,\"optional\",[[24,2,[]]],null]],null]],null]],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-table/filter-operators/csv.hbs"
    }
  });

  _exports.default = _default;
});