define("js-common/templates/components/analytics-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QkO2fgCZ",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"component\",[[25,[\"componentName\"]]],[[\"data\",\"phaseOneGroup\",\"phaseTwoGroup\"],[[25,[\"data\"]],[25,[\"phaseOneGroup\"]],[25,[\"phaseTwoGroup\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/analytics-sidebar.hbs"
    }
  });

  _exports.default = _default;
});