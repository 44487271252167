define("@smith-carson/ui/templates/components/sc-accordion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xa4FTzEx",
    "block": "{\"symbols\":[\"panels\",\"&attrs\",\"@accordion\",\"@showControls\",\"@open\",\"&default\"],\"statements\":[[8,\"div\"],[13,\"class\",\"sc-accordion\"],[14,2],[9],[0,\"\\n  \"],[5,\"cp-panels\",[[13,\"class\",\"sc-accordion__list\"]],[[\"@accordion\",\"@name\"],[[24,0,[\"accordion\"]],[24,0,[\"groupName\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[29,\"and\",[[24,4,[]],[29,\"not\",[[24,3,[]]],null]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"sc-accordion__controls\"],[9],[0,\"\\n        \"],[5,\"sc-button\",[],[[\"@label\",\"@color\",\"@size\",\"@onClick\"],[\"Expand All\",\"white\",\"sm\",[29,\"action\",[[24,0,[]],[24,0,[\"expandAll\"]]],null]]]],[0,\"\\n\\n        |\\n\\n        \"],[5,\"sc-button\",[],[[\"@label\",\"@color\",\"@size\",\"@onClick\"],[\"Collapse All\",\"white\",\"sm\",[29,\"action\",[[24,0,[]],[24,0,[\"collapseAll\"]]],null]]]],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"ol\"],[11,\"class\",\"sc-accordion__list\"],[9],[0,\"\\n      \"],[15,6,[[29,\"hash\",null,[[\"item\",\"expandAll\",\"collapseAll\",\"toggleOne\"],[[29,\"component\",[[24,0,[\"itemComponent\"]]],[[\"panels\",\"open\"],[[24,1,[]],[24,5,[]]]]],[29,\"action\",[[24,0,[]],[24,0,[\"expandAll\"]]],null],[29,\"action\",[[24,0,[]],[24,0,[\"collapseAll\"]]],null],[29,\"action\",[[24,0,[]],[25,[\"toggleOne\"]]],null]]]]]],[0,\"\\n    \"],[10],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-accordion.hbs"
    }
  });

  _exports.default = _default;
});