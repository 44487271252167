define("ember-bootstrap/templates/components/bs-dropdown/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yKJZe4/u",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"_isOpen\"]]],null,{\"statements\":[[4,\"ember-popper\",null,[[\"id\",\"class\",\"ariaRole\",\"placement\",\"popperTarget\",\"renderInPlace\",\"popperContainer\",\"modifiers\"],[[29,\"concat\",[[25,[\"dropdownElementId\"]],\"__menu\"],null],[29,\"concat\",[\"dropdown-menu \",[25,[\"alignClass\"]],[29,\"if\",[[25,[\"isOpen\"]],\" show\"],null],\" \",[25,[\"class\"]]],null],[25,[\"ariaRole\"]],[25,[\"popperPlacement\"]],[25,[\"toggleElement\"]],[25,[\"_renderInPlace\"]],\"#ember-bootstrap-wormhole\",[25,[\"popperModifiers\"]]]],{\"statements\":[[0,\"    \"],[15,1,[[29,\"hash\",null,[[\"item\",\"link-to\",\"divider\"],[[29,\"component\",[[25,[\"itemComponent\"]]],null],[29,\"component\",[[25,[\"linkToComponent\"]]],null],[29,\"component\",[[25,[\"dividerComponent\"]]],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-dropdown/menu.hbs"
    }
  });

  _exports.default = _default;
});