define("js-common/templates/components/document-preview-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s2N0A9r4",
    "block": "{\"symbols\":[\"@text\",\"@color\",\"@icon\",\"@iconSet\",\"@size\",\"@docId\"],\"statements\":[[4,\"link-to\",[\"preview\",[24,6,[]]],[[\"target\",\"click\",\"class\"],[[25,[\"target\"]],[29,\"action\",[[24,0,[]],\"clicked\"],null],\"btn btn-link\"]],{\"statements\":[[4,\"if\",[[24,3,[]]],null,{\"statements\":[[0,\"    \"],[5,\"sc-icon\",[[13,\"class\",\"mr-2\"]],[[\"@color\",\"@icon\",\"@iconSet\",\"@size\"],[[24,2,[]],[24,3,[]],[24,4,[]],[24,5,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,1,[]]],null,{\"statements\":[[0,\"    \"],[1,[24,1,[]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/document-preview-url.hbs"
    }
  });

  _exports.default = _default;
});