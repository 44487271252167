define("@smith-carson/ui/mixins/table-common", ["exports", "ember-light-table", "better-multiselect"], function (_exports, _emberLightTable, _betterMultiselect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides `Ember.Mixin` that initializes and provides common functionality
      for modules that implement `EmberLightTable`.
  
      @module TableCommonMixin
      @extends Ember.Mixin
   */
  var _default = Ember.Mixin.create({
    columns: null,
    meta: null,
    rowComponent: 'lt-row',
    rows: null,
    table: null,
    init: function init() {
      var canReorder = this.canReorder,
          columns = this.columns,
          rows = this.rows; // Support reordering of rows

      if (canReorder) {
        (false && !(!!this.get('tableActions.onRowMove')) && Ember.assert( // eslint-disable-next-line max-len
        'You must provide the `onRowMove` action on the `tableActions` hash to handle contextual actions (when `canReorder` is `true`)', !!this.get('tableActions.onRowMove')));
        this.set('rowComponent', 'sc-light-table/rows/draggable-row');
        this.get('columns').addObject({
          align: 'center',
          cellType: 'draggable-row',
          label: '',
          resizable: true,
          sortable: false,
          width: '5rem'
        });
      } // Process `columns` before instantiating `table` (using `for` loop for early termination)


      for (var i = 0; i < columns.length; i += 1) {
        var column = columns[i];

        if (column.cellType === 'select-row') {
          Object.assign(column, {
            component: 'light-table/select-all'
          });
          break;
        }
      }

      var table = new _emberLightTable.default(columns, rows); // Bind `moveRow` and `onRowSelect` to the `Table` instance

      Object.assign(table, {
        moveRow: this.moveRow.bind(this),
        onRowSelect: this.onRowSelect.bind(this)
      }); // Store the `Multiselect` and `Table` instances for later access

      this.setProperties({
        multiselect: new _betterMultiselect.default(),
        table: table
      }); // Call `_super` after defining `table` on the instance

      this._super.apply(this, arguments);
    },

    /**
        Resets drop zones by removing drop zone classes and the stored
        `dropSubject`.
         @method clearDropZones
     */
    clearDropZones: function clearDropZones() {
      var dropZones = this.element.parentElement.querySelectorAll('.drop-zone');
      dropZones.forEach(function (dropZone) {
        return dropZone.classList.remove('drop-zone', 'drop-zone-bottom', 'drop-zone-top');
      });
      this.set('dropSubject', null);
    },

    /**
        Handles the `dragend` event by removing all drop-zone and draggable HTML
        class names and attributes from rows and removing the stored
        `dragSubject`.
         @method dragEnd
     */
    dragEnd: function dragEnd(event) {
      var target = event.target;
      var draggingRow = target.closest('tr.draggable');
      this.clearDropZones();

      if (draggingRow) {
        draggingRow.removeAttribute('draggable');
        draggingRow.classList.remove('is-dragging');
        this.set('dragSubject', null);
      }
    },

    /**
        Stores the current drop target as `dropSubject` and adds the appropriate
        HTML class name to the drop target row.
         @method dragEnter
        @param {Event} event
     */
    dragEnter: function dragEnter(event) {
      var target = event.target;

      var _this$get = this.get('dragSubject'),
          draggingRow = _this$get.draggingRow;

      var droppableRow = target.closest('[dropzone="move"]');

      if (droppableRow && droppableRow !== draggingRow) {
        this.clearDropZones();
        this.set('dropSubject', droppableRow);
        droppableRow.classList.add('drop-zone');
      }
    },

    /**
        Handles the `dragover` event by determining the cursor position relative
        to the current drop target row and assigning the appropriate HTML class
        name to the drop target row.
         @method dragOver
        @param {Event} event
     */
    // eslint-disable-next-line complexity
    dragOver: function dragOver(event) {
      event.preventDefault();
      Object.assign(event.dataTransfer, {
        dropEffect: 'move'
      });
      var clientY = event.clientY,
          target = event.target;

      var _this$getProperties = this.getProperties('dragSubject', 'dropSubject'),
          dragSubject = _this$getProperties.dragSubject,
          dropSubject = _this$getProperties.dropSubject;

      var draggingRow = dragSubject.draggingRow; // TODO: Determine why this multiple choice assignment is necessary

      var droppableRow = dropSubject || target.closest('[dropzone="move"]');

      if (!draggingRow || !droppableRow) {
        return;
      } // Determine drop side (relative to droppable row)


      var _droppableRow$getBoun = droppableRow.getBoundingClientRect(),
          bottom = _droppableRow$getBoun.bottom,
          top = _droppableRow$getBoun.top;

      var halfway = (bottom - top) / 2;
      var dropSide = clientY > halfway + top ? 'bottom' : 'top';

      if (droppableRow && // Has droppable row...
      droppableRow !== draggingRow && ( // ...that is NOT the row being dragged, and...
      // if over bottom of row without a bottom indicator (except for row after `draggingRow`)...
      dropSide === 'bottom' && droppableRow !== draggingRow.previousElementSibling && !droppableRow.classList.contains('drop-zone-bottom') || // ...or over top of row without a top indicator (except for row before `draggingRow`).
      dropSide === 'top' && droppableRow !== draggingRow.nextElementSibling && !droppableRow.classList.contains('drop-zone-top'))) {
        this.set('dropSubjectSide', dropSide);
        droppableRow.classList.remove('drop-zone-bottom', 'drop-zone-top');
        droppableRow.classList.add("drop-zone-".concat(dropSide));
      }
    },

    /**
        Supports native drag-and-drop by assigning `dataTransfer.effectAllowed`
        and storing the event target row on the component for subsequent
        processing.
         @method dragStart
        @param {Event} event
     */
    dragStart: function dragStart(event) {
      var target = event.target;
      Object.assign(event.dataTransfer, {
        effectAllowed: 'move'
      });
      var draggingRow = target.closest('tr.draggable');

      if (draggingRow) {
        draggingRow.classList.add('is-dragging');
        this.set('dragSubject', {
          draggingRow: draggingRow,
          rowContentJSON: event.dataTransfer.getData('application/json')
        });
      }
    },

    /**
        Handles the `drop` event by determining the index of the row being
        dragged and the index to move to, moving the `ember-light-table` `row`
        object, treating the HTML element (with `highlight` class name), and
        calling back to the invoking context with old index, the new index, and
        a JSON representation of the row data.
         @method drop
        @param {Event} event
     */
    drop: function drop()
    /* event */
    {
      var _this$getProperties2 = this.getProperties('dragSubject', 'dropSubjectSide', 'dropSubject', 'table'),
          dragSubject = _this$getProperties2.dragSubject,
          dropSubjectSide = _this$getProperties2.dropSubjectSide,
          dropSubject = _this$getProperties2.dropSubject,
          table = _this$getProperties2.table;

      var draggingRow = dragSubject.draggingRow; // Actual `tr` element

      var dragIndex = Array.prototype.indexOf.call(draggingRow.parentElement.children, draggingRow);
      var dropIndex = Array.prototype.indexOf.call(dropSubject.parentElement.children, dropSubject); // If moving down but dropping above target row then decrement `dropIndex`

      if (dragIndex < dropIndex && dropSubjectSide === 'top') {
        dropIndex -= 1;
      }

      var lightTableRow = table.rows[dragIndex]; // `ember-light-table` `Proxy` row

      this.moveRow(lightTableRow, draggingRow, dragIndex, dropIndex);
      this.clearDropZones();
    },

    /**
        Helper function handles the actual reordering of `ember-light-table`
        rows and call back to the calling context (called from this module and
        the `DraggableRowComponent`).
         @method moveRow
        @param {Row} row
        @param {HTMLElement} rowElement
        @param {Number} oldIndex
        @param {Number} newIndex
     */
    moveRow: function moveRow(row, rowElement, oldIndex, newIndex) {
      var _this$getProperties3 = this.getProperties('table', 'tableActions.onRowMove'),
          table = _this$getProperties3.table,
          onRowMove = _this$getProperties3['tableActions.onRowMove']; // Add highlight HTML class name


      rowElement.classList.add('highlight'); // Reorder light-table rows and re-render table

      table.rows.removeObject(row);
      table.rows.insertAt(newIndex, row);
      table.notifyPropertyChange('rows'); // Call back to context

      onRowMove(oldIndex, newIndex, row.content); // Remove highlight HTML class name

      Ember.run.next(function () {
        rowElement.classList.remove('highlight');
      });
    },

    /**
        Handles updating the set of selected rows based on user input. Currently
        not leveraging the `Multiselect#click` handler (which results in single
        selections and defaults non-modified clicks to the `commandClick`
        handler).
         @method onRowSelect
        @param {jQuery.Event} event
        @param {Object} row `ember-light-table` row
     */
    onRowSelect: function onRowSelect(event, row) {
      var rows = this.get('table.rows');
      var index = rows.indexOf(row);

      if (event.shiftKey) {
        this.multiselect.shiftClick(index);
      } else {
        // Don't require meta key modifier
        this.multiselect.commandClick(index);
      }

      this.updateRowSelection();
    },

    /**
        Updates `LightTable::Rows::selected` and `model.selected` based on
        Multiselect `Set` state.
         @method updateRowSelection
     */
    updateRowSelection: function updateRowSelection() {
      var rows = this.get('table.rows');
      var selection = this.get('multiselect.selection');
      rows.forEach(function (row, index) {
        var isSelected = selection.has(index);
        row.setProperties({
          'content.selected': isSelected,
          selected: isSelected
        });
      });
    },
    actions: {
      /**
          Handles the `onColumnClick` event by passing the clicked `column`
          and the `table` to the invoking context when `column.sorted`
          is `true`.
           @method onColumnClick
          @param {Column} column
       */
      onColumnClick: function onColumnClick(column) {
        if (column.sorted) {
          this.get('onColumnClick')(column, this.get('table'));
        }
      },

      /**
          Supports contextual `onRowClick` handling.
           @method onRowClick
          @param {Row} row
       */
      onRowClick: function onRowClick(row) {
        var onRowClick = this.get('onRowClick');

        if (typeof onRowClick === 'function') {
          onRowClick(row, this.get('table'));
        }
      }
    }
  });

  _exports.default = _default;
});