define("js-common/models/appl-list", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    display: attr('string'),
    value: attr('string'),
    weight: attr('number'),
    shortName: attr('string'),
    applListsCategory: belongsTo('appl-lists-category')
  });

  _exports.default = _default;
});