define("js-common/models/document", ["exports", "js-common/models/resource", "js-common/mixins/document-model"], function (_exports, _resource, _documentModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // because Document is an extended resource and it's required for polymorphism we need to create it like this
  // the creation has to be done in the app and not in the addon
  var _default = _resource.default.extend(_documentModel.default, {});

  _exports.default = _default;
});