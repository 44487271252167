define("@smith-carson/ui/templates/components/sc-file-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ix/Ihj2m",
    "block": "{\"symbols\":[\"@onFileChange\",\"@multi\",\"&default\"],\"statements\":[[4,\"if\",[[26,3]],null,{\"statements\":[[0,\"\\n  \"],[15,3,[[29,\"hash\",null,[[\"fileChooser\",\"progressBar\",\"fileUploader\"],[[29,\"component\",[\"sc-file-uploader/file-chooser\"],[[\"files\",\"multi\",\"onChange\",\"validExtensions\",\"maxUploadSize\"],[[24,0,[\"files\"]],[24,2,[]],[29,\"pipe\",[[29,\"action\",[[24,0,[]],[29,\"optional\",[[24,1,[]]],null]],null],[29,\"action\",[[24,0,[]],[29,\"mut\",[[24,0,[\"files\"]]],null]],null]],null],[24,0,[\"validExtensions\"]],[24,0,[\"maxUploadSize\"]]]]],[29,\"component\",[\"sc-file-uploader/progress-bar\"],[[\"progress\"],[[24,0,[\"progress\"]]]]],[24,0,[]]]]]]],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-file-uploader.hbs"
    }
  });

  _exports.default = _default;
});