define("@smith-carson/ui/pods/components/light-table/cells/badge/component", ["exports", "ember-light-table/components/cells/base", "@smith-carson/ui/pods/components/light-table/cells/badge/template"], function (_exports, _base, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides a custom `LightTable` cell type for rendering status indicators
      (along with optional info tooltips) to an `ember-light-table` row.
  
      @module LightTable::Cells::BadgeComponent
      @extends EmberLightTable.CellsBaseComponent
   */
  var _default = _base.default.extend({
    classNames: ['badge-cell'],
    layout: _template.default,
    label: Ember.computed('column.valuePath', 'row', function () {
      return this.get("row.".concat(this.column.valuePath));
    }),

    /**
        Provides the badgeType for the badge specific HTML class name for visual treatment.
         @property statusClassName
        @type {String}
     */
    badgeType: Ember.computed.reads('column.extra.badgeType')
  });

  _exports.default = _default;
});