define("@smith-carson/ui/pods/components/sc-light-table/cells/days-since-creation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nFk0S3V8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"case-days-since-creation-cell\"],[11,\"data-test-component\",\"case-days-since-creation-cell\"],[9],[0,\"\\n    \"],[7,\"span\"],[12,\"class\",[24,0,[\"alertDaysSinceCreationStyle\"]]],[9],[0,\"\\n       \"],[1,[24,0,[\"row\",\"calcDaysSinceCreation\"]],false],[0,\"\\n        \"],[5,\"bs-tooltip\",[],[[\"@placement\"],[\"right\"]],{\"statements\":[[1,[24,0,[\"alertDaysSinceCreationTooltip\"]],false]],\"parameters\":[]}],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/pods/components/sc-light-table/cells/days-since-creation/template.hbs"
    }
  });

  _exports.default = _default;
});