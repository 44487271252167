define("js-common/models/crm-note", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    modelName: 'crm-note',
    // before we were using constructor.modelname but is no longer reliable
    body: attr('string'),
    createdOn: attr('estdate'),
    updatedOn: attr('estdate'),
    // relationships
    createdBy: belongsTo('employee', {
      async: true
    }),
    parent: belongsTo('crm-note', {
      async: true,
      inverse: 'comments'
    }),
    crmNoteCategory: belongsTo('crm-note-category', {
      async: true
    }),
    comments: hasMany('crm-note', {
      async: true,
      inverse: 'parent'
    }),
    crmNoteHasClients: hasMany('crm-note-has-client', {
      async: false
    }),
    crmNoteHasDeals: hasMany('crm-note-has-deal', {
      async: false
    })
  });

  _exports.default = _default;
});