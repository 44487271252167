define("@smith-carson/ui/pods/components/light-table/cells/tooltip/component", ["exports", "ember-light-table/components/cells/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Extends the base `ember-light-table cell` component to add the ability to
      view all (possibly truncated) content in a tooltip (`title` attribute).
  
      @module LightTable::Cells::TooltipComponent
      @extends EmberLightTable.CellsBaseComponent
   */
  var _default = _base.default.extend({
    attributeBindings: ['title'],

    /**
        Provides the cell's content for use in the `title` attribute.
         @property title
        @type {String}
     */
    title: Ember.computed(function () {
      var column = this.get('column');
      var format = column.format,
          valuePath = column.valuePath;

      if (typeof format === 'function') {
        return format();
      }

      return this.row.get(valuePath);
    })
  });

  _exports.default = _default;
});