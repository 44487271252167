define("js-common/models/report-component-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    name: attr(),
    reportComponentTemplates: hasMany('report-component-templates', {
      async: true
    }),
    reportComponents: hasMany('report-component', {
      async: true
    })
  });

  _exports.default = _default;
});