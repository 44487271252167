define("js-common/models/user", ["exports", "jquery", "ember-data", "ember-api-actions"], function (_exports, _jquery, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    firstName: attr('string'),
    lastName: attr('string'),
    email: attr('string'),
    userName: attr('string'),
    password: attr('string'),
    salt: attr('string'),
    passwordExpired: attr('string'),
    status: attr('string'),
    lastLogin: attr('estdate'),
    lastLogout: attr('estdate'),
    tmNum: attr('number'),
    tmType: attr('string'),
    salesforceId: attr('string'),
    token: attr('string'),
    tokenExpires: attr('string'),
    userType: attr('string'),
    avatarId: attr('number'),
    currentEmployerName: attr('string'),
    currentEmployerId: attr('string'),
    phoneNumber: attr('string'),
    // relationship
    employee: belongsTo('employee', {
      async: true,
      inverse: 'user'
    }),
    createdBy: belongsTo('employee', {
      async: false
    }),
    // because the FK is user_id
    updatedBy: belongsTo('employee', {
      async: false
    }),
    userAddrs: hasMany('user-addr', {
      async: false
    }),
    userUrls: hasMany('user-url', {
      async: false
    }),
    userNumbers: hasMany('user-number', {
      async: false
    }),
    matterHasUser: hasMany('matter-has-user', {
      async: false
    }),
    firmHasClients: hasMany('firm-has-client', {
      async: false
    }),
    userEventLogs: hasMany('user-event-log', {
      async: false,
      inverse: 'createdBy'
    }),
    userHasGroups: hasMany('user-has-groups', {
      async: false
    }),
    notes: hasMany('note', {
      async: true,
      inverse: 'createdBy'
    }),
    documentTemplates: hasMany('document-template', {
      async: true
    }),
    // calculated
    fullName: Ember.computed('firstName', 'lastName', function () {
      if (!this.lastName && !this.firstName) {
        return null;
      }

      var fullName = this.lastName + ', ' + this.firstName;
      return (0, _jquery.default)('<div/>').html(fullName).text();
    }),
    realFullName: Ember.computed('firstName', 'lastName', function () {
      var firstName = this.firstName;
      var lastName = this.lastName;
      return "".concat(firstName, " ").concat(lastName);
    }),
    isActive: Ember.computed.equal('status', 'Active'),
    primaryAddress: Ember.computed('userAddrs.[]', function () {
      return this.userAddrs.findBy('primary', 1);
    }),
    workAddress: Ember.computed('userAddrs.[]', function () {
      return this.userAddrs.findBy('type', 'Work');
    }),
    mainFirm: Ember.computed('firmHasClients.[]', function () {
      var firmHasClients = this.firmHasClients;
      var mainFirm;
      var currentEmployer = firmHasClients.findBy('relationship', 'Current Employer');

      if (Ember.isPresent(currentEmployer)) {
        mainFirm = currentEmployer;
      } else {
        mainFirm = firmHasClients.get('firstObject');
      }

      if (Ember.isPresent(mainFirm)) {
        var rel = mainFirm.get('relationship');
        return _emberData.default.PromiseObject.create({
          promise: mainFirm.get('firm').then(function (firm) {
            var firmName = firm.get('name');
            return "".concat(rel, ": ").concat(firmName);
          })
        });
      }

      return _emberData.default.PromiseObject.create({
        promise: new Ember.RSVP.Promise(function (resolve, reject) {
          resolve('');
        })
      });
    }),
    // only for UI
    groupName: attr(),
    // method use to impersonate another user
    impersonateUser: (0, _emberApiActions.collectionAction)({
      path: 'impersonate-user',
      type: 'post'
    }),
    switchApp: (0, _emberApiActions.collectionAction)({
      path: 'switch-app',
      type: 'post'
    })
  });

  _exports.default = _default;
});