define("js-common/services/current-matter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    /**
     * the currently "open" matter
     */
    matter: null,

    /**
     * quick access to the matter_id
     */
    id: null,

    /**
     * store the current route so menus can be updated
     */
    currentRoute: null,

    /**
     * the matter fields related to the matter
     */
    matterFields: null,

    /**
     * mdl_dashboard or jury_dashboard
     */
    portalDashboard: null,

    /**
     * Reset state of the matter service
     */
    reset: function reset() {
      this.set('id', null);
      this.set('matter', null);
      this.set('matterFields', null);
    }
  });

  _exports.default = _default;
});