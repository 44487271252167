define("ember-array-helper/helpers/array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function array(params) {
    // params is a frozen, non-ember array
    return Ember.A(params.slice());
  });

  _exports.default = _default;
});