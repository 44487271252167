define("@smith-carson/ui/pods/components/light-table/sort-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XpOEN7ZC",
    "block": "{\"symbols\":[],\"statements\":[[5,\"sc-icon\",[[13,\"class\",\"sort-icon\"],[13,\"data-test-icon\",\"sort\"]],[[\"@icon\",\"@iconSet\"],[[24,0,[\"iconName\"]],\"fa\"]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/pods/components/light-table/sort-icon/template.hbs"
    }
  });

  _exports.default = _default;
});