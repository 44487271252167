define("ember-bootstrap/templates/components/bs-modal/dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dMeAWVB+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"modal-dialog \",[23,\"sizeClass\"],\" \",[29,\"if\",[[25,[\"centered\"]],\"modal-dialog-centered\"],null]]]],[11,\"role\",\"document\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"modal-content\"],[9],[0,\"\\n    \"],[15,1],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-modal/dialog.hbs"
    }
  });

  _exports.default = _default;
});