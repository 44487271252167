define("js-common/components/x-preview-document/result-preview-dropdown", ["exports", "js-common/templates/components/x-preview-document/result-preview-dropdown", "ember-lifeline/mixins/run"], function (_exports, _resultPreviewDropdown, _run) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_run.default, {
    layout: _resultPreviewDropdown.default,
    classNames: ['btn-group'],
    pageSize: 25,
    currentPage: 1,
    showDropDown: false,
    pageOffset: Ember.computed('pageSize', 'currentPage', function () {
      var pageSize = this.get('pageSize');
      var currentPage = this.get('currentPage');
      return pageSize * (currentPage - 1);
    }),
    totalPages: Ember.computed('pageSize', 'matchTotal', function () {
      var pageSize = this.get('pageSize');
      var matchTotal = this.get('matchTotal');
      return Math.ceil(matchTotal / pageSize);
    }),
    currentPageReset: Ember.observer('pageSize', 'currentMatchIdx', function () {
      var pageSize = this.get('pageSize');
      var currentMatchIdx = this.get('currentMatchIdx');
      this.set('currentPage', Math.ceil(currentMatchIdx / pageSize));
    }),
    actions: {
      changePage: function changePage() {
        var _this = this;

        this.runTask(function () {
          _this.$().addClass('open');
        });
      }
    }
  });

  _exports.default = _default;
});