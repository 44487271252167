define("@smith-carson/ui/pods/components/case-group-workflows/component", ["exports", "@smith-carson/ui/pods/components/case-group-workflows/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides affordance for listing and editing Case Group Workflows.
  
      @module CaseGroupWorkflowsComponent
      @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('columns', [{
        cellType: 'select-row',
        sortable: false,
        width: '2.5rem'
      }, {
        label: 'Name',
        resizable: true,
        valuePath: 'name'
      }, {
        label: 'Percentage',
        resizable: true,
        valuePath: 'percentage',
        format: function format() {
          var percentage = this.get('row.percentage');
          return "".concat(percentage, "%");
        }
      }]);
    },
    actions: {
      /**
          Sets the current `selectedCaseGroupWorkflow` and the
          `showCaseGroupWorkflowsModal` boolean flag to support editing of the
          current case group workflow in the sidebar.
           @method editCaseGroupWorkflow
          @param {Row} row ember-light-table Row object
       */
      editCaseGroupWorkflow: function editCaseGroupWorkflow(row) {
        this.setProperties({
          selectedCaseGroupWorkflow: row.content,
          showCaseGroupWorkflowsModal: true
        });
      },

      /**
          Reorders the case group workflows data collection based on user
          interaction in the `ember-light-table`.
           @method moveRow
          @param {Number} from Index of item to move
          @param {Number} to Index to move it to
       */
      moveRow: function moveRow(from, to) {
        this.get('caseGroupWorkflows').move(from, to);
      }
    }
  });

  _exports.default = _default;
});