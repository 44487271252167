define("js-common/models/owner", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    firstName: attr('string'),
    lastName: attr('string'),
    isPrimary: attr('number'),
    fullName: Ember.computed('firstName', 'lastName', function () {
      if (!this.lastName && !this.firstName) {
        return null;
      }

      return this.lastName + ', ' + this.firstName;
    })
  });

  _exports.default = _default;
});