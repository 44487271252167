define("@smith-carson/ui/templates/components/sc-accordion/item/title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/y6UUxoa",
    "block": "{\"symbols\":[\"Panel\",\"@title\",\"&default\",\"@panel\"],\"statements\":[[4,\"let\",[[24,4,[]]],null,{\"statements\":[[0,\"\\n\"],[6,[24,1,[\"toggle\"]],[[13,\"class\",\"sc-accordion__item__title\"]],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"span\"],[9],[0,\"\\n\"],[4,\"if\",[[26,3]],null,{\"statements\":[[0,\"      \"],[15,3],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[24,2,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\\n  \"],[5,\"sc-icon\",[[13,\"class\",\"sc-accordion__item__title__indicator\"]],[[\"@icon\",\"@iconSet\"],[\"caret-down\",\"fa\"]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-accordion/item/title.hbs"
    }
  });

  _exports.default = _default;
});