define("js-common/templates/components/sort-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PLoWIBdo",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[11,\"href\",\"javascript:void();\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"fieldText\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"color-black\"],[9],[0,\"\\n      \"],[1,[23,\"fieldText\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[29,\"eq\",[[25,[\"simpleSortField\"]],[25,[\"forField\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[29,\"fa-icon\",[[29,\"concat\",[\"sort-\",[25,[\"directionSortField\"]]],null]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"showDoubleArrows\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"fa-icon\",[\"sort\"],null],false],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}],[3,\"action\",[[24,0,[]],\"sortChanged\"]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/sort-icon.hbs"
    }
  });

  _exports.default = _default;
});