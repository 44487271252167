define("js-common/models/todo-category", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  var Handlebars = Ember.Handlebars;

  var _default = Model.extend({
    name: attr('string'),
    color: attr('string'),
    styleColor: Ember.computed('color', function () {
      var color = Handlebars.Utils.escapeExpression(this.color);
      return Ember.String.htmlSafe('background-color: ' + color);
    })
  });

  _exports.default = _default;
});