define("js-common/models/receivable-note", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    note: attr('string'),
    createdOn: attr('estdate'),
    firm: belongsTo('firm'),
    createdBy: belongsTo('user'),
    receivableNoteHasInvoceOutputs: hasMany('receivable-note-has-invoice-output')
  });

  _exports.default = _default;
});