define("js-common/services/analytics-forms-data", ["exports", "js-common/mixins/analytics-util", "js-common/mixins/analytics-data-builder"], function (_exports, _analyticsUtil, _analyticsDataBuilder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_analyticsUtil.default, _analyticsDataBuilder.default, {
    analyticsLists: null,
    currentSubject: null,
    baseData: null,
    profileEditMode: false,
    sidebarEditMode: false,
    refreshData: function refreshData() {
      var kwargs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (typeof kwargs.sidebarEditMode !== 'undefined') {
        this.set('sidebarEditMode', kwargs.sidebarEditMode);
      }

      if (typeof kwargs.profileEditMode !== 'undefined') {
        this.set('profileEditMode', kwargs.profileEditMode);
      }

      this._buildData(this.currentSubject, this.analyticsLists);
    },
    buildData: function buildData(subject, analyticsLists) {
      this.set('currentSubject', subject);
      this.set('analyticsLists', analyticsLists);

      this._buildData(subject, analyticsLists);
    },
    _buildData: function _buildData(subject, analyticsLists) {
      var kwargs = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (typeof kwargs.sidebarEditMode !== 'undefined') {
        this.set('sidebarEditMode', kwargs.sidebarEditMode);
      }

      if (typeof kwargs.profileEditMode !== 'undefined') {
        this.set('profileEditMode', kwargs.profileEditMode);
      }

      var baseData = this.buildObjectWithAnalyticOptions(subject, analyticsLists);
      this.set('baseData', baseData);
    },
    dataForProfile: Ember.computed('baseData', 'currentSubject', 'profileEditMode', function () {
      return this.getSubjectAndAnalyticsData(this.baseData, this.currentSubject, this.profileEditMode);
    }),
    dataForSidebar: Ember.computed('baseData', 'currentSubject', 'sidebarEditMode', function () {
      return this.getSubjectAndAnalyticsData(this.baseData, this.currentSubject, this.sidebarEditMode);
    })
  });

  _exports.default = _default;
});