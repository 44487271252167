define("js-common/models/collection", ["exports", "ember-data", "ember-api-actions"], function (_exports, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    name: attr('string'),
    description: attr('string'),
    budget: attr('number'),
    ownedBy: belongsTo('employee', {
      async: true
    }),
    reportComponent: belongsTo('report-component', {
      async: true
    }),
    collectionColumns: hasMany('collection-column', {
      async: false
    }),
    getMatterItems: (0, _emberApiActions.memberAction)({
      path: 'matter_items',
      type: 'get',
      urlType: 'findRecord'
    })
  });

  _exports.default = _default;
});