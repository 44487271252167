define("js-common/mixins/analytics-data-builder", ["exports", "js-common/mixins/crud/error"], function (_exports, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_error.default, {
    getAnalyticsListDict: function getAnalyticsListDict(analyticsLists) {
      var dict = {};

      if (analyticsLists) {
        analyticsLists.forEach(function (analyticList) {
          if (dict[analyticList.categoryName]) {
            dict[analyticList.categoryName].push(analyticList);
          } else {
            dict[analyticList.categoryName] = [analyticList];
          }
        });
      }

      return dict;
    },
    buildObjectWithAnalyticOptions: function buildObjectWithAnalyticOptions(subject, analyticsLists) {
      return Ember.Object.create({
        statesOptions: this.buildStatesOptions(subject, analyticsLists.states),
        propertyOptions: analyticsLists.property,
        civilOptions: this.buildCivilOptions(subject, analyticsLists.civil_analysis),
        civilData: subject.subjectAnalyticsCivil,
        criminalData: subject.subjectAnalyticsCriminalCases,
        criminalOptions: this.buildCriminalOptions(subject, analyticsLists.criminal_analysis, analyticsLists.criminal_charges, analyticsLists.criminal_rights),
        trafficOptions: this.buildTrafficOptions(subject, analyticsLists.traffic_analysis),
        employmentOptions: this.buildEmploymentOptions(subject, analyticsLists.employment_status),
        educationOptions: this.buildEducationOptions(subject, analyticsLists.education_analysis),
        socnetOptions: this.buildSocnetOptions(subject, analyticsLists.socnet_other, analyticsLists.socnet_themes, analyticsLists.socnet_themes_hidden, analyticsLists.socnet_tones),
        possibleConnectionsOptions: this.buildPossibleConnectionsOptions(subject, analyticsLists.possible_connections_category),
        maritalStatusOptions: this.buildMaritalStatusOptions(subject, analyticsLists.marital_status),
        childrenOptions: this.buildChildrenOptions(subject, analyticsLists.has_children, analyticsLists.children),
        employmentTwoOptions: this.buildEmploymentTwoOptions(subject, analyticsLists.employment_type, analyticsLists.employment_length, analyticsLists.employment_analysis, analyticsLists.employment_analysis_category),
        productUseOptions: this.buildProductUseOptions(subject, analyticsLists.product_use, analyticsLists.product_use_tobacco, analyticsLists.addiction_withdrawal_experience, analyticsLists.juror_medical_issues),
        otherOptions: this.buildOtherOptions(subject, analyticsLists.political_views, analyticsLists.media_outlet),
        higherEducationOptions: this.buildHigherEducationOptions(subject, analyticsLists.higher_education, analyticsLists.higher_education_masters, analyticsLists.higher_education_post_grad),
        religionOptions: this.buildReligionOptions(subject, analyticsLists.religious_interests, analyticsLists.religious_affiliation, analyticsLists.religious_level_of_interest)
      });
    },
    getSubjectAndAnalyticsData: function getSubjectAndAnalyticsData(data, subject, editMode) {
      return Ember.Object.create({
        subject: subject,
        editMode: editMode,
        statesOptions: data.get('statesOptions'),
        propertyOptions: data.get('propertyOptions'),
        civilOptions: data.get('civilOptions'),
        civilData: subject.get('subjectAnalyticsCivil'),
        criminalData: subject.get('subjectAnalyticsCriminalCases'),
        criminalOptions: data.get('criminalOptions'),
        trafficOptions: data.get('trafficOptions'),
        employmentOptions: data.get('employmentOptions'),
        educationOptions: data.get('educationOptions'),
        socnetOptions: data.get('socnetOptions'),
        possibleConnectionsOptions: data.get('possibleConnectionsOptions'),
        maritalStatusOptions: data.get('maritalStatusOptions'),
        childrenOptions: data.get('childrenOptions'),
        employmentTwoOptions: data.get('employmentTwoOptions'),
        productUseOptions: data.get('productUseOptions'),
        otherOptions: data.get('otherOptions'),
        higherEducationOptions: data.get('higherEducationOptions'),
        religionOptions: data.get('religionOptions')
      });
    },
    buildOptionsList: function buildOptionsList(list, subject, field) {
      var fieldValue = subject.get(field);
      return list.map(function (c) {
        return Ember.Object.create({
          display: c.get('display'),
          value: c.get('value'),
          order: c.get('order'),
          shortName: c.get('shortName'),
          checked: fieldValue && fieldValue.includes(c.get('display'))
        });
      });
    },

    /**
     *
     * @param subject
     * @param fieldsData = list of objects like {key:.. , optionsList:.., field:..}
     * key = where we set the optionsList on the options objects
     * optionsList = what results from buildOptionsList()
     * field = subject analytics field
     */
    buildOptionsObjects: function buildOptionsObjects(subject, fieldsData) {
      var _this = this;

      var options = Ember.Object.create({});
      fieldsData.forEach(function (data) {
        options.set(data.key, _this.buildOptionsList(data.optionsList, subject, data.field));
      });
      return options;
    },
    buildStatesOptions: function buildStatesOptions(subject, states) {
      return this.buildOptionsList(states, subject, 'subjectAnalyticsStates');
    },
    buildCivilOptions: function buildCivilOptions(subject, civilList) {
      return civilList.rejectBy('shortName', 'no_civil_records').map(function (c) {
        return Ember.Object.create({
          label: c.get('display'),
          key: c.get('shortName')
        });
      });
    },
    buildCriminalOptions: function buildCriminalOptions(subject, criminalList, criminalChargesList, criminalRightsList) {
      return this.buildOptionsObjects(subject, [{
        key: 'criminalAnalysis',
        optionsList: criminalList,
        field: 'subjectAnalyticsCriminal'
      }, {
        key: 'criminalCharges',
        optionsList: criminalChargesList,
        field: 'subjectAnalyticsCriminalCharges'
      }, {
        key: 'criminalRights',
        optionsList: criminalRightsList,
        field: 'subjectAnalyticsCriminalRights'
      }]);
    },
    buildTrafficOptions: function buildTrafficOptions(subject, trafficList) {
      return this.buildOptionsList(trafficList, subject, 'subjectAnalyticsTraffic');
    },
    buildEmploymentOptions: function buildEmploymentOptions(subject, employmentStatusList) {
      var options = Ember.Object.create({});
      options.set('employmentStatus', this.buildOptionsList(employmentStatusList, subject, 'subjectAnalyticsEmploymentStatus'));
      return options;
    },
    buildEducationOptions: function buildEducationOptions(subject, educationList) {
      return this.buildOptionsList(educationList, subject, 'educationAnalysis');
    },
    buildSocnetOptions: function buildSocnetOptions(subject, socnetOtherList, socnetThemesList, socnetThemesHiddenList, socnetTonesList) {
      return this.buildOptionsObjects(subject, [{
        key: 'socnetOtherOptions',
        optionsList: socnetOtherList,
        field: 'subjectAnalyticsSocnetOther'
      }, {
        key: 'socnetThemesOptions',
        optionsList: socnetThemesList,
        field: 'subjectAnalyticsSocnetThemes'
      }, {
        key: 'socnetThemesHiddenOptions',
        optionsList: socnetThemesHiddenList,
        field: 'subjectAnalyticsSocnetThemesHidden'
      }, {
        key: 'socnetTonesOptions',
        optionsList: socnetTonesList,
        field: 'subjectAnalyticsSocnetTones'
      }]);
    },
    buildPossibleConnectionsOptions: function buildPossibleConnectionsOptions(subject, possibleConnectionsCategoriesList) {
      return this.buildOptionsObjects(subject, [{
        key: 'possibleConnectionsCategoryOptions',
        optionsList: possibleConnectionsCategoriesList,
        field: 'subjectAnalyticsPossibleConnectionsCategories'
      }]);
    },
    buildMaritalStatusOptions: function buildMaritalStatusOptions(subject, maritalStatusList) {
      return this.buildOptionsList(maritalStatusList, subject, 'maritalStatus');
    },
    buildChildrenOptions: function buildChildrenOptions(subject, childrenOptions, hasChildrenOptions) {
      return this.buildOptionsObjects(subject, [{
        key: 'childrenOptions',
        optionsList: childrenOptions,
        field: 'children'
      }, {
        key: 'hasChildrenOptions',
        optionsList: hasChildrenOptions,
        field: 'hasChildren'
      }]);
    },
    buildEmploymentTwoOptions: function buildEmploymentTwoOptions(subject, employmentTypeList, employmentLengthList, employmentAnalysisList, employmentAnalysisCategoryList) {
      var employmentType = subject.get('subjectAnalyticsEmploymentType');
      var employmentRole = subject.get('subjectAnalyticsEmploymentRole');
      var employmentOther = subject.get('subjectAnalyticsEmploymentOther');
      var employmentCurrentLast = subject.get('employmentCurrentLast');
      var employmentDetailsOptions = employmentTypeList.map(function (e) {
        var indexOfType = employmentType ? employmentType.indexOf(e.get('display')) : -1;
        return Ember.Object.create({
          display: e.get('display'),
          value: e.get('value'),
          order: e.get('order'),
          shortName: e.get('shortName'),
          checked: employmentType && employmentType.includes(e.get('display')),
          role: employmentRole && indexOfType > -1 ? employmentRole[indexOfType] : '',
          other: employmentOther && indexOfType > -1 ? employmentOther[indexOfType] : '',
          currentLast: e.get('display') === employmentCurrentLast
        });
      });
      var employmentLengthOptions = this.buildOptionsList(employmentLengthList.sortBy('order'), subject, 'employmentLengthCurrent');
      return {
        employmentDetailsOptions: employmentDetailsOptions,
        employmentLengthOptions: employmentLengthOptions,
        employmentAnalysis: this.buildOptionsList(employmentAnalysisList, subject, 'employmentAnalysis'),
        employmentAnalysisCategory: this.buildOptionsList(employmentAnalysisCategoryList, subject, 'subjectAnalyticsEmploymentAnalysisCategory')
      };
    },
    buildProductUseOptions: function buildProductUseOptions(subject, productUseList, productUseSecondariesList, addictionList, medicalList) {
      return this.buildOptionsObjects(subject, [{
        key: 'productUseOptions',
        optionsList: productUseList,
        field: 'subjectAnalyticsProductUse'
      }, {
        key: 'productUseSecondariesOptions',
        optionsList: productUseSecondariesList,
        field: 'subjectAnalyticsProductUseSecondaries'
      }, {
        key: 'addictionOptions',
        optionsList: addictionList,
        field: 'addictionWithdrawalExperience'
      }, {
        key: 'medicalOptions',
        optionsList: medicalList,
        field: 'subjectAnalyticsJurorMedicalIssues'
      }]);
    },
    buildOtherOptions: function buildOtherOptions(subject, politicalViewsList, mediaOutletList) {
      return this.buildOptionsObjects(subject, [{
        key: 'politicalViewsOptions',
        optionsList: politicalViewsList.sortBy('order'),
        field: 'politicalViews'
      }, {
        key: 'mediaOutletOptions',
        optionsList: mediaOutletList.sortBy('order'),
        field: 'subjectAnalyticsMediaOutlet'
      }]);
    },
    buildHigherEducationOptions: function buildHigherEducationOptions(subject, higherEducationList, higherEducationMastersList, higherEducationPostGradList) {
      return this.buildOptionsObjects(subject, [{
        key: 'higherEducationOptions',
        optionsList: higherEducationList,
        field: 'subjectAnalyticsHigherEducation'
      }, {
        key: 'higherEducationMastersOptions',
        optionsList: higherEducationMastersList,
        field: 'higherEducationMasters'
      }, {
        key: 'higherEducationPostGradOptions',
        optionsList: higherEducationPostGradList,
        field: 'higherEducationPostGrad'
      }]);
    },
    buildReligionOptions: function buildReligionOptions(subject, religiousInterestsList, religiousAffiliationsList, religiousLevelsOfInterestList) {
      return this.buildOptionsObjects(subject, [{
        key: 'religiousInterestsOptions',
        optionsList: religiousInterestsList.sortBy('order'),
        field: 'religiousInterests'
      }, {
        key: 'religiousAffiliationOptions',
        optionsList: religiousAffiliationsList.sortBy('order'),
        field: 'subjectAnalyticsReligiousAffiliation'
      }, {
        key: 'religiousLevelOfInterestOptions',
        optionsList: religiousLevelsOfInterestList.sortBy('order'),
        field: 'subjectAnalyticsReligiousLevelOfInterest'
      }]);
    }
  });

  _exports.default = _default;
});