define("@smith-carson/ui/pods/components/light-table/cells/select-row/component", ["exports", "ember-light-table/components/cells/base", "@smith-carson/ui/pods/components/light-table/cells/select-row/template"], function (_exports, _base, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides affordance (checkbox) for selecting rows in an `ember-light-table`.
  
      Usage:
      ```
          {
              cellType: 'select-row',
              extra: { rowIdPath: 'tmCode' },
              width: '2.5rem',
          }
      ```
  
      @module LightTable::Cells::SelectRowComponent
      @extends EmberLightTable.CellsBaseComponent
   */
  var _default = _base.default.extend({
    classNames: ['select-row-cell'],
    isSelected: Ember.computed.alias('row.selected'),
    layout: _template.default,

    /**
        Provides a known `id` value for the checkbox for reference by the
        (visually hidden) `label` element's `for` attribute.
         @property ckbId
        @type {String}
     */
    ckbId: Ember.computed('elementId', function () {
      return "ckb-".concat(this.elementId);
    }),

    /**
     * Stop propagation of `click` event on entire cell.
     *
     * @method click
     * @param {jQuery.Event} event
     */
    click: function click(event) {
      event.stopPropagation();
    },
    actions: {
      /**
          Toggles the `selected` property on `row` and `row.content` and
          passes the event to the invoking context.
           @method toggleSelected
       */
      toggleSelected: function toggleSelected(event) {
        var row = this.get('row');
        var isRowSelected = row.get('selected');
        this.table.onRowSelect(event, row); // Return `false` if checkbox state is not changing (prevent checkbox state change)

        return row.get('selected') !== isRowSelected;
      }
    }
  });

  _exports.default = _default;
});