define("@smith-carson/ui/templates/components/sc-light-table/cells/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8+zmY1bm",
    "block": "{\"symbols\":[],\"statements\":[[5,\"sc-checkbox\",[],[[\"@checked\",\"@onChange\"],[[24,0,[\"isSelected\"]],[29,\"action\",[[24,0,[]],[29,\"optional\",[[25,[\"tableActions\",\"selectAction\"]]],null],[25,[\"row\"]]],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-light-table/cells/select.hbs"
    }
  });

  _exports.default = _default;
});