define("js-common/templates/components/edit-field/date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B4jGFmJo",
    "block": "{\"symbols\":[\"@disabled\",\"&attrs\"],\"statements\":[[5,\"sc-date-picker\",[[13,\"disabled\",[24,1,[]]],[13,\"class\",\"form-control\"],[14,2]],[[\"@value\",\"@onSelection\",\"@format\"],[[29,\"get-date\",[[25,[\"value\"]]],null],[29,\"action\",[[24,0,[]],\"dateChanged\"],null],\"MM-DD-YYYY\"]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/edit-field/date.hbs"
    }
  });

  _exports.default = _default;
});