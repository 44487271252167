define("@smith-carson/ui/pods/components/sc-light-table/cells/case-next-due/component", ["exports", "moment", "@smith-carson/ui/pods/components/sc-light-table/cells/case-next-due/template"], function (_exports, _moment, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides a custom light-table cell for rendering the Case _Next Due_ column
      content.
  
      @module ScLightTable::Cells::CaseNextDueComponent
      @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    /**
        Calculates the number of days the dude date has been pushed back based
        on current next due date (`calcNextDueDate`) and initial due date
        (`calcInitialDueOn`).
         @property daysPushedBack
        @type {Number}
     */
    daysPushedBack: Ember.computed('row.{calcInitialDueOn,calcNextDueDate}', function () {
      var row = this.row;

      var _row$getProperties = row.getProperties('calcInitialDueOn', 'calcNextDueDate'),
          calcInitialDueOn = _row$getProperties.calcInitialDueOn,
          calcNextDueDate = _row$getProperties.calcNextDueDate;

      var initialDueDate = new Date(calcInitialDueOn);
      var nextDueDate = new Date(calcNextDueDate);
      var daysPushedBack = Math.round((nextDueDate - initialDueDate) / (1000 * 60 * 60 * 24)); // Early return if `calcInitialDueOn` is `null` or `daysPushedBack` `isNaN`

      if (!calcInitialDueOn || Number.isNaN(daysPushedBack)) {
        return 0;
      }

      return daysPushedBack;
    }),

    /**
        Ember computed alias for row's `calcHardDeadline` value.
         @property isHardDeadline
        @type {Boolean}
     */
    isHardDeadline: Ember.computed.alias('row.calcHardDeadline'),

    /**
        Provides boolean flag for determining the need to render the "pushed
        back" indicator.
         @property isPushedBack
        @type {Boolean}
     */
    isPushedBack: Ember.computed.gt('daysPushedBack', 60),

    /**
        Provides the icon for the "pushed back" indicator based on the number of
        days the due date has been pushed back.
         @property pushedBackIcon
        @type {String}
     */
    pushedBackIcon: Ember.computed('daysPushedBack', 'isPushedBack', function () {
      var daysPushedBack = this.daysPushedBack;

      if (daysPushedBack < 60) {
        return null;
      }

      return daysPushedBack > 90 ? 'exclamation-circle' : 'exclamation-triangle';
    }),

    /**
        Provides the bootstrap text color utility class for the "pushed back"
        indicator icon based on the number of days the due date has been
        pushed back.
         @property pushedBackTextClass
        @type {String}
     */
    pushedBackTextClass: Ember.computed('daysPushedBack', 'isPushedBack', function () {
      var daysPushedBack = this.daysPushedBack;

      if (daysPushedBack < 60) {
        return null;
      }

      return daysPushedBack > 90 ? 'text-danger' : 'text-warning';
    }),

    /**
        Provides the tooltip text for the "pushed back" indicator.
         @property pushedBackTooltip
        @type {String}
     */
    pushedBackTooltip: Ember.computed('daysPushedBack', 'isPushedBack', 'row.calcInitialDueOn', function () {
      var daysPushedBack = this.daysPushedBack,
          row = this.row;

      var _row$getProperties2 = row.getProperties('calcInitialDueOn'),
          calcInitialDueOn = _row$getProperties2.calcInitialDueOn;

      if (daysPushedBack < 60) {
        return null;
      }

      return Ember.String.htmlSafe("+".concat(daysPushedBack, " days<br>Initially due on ").concat((0, _moment.default)(calcInitialDueOn).format('MM/DD/YYYY')));
    }),
    isPastDue: Ember.computed('row.calcNextDueDate', function () {
      var row = this.row;

      var _row$getProperties3 = row.getProperties('calcNextDueDate'),
          calcNextDueDate = _row$getProperties3.calcNextDueDate;

      return (0, _moment.default)(calcNextDueDate).isBefore((0, _moment.default)(), 'day');
    }),
    actions: {
      /**
          Sets `showDueDatesModal` flag to true and stops propagation of
          DOM event.
           @method viewDueDates
          @param {PointerEvent} event
       */
      viewDueDates: function viewDueDates(event) {
        event.stopPropagation();
        this.set('showDueDatesModal', true);
      }
    }
  });

  _exports.default = _default;
});