define("@smith-carson/ui/templates/components/sc-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "brmUexDv",
    "block": "{\"symbols\":[\"@label\",\"&default\",\"&attrs\"],\"statements\":[[8,\"span\"],[13,\"class\",[30,[\"sc-badge \",[24,0,[\"colorClass\"]],\" \",[24,0,[\"sizeClass\"]],\" \",[24,0,[\"shapeClass\"]]]]],[14,3],[9],[0,\"\\n\"],[4,\"if\",[[26,2]],null,{\"statements\":[[0,\"    \"],[15,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[24,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-badge.hbs"
    }
  });

  _exports.default = _default;
});