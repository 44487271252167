define("@smith-carson/ui/pods/components/light-table/cells/draggable-row/component", ["exports", "ember-light-table/components/cells/base", "@smith-carson/ui/pods/components/light-table/cells/draggable-row/template"], function (_exports, _base, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Helper function that determines if the row can be moved
  function canMoveRow(isFirst, isLast, key) {
    return key === 'ArrowUp' && !isFirst || key === 'ArrowDown' && !isLast;
  }
  /**
      Provides a custom `ember-light-table` cell type that renders a drag handle
      affordance that can be used to reorder rows.
  
      Note: This column is automatically injected as the last column when the
      `canReorder` argument passed to the `ScLightTableComponent` is `true`.
  
      @module LightTable::Cells::DraggableRowComponent
      @extends EmberLightTable.CellsBaseComponent
   */


  var _default = _base.default.extend({
    classNames: ['draggable-row-cell'],
    layout: _template.default,

    /**
        Provides a boolean flag indicating if the current row is the first row.
         @property isFirst
        @type {Boolean}
     */
    isFirst: Ember.computed('row', 'table.rows.[]', function () {
      var _this$getProperties = this.getProperties('row', 'table'),
          row = _this$getProperties.row,
          table = _this$getProperties.table;

      return table.rows.firstObject === row;
    }),

    /**
        Provides a boolean flag indicating if the current row is the last row.
         @property isLast
        @type {Boolean}
     */
    isLast: Ember.computed('row', 'table.rows.[]', function () {
      var _this$getProperties2 = this.getProperties('row', 'table'),
          row = _this$getProperties2.row,
          table = _this$getProperties2.table;

      return table.rows.lastObject === row;
    }),
    actions: {
      /**
          Handles the `keydown` event on the drag handle button element by
          calculating the new row index (if arrow key is up or down) and calls
          the `table.moveRow` method.
           @method onKeyDown
          @param {Event} event
       */
      onKeyDown: function onKeyDown(event) {
        var key = event.key;
        var button = this.element.querySelector('button');
        var rowElement = this.element.parentElement;

        var _this$getProperties3 = this.getProperties('isFirst', 'isLast', 'row', 'table'),
            isFirst = _this$getProperties3.isFirst,
            isLast = _this$getProperties3.isLast,
            row = _this$getProperties3.row,
            table = _this$getProperties3.table; // Early return for noop key/index values


        if (!canMoveRow(isFirst, isLast, key)) {
          return;
        }

        event.preventDefault();
        var currentIndex = table.rows.indexOf(row);
        var newIndex = key === 'ArrowDown' ? currentIndex + 1 : currentIndex - 1;
        table.moveRow(row, rowElement, currentIndex, newIndex);
        Ember.run.next(function () {
          button.focus();
        });
      },

      /**
          Handles the `mousedown` event on the drag handle button element by
          adding the `draggable` attribute to the relevant row `tr`.
           @method onMouseDown
          @param {Event} event
       */
      onMouseDown: function onMouseDown(event) {
        var row = event.target.closest('tr.draggable');

        if (row) {
          row.setAttribute('draggable', true);
        }
      }
    }
  });

  _exports.default = _default;
});