define("js-common/mixins/profile-analytics-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currentMatter: Ember.inject.service(),
    componentName: Ember.computed('field.display', function () {
      var baseDir = this.get('currentMatter.matter.isJuryCase') ? 'subjects/profile/modules/jury' : 'subjects/profile/modules/non-jury';
      var field = this.get('field.value');

      if (this.get('currentMatter.matter.isJuryCase')) {
        return this.getJuryModule(baseDir, field);
      } else {
        return this.getNonJuryModule(baseDir, field);
      }
    }),
    getJuryModule: function getJuryModule(baseDir, field) {
      var componentName = '';

      switch (field) {
        case 'affiliation':
          componentName = "".concat(baseDir, "/voter-module");
          break;

        case 'age':
        case 'age_notes':
          componentName = "".concat(baseDir, "/age-module");
          break;

        case 'case_related_analytics':
          componentName = "".concat(baseDir, "/case-related-module");
          break;

        case 'children_analytics':
          componentName = "".concat(baseDir, "/children-module");
          break;

        case 'civil':
        case 'civil_analytics':
          componentName = "".concat(baseDir, "/civil-module");
          break;

        case 'criminal':
        case 'traffic_analytics':
        case 'criminal_analytics':
          componentName = "".concat(baseDir, "/criminal-module");
          break;

        case 'criminal_cases':
          componentName = "".concat(baseDir, "/criminal-cases-module");
          break;

        case 'education_analytics':
          componentName = "".concat(baseDir, "/education-module");
          break;

        case 'employment_analytics':
          componentName = "".concat(baseDir, "/employment-module");
          break;

        case 'employment_status_analytics':
          componentName = "".concat(baseDir, "/employment-status-module");
          break;

        case 'gender':
        case 'gender_notes':
          componentName = "".concat(baseDir, "/gender-module");
          break;

        case 'marital_analytics':
          componentName = "".concat(baseDir, "/marital-module");
          break;

        case 'national_origin':
        case 'national_origin_notes':
          componentName = "".concat(baseDir, "/national-origin-module");
          break;

        case 'race':
        case 'race_notes':
          componentName = "".concat(baseDir, "/race-module");
          break;

        case 'counties':
        case 'states_analytics':
          componentName = "".concat(baseDir, "/states-module");
          break;

        case 'social_media_analytics':
          componentName = "".concat(baseDir, "/social-media-module");
          break;

        case 'years_in_county_analytics':
          componentName = "".concat(baseDir, "/years-in-county-module");
          break;

        case 'current_city':
          componentName = "".concat(baseDir, "/current-city-module");
          break;

        case 'property':
          componentName = "".concat(baseDir, "/property-module");
          break;

        default:
          componentName = "".concat(baseDir, "/default-module");
          break;
      }

      return componentName;
    },
    getNonJuryModule: function getNonJuryModule(baseDir, field) {
      var componentName = '';

      switch (field) {
        case 'bankruptcy':
        case 'dob':
        case 'civil':
        case 'counties':
        case 'criminal':
        case 'death':
        case 'education':
        case 'employment':
        case 'family':
        case 'marital':
        case 'medical':
        case 'relationship':
        case 'residential':
        case 'social_media':
        case 'ssan':
        case 'traffic':
          componentName = "".concat(baseDir, "/default-with-notes-module");
          break;

        default:
          componentName = "".concat(baseDir, "/default-module");
          break;
      }

      return componentName;
    }
  });

  _exports.default = _default;
});