define("js-common/helpers/subject-field-content-is-null", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "js-common/mixins/analytics-util"], function (_exports, _slicedToArray2, _analyticsUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Check if a subject field has any values in the following way:
   * regular fields: checks if content is empty
   * analytic fields: check if all of the field contents that are displayed (widget-field-read) are empty
   *
   * matterField = the main (parent / single) subject field we want to check
   * childMatterFields = (optional) a list of other subject fields to be checked alongside the main (parent) one
   *
   */
  var _default = Ember.Helper.extend(_analyticsUtil.default, {
    displayedFields: {
      case_related_analytics: ['possibleConnectionsCategory'],
      children_analytics: ['children'],
      civil_analytics: ['civilAnalysis', 'civilPartytype'],
      criminal_cases: ['criminalCases', 'criminalCharge', 'criminalTotal', 'criminalRights'],
      criminal_analytics: ['criminalAnalysis', 'criminalCharge', 'criminalTotal', 'criminalRights'],
      current_city: ['currentCity'],
      education_analytics: ['educationAnalysis'],
      employment_analytics: ['employmentStatus', 'isGovSector', 'isMilitaryVet', 'occupations', 'subjectAnalyticsEmploymentAnalysisCategory'],
      employment_status_analytics: ['employmentStatus'],
      marital_analytics: ['maritalStatus'],
      primary_property_value: ['primaryPropertyValue'],
      property: ['property'],
      social_media_analytics: ['socnetOther', 'socnetThemes', 'socnetThemesOther', 'socnetTones', 'socnetTonesOther'],
      states_analytics: ['states'],
      traffic_analytics: ['subjectAnalyticsTraffic'],
      years_in_county_analytics: ['yearsInCounty']
    },
    isSubjectFieldContentNull: function isSubjectFieldContentNull(subject, matterField) {
      var key = Ember.String.camelize(matterField.get('value'));
      var content = subject.get(key);
      var displayedFields = this.displayedFields;

      switch (matterField.get('fieldClass')) {
        case 'widget':
          var fields = displayedFields[matterField.get('value')];

          if (!fields) {
            Ember.Logger.debug('Incorrect subject field name');
            return true;
          }

          var isNull = true;
          fields.forEach(function (field) {
            if (subject.get(field) && subject.get(field) !== '{}') {
              isNull = false;
            }
          });

          if (isNull && matterField.get('value') === 'social_media_analytics' && (this.calculateAllLinkOfTypeNone(subject.get('hyperlinks')) || this.calculateProfileCount(subject.get('hyperlinks')) > 0)) {
            return false;
          }

          return isNull;

        case 'subject-custom':
          return false;

        default:
          return Ember.isEmpty(content);
      }
    },
    compute: function compute(_ref) {
      var _this = this;

      var _ref2 = (0, _slicedToArray2.default)(_ref, 3),
          subject = _ref2[0],
          matterField = _ref2[1],
          childMatterFields = _ref2[2];

      var parentIsNull = this.isSubjectFieldContentNull(subject, matterField);
      var childrenAreNull = false;
      var allChildrenAreNull = false;

      if (childMatterFields && childMatterFields.length) {
        childMatterFields.forEach(function (field) {
          allChildrenAreNull = allChildrenAreNull || _this.isSubjectFieldContentNull(subject, field);
        });
        childrenAreNull = allChildrenAreNull;
      } else {
        childrenAreNull = true;
      }

      return parentIsNull && childrenAreNull;
    }
  });

  _exports.default = _default;
});