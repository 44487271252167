define("js-common/templates/components/analytic-widget/age", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wwaxqW/d",
    "block": "{\"symbols\":[\"@stats\",\"@canEdit\",\"@toggleViewable\"],\"statements\":[[5,\"sc-analytic-card\",[],[[\"@isEnabled\",\"@canEdit\",\"@onToggle\",\"@title\",\"@chartHeight\"],[[24,1,[\"age_comparison_by_generation\",\"matterHasStat\",\"isEnabled\"]],[24,2,[]],[29,\"action\",[[24,0,[]],[24,3,[]],[24,1,[\"age_comparison_by_generation\",\"matterHasStat\"]]],null],\"Age\",\"190\"]],{\"statements\":[[0,\"\\n  \"],[5,\"sc-chart\",[],[[\"@chartData\",\"@chartType\",\"@extendFullHeight\"],[[24,0,[\"ageData\"]],\"vertical-bar-chart\",true]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/analytic-widget/age.hbs"
    }
  });

  _exports.default = _default;
});