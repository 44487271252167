define('ember-cli-google-recaptcha/components/g-recaptcha-invisible', ['exports', 'ember-cli-google-recaptcha/-private/g-recaptcha-base'], function (exports, _gRecaptchaBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _gRecaptchaBase.default.extend({
    classNames: ['g-recaptcha--invisible'],

    badge: 'bottomright',

    type: 'image',

    size: 'invisible',

    _extendedOptions: Ember.computed('badge', function () {
      var badge = this.get('badge');
      return { badge: badge };
    }),

    didRenderCaptcha: function didRenderCaptcha() {
      // Handle reset the recaptcha.
      var execute = this.get('execute');

      if (execute) {
        this._execute();
      }
    },
    didUpdate: function didUpdate() {
      this._super.apply(this, arguments);

      var _getProperties = this.getProperties(['reset', 'execute']),
          reset = _getProperties.reset,
          execute = _getProperties.execute;

      var promises = [];

      if (reset) {
        promises.push(this._reset());
      }

      if (execute) {
        promises.push(this._execute());
      }

      return Ember.RSVP.Promise.all(promises);
    }
  });
});