define("js-common/helpers/get-appl-list", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
          applList = _ref2[0],
          value = _ref2[1];

      if (Ember.isArray(value)) {
        return value.map(function (val) {
          return applList.findBy('value', val);
        });
      } else {
        return applList.findBy('value', value);
      }
    }
  });

  _exports.default = _default;
});