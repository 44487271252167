define("js-common/helpers/do-nothing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute() {
      return function () {};
    }
  });

  _exports.default = _default;
});