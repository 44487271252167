define("ember-route-helpers/utils/mount-point", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prefixMountPoint = prefixMountPoint;
  _exports.getMountPoint = getMountPoint;

  /**
   * Prefix a property (usually a routeName) with the mount point, if is an engine.
   *
   * @param  {String} mountPoint
   * @param  {String} propValue
   * @return {String}
   */
  function prefixMountPoint(mountPoint, propValue) {
    if (Ember.typeOf(propValue) !== 'string') {
      (false && !(Ember.typeOf(propValue) !== 'string') && Ember.assert('propValue argument must be an string', Ember.typeOf(propValue) !== 'string'));
    }

    if (Ember.typeOf(mountPoint) !== 'string' || Ember.isBlank(mountPoint)) {
      return propValue;
    }

    if (propValue === 'application') {
      return mountPoint;
    }

    return "".concat(mountPoint, ".").concat(propValue);
  }
  /**
   * Check if an element is into an engine and returns its mountPoint.
   *
   * @method getMountPoint
   * @param {Object} owner
   * @return {string|Boolean}. Mount point if is engine; false otherwise.
   */


  function getMountPoint(owner) {
    if (owner && typeof owner.mountPoint === 'string') {
      return owner.mountPoint;
    }

    return false;
  }
});