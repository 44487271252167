define("js-common/components/pdf-js/toolbar", ["exports", "ember-pdf-js/components/pdf-js-toolbar", "js-common/templates/components/pdf-js/toolbar"], function (_exports, _pdfJsToolbar, _toolbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pdfJsToolbar.default.extend({
    layout: _toolbar.default,
    classNames: ['position-fixed', 'pdf-js__toolbar'],
    highlightAll: true
  });

  _exports.default = _default;
});