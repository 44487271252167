define("@smith-carson/ui/templates/components/sc-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NbTzfkxi",
    "block": "{\"symbols\":[\"@size\",\"&default\"],\"statements\":[[4,\"if\",[[27,2]],null,{\"statements\":[[0,\"  \"],[15,2,[[29,\"hash\",null,[[\"header\",\"body\",\"footer\"],[[29,\"component\",[[24,0,[\"headComponent\"]]],[[\"size\"],[[24,1,[]]]]],[29,\"component\",[[24,0,[\"bodyComponent\"]]],[[\"size\"],[[24,1,[]]]]],[29,\"component\",[[24,0,[\"footComponent\"]]],[[\"size\"],[[24,1,[]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,[24,0,[\"bodyComponent\"]],[],[[\"@size\"],[[24,1,[]]]],{\"statements\":[[0,\"\\n    \"],[15,2],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-card.hbs"
    }
  });

  _exports.default = _default;
});