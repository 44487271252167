define("@smith-carson/ui/templates/components/sc-light-table/columns/action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mt3kkDOx",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"tableActions\",\"allowQuickSearch\"]]],null,{\"statements\":[[0,\"  \"],[5,\"sc-icon-button\",[],[[\"@onClick\",\"@icon\",\"@color\",\"@size\"],[[29,\"action\",[[24,0,[]],[25,[\"tableActions\",\"toggleFilters\"]]],null],[24,0,[\"actionIcon\"]],\"primary\",\"lg\"]],{\"statements\":[[0,\"\\n    \"],[5,\"sc-icon-button\",[],[[\"@onClick\",\"@icon\",\"@color\",\"@size\"],[[29,\"action\",[[24,0,[]],[25,[\"tableActions\",\"toggleFilters\"]]],null],[29,\"if\",[[25,[\"tableActions\",\"showFilters\"]],\"expand\",\"collapse\"],null],\"primary\",\"sm\"]]],[0,\"\\n\"],[4,\"if\",[[25,[\"tableActions\",\"searchHoverText\"]]],null,{\"statements\":[[0,\"      \"],[5,\"sc-popover\",[],[[\"@triggerEvents\"],[\"hover\"]],{\"statements\":[[0,\"\\n        \"],[1,[25,[\"tableActions\",\"searchHoverText\"]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-light-table/columns/action.hbs"
    }
  });

  _exports.default = _default;
});