define("js-common/models/firm-has-matter", ["exports", "ember-data", "ember-api-actions"], function (_exports, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    modelName: 'firm-has-matter',
    // before we were using constructor.modelName but is no longer reliable
    // define me so they are available in a model:instance
    primary: _emberData.default.attr('boolean'),
    defendant: _emberData.default.attr('boolean'),
    councilType: _emberData.default.attr('string'),
    relationshipToMatter: _emberData.default.attr('string'),
    firmMatterNumber: _emberData.default.attr('string'),
    // relationships
    firm: _emberData.default.belongsTo('firm', {
      async: true
    }),
    matter: _emberData.default.belongsTo('matter', {
      async: true
    }),
    firmHasMatterHasTaxes: _emberData.default.hasMany('firm-has-matter-has-tax', {
      async: true
    }),
    invoiceCount: (0, _emberApiActions.memberAction)({
      type: 'GET',
      path: 'invoice_count'
    })
  });

  _exports.default = _default;
});