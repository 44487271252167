define("js-common/models/collection-column", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  var ASSIGNEE = 1;
  var STATUS = 2;
  var COMMENT = 4;

  var _default = Model.extend({
    lists: Ember.inject.service(),
    label: attr('string'),
    fieldName: attr('string'),
    apiName: attr('string'),
    weight: attr('number'),
    columnWidth: attr('number'),
    percentage: attr('number'),
    viewRenderType: attr('string'),
    editRenderType: attr('string'),
    editRenderList: attr('string'),
    dashboardViewable: attr('number'),
    workflowEnabled: attr('number'),
    subtractDays: attr('number'),
    collection: belongsTo('collection'),
    workflow: belongsTo('workflow'),
    valuePath: Ember.computed.alias('fieldName'),
    workflowAssigneeEnabled: Ember.computed('workflowEnabled', {
      // eslint-disable-next-line no-unused-vars
      get: function get(key) {
        // eslint-disable-next-line no-bitwise
        return this.workflowEnabled & ASSIGNEE;
      },
      // eslint-disable-next-line no-unused-vars
      set: function set(key, value) {
        var enabled = this.workflowEnabled;

        if (value) {
          // eslint-disable-next-line no-bitwise
          enabled |= ASSIGNEE;
        } else {
          // eslint-disable-next-line no-bitwise
          enabled &= ~ASSIGNEE;
        }

        this.set('workflowEnabled', enabled);
        return value;
      }
    }),
    workflowStatusEnabled: Ember.computed('workflowEnabled', {
      // eslint-disable-next-line no-unused-vars
      get: function get(key) {
        // eslint-disable-next-line no-bitwise
        return this.workflowEnabled & STATUS;
      },
      // eslint-disable-next-line no-unused-vars
      set: function set(key, value) {
        var enabled = this.workflowEnabled;

        if (value) {
          // eslint-disable-next-line no-bitwise
          enabled |= STATUS;
        } else {
          // eslint-disable-next-line no-bitwise
          enabled &= ~STATUS;
        }

        this.set('workflowEnabled', enabled);
        return value;
      }
    }),
    workflowCommentEnabled: Ember.computed('workflowEnabled', {
      // eslint-disable-next-line no-unused-vars
      get: function get(key) {
        // eslint-disable-next-line no-bitwise
        return this.workflowEnabled & COMMENT;
      },
      // eslint-disable-next-line no-unused-vars
      set: function set(key, value) {
        var enabled = this.workflowEnabled;

        if (value) {
          // eslint-disable-next-line no-bitwise
          enabled |= COMMENT;
        } else {
          // eslint-disable-next-line no-bitwise
          enabled &= ~COMMENT;
        }

        this.set('workflowEnabled', enabled);
        return value;
      }
    }),
    isLabelEditable: Ember.computed('field_name', function () {
      return this.field_name.indexOf('custom') >= 0;
    }),

    /**
     * END BOOLEAN CALCS
     */

    /** this property gets the list from the lists service with the right listName */
    options: Ember.computed('editRenderList', 'editRenderType', function () {
      if (this.editRenderType === 'enum' || this.editRenderType === 'radio' || this.editRenderType === 'checkboxes') {
        return this.lists.getListForGroup(this.editRenderList);
      }

      return [];
    })
  });

  _exports.default = _default;
});