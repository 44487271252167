define("@smith-carson/ui/pods/components/light-table/cells/link-button/component", ["exports", "ember-light-table/components/cells/base", "@smith-carson/ui/pods/components/light-table/cells/link-button/template"], function (_exports, _base, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides a custom `LightTable` cell type for rendering a value as a
      link-button. Supports primitives, objects and arrays of objects.
  
      The button label will be derived from either the `format` function, the
      `labelPath` that is passed via `extra` (resolves to `valuePath.labelPath`),
      or the default `valuePath` reference on the `row`.
  
      Usage:
      ```
          {
              cellType: 'link-button',
              extra: {
                  actionName: 'filterByOwner', // this action must be passed in the `tableActions`
                  buttonTitle: 'Filter cases by this owner', // (optional) title/tooltip for link-button
                  labelPath: 'propertyName', // (optional) relative to the `valuePath` reference
              },
  
              // (optional) if no `labelPath` then there must be a `format` function (note that `row` will be passed in)
              format(row) {
                  const person = row.get('owners').firstObject;
                  const { firstName, lastName } = person.getProperties('firstName', 'lastName');
  
                  return `${firstName.charAt(0)}. ${lastName}`;
              },
              label: 'Owner',
              valuePath: 'owners',
          },
      ```
  
      @module LightTable::Cells::LinkButtonComponent
      @extends EmberLightTable.CellsBaseComponent
   */
  var _default = _base.default.extend({
    classNames: ['link-btn-cell'],
    layout: _template.default,
    linkData: null,
    init: function init() {
      this._super.apply(this, arguments);

      var _this$getProperties = this.getProperties('column', 'row'),
          column = _this$getProperties.column,
          row = _this$getProperties.row;

      var _column$getProperties = column.getProperties('extra', 'format', 'valuePath'),
          extra = _column$getProperties.extra,
          valuePath = _column$getProperties.valuePath;

      var actionName = extra.actionName;
      (false && !(valuePath) && Ember.assert('You must provide a `valuePath` to the `LinkButtonComponent`', valuePath));
      (false && !(actionName && typeof this.tableActions[actionName] === 'function') && Ember.assert('You must provide an `actionName` and associated `tableAction` method to the `LinkButtonComponent`', actionName && typeof this.tableActions[actionName] === 'function'));
      var rowValue = row.get(valuePath); // Supports arrays so ensure we are working with an array

      this.set('linkData', Array.isArray(rowValue) ? rowValue : [rowValue]);
    },

    /**
        Provides a collection of `linkButton` objects mapped from the link
        button data items.
         @property linkButtons
     */
    linkButtons: Ember.computed('linkData', function () {
      var _this = this;

      var linkData = this.get('linkData');
      return linkData.map(function (linkDatum) {
        return Ember.Object.create({
          item: linkDatum,
          label: _this.getButtonLabel(linkDatum)
        });
      });
    }),

    /**
        Provides the text label for the the `button` based on `extra.labelPath`,
        `format` function (if defined), or raw item value.
         @method getButtonLabel
        @param {*} buttonItem
        @returns {String}
     */
    getButtonLabel: function getButtonLabel(buttonItem) {
      var column = this.get('column');

      var _column$getProperties2 = column.getProperties('extra', 'format'),
          extra = _column$getProperties2.extra,
          format = _column$getProperties2.format;

      var labelPath = extra.labelPath; // If label comes for `format` function...

      if (typeof format === 'function') {
        return format(buttonItem);
      } // ...or is label comes from property on an object...


      if (labelPath) {
        return buttonItem.get(labelPath);
      } // ...or default to raw value for the cell.


      return buttonItem;
    },

    /**
     * Stop propagation of `click` event on entire cell.
     *
     * @method click
     * @param {jQuery.Event} event
     */
    click: function click(event) {
      event.stopPropagation();
    },
    actions: {
      /**
              Handles the link-button `click` by looking up and invoking the
              associated action passed via `tableActions`.
               @method onClick
              @param {*} item The primitive value or object associated with the link
           */
      onClick: function onClick(item) {
        var _this$getProperties2 = this.getProperties('column', 'row'),
            column = _this$getProperties2.column,
            row = _this$getProperties2.row;

        var actionName = column.get('extra.actionName');
        this.tableActions[actionName](column, row, item);
      }
    }
  });

  _exports.default = _default;
});