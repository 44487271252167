define("js-common/models/invoice-output", ["exports", "ember-data", "ember-api-actions"], function (_exports, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    billSplit: attr('number'),
    format: attr('string'),
    createdOn: attr('estdate'),
    qbInvoiceId: attr('number'),
    amount: attr('number'),
    lastPaidOn: attr('estdate'),
    amountPaid: attr('number'),
    // relationships
    invoice: belongsTo('invoice', {
      async: false
    }),
    firmHasMatter: belongsTo('firm-has-matter', {
      async: false
    }),
    invoiceSubs: hasMany('invoice-sub', {
      async: false
    }),
    invoiceOutputPayments: hasMany('invoice-output-payment', {
      async: false
    }),
    updatePayment: (0, _emberApiActions.memberAction)({
      type: 'PUT',
      path: 'update_payment'
    })
  });

  _exports.default = _default;
});