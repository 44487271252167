define("@smith-carson/ui/templates/components/sc-table/pager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ikInbs7Q",
    "block": "{\"symbols\":[\"page\",\"@current\"],\"statements\":[[7,\"ul\"],[12,\"class\",[30,[\"pagination \",[23,\"paginationSizeClass\"]]]],[9],[0,\"\\n\\n  \"],[7,\"li\"],[12,\"class\",[30,[\"page-item previous \",[29,\"if\",[[25,[\"isFirstDisabled\"]],\"disabled\"],null]]]],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"class\",\"page-link\"],[12,\"href\",[30,[[23,\"previousUrl\"]]]],[11,\"title\",\"Previous\"],[9],[0,\"\\n      \"],[5,\"sc-icon\",[],[[\"@icon\",\"@iconSet\",\"@size\",\"@fixedWidth\"],[\"chevron-left\",\"fa\",\"sm\",true]]],[0,\"\\n    \"],[3,\"action\",[[24,0,[]],\"previous\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"each\",[[25,[\"pages\"]]],[[\"key\"],[\"@index\"]],{\"statements\":[[0,\"    \"],[1,[29,\"component\",[[25,[\"itemComponent\"]]],[[\"disabled\",\"page\",\"selected\",\"seperator\",\"urlTemplate\",\"firstPage\",\"firstPageUrlTemplate\",\"pageSet\"],[[25,[\"disabled\"]],[24,1,[]],[24,2,[]],[25,[\"seperator\"]],[25,[\"urlTemplate\"]],[25,[\"firstPage\"]],[25,[\"firstPageUrlTemplate\"]],[29,\"action\",[[24,0,[]],\"pageChanged\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n  \"],[7,\"li\"],[12,\"class\",[30,[\"page-item next \",[29,\"if\",[[25,[\"isLastDisabled\"]],\"disabled\"],null]]]],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"class\",\"page-link\"],[12,\"href\",[30,[[23,\"nextUrl\"]]]],[11,\"title\",\"Next\"],[9],[0,\"\\n      \"],[5,\"sc-icon\",[],[[\"@icon\",\"@iconSet\",\"@size\",\"@fixedWidth\"],[\"chevron-right\",\"fa\",\"sm\",true]]],[0,\"\\n    \"],[3,\"action\",[[24,0,[]],\"next\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-table/pager.hbs"
    }
  });

  _exports.default = _default;
});