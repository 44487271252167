define("js-common/mixins/open-route-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // OpenRouteMixin is a tagging mixin that adds no behaviour itself.
  // Apply it to routes that you want publicly viewable to all users,
  // both authenticated and non-authenticated.
  //
  // SecureDefaultRouteFactory will alter its behaviour when
  // it detects a route that is tagged with OpenRouteMixin.
  var _default = Ember.Mixin.create({});

  _exports.default = _default;
});