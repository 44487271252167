define("js-common/models/document-download", ["exports", "ember-data", "ember-api-actions"], function (_exports, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    code: attr('string'),
    note: attr('string'),
    url: attr('string'),
    matterId: attr('number'),
    requiresPassword: attr('boolean'),
    expiresOn: attr('estdate'),
    createdOn: attr('estdate'),
    updatedOn: attr('estdate'),
    user: belongsTo('user'),
    resource: belongsTo('resource'),
    expire: (0, _emberApiActions.memberAction)({
      path: 'expire',
      type: 'put'
    })
  });

  _exports.default = _default;
});