define("@smith-carson/ui/templates/components/sc-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MoDUgS2s",
    "block": "{\"symbols\":[\"CheckBoxInput\",\"@label\",\"&default\",\"@checked\",\"@name\",\"@onChange\",\"@disabled\",\"&attrs\"],\"statements\":[[4,\"let\",[[24,0,[\"inputComponent\"]]],null,{\"statements\":[[0,\"    \"],[6,[24,1,[]],[[13,\"disabled\",[24,7,[]]]],[[\"@value\",\"@name\",\"@onChange\"],[[24,4,[]],[24,5,[]],[29,\"unless\",[[24,7,[]],[24,6,[]]],null]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[8,\"label\"],[13,\"for\",[24,0,[\"checkboxInputId\"]]],[13,\"class\",\"sc-checkbox__label\"],[14,8],[9],[0,\"\\n\"],[4,\"if\",[[26,3]],null,{\"statements\":[[0,\"        \"],[15,3],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[24,2,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-checkbox.hbs"
    }
  });

  _exports.default = _default;
});