define("js-common/mixins/authorized-route-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This mixin is used to check if a route needs a set of special permissions from the current user
   * in order to be accessible
   */
  var _default = Ember.Mixin.create({
    /**
    The session service.
     @property session
    @readOnly
    @type SessionService
    @public
    */
    session: Ember.inject.service('session'),
    permissions: Ember.inject.service('permissions'),

    /**
    Checks if the currentUser has the permissions designated on the actual route where this mixin is added
     Actual route needs a property with an array of group names called `requiredPermissions`
     @method beforeModel
    @param {Transition} transition The transition that lead to this route
    @public
    */
    beforeModel: function beforeModel(transition) {
      // to allow show the unauthorized route
      if (transition.targetName === 'unauthorized') {
        return this._super.apply(this, arguments);
      }

      var allPermissions = this.permissions.check(this.requiredPermissions);
      var somePermission = this.permissions.checkAny(this.requireAnyPermission);

      if (allPermissions || somePermission) {
        return this._super.apply(this, arguments);
      } else {
        transition.abort();
        this.transitionTo('unauthorized');
      }
    }
  });

  _exports.default = _default;
});