define("js-common/models/billable", ["exports", "ember-data", "accounting/format-money", "ember-api-actions"], function (_exports, _emberData, _formatMoney, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    qty: attr('number'),
    rate: attr('number'),
    cut: attr('number'),
    total: attr('number'),
    description: attr('string'),
    billed: attr('boolean', {
      defaultValue: false
    }),
    billType: attr('string'),
    // can't use billableType due to conflict w/ relationship
    reimbursementStatus: attr('string', {
      defaultValue: 'Company Card'
    }),
    reimbursedOn: attr('date'),
    autoCalculated: attr('boolean', {
      defaultValue: true
    }),
    employeeName: attr('string'),
    canSeeAmounts: attr('boolean', {
      defaultValue: true
    }),
    hoursSpent: attr('number'),
    expenses: [],
    // relationships
    invoiceGroup: belongsTo('invoice-group', {
      async: false
    }),
    billableGroup: belongsTo('billable-group', {
      async: false
    }),
    billableType: belongsTo('billable-type', {
      async: false
    }),
    invoice: belongsTo('invoice', {
      async: true
    }),
    matter: belongsTo('matter', {
      async: true
    }),
    budget: belongsTo('budget', {
      async: true
    }),
    billableGroupId: Ember.computed.alias('billableGroup.id'),
    invoiceId: Ember.computed(function () {
      return this.belongsTo('invoice').id();
    }),
    billableTypeName: Ember.computed.alias('billableType.name'),
    reimbursementStatusName: Ember.computed('reimbursementStatus', function () {
      switch (this.reimbursementStatus) {
        case 'Company':
          return 'IGNORE';

        case 'Company Card':
          return 'Company Credit Card';

        case 'Company Check':
          return 'Company Check';

        case 'Employee':
          return 'Employee';

        case 'Employee Reimbursed':
          return 'Employee and Reimbursed';

        default:
          return 'N/A';
      }
    }),
    // make it easy to know what type of billable a template is dealing with
    isTime: Ember.computed(function () {
      if (this.billType === 'Time') {
        return true;
      }
    }),
    isExpense: Ember.computed(function () {
      if (this.billType === 'Time:Expense' || this.billType === 'Piece:Expense') {
        return true;
      }
    }),
    isPiece: Ember.computed(function () {
      if (this.billType === 'Piece') {
        return true;
      }
    }),
    formattedRate: Ember.computed('rate', function () {
      return (0, _formatMoney.default)(this.rate);
    }),
    formattedTotal: Ember.computed('total', function () {
      return (0, _formatMoney.default)(this.total);
    }),
    reimburseEmployee: (0, _emberApiActions.collectionAction)({
      path: 'reimburse-employee',
      type: 'post'
    }),
    undoReimburseEmployee: (0, _emberApiActions.collectionAction)({
      path: 'undo-reimburse-employee',
      type: 'post'
    })
  });

  _exports.default = _default;
});