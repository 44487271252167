define("@smith-carson/ui/templates/components/sc-scroll-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ah1wa2CY",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[8,\"div\"],[13,\"id\",[24,0,[\"scrollViewId\"]]],[13,\"class\",\"sc-scroll-view\"],[14,1],[9],[0,\"\\n  \"],[15,2],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-scroll-view.hbs"
    }
  });

  _exports.default = _default;
});