define("js-common/models/case-note", ["exports", "js-common/models/note", "ember-data"], function (_exports, _note, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;

  var _default = _note.default.extend({
    children: hasMany('case-note', {
      async: false,
      inverse: 'parent'
    }),
    parent: belongsTo('case-note', {
      async: false,
      inverse: 'children'
    })
  });

  _exports.default = _default;
});