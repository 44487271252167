define("@smith-carson/ui/helpers/component-classes", ["exports", "@babel/runtime/helpers/esm/typeof"], function (_exports, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentClasses = componentClasses;
  _exports.default = void 0;

  function componentClasses(params) {
    var component = params[0];
    (false && !(params.length > 0) && Ember.assert('Helper must receive a positional param', params.length > 0));
    (false && !((0, _typeof2.default)(component) === 'object' && component.isComponent) && Ember.assert('Helper must receive an Ember Component object', (0, _typeof2.default)(component) === 'object' && component.isComponent));
    var result = [];
    var componentClass = component.get('class');
    var componentClassNames = component.get('classNames');

    if (componentClass) {
      result.push(componentClass);
    }

    if (componentClassNames) {
      result = result.concat(componentClassNames);
    }

    return result.join(' ');
  }

  var _default = Ember.Helper.helper(componentClasses);

  _exports.default = _default;
});