define("@smith-carson/ui/templates/components/sc-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pwxm+/fV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"circle-display\"],[12,\"style\",[30,[\"background-color: \",[23,\"color\"]]]],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"value\"],[9],[1,[23,\"value\"],false],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"percent-sign\"],[9],[0,\"%\"],[10],[0,\"\\n  \"],[7,\"br\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-circle.hbs"
    }
  });

  _exports.default = _default;
});