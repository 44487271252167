define("js-common/mixins/token-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This mixin is used to allow auth with a token query param eg: url.com?newtoken=xyz
   */
  var _default = Ember.Mixin.create({
    initialTargetName: null,
    isTokenAuthenticating: null,
    model: function model(params) {
      var _this = this;

      var newtoken = params.newtoken;
      var self = this;

      if (this.get('session.isAuthenticated')) {
        this.raven.callRaven('setUserContext', {
          id: self.get('session.data.authenticated.id'),
          name: self.get('session.data.authenticated.user_name')
        });
      }

      if (newtoken) {
        this.set('isTokenAuthenticating', true);

        if (this.get('session.isAuthenticated')) {
          var oldToken = this.session.get('data').authenticated.token; // check if it's the same token to skip invalid reloads

          if (oldToken === newtoken) {
            return;
          }
        }

        return this.session.authenticate('authenticator:token', newtoken).then(function () {
          _this.sessionAuthenticated();
        }).catch(function () {
          _this.transitionTo('auth.login');
        });
      }
    },
    sessionAuthenticated: function sessionAuthenticated() {
      var self = this;
      this.raven.callRaven('setUserContext', {
        id: self.get('session.data.authenticated.id'),
        name: self.get('session.data.authenticated.user_name')
      });

      if (!this.isTokenAuthenticating) {
        // this will attempt to transition to previous transition or default url
        this._super();
      } else {
        this.set('isTokenAuthenticating', false);
      }
    },
    sessionInvalidated: function sessionInvalidated() {
      if (!this.isTokenAuthenticating) {
        // this will reload the app to delete the old store and state
        this._super();
      }

      this.raven.callRaven('setUserContext', {
        id: null,
        name: null
      });
    }
  });

  _exports.default = _default;
});