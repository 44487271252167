define("js-common/utils/get-sc-portal-dashboard-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getScPortalDashboardType;

  function getScPortalDashboardType(scPortalDashboardType) {
    var dashboardType = 'mdl';

    if (scPortalDashboardType.length === 2) {
      dashboardType = scPortalDashboardType.rejectBy('name', 'matters.default.sc_portal_dashboard_type').get('firstObject.value');
    } else if (scPortalDashboardType.length === 1) {
      dashboardType = scPortalDashboardType.get('firstObject.value');
    }

    return dashboardType;
  }
});