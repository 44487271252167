define("js-common/models/collection-has-matter-column", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr,
      Model = _emberData.default.Model;

  var _default = Model.extend({
    weight: attr('number'),
    columnWidth: attr('number'),
    display: attr('string'),
    value: attr('string'),
    enabled: attr('boolean'),
    collection: belongsTo('collection', {
      async: true
    }),
    matterColumn: belongsTo('matter-column', {
      async: true
    })
  });

  _exports.default = _default;
});