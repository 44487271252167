define("js-common/templates/components/task-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z5zX8Jh3",
    "block": "{\"symbols\":[\"&default\",\"&inverse\"],\"statements\":[[4,\"if\",[[26,1]],null,{\"statements\":[[4,\"if\",[[25,[\"task\",\"isRunning\"]]],null,{\"statements\":[[4,\"if\",[[26,2]],null,{\"statements\":[[0,\"      \"],[15,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[15,1],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[15,1],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[23,\"text\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/task-button.hbs"
    }
  });

  _exports.default = _default;
});