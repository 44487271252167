define("@smith-carson/ui/components/sc-file-uploader/-lib/file-validator", ["exports", "@smith-carson/ui/utils/human-file-size"], function (_exports, _humanFileSize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /**
   * Validate that a file object is valid for upload.
   *
   * @export
   * @param {*} file
   * @param {*} notifyService
   * @param {*} { maxUploadSize, validExtensions }
   * @returns true if valid, else false
   */
  function _default(file, notifyService, _ref) {
    var maxUploadSize = _ref.maxUploadSize,
        validExtensions = _ref.validExtensions;
    var isValid = true;

    if (!_validateExtensions(file, validExtensions)) {
      isValid = false;

      _notifyInvalidFile(file, 'invalidExtensions', notifyService, {
        validExtensions: validExtensions
      });
    }

    if (file.size > maxUploadSize) {
      isValid = false;

      _notifyInvalidFile(file, 'maxUploadSize', notifyService, {
        maxUploadSize: maxUploadSize
      });
    }

    return isValid;
  }

  var _notifyInvalidFile = function _notifyInvalidFile(file, invalidCode, notifyService, options) {
    switch (invalidCode) {
      case 'invalidExtensions':
        notifyService.error("\"".concat(file.name, "\" has an invalid extension, valid extensions are: ").concat(options.validExtensions.join(', ')));
        return;

      case 'maxUploadSize':
        notifyService.error("\"".concat(file.name, "\" exceeds the upload file size limit of ").concat((0, _humanFileSize.default)(options.maxUploadSize)));
        return;
    }
  };

  var _validateExtensions = function _validateExtensions(file, validExtensions) {
    var ext = _getFileExtension(file.name);

    return !ext || !_isExtensionValid(ext.toLowerCase(), validExtensions) ? false : true;
  };

  var _getFileExtension = function _getFileExtension(filename) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  };

  var _isExtensionValid = function _isExtensionValid(ext, validExtensions) {
    return validExtensions.includes(ext);
  };
});