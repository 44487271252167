define("js-common/templates/components/edit-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xyfaknvl",
    "block": "{\"symbols\":[\"@isInModal\"],\"statements\":[[1,[29,\"component\",[[25,[\"componentName\"]]],[[\"field\",\"disabled\",\"value\",\"options\",\"expandOptions\",\"checkedValues\",\"selectedMultiValues\",\"radioGroupValue\",\"selectedOption\",\"searchField\",\"searchEnabled\",\"optionDisplayField\",\"triggerValueChanged\",\"updateSelectedOption\",\"updateCheckedOptions\",\"renderInPlace\",\"dropdownClass\"],[[25,[\"field\"]],[25,[\"disabled\"]],[25,[\"value\"]],[25,[\"sortedOptions\"]],[25,[\"expandOptions\"]],[25,[\"checkedValues\"]],[25,[\"selectedMultiValues\"]],[25,[\"radioGroupValue\"]],[25,[\"selectedOption\"]],[25,[\"searchField\"]],[25,[\"searchEnabled\"]],[25,[\"optionDisplayField\"]],[29,\"action\",[[24,0,[]],\"triggerValueChanged\"],null],[29,\"action\",[[24,0,[]],\"updateSelectedOption\"],null],[29,\"action\",[[24,0,[]],\"updateCheckedOptions\"],null],[29,\"if\",[[24,1,[]],false,true],null],[29,\"if\",[[24,1,[]],\"ember-power-select-dropdown--in-modal \"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/edit-field.hbs"
    }
  });

  _exports.default = _default;
});