define("js-common/services/go-back", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "jquery"], function (_exports, _toConsumableArray2, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Service intended to be used as a way to track where the user came from
   * after an operation in another page, use this service to go back to the initial
   * location to continue with work
   */
  var _default = Ember.Service.extend({
    /**
     * @property
     * @private
     * used to keep a stack of routes visited by the user
     */
    routesStack: Ember.A(),

    /**
     * @service
     * @private
     * used to move a user to a different route
     */
    routing: Ember.inject.service('-routing'),
    scrollPositions: {},
    goToPositionWaitTime: 1000,
    // in milliseconds

    /**
     * @method
     * @public
     * this receives a Transition.handlerInfos array and the queryParams of a transition
     * with this info we keep the stack up to date
     */
    trackOrigin: function trackOrigin(handlerInfos, queryParams) {
      var _this = this;

      var scrollbarPosition = (0, _jquery.default)('html').scrollTop(); // Contexts are extracted from each handlerInfo
      // and they are the dynamic segments of a route

      var contexts = [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = handlerInfos[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var info = _step.value;

          for (var _i = 0, _Object$values = Object.values(info.params); _i < _Object$values.length; _i++) {
            var value = _Object$values[_i];
            contexts.push(value);
          }
        } // We get the route name from the last handler info which is the new route

      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      var lastHandler = Ember.A(handlerInfos).get('lastObject');
      var name = lastHandler ? lastHandler.name : ''; // When a query param is changed this can be tracked, but if the name matches the previous route
      // we just delete the previous route, and add the new route

      var lastRoute = this.routesStack.popObject();

      if (Ember.isPresent(lastRoute) && lastRoute.name === name) {
        Ember.debug('This route was replaced during `track` because it is a duplicate from last one ' + name);
      } else {
        if (Ember.isPresent(lastRoute)) {
          var existTable = (0, _jquery.default)('.lt-scrollable table');

          if (existTable.length) {
            var positions = this.scrollPositions;
            positions[lastRoute.name] = existTable.offset();
            this.set('scrollPositions', positions);
          }

          lastRoute.scrollbarPosition = scrollbarPosition;
          this.routesStack.pushObject(lastRoute);
        }
      } // Data needed to transition back


      var addRoute = {
        name: name,
        queryParams: queryParams,
        contexts: contexts,
        scrollbarPosition: scrollbarPosition
      };
      var currentPositions = this.scrollPositions;
      var waitTime = this.goToPositionWaitTime;

      if (Ember.isPresent(currentPositions[name])) {
        Ember.run.later(function () {
          var ltScrollable = (0, _jquery.default)('.lt-scrollable');
          var scrollTable = (0, _jquery.default)('.lt-scrollable table');

          if (scrollTable.length) {
            var fromTop = ltScrollable.offset().top;
            var perc = Math.abs(currentPositions[name].top - fromTop) / scrollTable.height();
            var componentScroll = Ember.getOwner(_this).lookup('-view-registry:main')[ltScrollable[0].id];
            componentScroll.send('recalculate');
            componentScroll.send('verticalDrag', perc);
          }
        }, waitTime);
      }

      this.routesStack.pushObject(addRoute);
    },

    /**
     * @method
     * @public
     * used to redirect the user to the previous route
     */
    toPreviousOrigin: function toPreviousOrigin() {
      var previousRoute = this._getPreviousTransition();

      if (Ember.isPresent(previousRoute)) {
        var router = this.routing;
        var transition = router.transitionTo(previousRoute.name, previousRoute.contexts, {
          queryParams: previousRoute.queryParams
        });
        transition.finally(function () {
          Ember.run.later(function () {
            (0, _jquery.default)('html').scrollTop(previousRoute.scrollbarPosition);
          }, 1000);
        });
      }
    },

    /**
     * @method
     * @public
     * used to get the name of the previous route
     */
    previousRouteName: function previousRouteName() {
      var stack = (0, _toConsumableArray2.default)(this.routesStack);
      var currentRoute = stack.popObject();

      if (!Ember.isPresent(currentRoute)) {
        return null;
      }

      var previousRoute;

      do {
        previousRoute = stack.pop();
      } while (Ember.isPresent(previousRoute) && currentRoute.name === previousRoute.name);

      if (Ember.isPresent(previousRoute)) {
        return previousRoute.name;
      }

      return null;
    },

    /**
     * @method
     * @public
     *
     * check if the stack is empty
     */
    canGoToPreviousOrigin: function canGoToPreviousOrigin() {
      return this.routesStack.length > 1;
    },

    /**
     * An alternative implementation of toPreviousOrigin that doesn't require having previously
     * called canGoToPreviousOrigin.
     *
     * Accepts an optional skip option that allows users to pass routes they're not interested to land on
     *
     * Returns false if it wasn't possible to do the transition, or the route details if
     * a transition was initiated
     */
    back: function back() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          skip = _ref.skip;

      var stack = (0, _toConsumableArray2.default)(this.routesStack);
      var currentRoute = stack.pop();

      if (!currentRoute) {
        return false;
      }

      var remove = [currentRoute];
      var previousRoute;

      do {
        previousRoute = stack.pop();
        remove.push(previousRoute);
      } while (previousRoute && (currentRoute.name === previousRoute.name || skip === previousRoute.name));

      if (!previousRoute) {
        return false;
      }

      this.routesStack.removeObjects(remove);
      var router = this.routing;
      var transition = router.transitionTo(previousRoute.name, previousRoute.contexts, {
        queryParams: previousRoute.queryParams
      });
      transition.finally(function () {
        Ember.run.later(function () {
          (0, _jquery.default)('html').scrollTop(previousRoute.scrollbarPosition);
        }, 1000);
      });
      return previousRoute;
    },

    /**
     * @method
     * @private
     * used to get the previous route different that the current one
     */
    _getPreviousTransition: function _getPreviousTransition() {
      var currentRoute = this.routesStack.popObject();

      if (!Ember.isPresent(currentRoute)) {
        return;
      }

      var previousRoute;

      do {
        previousRoute = this.routesStack.pop();
      } while (Ember.isPresent(previousRoute) && currentRoute.name === previousRoute.name);

      return previousRoute;
    },
    _reset: function _reset() {
      this.set('routesStack', Ember.A());
    }
  });

  _exports.default = _default;
});