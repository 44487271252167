define("@smith-carson/ui/templates/components/sc-app-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G0h96i0F",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[29,\"hash\",null,[[\"body\",\"toggle\"],[[29,\"component\",[[24,0,[\"bodyComponent\"]]],[[\"collapsed\"],[[24,0,[\"collapsed\"]]]]],[29,\"component\",[[24,0,[\"toggleComponent\"]]],[[\"collapsed\",\"onClick\"],[[24,0,[\"collapsed\"]],[29,\"action\",[[24,0,[]],\"collapse\"],null]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-app-sidebar.hbs"
    }
  });

  _exports.default = _default;
});