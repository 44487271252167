define("js-common/transforms/estdate", ["exports", "ember-data/transform", "moment"], function (_exports, _transform, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * this module do the correct transformation
   * between the EST date coming from the server
   * to a date in js so it can be used by ember
   * without losing the Time Zone information
   */
  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      // read from API
      if (serialized) {
        if (serialized === '0000-00-00 00:00:00') {
          return null;
        } else {
          return _moment.default.tz(serialized, 'America/New_York').toDate();
        }
      }

      return serialized;
    },
    serialize: function serialize(deserialized) {
      // sent to API
      if (deserialized) {
        return (0, _moment.default)(deserialized).tz('America/New_York').format('YYYY-MM-DD HH:mm:ss');
      }

      return deserialized;
    }
  });

  _exports.default = _default;
});