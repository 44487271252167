define("js-common/models/invoice-output-payment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    amount: attr('number'),
    paidOn: attr('string'),
    paymentType: attr('string'),
    writeOff: Ember.computed('paymentType', {
      get: function get(key) {
        return this.paymentType !== 'payment';
      },
      set: function set(key, value) {
        this.set('paymentType', value ? 'write-off' : 'payment');
        return value;
      }
    }),
    // relationships
    invoiceOutput: belongsTo('invoice-output', {
      async: false
    })
  });

  _exports.default = _default;
});