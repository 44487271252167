define("js-common/models/passive-event", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    description: attr('string'),
    eventType: attr('string'),
    eventAction: attr('string'),
    createdOn: attr('estdate'),
    tableName: attr('string'),
    tableId: attr('number'),
    diff: attr('string'),
    newData: attr('string'),
    createdBy: belongsTo('user', {
      async: true
    }),
    matter: belongsTo('matter', {
      async: false
    }),
    eventActionTitle: Ember.computed('eventAction', function () {
      switch (this.eventAction) {
        case 'New':
          return 'created';

        case 'Edit':
          return 'edited';

        case 'Delete':
          return 'deleted';
      }
    }),
    createdDay: Ember.computed('createdOn', function () {
      return (0, _moment.default)(this.createdOn).format('MMM DD');
    }),
    createdOnFormatted: Ember.computed('createdOn', function () {
      return (0, _moment.default)(this.createdOn).format('MM-DD-YY');
    })
  });

  _exports.default = _default;
});