define("@smith-carson/ui/pods/components/light-table/cells/select-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VJdxZ0xt",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"input\",null,[[\"checked\",\"data-test-input\",\"id\",\"type\",\"click\"],[[29,\"readonly\",[[25,[\"row\",\"selected\"]]],null],\"light-table-cell-select-row\",[24,0,[\"ckbId\"]],\"checkbox\",[29,\"action\",[[24,0,[]],\"toggleSelected\"],null]]]],false],[0,\"\\n\"],[7,\"label\"],[11,\"class\",\"sr-only\"],[11,\"data-test-label\",\"light-table-cell-select-row\"],[12,\"for\",[24,0,[\"ckbId\"]]],[9],[0,\"\\n    Select row\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/pods/components/light-table/cells/select-row/template.hbs"
    }
  });

  _exports.default = _default;
});