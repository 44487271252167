define("ember-bootstrap/templates/components/bs-popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GjQKVQSv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[29,\"unbound\",[[25,[\"_parentFinder\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[25,[\"inDom\"]]],null,{\"statements\":[[4,\"component\",[[25,[\"elementComponent\"]]],[[\"id\",\"parent\",\"placement\",\"fade\",\"showHelp\",\"title\",\"class\",\"renderInPlace\",\"popperTarget\",\"autoPlacement\",\"viewportElement\",\"viewportPadding\"],[[25,[\"overlayId\"]],[24,0,[]],[25,[\"placement\"]],[25,[\"fade\"]],[25,[\"showHelp\"]],[25,[\"title\"]],[25,[\"class\"]],[25,[\"_renderInPlace\"]],[25,[\"triggerTargetElement\"]],[25,[\"autoPlacement\"]],[25,[\"viewportElement\"]],[25,[\"viewportPadding\"]]]],{\"statements\":[[0,\"    \"],[15,1,[[29,\"hash\",null,[[\"close\"],[[29,\"action\",[[24,0,[]],\"close\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-popover.hbs"
    }
  });

  _exports.default = _default;
});