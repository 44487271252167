define("js-common/models/firm", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    modelName: 'firm',
    // before we were using constructor.modelName but is no longer reliable
    name: attr('string'),
    pwId: attr('number'),
    qbName: attr('string'),
    tmNum: attr('number'),
    phone: attr('string'),
    address: attr('string'),
    suite: attr('string'),
    city: attr('string'),
    state: attr('string'),
    zip: attr('string'),
    region: attr('string'),
    industry: attr('string'),
    rating: attr('string'),
    scBusiness: attr('string'),
    history: attr('string'),
    recentMatterName: attr('string'),
    matterCounts: attr('number'),
    // relationships
    firmHasMatters: hasMany('firm-has-matter', {
      async: false
    }),
    matters: hasMany('matters', {
      async: false
    }),
    clients: hasMany('client', {
      async: false
    }),
    location: Ember.computed('state', 'city', function () {
      var delimiter = '';
      var state = this.state;
      var city = Ember.String.w("".concat(this.city).toLowerCase()).map(Ember.String.capitalize).join(' ');

      if (state && city) {
        delimiter = ', ';
      }

      return city + delimiter + state;
    })
  });

  _exports.default = _default;
});