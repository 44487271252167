define("js-common/initializers/raven", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    var application = arguments[1] || arguments[0];
    application.inject('route', 'raven', "service:raven");
    application.inject('component', 'raven', "service:raven");
    application.inject('controller', 'raven', "service:raven");
    application.inject('model', 'raven', "service:raven");
  }

  var _default = {
    initialize: initialize,
    name: 'raven'
  };
  _exports.default = _default;
});