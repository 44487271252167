define("js-common/components/subject-icons/published", ["exports", "js-common/templates/components/subject-icons/published"], function (_exports, _published) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _published.default,
    tagName: ''
  });

  _exports.default = _default;
});