define("js-common/templates/components/analytic-widget/race", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fxu4gVtY",
    "block": "{\"symbols\":[\"@stats\",\"@canEdit\",\"@toggleViewable\"],\"statements\":[[5,\"sc-analytic-card\",[],[[\"@isEnabled\",\"@canEdit\",\"@onToggle\",\"@title\"],[[24,1,[\"race_comparison\",\"matterHasStat\",\"isEnabled\"]],[24,2,[]],[29,\"action\",[[24,0,[]],[24,3,[]],[24,1,[\"race_comparison\",\"matterHasStat\"]]],null],\"Race\"]],{\"statements\":[[0,\"\\n  \"],[5,\"sc-analytic-widget\",[],[[\"@chartType\",\"@chartData\"],[\"doughnut-with-legend\",[24,0,[\"raceData\"]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/analytic-widget/race.hbs"
    }
  });

  _exports.default = _default;
});