define("@smith-carson/ui/pods/components/sc-light-table/cells/days-since-creation/component", ["exports", "moment", "@smith-carson/ui/pods/components/sc-light-table/cells/days-since-creation/template"], function (_exports, _moment, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides a custom light-table cell for rendering the Case _Next Due_ column
      content.
  
      @module ScLightTable::Cells::CaseNextDueComponent
      @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    /**
        Calculates the number of days the dude date has been pushed back based
        on current next due date (`calcNextDueDate`) and initial due date
        (`calcInitialDueOn`).
         @property daysPushedBack
        @type {Number}
     */
    alertDaysSinceCreation: Ember.computed('row.{calcNumberPublishedReports,calcDaysSinceCreation}', function () {
      var row = this.row;

      var _row$getProperties = row.getProperties('calcNumberPublishedReports', 'calcDaysSinceCreation'),
          calcNumberPublishedReports = _row$getProperties.calcNumberPublishedReports,
          calcDaysSinceCreation = _row$getProperties.calcDaysSinceCreation;

      return calcDaysSinceCreation > 90 && (calcNumberPublishedReports ? calcNumberPublishedReports : 0) === 0;
    }),

    /**
        Provides the bootstrap text color utility class for the "pushed back"
        indicator icon based on the number of days the due date has been
        pushed back.
         @property pushedBackTextClass
        @type {String}
     */
    alertDaysSinceCreationStyle: Ember.computed('alertDaysSinceCreation', function () {
      var alertDaysSinceCreation = this.alertDaysSinceCreation;

      if (!alertDaysSinceCreation) {
        return null;
      }

      return 'text-danger';
    }),

    /**
        Provides the tooltip text for the "pushed back" indicator.
         @property pushedBackTooltip
        @type {String}
     */
    alertDaysSinceCreationTooltip: Ember.computed('alertDaysSinceCreation', 'row.calcDaysSinceCreation', function () {
      var alertDaysSinceCreation = this.alertDaysSinceCreation,
          row = this.row;

      var _row$getProperties2 = row.getProperties('calcDaysSinceCreation'),
          calcDaysSinceCreation = _row$getProperties2.calcDaysSinceCreation;

      if (!alertDaysSinceCreation) {
        return null;
      }

      return Ember.String.htmlSafe("+".concat(calcDaysSinceCreation, " days since created"));
    })
  });

  _exports.default = _default;
});