define("@smith-carson/ui/pods/components/light-table/cells/tags/component", ["exports", "ember-light-table/components/cells/base", "@smith-carson/ui/pods/components/light-table/cells/tags/template"], function (_exports, _base, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides a custom `LightTable` cell type for rendering a collection of
      public and private categorization tags (as `primary` and `secondary`
      Bootstrap badges).
  
      Usage:
      ```
          {
              cellType: 'tags',
              label: 'Tags',
              sortable: false,
              valuePath: 'tags',
          }
      ```
  
      @module LightTable::Cells::TagsComponent
      @extends EmberLightTable.CellsBaseComponent
   */
  var _default = _base.default.extend({
    classNames: ['tags-cell'],
    layout: _template.default,
    showTagsTooltip: Ember.computed('row.caseTags', function () {
      var tags = this.row.get('caseTags').toArray();
      return tags.length > 0;
    }),

    /**
        Sorts tags by public/private before rendering into the template.
         @property sortedTags
        @type {Array}
     */
    sortedTags: Ember.computed('row.caseTags', function () {
      var tags = this.row.get('caseTags').toArray();

      if (!(tags instanceof Array)) {
        return [];
      }

      return tags.sort(function (a, b) {
        if (a.public > b.public) {
          return -1;
        }

        if (a.public < b.public) {
          return 1;
        }

        return 0;
      });
    }),
    tagsMarkup: Ember.computed('sortedTags', 'row.caseTags', function () {
      var tags = this.row.get('caseTags').toArray();

      if (!tags) {
        return '';
      }

      var markup = tags.reduce(function (accumulator, currentValue) {
        var badgeClass = currentValue.isPublic ? 'tiger' : 'light';
        return "".concat(accumulator, "<span class=\"badge badge-pill badge-").concat(badgeClass, "\">").concat(currentValue.name, "</span>&nbsp;");
      }, '');
      return Ember.String.htmlSafe(markup);
    })
  });

  _exports.default = _default;
});