define("js-common/components/timelines/new-multiple-timeline-events", ["exports", "js-common/mixins/crud/error", "js-common/templates/components/timelines/new-multiple-timeline-events", "moment"], function (_exports, _error, _newMultipleTimelineEvents, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_error.default, {
    layout: _newMultipleTimelineEvents.default,
    store: Ember.inject.service(),
    onInit: Ember.on('init', function () {
      var _this = this;

      var vitalEventTypes = this.get('eventTypes').filterBy('timelineEventParent.name', 'Vitals');
      var maritalEventTypes = this.get('eventTypes').filterBy('timelineEventParent.name', 'Marital/Relationship History');
      var vitalEvents = [];
      var maritalEvents = [];
      vitalEventTypes.forEach(function (eventType) {
        var newEvent = Ember.Object.create({
          matter: _this.get('matter'),
          timelineEventType: eventType,
          country: 'United States',
          state: null,
          county: '',
          city: null,
          year: '',
          month: '',
          day: '',
          isValid: true
        });
        vitalEvents.pushObject(newEvent);
      });
      maritalEventTypes.forEach(function (eventType) {
        var newEvent = Ember.Object.create({
          matter: _this.get('matter'),
          timelineEventType: eventType,
          country: 'United States',
          state: null,
          county: '',
          city: null,
          year: '',
          month: '',
          day: '',
          isValid: true
        });
        maritalEvents.pushObject(newEvent);
      });
      this.set('vitalEvents', vitalEvents);
      this.set('maritalEvents', maritalEvents);
    }),
    requiredFieldsFilled: function requiredFieldsFilled() {
      this.set('disableIfMissingRequiredFields', !this.get('subject'));
    },

    /**
     * Returns true if all events that have year, month, day inputs filled represent valid dates
     * Sets isValid=false otherwise
     */
    allDatesAreValid: function allDatesAreValid() {
      var vitalEvents = this.get('vitalEvents');
      var maritalEvents = this.get('maritalEvents');
      var allDatesAreValid = true;
      vitalEvents.forEach(function (event) {
        var year = event.get('year');
        var month = event.get('month');
        var day = event.get('day');

        if (year || month || day) {
          if (!(0, _moment.default)(year + '/' + month + '/' + day, 'YYYY/MM/DD', true).isValid()) {
            event.set('isValid', false);
            allDatesAreValid = false;
          } else {
            var date = new Date(year, month - 1, day);
            event.set('startEarliestDate', date);
            event.set('startLatestDate', date);
            event.set('endEarliestDate', date);
            event.set('endLatestDate', date);
          }
        }
      });
      maritalEvents.forEach(function (event) {
        var year = event.get('year');
        var month = event.get('month');
        var day = event.get('day');

        if (year || month || day) {
          if (!(0, _moment.default)(year + '/' + month + '/' + day, 'YYYY/MM/DD', true).isValid()) {
            event.set('isValid', false);
            allDatesAreValid = false;
          } else {
            var date = new Date(year, month - 1, day);
            event.set('startEarliestDate', date);
            event.set('startLatestDate', date);
            event.set('endEarliestDate', date);
            event.set('endLatestDate', date);
          }
        }
      });
      return allDatesAreValid;
    },
    resetIsValid: function resetIsValid() {
      var vitalEvents = this.get('vitalEvents');
      var maritalEvents = this.get('maritalEvents');
      vitalEvents.forEach(function (event) {
        event.set('isValid', true);
      });
      maritalEvents.forEach(function (event) {
        event.set('isValid', true);
      });
    },
    actions: {
      saveNewEvents: function saveNewEvents() {
        var _this2 = this;

        var subject = this.get('subject');
        var subjectId = subject.get('id');
        var vitalEvents = this.get('vitalEvents'); // ember objects

        var maritalEvents = this.get('maritalEvents'); // ember objects

        var eventPromises = [];
        var relationshipAndDocumentPromises = [];
        var noEventsFilled = true;
        var storeVitalEvents = [];
        var storeMaritalEvents = [];
        this.resetIsValid();

        if (this.allDatesAreValid()) {
          vitalEvents.forEach(function (event) {
            var data = event.getProperties('matter', 'timelineEventType', 'country', 'state', 'county', 'city', 'startEarliestDate', 'startLatestDate', 'endEarliestDate', 'endLatestDate');

            if (data['startEarliestDate']) {
              var ev = _this2.get('store').createRecord('timeline-event', data);

              noEventsFilled = false;
              ev.set('subject', subject);
              storeVitalEvents.push(ev);
              eventPromises.push(ev.save());
            }
          });
          maritalEvents.forEach(function (event) {
            var data = event.getProperties('matter', 'timelineEventType', 'country', 'state', 'county', 'city', 'startEarliestDate', 'startLatestDate', 'endEarliestDate', 'endLatestDate');

            if (data['startEarliestDate']) {
              var ev = _this2.get('store').createRecord('timeline-event', data);

              noEventsFilled = false;
              ev.set('subject', subject);
              storeMaritalEvents.push(ev);
              eventPromises.push(ev.save());
            }
          });
          Ember.RSVP.Promise.all(eventPromises).then(function () {
            storeVitalEvents.forEach(function (event) {
              if (event.get('startEarliestDate')) {
                relationshipAndDocumentPromises.push(_this2.get('store').createRecord('resource_has_resource', {
                  parentId: subjectId,
                  childId: event.get('id')
                }).save());

                if (_this2.get('documentId')) {
                  relationshipAndDocumentPromises.push(_this2.get('store').createRecord('resource_has_resource', {
                    parentId: _this2.get('documentId'),
                    childId: event.get('id')
                  }).save());
                }
              }
            });
            storeMaritalEvents.forEach(function (event) {
              if (event.get('startEarliestDate')) {
                relationshipAndDocumentPromises.push(_this2.get('store').createRecord('resource_has_resource', {
                  parentId: subjectId,
                  childId: event.get('id')
                }).save());

                if (_this2.get('documentId')) {
                  relationshipAndDocumentPromises.push(_this2.get('store').createRecord('resource_has_resource', {
                    parentId: _this2.get('documentId'),
                    childId: event.get('id')
                  }).save());
                }
              }
            });
            Ember.RSVP.Promise.all(relationshipAndDocumentPromises).then(function () {
              if (noEventsFilled) {
                _this2.get('notify').info('No new events were created. You must fill in the fields in order to create events');
              } else {
                _this2.get('notify').success('Events have been saved successfully');
              }

              _this2.closeThisForm();

              return _this2.attrs.refresh();
            }, function () {
              _this2.get('notify').error('An error has occurred while saving events. Please check that information is correct.');
            });
          });
        }
      },
      cancelNewEvents: function cancelNewEvents() {
        this.closeThisForm();
      },
      subjectSelectionChange: function subjectSelectionChange(subject) {
        this.set('subject', subject);
        this.requiredFieldsFilled();
      },
      closeThisForm: function closeThisForm() {
        this.attrs.closeThisForm();
      }
    }
  });

  _exports.default = _default;
});