define("js-common/components/custom-checkbox", ["exports", "js-common/templates/components/custom-checkbox"], function (_exports, _customCheckbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _customCheckbox.default,
    classNames: ['custom-checkbox-container'],
    disabled: false,
    id: Ember.computed(function () {
      return 'customCheckbox' + this.elementId;
    }),
    change: function change()
    /* event */
    {
      this._super.apply(this, arguments);

      if (typeof this.attrs['on-change'] === 'function') {
        this.attrs['on-change'](this.value, this.checked);
      }
    }
  });

  _exports.default = _default;
});