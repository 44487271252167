define("js-common/helpers/get-date", ["exports", "@babel/runtime/helpers/esm/toArray", "moment"], function (_exports, _toArray2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(_ref, hash) {
      var _ref2 = (0, _toArray2.default)(_ref),
          value = _ref2[0],
          rest = _ref2.slice(1);

      if (value) {
        if (Ember.testing) {
          return (0, _moment.default)(value).toDate();
        }

        var result = (0, _moment.default)(_moment.default.tz(value, 'America/New_York').format('YYYY-MM-DD') + 'T00:00:00').toDate();
        return result;
      }

      return null;
    }
  });

  _exports.default = _default;
});