define("js-common/helpers/min-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.minDate = minDate;
  _exports.default = void 0;

  function minDate(params) {
    return params.every(function (p) {
      return p.toString().includes('Jan 01 1000');
    });
  }

  var _default = Ember.Helper.helper(minDate);

  _exports.default = _default;
});