define("js-common/components/sort-icon", ["exports", "js-common/templates/components/sort-icon"], function (_exports, _sortIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sortIcon.default,
    tagName: '',

    /**
     * @property
     * @public
     *
     * what field is being sorted right now
     */
    currentSortField: '',

    /**
     * @property
     * @public
     *
     * what field this sort is representing
     */
    forField: '',
    fieldText: '',
    showDoubleArrows: true,
    simpleSortField: Ember.computed('currentSortField', function () {
      var parts = this.currentSortField.split(':');
      return parts[0];
    }),
    directionSortField: Ember.computed('currentSortField', function () {
      var parts = this.currentSortField.split(':');

      if (parts.length === 2) {
        return parts[1];
      } else {
        return 'asc';
      }
    }),
    contrary: function contrary(dir) {
      return dir === 'asc' ? ':desc' : ':asc';
    },
    actions: {
      sortChanged: function sortChanged() {
        if (this.simpleSortField === this.forField) {
          this.sendAction('sortChanged', this.forField + this.contrary(this.directionSortField));
        } else {
          this.sendAction('sortChanged', this.forField);
        }
      }
    }
  });

  _exports.default = _default;
});