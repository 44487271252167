define("js-common/templates/components/tui-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CAig1Jau",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[8,\"div\"],[14,1],[9],[0,\"\\n  \"],[15,2],[0,\"\\n\"],[3,\"did-insert\",[[24,0,[\"setupEditor\"]]]],[3,\"will-destroy\",[[24,0,[\"destroyEditor\"]]]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/tui-editor.hbs"
    }
  });

  _exports.default = _default;
});