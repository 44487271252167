define("js-common/templates/components/x-preview-document/result-preview-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PrwU3xnW",
    "block": "{\"symbols\":[\"DD\",\"Menu\",\"context\",\"Result\",\"@searchTerms\",\"@goToMatch\",\"@toolbar\",\"@searchResultContexts\",\"@matchTotal\"],\"statements\":[[4,\"if\",[[29,\"gt\",[[24,9,[]],0],null]],null,{\"statements\":[[0,\"  \"],[6,[24,7,[\"dropdown\"]],[],[[\"@type\"],[\"secondary\"]],{\"statements\":[[0,\"\\n    \"],[6,[24,1,[\"button\"]],[],[[],[]],{\"statements\":[[0,\"\\n      Results preview\\n      \"],[7,\"span\"],[11,\"class\",\"caret\"],[9],[10],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[24,1,[\"menu\"]],[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[29,\"take\",[[25,[\"pageSize\"]],[29,\"drop\",[[25,[\"pageOffset\"]],[24,8,[]]],null]],null]],null,{\"statements\":[[0,\"        \"],[6,[24,2,[\"item\"]],[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"with\",[[29,\"component\",[\"x-preview-document/search-result-preview\"],null]],null,{\"statements\":[[0,\"            \"],[6,[24,4,[]],[],[[\"@context\",\"@search\",\"@goToMatch\"],[[24,3,[]],[24,5,[]],[24,6,[]]]]],[0,\"\\n\"]],\"parameters\":[4]},null],[0,\"        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[3]},null],[4,\"if\",[[24,9,[]]],null,{\"statements\":[[0,\"        \"],[6,[24,2,[\"item\"]],[],[[],[]],{\"statements\":[[0,\"\\n          \"],[1,[29,\"pagination-pager\",null,[[\"count\",\"current\",\"change\"],[[25,[\"totalPages\"]],[25,[\"currentPage\"]],[29,\"action\",[[24,0,[]],\"changePage\"],null]]]],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"]],\"parameters\":[2]}],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/x-preview-document/result-preview-dropdown.hbs"
    }
  });

  _exports.default = _default;
});