define("ember-bootstrap/templates/components/bs-navbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VMgqYKdj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"fluid\"]]],null,{\"statements\":[[0,\"  \"],[15,1,[[29,\"hash\",null,[[\"toggle\",\"content\",\"nav\",\"collapse\",\"expand\",\"toggleNavbar\"],[[29,\"component\",[[25,[\"toggleComponent\"]]],[[\"onClick\",\"collapsed\"],[[29,\"action\",[[24,0,[]],\"toggleNavbar\"],null],[25,[\"_collapsed\"]]]]],[29,\"component\",[[25,[\"contentComponent\"]]],[[\"collapsed\",\"onHidden\",\"onShown\"],[[25,[\"_collapsed\"]],[25,[\"onCollapsed\"]],[25,[\"onExpanded\"]]]]],[29,\"component\",[[25,[\"navComponent\"]]],[[\"linkToComponent\"],[[29,\"component\",[[25,[\"linkToComponent\"]]],[[\"onCollapse\"],[[29,\"action\",[[24,0,[]],\"collapse\"],null]]]]]]],[29,\"action\",[[24,0,[]],\"collapse\"],null],[29,\"action\",[[24,0,[]],\"expand\"],null],[29,\"action\",[[24,0,[]],\"toggleNavbar\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n    \"],[15,1,[[29,\"hash\",null,[[\"toggle\",\"content\",\"nav\",\"collapse\",\"expand\",\"toggleNavbar\"],[[29,\"component\",[[25,[\"toggleComponent\"]]],[[\"onClick\",\"collapsed\"],[[29,\"action\",[[24,0,[]],\"toggleNavbar\"],null],[25,[\"_collapsed\"]]]]],[29,\"component\",[[25,[\"contentComponent\"]]],[[\"collapsed\",\"onHidden\",\"onShown\"],[[25,[\"_collapsed\"]],[25,[\"onCollapsed\"]],[25,[\"onExpanded\"]]]]],[29,\"component\",[[25,[\"navComponent\"]]],[[\"linkToComponent\"],[[29,\"component\",[[25,[\"linkToComponent\"]]],[[\"onCollapse\"],[[29,\"action\",[[24,0,[]],\"collapse\"],null]]]]]]],[29,\"action\",[[24,0,[]],\"collapse\"],null],[29,\"action\",[[24,0,[]],\"expand\"],null],[29,\"action\",[[24,0,[]],\"toggleNavbar\"],null]]]]]],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-navbar.hbs"
    }
  });

  _exports.default = _default;
});