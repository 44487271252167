define("js-common/models/tag", ["exports", "ember-data", "js-common/mixins/random-color-generator"], function (_exports, _emberData, _randomColorGenerator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;

  var _default = Model.extend(_randomColorGenerator.default, {
    name: attr('string'),
    type: attr('string'),
    isInternal: attr('number', {
      defaultValue: 0
    }),
    resourceHasTag: _emberData.default.hasMany('resource-has-tag', {
      async: false
    }),
    resource: _emberData.default.belongsTo('resource', {
      async: false
    }),
    color: Ember.computed('name', function () {
      var id = parseInt(this.id);
      return this.getRandomColor(Math.abs(Math.sin(id) + id) * 1547 % 1);
    })
  });

  _exports.default = _default;
});