define("js-common/models/db-has-tag", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    tagCategory: attr('string', {
      defaultValue: 'special'
    }),
    db: belongsTo('db', {
      async: true
    }),
    dbTag: belongsTo('db-tag', {
      async: false
    })
  });

  _exports.default = _default;
});