define("js-common/models/post", ["exports", "ember-data", "ember-api-actions"], function (_exports, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;
  var number = attr('number', {
    defaultValue: 0
  });
  var estdate = attr('estdate');
  var string = attr('string');

  var _default = Model.extend({
    title: string,
    datePosted: string,
    url: string,
    description: string,
    requestScrape: number,
    createdOn: estdate,
    updatedOn: estdate,
    isUrgent: number,
    isForensic: number,
    scrapeNotes: string,
    dueDate: string,
    weight: number,
    viewable: number,
    createdBy: belongsTo('user', {
      async: true
    }),
    updatedBy: belongsTo('user', {
      async: true
    }),
    hyperlinkResource: belongsTo('hyperlink', {
      async: true
    }),
    hyperlinkResourceId: number,
    documentResource: belongsTo('document', {
      async: true
    }),
    documentResourceId: number,
    updateCaptureRequest: (0, _emberApiActions.memberAction)({
      path: 'update-scrape',
      type: 'put'
    })
  });

  _exports.default = _default;
});