define("js-common/models/crm-note-has-deal", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    // only relationships here
    crmNote: belongsTo('crm-note', {
      async: false
    }),
    deal: belongsTo('deal', {
      async: true
    })
  });

  _exports.default = _default;
});