define("@smith-carson/ui/templates/components/sc-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PkLODT7/",
    "block": "{\"symbols\":[\"&default\",\"@fixedWidth\",\"@spin\",\"&attrs\"],\"statements\":[[4,\"if\",[[29,\"eq\",[[24,0,[\"_iconSet\"]],\"sc\"],null]],null,{\"statements\":[[0,\"  \"],[8,\"i\"],[13,\"class\",[30,[\"sc-icon sc-icon--iconset-sc \",[24,0,[\"iconSizeClass\"]],\" \",[24,0,[\"fixedWidthClass\"]],\" \",[24,0,[\"iconColorClass\"]]]]],[14,4],[9],[0,\"\\n    \"],[1,[29,\"svg-jar\",[[24,0,[\"_icon\"]]],null],false],[0,\"\\n  \"],[3,\"on\",[\"mouseenter\",[24,0,[\"_mouseEnter\"]]]],[3,\"on\",[\"mouseleave\",[24,0,[\"_mouseLeave\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"eq\",[[24,0,[\"iconSet\"]],\"fa\"],null]],null,{\"statements\":[[0,\"  \"],[5,\"fa-icon\",[[13,\"class\",[30,[\"sc-icon sc-icon--iconset-fa \",[24,0,[\"iconSizeClass\"]],\" \",[24,0,[\"iconColorClass\"]]]]],[14,4]],[[\"@mouseEnter\",\"@mouseLeave\",\"@icon\",\"@fixedWidth\",\"@spin\"],[[29,\"action\",[[24,0,[]],[24,0,[\"_mouseEnter\"]]],null],[29,\"action\",[[24,0,[]],[24,0,[\"_mouseLeave\"]]],null],[24,0,[\"_icon\"]],[24,2,[]],[24,3,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[26,1]],null,{\"statements\":[[15,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-icon.hbs"
    }
  });

  _exports.default = _default;
});