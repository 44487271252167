define("js-common/models/eligible-billable", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    itemId: attr('number'),
    amount: attr('number'),
    expenses: attr('number'),
    invoiceGroupId: attr('number'),
    invoiceGroupName: attr('string'),
    matterId: attr('number'),
    services: attr('number'),
    tmCode: attr('string'),
    matterName: attr('string'),
    firstName: attr('string'),
    lastName: attr('string'),
    bgType: attr('string'),
    billableTypeName: attr('string'),
    start: attr('string'),
    selected: attr('boolean') // this is used by the UI to determine whether the billable has been selected or not.  it doesn't have a counterpart value on the API

  });

  _exports.default = _default;
});