define("js-common/helpers/set-date", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "moment"], function (_exports, _slicedToArray2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
          target = _ref2[0],
          variable = _ref2[1];

      return function (dateValue) {
        var formattedDateValue;

        if (dateValue) {
          formattedDateValue = (0, _moment.default)(dateValue).format('YYYY-MM-DD');
        } else {
          formattedDateValue = null;
        }

        Ember.set(target, variable, formattedDateValue);
      };
    }
  });

  _exports.default = _default;
});