define("js-common/helpers/value-from-map", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.valueFromMap = valueFromMap;
  _exports.default = void 0;

  function valueFromMap(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        map = _ref2[0],
        key = _ref2[1];

    return map ? Ember.get(map, key) : '';
  }

  var _default = Ember.Helper.helper(valueFromMap);

  _exports.default = _default;
});