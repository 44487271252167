define("js-common/templates/components/subject-field-notes-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DHDlgLZm",
    "block": "{\"symbols\":[\"field\"],\"statements\":[[4,\"each\",[[25,[\"childNoteFields\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[12,\"class\",[30,[[29,\"unless\",[[24,1,[\"isViewableByClient\"]],\"text-dark-gray\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[29,\"get\",[[25,[\"subject\"]],[24,1,[\"name\"]]],null]],null,{\"statements\":[[4,\"if\",[[29,\"and\",[[25,[\"prefix\"]],[29,\"not-eq\",[[24,1,[\"fieldType\"]],\"longtext+markdown\"],null]],null]],null,{\"statements\":[[0,\"        \"],[4,\"if\",[[29,\"eq\",[[24,1,[\"name\"]],\"affiliationHistory\"],null]],null,{\"statements\":[[0,\",\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          -\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"      \"],[1,[29,\"formatted-subject-field-value\",[[29,\"get\",[[25,[\"subject\"]],[24,1,[\"name\"]]],null],[25,[\"subject\"]],[24,1,[]],[24,1,[\"name\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/subject-field-notes-value.hbs"
    }
  });

  _exports.default = _default;
});