define("js-common/models/client-invoice-history", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  /**
   * this model relies on a faux api model
   * there is no dedicated end point backing this model, it is only populated through side loading
   */

  var _default = Model.extend({
    mostRecentInvoice: attr('number'),
    mostRecentInvoiceBillDate: attr('estdate'),
    yearToDateRevenue: attr('number'),
    allRevenue: attr('number'),
    allInvoiceCount: attr('number'),
    yearToDateInvoiceCount: attr('number'),
    // relationships
    client: belongsTo('client', {
      async: false
    })
  });

  _exports.default = _default;
});