define("js-common/models/matter-has-recognition", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    matter: belongsTo('matter', {
      async: true
    }),
    state: attr('string'),
    split: attr('number'),
    formattedSplit: Ember.computed('split', function () {
      var perc = Number(this.split).toFixed(2);
      return "".concat(perc, " %");
    })
  });

  _exports.default = _default;
});