define("js-common/templates/components/analytics-sidebar/phase-two-read", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z6iAqsAg",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"analytics-modules/years-in-county-phase-two\",null,[[\"data\",\"state\"],[[25,[\"data\"]],\"analyticsTabReadOnly\"]]],false],[0,\"\\n\"],[1,[29,\"analytics-modules/marital-phase-two\",null,[[\"data\",\"state\"],[[25,[\"data\"]],\"analyticsTabReadOnly\"]]],false],[0,\"\\n\"],[1,[29,\"analytics-modules/children-phase-two\",null,[[\"data\",\"state\"],[[25,[\"data\"]],\"analyticsTabReadOnly\"]]],false],[0,\"\\n\"],[1,[29,\"analytics-modules/employment-phase-two\",null,[[\"data\",\"state\"],[[25,[\"data\"]],\"analyticsTabReadOnly\"]]],false],[0,\"\\n\"],[1,[29,\"analytics-modules/product-use\",null,[[\"data\",\"state\"],[[25,[\"data\"]],\"analyticsTabReadOnly\"]]],false],[0,\"\\n\"],[1,[29,\"analytics-modules/other\",null,[[\"data\",\"state\"],[[25,[\"data\"]],\"analyticsTabReadOnly\"]]],false],[0,\"\\n\"],[1,[29,\"analytics-modules/questionnaire\",null,[[\"data\",\"state\"],[[25,[\"data\"]],\"analyticsTabReadOnly\"]]],false],[0,\"\\n\"],[1,[29,\"analytics-modules/higher-education\",null,[[\"data\",\"state\"],[[25,[\"data\"]],\"analyticsTabReadOnly\"]]],false],[0,\"\\n\"],[1,[29,\"analytics-modules/religious-interests\",null,[[\"data\",\"state\"],[[25,[\"data\"]],\"analyticsTabReadOnly\"]]],false],[0,\"\\n\"],[1,[29,\"analytics-modules/social-media-phase-two\",null,[[\"data\",\"state\"],[[25,[\"data\"]],\"analyticsTabReadOnly\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/analytics-sidebar/phase-two-read.hbs"
    }
  });

  _exports.default = _default;
});