define("js-common/utils/doc-name-parsing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = docNameParsing;

  function getSubjectByFullName(subjects, subjName) {
    var subject = subjects.findBy('fullNameWithMiddle', subjName);

    if (subject) {
      return subject;
    } else {
      subjName = subjName.toLowerCase();
      subjects.forEach(function (subj) {
        if (subj.get('fullNameWithMiddle').toLowerCase().indexOf(subjName) > -1) {
          subject = subj;
        }
      });
      return subject;
    }
  }

  function docNameParsing(originalDocName, currentSubject, subjects, documentTypes) {
    var subject, tabNumber, docType, fileName, socnetName;
    var subjectFound = false;
    var docTypeFound = false;
    var sections = [];

    if (originalDocName.indexOf('TAB') === 0) {
      var tabSections = originalDocName.split(' - ');
      var tab = tabSections[0];
      tabNumber = tab.replace('TAB ', '').trim();
      sections = tabSections.slice(1);
    } else {
      sections = originalDocName.split(' - ');
    }

    if (currentSubject) {
      subject = currentSubject;
      subjectFound = currentSubject.get('fullNameWithMiddle').toLowerCase().includes(sections[0].toLowerCase().trim());
    } else {
      var possibleName = sections[0];
      var proposedSubject;

      if (possibleName.indexOf('(')) {
        var proposedSubjectEls = possibleName.split('(');
        proposedSubject = proposedSubjectEls[0];
      } else {
        proposedSubject = sections[0];
      }

      if (proposedSubject) {
        var subjectInList = getSubjectByFullName(subjects, proposedSubject.trim());

        if (subjectInList) {
          subject = subjectInList;
          subjectFound = true;
        } else {
          subject = null;
        }
      }
    }

    var proposedDocType = sections[1];

    if (proposedDocType) {
      if (proposedDocType.indexOf(' (') > -1) {
        // It has information about sonetName
        var docTypeSections = proposedDocType.split(' (');
        proposedDocType = docTypeSections[0];
        socnetName = docTypeSections.splice(1).join(' (').replace(')', '').trim();
      }

      var trimmedProposedDocType = proposedDocType.trim().toUpperCase();
      var docTypeInList = documentTypes.findBy('value', trimmedProposedDocType);

      if (docTypeInList) {
        docType = docTypeInList.get('value');
        docTypeFound = true;
      } else {
        documentTypes.forEach(function (documentType) {
          if (documentType.get('value').indexOf(trimmedProposedDocType) >= 0) {
            docType = documentType.get('value');
            docTypeFound = true;
          }
        });
      }
    }

    var newSections = [];
    sections.forEach(function (s, i) {
      if (i === 0 && !subjectFound) {
        newSections.push(sections[0].trim());
      }

      if (i === 1 && !docTypeFound) {
        newSections.push(sections[1].trim());
      }

      if (i > 1) {
        newSections.push(sections[i].trim());
      }
    });

    if (newSections.length > 0) {
      var newFileName = newSections.join(' - ');
      fileName = newFileName;
    }

    return {
      subject: subject,
      subjectName: subject ? subject.get('fullNameWithMiddle') : null,
      tabNumber: tabNumber,
      docType: docType,
      socnetName: socnetName,
      fileName: fileName
    };
  }
});