define("ember-on-modifier/modifiers/on", ["exports", "@babel/runtime/helpers/esm/classCallCheck", "@babel/runtime/helpers/esm/toArray", "@babel/runtime/helpers/esm/toConsumableArray", "ember-on-modifier/utils/event-listener"], function (_exports, _classCallCheck2, _toArray2, _toConsumableArray2, _eventListener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.__counts = __counts;
  _exports.default = void 0;

  /**
   * These are private API and only used for testing instrumentation.
   */
  var adds = 0;
  var removes = 0;

  function __counts() {
    return {
      adds: adds,
      removes: removes
    };
  }

  var assertValidEventOptions = false
  /* DEBUG */
  && function () {
    var ALLOWED_EVENT_OPTIONS = ['capture', 'once', 'passive'];

    var joinOptions = function joinOptions(options) {
      return options.map(function (o) {
        return "'".concat(o, "'");
      }).join(', ');
    };

    return function (eventOptions, eventName) {
      var invalidOptions = Object.keys(eventOptions).filter(function (o) {
        return !ALLOWED_EVENT_OPTIONS.includes(o);
      });
      (false && !(invalidOptions.length === 0) && Ember.assert("ember-on-modifier: Provided invalid event options (".concat(joinOptions(invalidOptions), ") to '").concat(eventName, "' event listener. Only these options are valid: ").concat(joinOptions(ALLOWED_EVENT_OPTIONS)), invalidOptions.length === 0));
    };
  }();

  function setupListener(element, eventName, callback, eventOptions, params) {
    if (false
    /* DEBUG */
    ) assertValidEventOptions(eventOptions, eventName);
    (false && !(typeof eventName === 'string' && eventName.length > 1) && Ember.assert("ember-on-modifier: '".concat(eventName, "' is not a valid event name. It has to be a string with a minimum length of 1 character."), typeof eventName === 'string' && eventName.length > 1));
    (false && !(typeof callback === 'function') && Ember.assert("ember-on-modifier: '".concat(callback, "' is not a valid callback. Provide a function."), typeof callback === 'function'));
    (false && !(!Array.isArray(params) || params.length === 0) && Ember.deprecate("ember-on-modifier: Passing additional arguments to be partially applied to the event listener is deprecated in order to comply with the RFC. Use the '{{fn}}' helper instead: https://www.npmjs.com/package/ember-fn-helper-polyfill", !Array.isArray(params) || params.length === 0, {
      id: 'ember-on-modifier.partial-application',
      until: '1.0.0',
      url: 'https://github.com/emberjs/rfcs/blob/master/text/0471-on-modifier.md'
    }));

    if (Array.isArray(params) && params.length > 0) {
      var _callback = callback;

      callback = function callback() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        return _callback.call.apply(_callback, [this].concat((0, _toConsumableArray2.default)(params), args));
      };
    }

    adds++;
    (0, _eventListener.addEventListener)(element, eventName, callback, eventOptions);
    return callback;
  }

  function destroyListener(element, eventName, callback, eventOptions) {
    if (element && eventName && callback) {
      removes++;
      (0, _eventListener.removeEventListener)(element, eventName, callback, eventOptions);
    }
  }

  var _default = Ember._setModifierManager(function () {
    return {
      createModifier: function createModifier() {
        return {
          element: null,
          eventName: undefined,
          callback: undefined,
          eventOptions: undefined
        };
      },
      installModifier: function installModifier(state, element, _ref) {
        var _ref$positional = (0, _toArray2.default)(_ref.positional),
            eventName = _ref$positional[0],
            callback = _ref$positional[1],
            params = _ref$positional.slice(2),
            eventOptions = _ref.named;

        state.callback = setupListener(element, eventName, callback, eventOptions, params);
        state.element = element;
        state.eventName = eventName;
        state.params = params;
        state.eventOptions = eventOptions;
      },
      updateModifier: function updateModifier(state, _ref2) {
        var _ref2$positional = (0, _toArray2.default)(_ref2.positional),
            eventName = _ref2$positional[0],
            callback = _ref2$positional[1],
            params = _ref2$positional.slice(2),
            eventOptions = _ref2.named;

        destroyListener(state.element, state.eventName, state.callback, state.eventOptions);
        state.callback = setupListener(state.element, eventName, callback, eventOptions, params);
        state.eventName = eventName;
        state.params = params;
        state.eventOptions = eventOptions;
      },
      destroyModifier: function destroyModifier(_ref3) {
        var element = _ref3.element,
            eventName = _ref3.eventName,
            callback = _ref3.callback,
            eventOptions = _ref3.eventOptions;
        destroyListener(element, eventName, callback, eventOptions);
      }
    };
  }, function OnModifier() {
    (0, _classCallCheck2.default)(this, OnModifier);
  });

  _exports.default = _default;
});