define("js-common/components/subjects/table/client-social-cell", ["exports", "js-common/components/subjects/table/social-cell"], function (_exports, _socialCell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _socialCell.default.extend({
    hideIcon: true,
    getCurrentHyperlink: function getCurrentHyperlink() {
      if (this.get('row.content.pending')) {
        return null;
      }

      var socNetIndex = parseInt(this.get('column.label').split('#')[1], 10) - 1;
      var hyperlinks = [];
      var orderedHyperlinks = this.get('row.content.hyperlinks').rejectBy('type', 'Media').rejectBy('type', 'Monitoring').rejectBy('type', null).rejectBy('viewable', 0).rejectBy('viewable', 2).sortBy('weight');
      orderedHyperlinks.forEach(function (hyperlink) {
        hyperlinks.push(hyperlink);
      });

      if (socNetIndex < hyperlinks.length) {
        return hyperlinks[socNetIndex];
      }

      return null;
    }
  });

  _exports.default = _default;
});