define("@smith-carson/ui/templates/components/sc-chart/horizontal-single-stacked-bar-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xZF3G6tq",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"each\",[[25,[\"barItems\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"stacked-item\"],[12,\"style\",[29,\"html-safe\",[[24,1,[\"style\"]]],null]],[9],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"showTooltip\"]]],null,{\"statements\":[[0,\"    \"],[5,\"sc-tooltip\",[],[[\"@placement\",\"@color\"],[\"top\",\"dark\"]],{\"statements\":[[0,\"\\n      \"],[1,[24,1,[\"hoverText\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-chart/horizontal-single-stacked-bar-chart.hbs"
    }
  });

  _exports.default = _default;
});