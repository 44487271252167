define("js-common/templates/components/edit-field/text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ObKfO4+o",
    "block": "{\"symbols\":[],\"statements\":[[5,\"sc-input\",[],[[\"@type\",\"@value\",\"@class\",\"@onChange\",\"@disabled\"],[\"text\",[29,\"readonly\",[[25,[\"value\"]]],null],\"form-control\",[23,\"triggerValueChanged\"],[23,\"disabled\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/edit-field/text.hbs"
    }
  });

  _exports.default = _default;
});