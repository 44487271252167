define("js-common/models/subscription-cost", ["exports", "ember-data", "accounting/format-money"], function (_exports, _emberData, _formatMoney) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    db: attr('string'),
    type: attr('string'),
    renews: attr('string'),
    method: attr('string'),
    monthly: attr('number'),
    annual: attr('number'),
    employee: belongsTo('employee', {
      async: true
    }),
    monthlyFormatted: Ember.computed('type', 'monthly', function () {
      if (this.type !== 'Monthly') {
        return '';
      }

      return (0, _formatMoney.default)(this.monthly);
    }),
    annualFormatted: Ember.computed('type', 'annual', function () {
      if (this.type !== 'Annually') {
        return '';
      }

      return (0, _formatMoney.default)(this.annual);
    })
  });

  _exports.default = _default;
});