define("js-common/helpers/set-var", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 3),
          target = _ref2[0],
          variable = _ref2[1],
          fixedValue = _ref2[2];

      Ember.Logger.debug('within compute: ');
      Ember.Logger.debug('target, variable, value');
      Ember.Logger.debug(target, variable, fixedValue);
      return function (dynamicValue) {
        Ember.Logger.debug('within the function: dynamicValue -> ', dynamicValue);
        var value = dynamicValue || fixedValue || undefined;
        Ember.Logger.debug('elected value', value);
        Ember.set(target, variable, value);
      };
    }
  });

  _exports.default = _default;
});