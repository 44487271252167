define("js-common/services/report-builder", ["exports", "jquery", "js-common/mixins/crud/error"], function (_exports, _jquery, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_error.default, {
    session: Ember.inject.service(),
    metrics: Ember.inject.service(),
    notify: Ember.inject.service(),
    ajax: Ember.inject.service(),

    /**
        Provides the right URL to POST to create the report on the API
         @property url
        @type {String}
     */
    url: Ember.computed(function () {
      var ENV = Ember.getOwner(this).resolveRegistration('config:environment');
      return "".concat(ENV.APP.restDestination, "/").concat(ENV.APP.restNameSpace, "/report_builder");
    }),

    /**
        Using the json object for report_builder, we send it to validate_report
        so the server checks if all filters are in order so we can generate
        correctly.
         @method validateReportOnServer
        @param {String} jsonPostObjectParamAsString
        @param {Object} completionCallback Optional callback function
        @return {Boolean}
     */
    validateReportOnServer: function validateReportOnServer(jsonPostObjectParamAsString, completionCallback) {
      var self = this;
      /*
          We can't use the ajax service here b/c of the `async: false`
          it always return a promise
       */

      var response = _jquery.default.ajax({
        url: "".concat(this.url, "/validate_report"),
        type: 'POST',
        data: {
          json_post: jsonPostObjectParamAsString
        },
        headers: {
          'X-Authorization': "Token: ".concat(this.get('session.data.authenticated.token'))
        },
        async: false,
        error: function error(xhr
        /* , status, error */
        ) {
          if (typeof completionCallback === 'function') {
            completionCallback();
          }

          self.handleXHR(xhr.responseJSON);
        }
      });

      return response.status >= 200 && response.status < 300;
    },

    /**
        Executes the reports and returns the result as a promise.
         @method getReportResult
        @param {Object} jsonPostObjectParam
        @param {Object} completionCallback Optional callback function
        @return {Promise}
     */
    getReportResult: function getReportResult(jsonPostObjectParam) {
      var jsonObjectAsString = JSON.stringify(jsonPostObjectParam);
      var url = this.url;
      return this.ajax.post(url, {
        data: {
          json_post: jsonObjectAsString,
          token: this.get('session.data.authenticated.token')
        }
      });
    },

    /**
        This method is used to validate and execute a report in the API using
        the report_builder endpoint.
         @method executeReport
        @param {Object} jsonPostObjectParam
        @param {Object} completionCallback Optional callback function
     */
    executeReport: function executeReport(jsonPostObjectParam, completionCallback) {
      var jsonObjectAsString = JSON.stringify(jsonPostObjectParam);

      if (!this.validateReportOnServer(jsonObjectAsString, completionCallback)) {
        return;
      }
      /**
          Uncomment this and the jQuery form target declaration below to debug
          reports (will actually open in new tab).
           const reportNumber = Math.floor(Math.random() * 100000);
          const reportWindow = window.open('', `reportWindow_${reportNumber}`);
           if (!reportWindow) {
              / * eslint-disable-next-line no-alert * /
              window.alert('Please allow popups on this domain');
               return;
          }
           reportWindow.document.write('Please wait while the report is generated...');
       */
      // send analytics event


      var reportName = 'report_builder';

      if ('report_builder' in jsonPostObjectParam && 'standard' in jsonPostObjectParam.report_builder && 'type' in jsonPostObjectParam.report_builder.standard) {
        reportName = jsonPostObjectParam.report_builder.standard.type;
      }

      if ('report_builder' in jsonPostObjectParam && 'custom' in jsonPostObjectParam.report_builder && 'entity' in jsonPostObjectParam.report_builder.custom) {
        reportName += ":".concat(jsonPostObjectParam.report_builder.custom.entity);
      }

      this.metrics.trackEvent({
        category: 'Documents',
        action: 'generate_report',
        label: reportName
      }); // Build form and post json object

      var form = (0, _jquery.default)('<form>', {
        action: this.url,
        enctype: 'application/json',
        id: 'reportForm',
        method: 'post',
        target: '_self'
        /**
            Uncomment this and the lines declaring `reportWindow` above to
            debug reports (will actually open in new tab).
             target: 'reportWindow_' + reportNumber,
         */

      });
      var jsonInput = (0, _jquery.default)('<input>', {
        name: 'json_post',
        value: jsonObjectAsString
      });
      var tokenInput = (0, _jquery.default)('<input>', {
        name: 'token',
        value: this.get('session.data.authenticated.token')
      });
      (0, _jquery.default)(form).append([jsonInput, tokenInput]);
      (0, _jquery.default)('body').append(form);
      (0, _jquery.default)('#reportForm').submit();
      (0, _jquery.default)(form).remove();
      this.notify.info('Preparing report...');

      if (typeof completionCallback === 'function') {
        completionCallback();
      }
    },

    /**
        Puts the execution of the report on a queue so a task will execute it
        and another component will be checking for the completion of the job id.
         @method enqueueReport
        @param {Object} jsonPostObjectParam
        @param {Object} callback Optional callback function
     */
    enqueueReport: function enqueueReport(jsonPostObjectParam, callback) {
      var jsonObjectAsString = JSON.stringify(jsonPostObjectParam);
      (false && !(Ember.typeOf(callback) === 'function') && Ember.assert('Please you nee to specify a callback where the job id will be returned after enqueue', Ember.typeOf(callback) === 'function'));

      if (!this.validateReportOnServer(jsonObjectAsString)) {
        return;
      } // send analytics event


      var reportName = 'report_builder';

      if ('report_builder' in jsonPostObjectParam && 'standard' in jsonPostObjectParam.report_builder && 'type' in jsonPostObjectParam.report_builder.standard) {
        reportName = jsonPostObjectParam.report_builder.standard.type;
      }

      if ('report_builder' in jsonPostObjectParam && 'custom' in jsonPostObjectParam.report_builder && 'entity' in jsonPostObjectParam.report_builder.custom) {
        reportName += ":".concat(jsonPostObjectParam.report_builder.custom.entity);
      }

      this.metrics.trackEvent({
        category: 'Documents',
        action: 'generate_report',
        label: reportName
      }); // build form and post json object to the new window opened

      this.ajax.post("".concat(this.url, "/enqueue_report"), {
        data: {
          json_post: jsonObjectAsString
        }
      }).then(function (response) {
        callback({
          ok: true,
          job: response.job_id
        });
      }, function (xhr, status, error) {
        callback({
          ok: false,
          error: error
        });
      });
    }
  });

  _exports.default = _default;
});