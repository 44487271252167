define("js-common/models/invoice-detail", ["exports", "ember-data", "js-common/models/invoice"], function (_exports, _emberData, _invoice) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _invoice.default.extend({
    timeEntries: attr(),
    manualEntries: attr(),
    subscriptionEntries: attr(),
    travelEntries: attr(),
    pieceEntries: attr(),
    canSeeAmounts: attr('boolean')
  });

  _exports.default = _default;
});