define("@smith-carson/ui/templates/components/sc-chart/legend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QdpQP9jf",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[4,\"each\",[[25,[\"chartData\"]]],null,{\"statements\":[[4,\"if\",[[24,1,[\"value\"]]],null,{\"statements\":[[0,\"        \"],[6,[24,0,[\"legendItemComponent\"]],[],[[\"@title\",\"@value\",\"@color\"],[[24,1,[\"title\"]],[24,1,[\"value\"]],[24,1,[\"color\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-chart/legend.hbs"
    }
  });

  _exports.default = _default;
});