define("js-common/components/string-is-truncated", ["exports", "js-common/templates/components/string-is-truncated"], function (_exports, _stringIsTruncated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _stringIsTruncated.default,
    lines: undefined,
    characters: undefined,
    isTruncated: Ember.computed('lines', 'characters', 'text', function () {
      var lines = this.lines;
      var characters = this.characters;
      var text = this.text;

      if (!Ember.isEmpty(characters)) {
        if (text > characters) {
          return true;
        }
      } else {
        if (!Ember.isEmpty(lines)) {
          // array of possible line break codings.
          var possibleLineBreaks = ['\r\n', '\n\r', '\r', '\n'];
          var lineBreak = ''; // try to determine what kind of line breaks are being used in this text.

          for (var i = 0; i < possibleLineBreaks.length; i++) {
            if (text.indexOf(possibleLineBreaks[i]) !== -1) {
              lineBreak = possibleLineBreaks[i];
              break;
            }
          } // if not line breaks are found, return original string.  otherwise, get the first four lines


          if (lineBreak !== '') {
            // split the string into an array delimited by the value of the lineBreak variable
            var arr = text.split(lineBreak);
            var count = 0;

            for (i = 0; i < arr.length; i++) {
              // use the number passed in from the template to determine how many lines to include and break when that number is reached
              if (count === lines) {
                break;
              } // ignore empty strings that appear before first actual line of text in the string


              if (arr[i] === '' && count === 0) {
                continue;
              }

              count++;
            }

            if (count >= lines) {
              return true;
            }
          }
        }
      }

      return false;
    })
  });

  _exports.default = _default;
});