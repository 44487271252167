define("@smith-carson/ui/templates/components/sc-accordion/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EypBeTIy",
    "block": "{\"symbols\":[\"panel\",\"@panels\",\"@open\",\"&default\"],\"statements\":[[6,[24,2,[\"panel\"]],[[13,\"class\",\"sc-accordion__item\"]],[[\"@name\",\"@open\"],[[24,0,[\"name\"]],[24,3,[]]]],{\"statements\":[[0,\"\\n  \"],[15,4,[[29,\"hash\",null,[[\"title\",\"body\"],[[29,\"component\",[[24,0,[\"titleComponent\"]]],[[\"panel\"],[[24,1,[]]]]],[29,\"component\",[[24,0,[\"bodyComponent\"]]],[[\"panel\"],[[24,1,[]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-accordion/item.hbs"
    }
  });

  _exports.default = _default;
});