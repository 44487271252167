define("js-common/models/task-has-note", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    resourceId: attr('number'),
    note: belongsTo('note', {
      async: false
    }),
    task: belongsTo('task', {
      async: false,
      inverse: 'notes'
    })
  });

  _exports.default = _default;
});