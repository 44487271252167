define("js-common/templates/components/subject-icons/published", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tXV/8bvP",
    "block": "{\"symbols\":[\"@subject\"],\"statements\":[[4,\"if\",[[24,1,[\"publishedOn\"]]],null,{\"statements\":[[0,\"  \"],[5,\"sc-icon\",[],[[\"@icon\",\"@color\",\"@size\",\"@fixedWidth\"],[\"check-circle-filled\",\"grass\",\"lg\",true]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/subject-icons/published.hbs"
    }
  });

  _exports.default = _default;
});