define("ember-bootstrap/templates/components/bs-accordion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kGVvGAmB",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[29,\"hash\",null,[[\"item\",\"change\"],[[29,\"component\",[[25,[\"itemComponent\"]]],[[\"selected\",\"onClick\"],[[25,[\"isSelected\"]],[29,\"action\",[[24,0,[]],\"change\"],null]]]],[29,\"action\",[[24,0,[]],\"change\"],null]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-accordion.hbs"
    }
  });

  _exports.default = _default;
});