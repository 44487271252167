define("js-common/templates/components/subject-formatted-field-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2LacIdeC",
    "block": "{\"symbols\":[\"field\"],\"statements\":[[4,\"each\",[[25,[\"displayedFields\"]]],null,{\"statements\":[[4,\"unless\",[[29,\"eq\",[[24,1,[]],[25,[\"displayedFields\",\"firstObject\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"hr\"],[11,\"style\",\"padding-top:5px;padding-bottom:5px;\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[29,\"eq\",[[24,1,[]],[25,[\"matterField\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[23,\"result\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"not\",[[24,1,[\"mergeField\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"h4\"],[11,\"class\",\"proximanova\"],[11,\"style\",\"font-weight:600;font-size:16px;\"],[9],[0,\"\\n        \"],[1,[24,1,[\"display\"]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[29,\"eq\",[[24,1,[\"fieldClass\"]],\"widget\"],null]],null,{\"statements\":[[0,\"      \"],[5,\"widget-field-read\",[],[[\"@field\",\"@data\",\"@isParent\",\"@isChild\",\"@isTable\"],[[24,1,[]],[23,\"data\"],false,true,[23,\"isTable\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[24,1,[\"value\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/subject-formatted-field-value.hbs"
    }
  });

  _exports.default = _default;
});