define("@smith-carson/ui/pods/components/sc-light-table/rows/draggable-row/component", ["exports", "ember-light-table/components/lt-row"], function (_exports, _ltRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides a custom `sc-light-table` `DraggableRowComponent` for supporting
      HTML Drag and Drop API on instances of `ember-light-table`.
  
      @module ScLightTable::Rows::DraggableRowComponent
      @extends EmberLightTable.RowComponent
   */
  var _default = _ltRow.default.extend({
    attributeBindings: ['dropzone'],
    classNames: ['draggable'],
    dropzone: 'move'
  });

  _exports.default = _default;
});