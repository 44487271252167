define("js-common/models/db-tag", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    ENV: Ember.computed(function () {
      return Ember.getOwner(this).resolveRegistration('config:environment');
    }),
    name: attr('string'),
    description: attr('string'),
    locked: attr('boolean'),
    parent: belongsTo('db-tag', {
      async: true,
      inverse: 'children'
    }),
    children: hasMany('db-tag', {
      async: true,
      inverse: 'parent'
    }),
    // Special attributes not part of the model added on the serializer
    parentId: attr('number'),
    grandParentId: attr('number'),
    tagCategory: attr('string'),
    // to hold the info passed to db-has-tag
    // computed to show or not on the list
    isCountry: Ember.computed('parentId', 'ENV', function () {
      var ENV = this.ENV;
      return parseInt(this.parentId, 10) === ENV.APP.tags.countryCategory;
    }),
    isState: Ember.computed('parentId', 'ENV', function () {
      var ENV = this.ENV;
      return parseInt(this.parentId, 10) === ENV.APP.tags.stateCategory;
    }),
    isCounty: Ember.computed('grandParentId', 'ENV', function () {
      var ENV = this.ENV;
      return parseInt(this.grandParentId, 10) === ENV.APP.tags.stateCategory;
    }),
    isUSA: Ember.computed('id', 'parentId', 'ENV', function () {
      var ENV = this.ENV;
      return parseInt(this.id, 10) === ENV.APP.tags.USACountry && parseInt(this.parentId, 10) === ENV.APP.tags.countryCategory;
    }),
    isNotOnline: Ember.computed.equal('name', 'Not Online'),
    isNotPublic: Ember.computed.equal('name', 'Not Public'),
    isSubpoenaReleaseRequired: Ember.computed.equal('name', 'Subpoena/Release Required'),
    isFixedCost: Ember.computed.equal('name', 'Fixed Cost'),
    isHidden: Ember.computed('isNotOnline', 'isNotPublic', 'isSubpoenaReleaseRequired', 'isState', 'isCounty', 'isUSA', function () {
      return this.isNotOnline || this.isNotPublic || this.isSubpoenaReleaseRequired || this.isState || this.isCounty || this.isUSA;
    })
  });

  _exports.default = _default;
});