define("@smith-carson/ui/templates/components/sc-analytic-widget/property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RgemhsGw",
    "block": "{\"symbols\":[],\"statements\":[[5,\"sc-circle\",[],[[\"@color\",\"@value\"],[\"#213e47\",[25,[\"chartData\",\"ownersPercent\"]]]]],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"owners_label\"],[9],[0,\"Property Ownership\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"property_value\"],[9],[1,[29,\"format-money\",[[25,[\"chartData\",\"ownershipValue\"]]],[[\"precision\"],[0]]],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"property_label\"],[9],[0,\"Median Value\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-analytic-widget/property.hbs"
    }
  });

  _exports.default = _default;
});