define("js-common/mixins/matter-field-bitmasks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // this list is shared in a few other places until I can figure out javascript constants
  var LOCKED_CASEWIDE = 1;
  var LOCKED_PORTAL = 2; // Client Portal

  var LOCKED_WORKFLOW = 4; // Admin Workflow

  var LOCKED_SUBJECT = 8; // Admin Subject Table

  var LOCKED_REPORT = 16; // Admin Subject Reports

  var LOCKED_ADMIN_SUBJECT_PROFILE = 32; // Admin Subject Reports

  var LOCKED_PORTAL_SUBJECT_TABLE = 64; // Client Portal Subject Table

  var LOCKED_PORTAL_REPORTS = 128; // Client Portal Reports

  var VIEWABLE_PORTAL = 1; // Client Portal

  var VIEWABLE_WORKFLOW = 2; // Admin Workflow

  var VIEWABLE_SUBJECT = 4; // Admin Subject Table

  var VIEWABLE_REPORT = 8; // Admin Subject Reports

  var VIEWABLE_ADMIN_SUBJECT_PROFILE = 16; // Admin Subject Profile

  var VIEWABLE_PORTAL_SUBJECT_TABLE = 32; // Client Portal Subject Table

  var VIEWABLE_PORTAL_REPORTS = 64; // Client Portal Reports

  var VIEWABLE_PORTAL_PROFILE_HEADER = 128; // show on list of fields at the right of name on sc-portal

  var VIEWABLE_PORTAL_SUBJECT_CARD = 256; // show on list of fields at the bottom of box cards on the right side

  var VIEWABLE_LIMITED_PUBLISH = 512; // copy fields to limited profile on fast paced cases

  /**
   * @param row the instance that needs to be changed
   * @param fieldName string the field that will change viewable or locked
   * @param bitMask integer the bit that needs to be added/removed
   * @param value boolean the value of the bit
   */

  function setBitMaskValue(row, fieldName, bitMask, value) {
    var fieldValue = row.get(fieldName);

    if (value) {
      fieldValue |= bitMask;
    } else {
      fieldValue &= ~bitMask;
    }

    row.set(fieldName, fieldValue);
    return value;
  }

  var _default = Ember.Mixin.create({
    /**
     * This mixin requires 3 field on the model mixed:
     * enabled
     * viewable
     * locked
     */
    isEnabledCase: Ember.computed('enabled', {
      get: function get(key) {
        return this.enabled === 1;
      },
      set: function set(key, value) {
        this.set('enabled', value ? 1 : 0);
        return value;
      }
    }),
    isDisabledCase: Ember.computed.not('isEnabledCase'),
    isLockedCase: Ember.computed('locked', {
      get: function get(key) {
        return this.locked & LOCKED_CASEWIDE;
      },
      set: function set(key, value) {
        return setBitMaskValue(this, 'locked', LOCKED_CASEWIDE, value);
      }
    }),
    isLockedPortal: Ember.computed('locked', 'isEnabledCase', {
      get: function get(key) {
        if ((this.locked & LOCKED_PORTAL) !== 0) {
          return 1;
        } else {
          return !this.isEnabledCase;
        }
      },
      set: function set(key, value) {
        return setBitMaskValue(this, 'locked', LOCKED_PORTAL, value);
      }
    }),
    isLockedWorkflow: Ember.computed('locked', 'isEnabledCase', {
      get: function get(key) {
        if ((this.locked & LOCKED_WORKFLOW) !== 0) {
          return 1;
        } else {
          return !this.isEnabledCase;
        }
      },
      set: function set(key, value) {
        return setBitMaskValue(this, 'locked', LOCKED_WORKFLOW, value);
      }
    }),
    isLockedSubject: Ember.computed('locked', 'isEnabledCase', {
      get: function get(key) {
        if ((this.locked & LOCKED_SUBJECT) !== 0) {
          return 1;
        } else {
          return !this.isEnabledCase;
        }
      },
      set: function set(key, value) {
        return setBitMaskValue(this, 'locked', LOCKED_SUBJECT, value);
      }
    }),
    isLockedReport: Ember.computed('locked', 'isEnabledCase', {
      get: function get(key) {
        if ((this.locked & LOCKED_REPORT) !== 0) {
          return 1;
        } else {
          return !this.isEnabledCase;
        }
      },
      set: function set(key, value) {
        return setBitMaskValue(this, 'locked', LOCKED_REPORT, value);
      }
    }),
    isLockedAdminSubjectProfile: Ember.computed('locked', 'isEnabledCase', 'enabled', {
      get: function get(key) {
        if ((this.locked & LOCKED_ADMIN_SUBJECT_PROFILE) !== 0) {
          return 1;
        } else {
          return !this.isEnabledCase;
        }
      },
      set: function set(key, value) {
        return setBitMaskValue(this, 'locked', LOCKED_ADMIN_SUBJECT_PROFILE, value);
      }
    }),
    isLockedPortalSubjectTable: Ember.computed('locked', 'isEnabledCase', 'enabled', {
      get: function get(key) {
        if ((this.locked & LOCKED_PORTAL_SUBJECT_TABLE) !== 0) {
          return 1;
        } else {
          return !this.isEnabledCase;
        }
      },
      set: function set(key, value) {
        return setBitMaskValue(this, 'locked', LOCKED_PORTAL_SUBJECT_TABLE, value);
      }
    }),
    isLockedPortalReport: Ember.computed('locked', 'isEnabledCase', 'enabled', {
      get: function get(key) {
        if ((this.locked & LOCKED_PORTAL_REPORTS) !== 0) {
          return 1;
        } else {
          return !this.isEnabledCase;
        }
      },
      set: function set(key, value) {
        return setBitMaskValue(this, 'locked', LOCKED_PORTAL_REPORTS, value);
      }
    }),
    // subject profile (portal)
    isViewablePortal: Ember.computed('viewable', {
      get: function get(key) {
        return this.viewable & VIEWABLE_PORTAL;
      },
      set: function set(key, value) {
        if (!value) {
          this.set('isViewablePortalSubjectTable', false);
          this.set('isViewablePortalReport', false);
        }

        return setBitMaskValue(this, 'viewable', VIEWABLE_PORTAL, value);
      }
    }),
    isViewableWorkflow: Ember.computed('viewable', {
      get: function get(key) {
        return this.viewable & VIEWABLE_WORKFLOW;
      },
      set: function set(key, value) {
        return setBitMaskValue(this, 'viewable', VIEWABLE_WORKFLOW, value);
      }
    }),
    // subject table (admin)
    isViewableSubject: Ember.computed('viewable', {
      get: function get(key) {
        return this.viewable & VIEWABLE_SUBJECT;
      },
      set: function set(key, value) {
        return setBitMaskValue(this, 'viewable', VIEWABLE_SUBJECT, value);
      }
    }),
    isViewableReport: Ember.computed('viewable', {
      get: function get(key) {
        return this.viewable & VIEWABLE_REPORT;
      },
      set: function set(key, value) {
        return setBitMaskValue(this, 'viewable', VIEWABLE_REPORT, value);
      }
    }),
    // subject profile (admin)
    isViewableAdminSubjectProfile: Ember.computed('viewable', {
      get: function get(key) {
        return this.viewable & VIEWABLE_ADMIN_SUBJECT_PROFILE;
      },
      set: function set(key, value) {
        return setBitMaskValue(this, 'viewable', VIEWABLE_ADMIN_SUBJECT_PROFILE, value);
      }
    }),
    // subject table (portal)
    isViewablePortalSubjectTable: Ember.computed('viewable', {
      get: function get(key) {
        return this.viewable & VIEWABLE_PORTAL_SUBJECT_TABLE;
      },
      set: function set(key, value) {
        return setBitMaskValue(this, 'viewable', VIEWABLE_PORTAL_SUBJECT_TABLE, value);
      }
    }),
    isViewablePortalReport: Ember.computed('viewable', {
      get: function get(key) {
        return this.viewable & VIEWABLE_PORTAL_REPORTS;
      },
      set: function set(key, value) {
        return setBitMaskValue(this, 'viewable', VIEWABLE_PORTAL_REPORTS, value);
      }
    }),
    isViewablePortalProfileHeader: Ember.computed('viewable', {
      get: function get(key) {
        return this.viewable & VIEWABLE_PORTAL_PROFILE_HEADER;
      },
      set: function set(key, value) {
        return setBitMaskValue(this, 'viewable', VIEWABLE_PORTAL_PROFILE_HEADER, value);
      }
    }),
    isViewablePortalSubjectCard: Ember.computed('viewable', {
      get: function get(key) {
        return this.viewable & VIEWABLE_PORTAL_SUBJECT_CARD;
      },
      set: function set(key, value) {
        return setBitMaskValue(this, 'viewable', VIEWABLE_PORTAL_SUBJECT_CARD, value);
      }
    }),
    isViewableLimitedPublish: Ember.computed('viewable', {
      get: function get(key) {
        return this.viewable & VIEWABLE_LIMITED_PUBLISH;
      },
      set: function set(key, value) {
        return setBitMaskValue(this, 'viewable', VIEWABLE_LIMITED_PUBLISH, value);
      }
    }),
    isViewableByClient: Ember.computed('isViewablePortal', 'isViewablePortalSubjectTable', 'isViewableReport', 'isViewablePortalReport', function () {
      return this.get('isViewablePortal') || this.get('isViewablePortalSubjectTable') || this.get('isViewableReport') || this.get('isViewablePortalReport');
    }),
    notAllowModifyPortalSubjectTable: Ember.computed('isViewablePortal', 'isLockedPortalSubjectTable', function () {
      return !this.isViewablePortal || this.isLockedPortalSubjectTable;
    }),
    notAllowModifyPortalReport: Ember.computed('isViewablePortal', 'isLockedPortalReport', function () {
      return !this.isViewablePortal || this.isLockedPortalReport;
    })
  });

  _exports.default = _default;
});