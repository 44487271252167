define("ember-route-helpers/helpers/transition-to-external", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/toArray"], function (_exports, _toConsumableArray2, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Given this "external routes":
   *
   * ```js
   * // host app
   * dependencies: {
   *   externalRoutes: {
   *     posts: 'blog.posts'
   *   }
   * }
   * ```
   *
   * ```js
   * // engine
   * dependencies: {
   *   externalRoutes: [
   *     'posts'
   *   ]
   * }
   * ```
   *
   * You can then use those external routes within a template:
   *
   * ```hbs
   * {{my-component onclick=(transition-to-external 'posts' (query-params id=3 comments=true)}}
   * ```
   */
  var _default = Ember.Helper.extend({
    compute: function compute(_ref) {
      var _ref2 = (0, _toArray2.default)(_ref),
          params = _ref2.slice(0);

      var _params = params.slice();

      var owner = Ember.getOwner(this);
      var router = owner.lookup('router:main');
      var queryParams = _params[_params.length - 1];
      _params[0] = owner._getExternalRoute(_params[0]);

      if (queryParams.isQueryParams) {
        _params[_params.length - 1] = {
          queryParams: queryParams.values
        };
      }

      return function () {
        for (var _len = arguments.length, invocationArgs = new Array(_len), _key = 0; _key < _len; _key++) {
          invocationArgs[_key] = arguments[_key];
        }

        router.transitionTo.apply(router, [].concat((0, _toConsumableArray2.default)(_params), invocationArgs));
      };
    }
  });

  _exports.default = _default;
});