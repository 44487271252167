define("@smith-carson/ui/templates/components/sc-analytic-widget/horizontal-single-stacked-bar-with-legend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lVZAm+aB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"chart\"],[9],[0,\"\\n  \"],[5,\"sc-chart\",[],[[\"@chartType\",\"@chartData\"],[\"horizontal-single-stacked-bar-chart\",[23,\"chartData\"]]]],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"legend\"],[9],[0,\"\\n  \"],[5,\"sc-chart\",[],[[\"@chartType\",\"@chartData\"],[\"legend\",[23,\"chartData\"]]]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-analytic-widget/horizontal-single-stacked-bar-with-legend.hbs"
    }
  });

  _exports.default = _default;
});