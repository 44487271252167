define("@smith-carson/ui/helpers/human-file-size", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "@smith-carson/ui/utils/human-file-size"], function (_exports, _slicedToArray2, _humanFileSize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        value = _ref2[0],
        round = _ref2[1];

    return (0, _humanFileSize.default)(value, round);
  });

  _exports.default = _default;
});