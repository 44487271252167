define("js-common/utils/doc-type-display-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = docTypeDisplayName;
  var displayNameMap = {
    BANK: 'Bank',
    BIRTH: 'Birth',
    CIVIL: 'Civil',
    CLIENT: 'Client',
    'COMP REPT': 'Comp Rept',
    DEATH: 'Death',
    EXPERT: 'Expert',
    'LAW ENFORCEMENT': 'Law Enforcement',
    MEDICAL: 'Medical',
    NEWS: 'News',
    PHOTO: 'Photo',
    QUESTIONNAIRE: 'Questionnaire',
    REPORT: 'Report',
    'REPORT TAB': 'Report Tab',
    'PROFILE PHOTO': 'Profile Photo',
    SCRAPE: 'Scrape',
    TRAFFIC: 'Traffic',
    VEHICLE: 'Vehicle',
    UNCLASSIFIED: 'Unclassified',
    CLIENT_UPLOAD: 'Client Upload',
    CRIM: 'Criminal',
    EDU: 'Education',
    'EMPL/BUS': 'Employment',
    PROP: 'Property',
    'MARITAL/DOMESTIC': 'Marital',
    'OTHER/MISC': 'Other',
    'WEB/SOCNET': 'Social Media'
  };

  function docTypeDisplayName(docType) {
    return displayNameMap[docType] || Ember.String.capitalize(docType.toLowerCase());
  }
});