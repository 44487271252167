define("js-common/models/receivable", ["exports", "ember-data", "ember-api-actions"], function (_exports, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    getFirms: (0, _emberApiActions.collectionAction)({
      path: 'firms',
      type: 'get'
    }),
    getInvoices: (0, _emberApiActions.collectionAction)({
      path: 'invoices',
      type: 'get'
    })
  });

  _exports.default = _default;
});