define("js-common/components/x-preview-document/search-result-preview", ["exports", "js-common/templates/components/x-preview-document/search-result-preview"], function (_exports, _searchResultPreview) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _searchResultPreview.default,
    tagName: 'li'
  });

  _exports.default = _default;
});