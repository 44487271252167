define("js-common/components/list-sort-select", ["exports", "js-common/templates/components/list-sort-select"], function (_exports, _listSortSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _listSortSelect.default,
    initializeComponent: Ember.on('init', function () {
      this.set('sortPropertiesForList', ['isSelected:desc', 'order:asc', this.labelProperty]);
    }),

    /**
     * @property
     * the array of things that the list is going to show
     */
    list: [],

    /**
     * @property
     * the path of the property to show as label
     */
    labelProperty: 'name',

    /**
     * @property
     * the path of the property to set as id
     */
    idProperty: 'id',

    /**
     * @property
     * message to show on the heading of the panel
     */
    message: 'Please select the things you need:',

    /**
     * @property
     * the thing being selected
     */
    thing: 'Things',

    /**
     * @property
     * @private
     * filter search value
     */
    filter: '',
    filterDebounced: '',
    filterOberver: Ember.observer('filter', function () {
      var self = this;
      clearTimeout(this.filterTimer);
      this.set('filterTimer', setTimeout(function () {
        self.set('filterDebounced', self.get('filter'));
      }, 600));
    }),

    /**
     * @computed
     * return the list to display on the UI when someone type in the quick filter
     */
    filteredList: Ember.computed('list', 'filterDebounced', function () {
      var self = this;

      if (Ember.isEmpty(this.list)) {
        return [];
      }

      return this.list.filter(function (item) {
        if (Ember.isPresent(self.get('filterDebounced'))) {
          return item.get(self.get('labelProperty')).toLowerCase().indexOf(self.get('filterDebounced').toLowerCase()) > -1;
        } else {
          return true;
        }
      });
    }),

    /**
     * @property
     * the internal properties of each item on the list array to sort by
     */
    sortPropertiesForList: ['isSelected:desc', 'order:asc'],

    /**
     * @computed
     * the list already sorted
     * to get selected first
     * then the order the user wants
     */
    sortedList: Ember.computed.sort('filteredList', 'sortPropertiesForList'),

    /**
     * bind to the select all items checkbox at the beginning of the list widget
     * when this property changes, it select/unselect the current list of items
     */
    selectAllItemsCheck: false,

    /**
     * observes the checkbox in order to select/unselect the current items
     *
     * @uses selectAllItemsCheck
     */
    changeSelectAllItemCheck: Ember.observer('selectAllItemsCheck', function () {
      var _this = this;

      Ember.run(function () {
        _this.filteredList.setEach('isSelected', _this.selectAllItemsCheck);
      });
    }),

    /**
     * @computed
     * list of selected items
     */
    selectedItems: Ember.computed.filter('list', 'isSelected'),
    actions: {
      clearFilter: function clearFilter() {
        this.set('filter', '');
        this.set('filterDebounced', '');
      },

      /**
       * Action called when the ember-sortable component
       * finished re-ordering a field inside the list of available fields
       * it just set a new order number based on the new order.
       *
       * @param sortedFields Array of Fields already ordered
       * @param draggedModel Field moved
       */
      reorderItems: function reorderItems(sortedItems
      /*, draggedModel */
      ) {
        var order = 1;
        sortedItems.forEach(function (item) {
          return item.set('order', order++);
        });
      },
      orderUp: function orderUp(item) {
        item.set('order', item.get('order') - 1);
      },
      orderDown: function orderDown(item) {
        item.set('order', item.get('order') + 1);
      }
    }
  });

  _exports.default = _default;
});