define("@smith-carson/ui/templates/components/sc-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R+qihG95",
    "block": "{\"symbols\":[\"@title\",\"&default\",\"@color\",\"@placement\"],\"statements\":[[0,\"\\n\"],[5,\"sc-popover\",[[13,\"class\",\"sc-tooltip\"]],[[\"@color\",\"@placement\",\"@triggerEvents\",\"@renderInPlace\",\"@singleton\"],[[29,\"or\",[[24,3,[]],\"white\"],null],[24,4,[]],\"hover\",false,false]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[26,2]],null,{\"statements\":[[0,\"    \"],[15,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[24,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-tooltip.hbs"
    }
  });

  _exports.default = _default;
});