define("js-common/templates/components/subject-custom-modules/dod", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aF/FdLud",
    "block": "{\"symbols\":[\"@field\",\"@value\",\"@changeValue\"],\"statements\":[[4,\"if\",[[29,\"eq\",[[25,[\"state\"]],\"defaultReadOnly\"],null]],null,{\"statements\":[[0,\"    \"],[1,[29,\"moment-format\",[[25,[\"data\",\"subject\",\"dod\"]],\"MMMM Do, YYYY\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[29,\"eq\",[[25,[\"state\"]],\"isEditing\"],null]],null,{\"statements\":[[0,\"    \"],[5,\"subject-edit-field\",[],[[\"@expandOptions\",\"@field\",\"@value\",\"@changeValue\"],[false,[24,1,[]],[24,2,[]],[24,3,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/subject-custom-modules/dod.hbs"
    }
  });

  _exports.default = _default;
});