define("js-common/services/popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    currentComponent: null,
    // bs-popover that is currently opened
    active: function active(component) {
      this.set('currentComponent', component);
    },
    close: function close() {
      this.set('currentComponent', null);
    }
  });

  _exports.default = _default;
});