define("js-common/models/key-attribute-rule", ["exports", "ember-data", "ember-api-actions"], function (_exports, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    matter: belongsTo('matter', {
      async: true
    }),
    field: attr('string'),
    operator: attr('string'),
    compareTo: attr('string'),
    fieldOrValue: attr('string'),
    resultIsFieldOrValue: attr('string'),
    resultFieldValue: attr('string'),
    icon: attr('string'),
    weight: attr('number'),
    filters: (0, _emberApiActions.collectionAction)({
      path: 'filters',
      type: 'get'
    })
  });

  _exports.default = _default;
});