define("@smith-carson/ui/pods/components/light-table/select-all/component", ["exports", "@smith-carson/ui/pods/components/light-table/select-all/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provide affordance for a select all checkbox in the header of a light-table.
  
      @module LightTable::SelectAllComponent
      @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    /**
        Provides a boolean flag indicating the state of the table's `rows`
        collection `selected` values.
         @property allSelected
        @type {Boolean}
     */
    allSelected: Ember.computed('table.rows.@each.selected', function () {
      var rows = this.get('table.rows'); // `every` returns `true` for an empty `Array`

      if (Ember.isEmpty(rows)) {
        return false;
      }

      return rows.every(function (row) {
        return row.selected;
      });
    }),
    actions: {
      /**
          Toggle the `selected` and `content.selected` values for all rows in
          the ember-light-table.
           @method toggleAllSelected
          @param {jQuery.Event} event
       */
      toggleAllSelected: function toggleAllSelected(event) {
        event.stopPropagation();
        var rows = this.get('table.rows');
        var isChecked = event.target.checked;
        rows.forEach(function (row) {
          return row.setProperties({
            'content.selected': isChecked,
            selected: isChecked
          });
        });
      }
    }
  });

  _exports.default = _default;
});