define("js-common/models/list-template-group", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    name: attr('string'),
    matterType: belongsTo('matter-type', {
      async: true
    }),
    listTemplates: hasMany('list-template', {
      async: true
    }),
    matterTypeWritable: Ember.computed('matterType', {
      get: function get(key) {
        return this.get('matterType.content');
      },
      set: function set(key, value) {
        this.set('matterType', value);
        return value;
      }
    })
  });

  _exports.default = _default;
});