define("js-common/components/general-tooltip", ["exports", "js-common/templates/components/general-tooltip"], function (_exports, _generalTooltip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _generalTooltip.default,
    tagName: 'span',
    classNames: ['badge bg-light-blue'],
    content: 'Help',
    position: 'top'
  });

  _exports.default = _default;
});