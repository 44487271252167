define("ember-cli-chartjs/templates/components/ember-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ss4GMqrz",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"button\"],[11,\"class\",\"chart-back\"],[12,\"style\",[23,\"buttonDisplay\"]],[9],[1,[23,\"backText\"],false],[3,\"action\",[[24,0,[]],\"backAction\"],[[\"bubbles\"],[false]]],[10],[0,\"\\n\\n\"],[7,\"canvas\"],[12,\"id\",[30,[[23,\"id\"]]]],[12,\"width\",[30,[[23,\"width\"]]]],[12,\"height\",[30,[[23,\"height\"]]]],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-chartjs/templates/components/ember-chart.hbs"
    }
  });

  _exports.default = _default;
});