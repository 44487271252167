define("js-common/mixins/data-entry-parser", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    eventTypeParentName: function eventTypeParentName(fieldValue) {
      if (fieldValue == 'death_information') {
        return 'Death';
      } else if (fieldValue == 'residential') {
        return 'Residence';
      } else {
        return fieldValue.capitalize();
      }
    },
    getEventType: function getEventType(timelineEventType, eventParentName, eventDetails) {
      switch (eventParentName.toUpperCase()) {
        case 'EDUCATION':
          if (eventDetails.toUpperCase().includes('ATTENDED')) {
            return timelineEventType.filterBy('name', 'Attended');
          } else if (eventDetails.toUpperCase().includes('GRADUATED')) {
            return timelineEventType.filterBy('name', 'Degree (unspecified)');
          } else if (eventDetails.toUpperCase().includes('PHD')) {
            return timelineEventType.filterBy('name', 'PhD degree');
          } else if (eventDetails.toUpperCase().includes('ASSOCIATE')) {
            return timelineEventType.filterBy('name', "Associate's degree");
          } else if (eventDetails.toUpperCase().includes('BACHELOR')) {
            return timelineEventType.filterBy('name', "Bachelor's degree");
          } else if (eventDetails.toUpperCase().includes('MASTER')) {
            return timelineEventType.filterBy('name', "Master's degree");
          } else if (eventDetails.toUpperCase().includes('HIGH') || eventDetails.toUpperCase().includes('DIPLOMA')) {
            return timelineEventType.filterBy('name', 'High School Diploma');
          } else {
            return timelineEventType.filterBy('name', 'Attended');
          }

        case 'BANKRUPTCY':
          if (eventDetails.toUpperCase().includes('CHAPTER 7')) {
            return timelineEventType.filterBy('name', 'Chapter 7');
          } else if (eventDetails.toUpperCase().includes('CHAPTER 13')) {
            return timelineEventType.filterBy('name', 'Chapter 13');
          } else {
            return timelineEventType.filterBy('name', 'Unspecified');
          }

        case 'EMPLOYMENT':
          if (eventDetails.toUpperCase().includes('MILITARY')) {
            return timelineEventType.filterBy('name', 'Military service');
          } else if (eventDetails.toUpperCase().includes('LICENCE')) {
            return timelineEventType.filterBy('name', 'Professional License');
          } else if (eventDetails.toUpperCase().includes('WORKERS')) {
            return timelineEventType.filterBy('name', 'Workers Compensation');
          } else if (eventDetails.toUpperCase().includes('BUSINESS')) {
            return timelineEventType.filterBy('name', 'Business Incorporation');
          } else {
            return timelineEventType.filterBy('name', 'Employed');
          }

        case 'TRAFFIC':
          if ((eventDetails.toUpperCase().includes('DL') || eventDetails.toUpperCase().includes('LICENCE')) && eventDetails.toUpperCase().includes('NO ACCIDENT') || eventDetails.toUpperCase().includes('NO ACCIDENTS OR VIOLATIONS') || eventDetails.toUpperCase().includes('NO VIOLATION')) {
            return timelineEventType.filterBy('name', "Driver's license");
          } else if (eventDetails.toUpperCase().includes('ACCIDENT')) {
            return timelineEventType.filterBy('name', 'Traffic accident');
          } else if (eventDetails.toUpperCase().includes('VIOLATION')) {
            return timelineEventType.filterBy('name', 'Traffic case');
          } else {
            return timelineEventType.filterBy('name', 'Traffic case');
          }

        case 'MARITAL':
          if (eventDetails.toUpperCase().includes('ABUSE')) {
            return timelineEventType.filterBy('name', 'Abuse');
          } else if (eventDetails.toUpperCase().includes('CHILD')) {
            return timelineEventType.filterBy('name', 'Child Support');
          } else if (eventDetails.toUpperCase().includes('COHABITAT')) {
            return timelineEventType.filterBy('name', 'Cohabitation');
          } else if (eventDetails.toUpperCase().includes('DIVORCE')) {
            return timelineEventType.filterBy('name', 'Divorce');
          } else if (eventDetails.toUpperCase().includes('SEPARAT')) {
            return timelineEventType.filterBy('name', 'Separation');
          } else {
            return timelineEventType.filterBy('name', 'Marriage');
          }

        case 'CIVIL':
          if (eventDetails.toUpperCase().includes('FORECLOSURE')) {
            return timelineEventType.filterBy('name', 'Foreclosure');
          } else if (eventDetails.toUpperCase().includes('COLLECTIONS')) {
            return timelineEventType.filterBy('name', 'Collections');
          } else if (eventDetails.toUpperCase().includes('CONTRACT')) {
            return timelineEventType.filterBy('name', 'Contract');
          } else if (eventDetails.toUpperCase().includes('PRODUCT')) {
            return timelineEventType.filterBy('name', 'Product Liability');
          } else if (eventDetails.toUpperCase().includes('VIOLENCE')) {
            return timelineEventType.filterBy('name', 'Repeat Violence');
          } else {
            return timelineEventType;
          }

        case 'CRIMINAL':
          if (eventDetails.toUpperCase().includes('ASSAULT')) {
            return timelineEventType.filterBy('name', 'Assault');
          } else if (eventDetails.toUpperCase().includes('BATTERY')) {
            return timelineEventType.filterBy('name', 'Battery');
          } else if (eventDetails.toUpperCase().includes('BURGLARY')) {
            return timelineEventType.filterBy('name', 'Burglary');
          } else if (eventDetails.toUpperCase().includes('CRIMINAL')) {
            return timelineEventType.filterBy('name', 'Criminal (traffic-related)');
          } else if (eventDetails.toUpperCase().includes('DISORDERLY')) {
            return timelineEventType.filterBy('name', 'Disorderly conduct');
          } else if (eventDetails.toUpperCase().includes('DRUG')) {
            return timelineEventType.filterBy('name', 'Drug-related');
          } else if (eventDetails.toUpperCase().includes('DUI')) {
            return timelineEventType.filterBy('name', 'DUI');
          } else if (eventDetails.toUpperCase().includes('FRAUD')) {
            return timelineEventType.filterBy('name', 'Fraud');
          } else if (eventDetails.toUpperCase().includes('PUBLIC INTOXICATION')) {
            return timelineEventType.filterBy('name', 'Public intoxication');
          } else if (eventDetails.toUpperCase().includes('RESISTING ARREST')) {
            return timelineEventType.filterBy('name', 'Resisting arrest');
          } else if (eventDetails.toUpperCase().includes('ROBBERY')) {
            return timelineEventType.filterBy('name', 'Robbery');
          } else if (eventDetails.toUpperCase().includes('THEFT')) {
            return timelineEventType.filterBy('name', 'Theft');
          } else if (eventDetails.toUpperCase().includes('TRESPASSING')) {
            return timelineEventType.filterBy('name', 'Trespassing');
          } else {
            return timelineEventType.filterBy('name', 'Unknown');
          }

        default:
          return timelineEventType;
      }
    },
    parseTextToTable: function parseTextToTable(text, parserFormat, copiedSubject) {
      String.prototype.splice = function (idx, rem, str) {
        return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
      };

      var parsedData = [],
          metaResources = [];
      var selectedFormat = parserFormat.get('firstObject.id');
      var parseExp = new RegExp(parserFormat.get('firstObject.parseFormat'));

      switch (selectedFormat) {
        case '1':
        case '2':
        case '3':
          text.trim().split('\n\n').map(function (data) {
            var gridData = {};
            var id = id + 1;
            var m = parseExp.exec(data);
            gridData = m.groups;
            gridData.id = id;

            if (gridData.schoolDetails.toUpperCase().includes('ATTENDED')) {
              gridData.school = gridData.schoolDetails.trim().substring(9).trim();
            } else {
              gridData.school = gridData.schoolDetails.split('From')[1].trim();
            }

            metaResources.push({
              key: 'timeline_education_school',
              value: gridData.school
            });
            gridData.metaResources = JSON.stringify(metaResources);
            gridData.state = gridData.state.trim();
            gridData.fullText = data;
            gridData.postToSubject = !copiedSubject;
            gridData.postToTimeline = true;
            parsedData.push(gridData);
          });
          return parsedData;

        case '4':
        case '5':
        case '6':
          text.trim().split('\n\n').map(function (data) {
            var gridData = {};
            var id = id + 1;
            var m = parseExp.exec(data);
            gridData = m.groups;
            gridData.id = id;
            gridData.fullText = data;
            gridData.postToSubject = !copiedSubject;
            gridData.postToTimeline = true;
            parsedData.push(gridData);
          });
          return parsedData;
        // Employment History Formats -Stores Employer & Employment Position or Title

        case '7':
        case '38':
          text.trim().split('\n\n').map(function (data) {
            var gridData = {};
            var id = id + 1;
            var m = parseExp.exec(data);
            gridData = m.groups;
            gridData.id = id;
            metaResources.push({
              key: 'timeline_employment_employer',
              value: gridData.employer
            });
            metaResources.push({
              key: 'timeline_employment_position',
              value: gridData.title
            });
            gridData.fullText = data;
            gridData.metaResources = JSON.stringify(metaResources);
            gridData.postToSubject = !copiedSubject;
            gridData.postToTimeline = true;
            parsedData.push(gridData);
          });
          return parsedData;

        /**
         *  Birth Notes Formats
         * */

        case '10':
        case '11':
          text.trim().split('\n\n').map(function (data) {
            var gridData = {};
            var id = id + 1;
            var m = parseExp.exec(data);
            gridData = m.groups;
            gridData.id = id;
            gridData.startDate = _moment.default.utc(gridData.startDate).format('YYYY-MM-DD');
            gridData.fullText = data;
            gridData.postToSubject = !copiedSubject;
            gridData.postToTimeline = true;
            parsedData.push(gridData);
          });
          return parsedData;

        /**
         * Death Notes Formats
         *  */

        case '12':
        case '13':
        case '14':
          text.trim().split('\n\n').map(function (data) {
            var gridData = {};
            var id = id + 1;
            var m = parseExp.exec(data);
            gridData = m.groups;
            gridData.id = id;
            gridData.startDate = _moment.default.utc(gridData.startDate).format('YYYY-MM-DD');
            gridData.fullText = data;
            metaResources.push({
              key: 'timeline_death_cause',
              value: gridData.details
            });
            gridData.metaResources = JSON.stringify(metaResources);
            gridData.postToSubject = !copiedSubject;
            gridData.postToTimeline = true;
            parsedData.push(gridData);
          });
          return parsedData;

        /**
         * Marital History Formats
         */

        case '15':
        case '16':
        case '17':
        case '18':
        case '19':
        case '37':
          text.trim().split('\n\n').map(function (data) {
            var gridData = {};
            var id = id + 1;
            var m = parseExp.exec(data);
            gridData = m.groups;
            gridData.id = id;

            if (gridData.startDate) {
              gridData.startDate = _moment.default.utc(gridData.startDate).format('YYYY-MM-DD');
            }

            if (gridData.startMonth && gridData.startDay) {
              var startDate = gridData.startMonth.trim() + ',' + gridData.startDay.trim() + ',' + gridData.startYear.trim();
              gridData.startMonth = _moment.default.utc(startDate).format('M');
            }

            if (gridData.startMonth && !gridData.startDay) {
              var _startDate = gridData.startMonth.trim() + ', 1,' + gridData.startYear.trim();

              gridData.startMonth = _moment.default.utc(_startDate).format('M');
            }

            gridData.fullText = data;
            gridData.postToSubject = !copiedSubject;
            gridData.postToTimeline = true;
            parsedData.push(gridData);
            return;
          });
          return parsedData;

        /**
         * Criminal & Civil - Court Name Formats */

        case '8':
        case '22':
        case '23':
        case '25':
        case '26':
        case '27':
        case '28':
          text.trim().split('\n\n').map(function (entry) {
            var courtName,
                state,
                county,
                courtText,
                district = '';
            entry.split('\n').map(function (data, index) {
              var gridData = {};

              if (index == 0) {
                if (data.includes('District of')) {
                  courtText = data;
                  var parts = data.split('District of');
                  district = parts[0].trim();
                  state = parts[1].split(':')[0].trim();
                } else {
                  courtText = data;

                  var _parts = data.split(',');

                  courtName = _parts[0].trim();
                  county = _parts[1].trim().split('County')[0].trim();
                  state = _parts[2].split(':')[0].trim();
                }
              } else {
                var id = id + 1;
                var key, value;
                var m = parseExp.exec(data);
                gridData = m.groups;
                gridData.id = id;
                gridData.courtName = courtName;
                gridData.county = county;
                gridData.district = district;
                gridData.state = state;

                if (gridData.startMonth) {
                  var startDate = gridData.startMonth.trim() + ',' + gridData.startDay.trim() + ',' + gridData.startYear.trim();
                  gridData.startMonth = _moment.default.utc(startDate).format('M');
                }

                if (selectedFormat !== '28') {
                  if (data.toUpperCase().includes('MISDEMEANOR')) {
                    key = 'timeline_criminal_role';
                    value = 'Misdemeanor';
                  } else if (data.toUpperCase().includes('FELONY')) {
                    key = 'timeline_criminal_role';
                    value = 'Felony';
                  } else if (data.toUpperCase().includes('PLAINTIFF')) {
                    key = 'timeline_civil_role';
                    value = 'Plaintiff';
                  } else if (data.toUpperCase().includes('DEFENDANT')) {
                    key = 'timeline_civil_role';
                    value = 'Defendant';
                  } else {
                    if (selectedFormat == '22' || selectedFormat == '23') {
                      key = 'timeline_criminal_role';
                      value = 'Unspecified';
                    } else {
                      key = 'timeline_civil_role';
                      value = 'Unspecified';
                    }
                  }

                  metaResources.push({
                    key: key,
                    value: value
                  });
                  gridData.metaResources = JSON.stringify(metaResources);
                }

                gridData.fullText = courtText + '\n' + data;
                gridData.postToSubject = !copiedSubject;
                gridData.postToTimeline = true;
                parsedData.push(gridData);
              }
            });
          });
          return parsedData;

        case '24':
          text.trim().split('\n\n').map(function (data) {
            var gridData = {};
            var id = id + 1;
            var key, value;
            var m = parseExp.exec(data);
            gridData = m.groups;
            gridData.id = id;

            if (data.toUpperCase().includes('MISDEMEANOR')) {
              key = 'timeline_criminal_role';
              value = 'Misdemeanor';
            } else if (data.toUpperCase().includes('FELONY')) {
              key = 'timeline_criminal_role';
              value = 'Felony';
            } else {
              key = 'timeline_criminal_role';
              value = 'Unspecified';
            }

            metaResources.push({
              key: key,
              value: value
            });
            gridData.metaResources = JSON.stringify(metaResources);
            gridData.startDate = _moment.default.utc(gridData.startDate).format('YYYY-MM-DD');
            gridData.fullText = data;
            gridData.postToSubject = !copiedSubject;
            gridData.postToTimeline = true;
            parsedData.push(gridData);
          });
          return parsedData;
        // Bankruptcy Formats

        case '9':
        case '29':
        case '30':
        case '31':
          text.trim().split('\n\n').map(function (entry) {
            var district,
                state,
                bankruptcyText,
                city = '';
            entry.split('\n').map(function (data, index) {
              var gridData = {};

              if (index == 0) {
                bankruptcyText = data;
                var parts = data.split('District of');
                district = parts[0].trim();
                city = parts[1].split(',')[1].split(':')[0].trim();
                state = parts[1].split(',')[0].trim();
              } else if (index == 1) {
                var id = id + 1;
                var m = parseExp.exec(data);
                gridData = m.groups;
                gridData.id = id;
                gridData.district = district;
                gridData.state = state;
                gridData.city = city;

                if (gridData.startMonth) {
                  var startDate = gridData.startMonth.trim() + ',' + gridData.startDay.trim() + ',' + gridData.startYear.trim();
                  gridData.startMonth = _moment.default.utc(startDate).format('M');
                }

                gridData.fullText = bankruptcyText + '\n' + data;
                gridData.postToSubject = !copiedSubject;
                gridData.postToTimeline = true;
                parsedData.push(gridData);
              } else {
                gridData.debtDetails = data;
              }
            });
          });
          return parsedData;
        // Traffic History Formats

        case '20':
        case '21':
        case '32':
        case '33':
        case '34':
        case '35':
        case '36':
          text.trim().split('\n\n').map(function (data) {
            var gridData = {};
            var id = id + 1;
            var m = parseExp.exec(data);
            gridData = m.groups;
            gridData.id = id;
            gridData.fullText = data;

            if (gridData.startDate) {
              gridData.startDate = _moment.default.utc(gridData.startDate).format('YYYY-MM-DD');
            }

            gridData.postToSubject = !copiedSubject;
            gridData.postToTimeline = true;
            parsedData.push(gridData);
          });
          return parsedData;

        default:
          return parsedData;
      }
    }
  });

  _exports.default = _default;
});