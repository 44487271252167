define("js-common/helpers/max-date", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.maxDate = maxDate;
  _exports.default = void 0;

  function maxDate(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        date = _ref2[0];

    return date.toString().includes('Dec 31 2999');
  }

  var _default = Ember.Helper.helper(maxDate);

  _exports.default = _default;
});