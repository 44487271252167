define("js-common/transforms/utcdate", ["exports", "ember-data/transform", "moment"], function (_exports, _transform, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * this module do the correct transformation
   * between the UTC date coming from the server
   * to a date in js so it can be used by ember
   * without losing the Time Zone information
   */
  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      if (serialized) {
        return (0, _moment.default)(serialized + ' +00:00', 'YYYY-MM-DD HH:mm:ss Z').toDate();
      }

      return serialized;
    },
    serialize: function serialize(deserialized) {
      if (deserialized) {
        return (0, _moment.default)(deserialized).utc().format('YYYY-MM-DD HH:mm:ss');
      }

      return deserialized;
    }
  });

  _exports.default = _default;
});