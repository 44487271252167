define("js-common/models/invoice-group", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    primary: (0, _attr.default)('number'),
    hasInvoices: (0, _attr.default)('boolean'),
    // relationships
    invoiceGroupMembers: (0, _relationships.hasMany)('invoice-group-member', {
      async: false
    }),
    billables: (0, _relationships.hasMany)('billable', {
      async: false
    }),
    matter: (0, _relationships.belongsTo)('matter', {
      async: false
    })
  });

  _exports.default = _default;
});