define('ember-cli-google-recaptcha/services/g-recaptcha', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var ENV = Ember.getOwner(this).resolveRegistration('config:environment');
      this.set('_siteKey', Ember.get(ENV, 'ember-cli-google.recaptcha.siteKey'));
    },


    /// Site key for the application.
    siteKey: Ember.computed.readOnly('_siteKey'),

    /**
     * Renders the container as a reCAPTCHA widget and returns the ID of the newly
     * created widget.
     *
     * @param container
     * @param params
     * @returns {RSVP.Promise|*}
     */
    render: function render(container, params) {
      var siteKey = this.get('siteKey');
      var options = Ember.merge({ sitekey: siteKey }, params);

      return this.getInstance().then(function (grecaptcha) {
        return grecaptcha.render(container, options);
      });
    },


    /**
     * Manually invoke the reCAPTCHA check. Used if the invisible reCAPTCHA is on a
     * div instead of a button.
     *
     * @param widgetId
     * @returns {RSVP.Promise|*}
     */
    execute: function execute(widgetId) {
      return this.getInstance().then(function (grecaptcha) {
        return grecaptcha.execute(widgetId);
      });
    },


    /**
     * Resets the reCAPTCHA widget.
     *
     * @param widgetId
     * @returns {RSVP.Promise|*}
     */
    reset: function reset(widgetId) {
      return this.getInstance().then(function (grecaptcha) {
        return grecaptcha.reset(widgetId);
      });
    },


    /**
     * Gets the response for the reCAPTCHA widget.
     *
     * @param widgetId
     * @returns {RSVP.Promise|*}
     */
    getResponse: function getResponse(widgetId) {
      return this.getInstance().then(function (grecaptcha) {
        return grecaptcha.getResponse(widgetId);
      });
    },
    getInstance: function getInstance() {
      if (Ember.isPresent(this._instance)) {
        return this._instance;
      }

      this._instance = new Ember.RSVP.Promise(function (resolve, reject) {
        // This is for Fastboot support.
        if (Ember.isNone(window) || Ember.isNone(window.document)) {
          return resolve();
        }

        window._grecaptcha_onload = function () {
          return resolve(window.grecaptcha);
        };

        // Load the recaptcha script from Google. We do not use jQuery because it is
        // easier and faster to load the script manually by injecting the script tag
        // into the head.
        var script = document.createElement('script');
        script.onerror = function (err) {
          return reject(err);
        };

        script.defer = true;
        script.async = true;
        script.src = 'https://www.google.com/recaptcha/api.js?onload=_grecaptcha_onload&render=explicit';

        var head = document.querySelector('head');
        head.appendChild(script);
      });

      return this._instance;
    }
  });
});